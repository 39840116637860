import { GridColumnModel } from "./project-grid-columns";
export const projectMilestoneColumnStatus = "status";
export const projectMilestoneColumnCollaborator = "collaborator";
export const projectMilestoneColumnOrderLine = "orderLineNumber";
export const projectMilestoneColumnMilestone = "milestone";
export const projectMilestoneColumnPredecessor = "predecessor";
export const projectMilestoneColumnWbs = "wbs";
export const projectMilestoneCompletionDate = "completionDate";
export const projectMilestoneStartDate = "startDate";
export const projectMilestoneDueDate = "dueDate";

export const projectMilestoneColumns: GridColumnModel[] = [
  {
    id: "wbsEmpty",
    value: " ",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "50px",
    widthNum: 50,
    orderNo: 1,
    align: "center",
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 0,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: "wbs",
    value: "Milestone",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "115px",
    widthNum: 115,
    orderNo: 2,
    align: "center",
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 1,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: projectMilestoneColumnMilestone,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "350px",
    widthNum: 350,
    orderNo: 3,
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 0,
    canHide: true,
    frozenPermanent: true,
  },
  {
    id: projectMilestoneColumnOrderLine,
    value: "Order Line Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "350px",
    widthNum: 350,
    orderNo: 4,
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 2,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: "projectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 5,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 3,
    canHide: true,
  },
  {
    id: "orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 6,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 4,
    canHide: true,
  },
  {
    id: projectMilestoneColumnStatus,
    value: "Milestone Status",
    selected: false,
    orderBy: "status",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 7,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 5,
    canHide: false,
  },
  {
    id: projectMilestoneColumnCollaborator,
    value: "Contributors",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "160px",
    widthNum: 160,
    orderNo: 8,
    isColumnNotReordable: false,
    isFlexWidth: false,
    orderNoShowHide: 6,
    canHide: false,
  },
  {
    id: "duration",
    value: "Duration",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 9,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 7,
    canHide: true,
  },
  {
    id: projectMilestoneStartDate,
    value: "Start Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 10,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 8,
    canHide: false,
  },
  {
    id: projectMilestoneDueDate,
    value: "Due Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 11,
    isColumnNotReordable: false,
    isFlexWidth: true,
    orderNoShowHide: 9,
    canHide: false,
  },
  {
    id: "completionDate",
    value: "Completion Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 12,
    isColumnNotReordable: false,
    isFlexWidth: false,
    orderNoShowHide: 10,
    canHide: true,
  },
  {
    id: "predecessor",
    value: "Predecessors",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "125px",
    widthNum: 125,
    orderNo: 13,
    isColumnNotReordable: false,
    isFlexWidth: false,
    orderNoShowHide: 11,
    canHide: true,
  },
];
