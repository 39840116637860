import { ResultModel } from "../models/result.model";
import { GetNewProjectLinesECDAdjustmentsModel } from "../shared/models/service-models/GetNewProjectLinesECDAdjustmentsModel";
import { GetNewProjectLinesECDAdjustmentsResultModel } from "../shared/models/service-models/GetNewProjectLinesECDAdjustmentsResultModel";
import { RemoveTimestampProjectLineMilestonesModel } from "../shared/models/service-models/RemoveTimestampProjectLineMilestonesModel";
import { RemoveTimestampResultModel } from "../shared/models/service-models/RemoveTimestampResultModel";
import { TimestampProjectLineMilestonesModel } from "../shared/models/service-models/TimestampProjectLineMilestones.model";
import { TimestampResultModel } from "../shared/models/service-models/TimestampResultModel";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;
const ISTIMESTAMPNEWRULE =
  process.env.REACT_APP_FEATUREFLAG_IS_MULTIPLE_PREDECESSOR === "true";
let TIMESTAMP_ENDPOINT = "/api/project-timestamp/timestamp-milestones";
let TIMESTAMP_ECD_ADJUSTMENT_ENDPOINT =
  "/api/project-timestamp/get-new-project-lines-ecd-adjustments";
let TIMESTAMP_REMOVE_ENDPOINT =
  "/api/project-timestamp/remove-timestamp-milestones";

if (ISTIMESTAMPNEWRULE) {
  TIMESTAMP_ENDPOINT = "/api/project-timestamp/timestamp-milestones-v2";
  TIMESTAMP_ECD_ADJUSTMENT_ENDPOINT =
    "/api/project-timestamp/get-new-project-lines-ecd-adjustments-v2";
}

const ProjectTimestampService = {
  timestampMilestones: async (model: TimestampProjectLineMilestonesModel) =>
    (await RequestFactory.post(
      GMAE2EURL,
      TIMESTAMP_ENDPOINT,
      model
    )) as Promise<ResultModel<TimestampResultModel>>,

  getNewProjectLinesECDAdjustments: async (
    model: GetNewProjectLinesECDAdjustmentsModel
  ) =>
    (await RequestFactory.post(
      GMAE2EURL,
      TIMESTAMP_ECD_ADJUSTMENT_ENDPOINT,
      model
    )) as Promise<ResultModel<GetNewProjectLinesECDAdjustmentsResultModel>>,

  removeTimestampMIlestones: async (
    model: RemoveTimestampProjectLineMilestonesModel
  ) =>
    (await RequestFactory.post(
      GMAE2EURL,
      TIMESTAMP_REMOVE_ENDPOINT,
      model
    )) as Promise<ResultModel<RemoveTimestampResultModel[]>>,
};

export default ProjectTimestampService;
