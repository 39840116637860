import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import { Calendar, CalendarDateTemplateParams } from "primereact/calendar";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateOrderlineECD } from "../../../../../features/projectManagement/projectManagementSlice";
import useToastr from "../../../../../hooks/useToastr";
import ProjectService from "../../../../../services/ProjectService";
import { enumerateDaysBetweenDates } from "../../../../../shared/date-helper/DateHelper";
import { E2EProjectLineECDAdjustmentModel } from "../../../../../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import ProjectOrderlineStatus from "../../enums/project-orderline-status";
import PmrE2EProjectDetailsOrderlineModel, {
  getProjectLineIsCompletedV2,
} from "../../interfaces/pmr-e2e-project-details-orderline-model";
import PmrE2EProjectModel from "../../interfaces/pmr-e2e-project-model";
import ECDAdjustmentsConfirmationDialog from "../ecd-adjustments-confirmation-dialog/ECDAdjustmentsConfirmationDialog";
import classes from "./ProjectOrderlineEcdTemplate.module.scss";

type Props = {
  orderlineDetail: PmrE2EProjectDetailsOrderlineModel;
  project: PmrE2EProjectModel;
  reloadList: () => void;
};

export default function ProjectOrderlineEcdTemplate({
  orderlineDetail,
  project,
  reloadList,
}: Props) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [
    eCDAdjustmentsConfirmationDialogVisible,
    setECDAdjustmentsConfirmationDialogVisible,
  ] = useState(false);
  const [adjustedECD, setNewAdjustedECD] = useState<Date | null>(null);
  const currentEcd = new Date(orderlineDetail.orderLineECD || "");
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccess, showError } = useToastr();
  const dispatch = useDispatch();

  const handleECDClick = () => {
    if (!isEditable) return;

    setNewAdjustedECD(currentEcd);
    setIsEditMode(true);

    setTimeout(() => {
      setIsCalendarVisible(true);
    });
  };

  useEffect(() => {
    if (!isEditMode) setIsCalendarVisible(false);
  }, [isEditMode]);

  const projectBookedDate = new Date(orderlineDetail.flexProjectBookedDate);

  const calendarStartDate = new Date(2020, 0, 1);
  const calendarEndDate = new Date(2030, 11, 31);

  const datesEarlierThanProjectBookedDate = useMemo(() => {
    const datesResult = enumerateDaysBetweenDates(
      calendarStartDate,
      projectBookedDate
    );

    return datesResult;
  }, [orderlineDetail.flexProjectBookedDate]);

  const calendarWeekendDates = useMemo(() => {
    const datesResult = enumerateDaysBetweenDates(
      calendarStartDate,
      calendarEndDate
    );

    return datesResult.filter((date) => {
      const day = date.getDay();

      return day === 0 || day === 6;
    });
  }, []);

  const ecdAdjustments: E2EProjectLineECDAdjustmentModel[] = [
    {
      e2EProjectLineId: orderlineDetail.id,
      orderlineDescription: orderlineDetail.orderLineNumber!,
      currentECD: currentEcd,
      adjustedECD: adjustedECD!,
      e2EProjectName: project.name,
    },
  ];

  const dateTemplate = (e: CalendarDateTemplateParams) => {
    const isEarlierThanProjectBookedDate =
      new Date(e.year, e.month, e.day) <
      new Date(
        projectBookedDate.getFullYear(),
        projectBookedDate.getMonth(),
        projectBookedDate.getDate()
      );

    const title = isEarlierThanProjectBookedDate
      ? "Cannot select Dates earlier than the selected Order Line's Order Booked Date."
      : "";

    return (
      <span
        className={clsx(
          isEarlierThanProjectBookedDate && classes["not-allowed"]
        )}
        title={title}
      >
        {e.day}
      </span>
    );
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const result = await ProjectService.updateOrderlineECD({
        e2EProjectLineId: orderlineDetail.id,
        newECD: moment(adjustedECD).format("yyyy-MM-DD"),
      });

      if (!result.isSuccess) return showError("Error", result.message);

      const message = `Order Line ECD updated successfully for ${orderlineDetail.orderLineNumber}. Schedule recalibrated.`;
      showSuccess("Order Line ECD updated", message);
      setECDAdjustmentsConfirmationDialogVisible(false);
      setIsEditMode(false);
      dispatch(
        updateOrderlineECD({
          id: orderlineDetail.id,
          newOrderLineECD: moment(adjustedECD).format("DD MMM yyyy"),
        })
      );
      reloadList();
    } catch {
      showError(
        "Error",
        "There is an error processing your request. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isEditable =
    orderlineDetail.orderLineStatus !== ProjectOrderlineStatus.Completed;

  const ecdAdjustmentsTableHeader = (
    <>
      Editing the Order Line ECD will trigger a recalibration of this order
      line's schedule. <br /> <br /> Do you want to proceed?
    </>
  );

  return (
    <>
      {isEditMode && (
        <Calendar
          dateTemplate={dateTemplate}
          dateFormat="dd M yy"
          disabledDates={[
            ...datesEarlierThanProjectBookedDate,
            ...calendarWeekendDates,
            currentEcd,
          ]}
          visible={isCalendarVisible}
          onVisibleChange={(e) => {
            if (e.visible) setIsCalendarVisible(true);
            if (
              e.type === "outside" &&
              !eCDAdjustmentsConfirmationDialogVisible
            )
              setIsCalendarVisible(false);
          }}
          value={adjustedECD!}
          onChange={(e) => {
            setNewAdjustedECD(e.value as Date);
            setECDAdjustmentsConfirmationDialogVisible(true);
          }}
          monthNavigator={true}
          yearNavigator={true}
          yearRange={`${calendarStartDate.getFullYear()}:${calendarEndDate.getFullYear()}`}
          panelClassName={classes["calendar-panel"]}
          onHide={() => setIsEditMode(false)}
        />
      )}

      {!isEditMode && orderlineDetail.orderLineECD && (
        <button
          title={
            !isEditable
              ? "ECD of a Completed Order Line cannot be updated/edited"
              : ""
          }
          className={clsx(
            classes["ecd"],
            !isEditable && classes["not-allowed"],
            getProjectLineIsCompletedV2(orderlineDetail.orderLineStatus) &&
              classes["project-line-is-completed"]
          )}
          onClick={handleECDClick}
        >
          {orderlineDetail.orderLineECD}
        </button>
      )}
      {!isEditMode && !orderlineDetail.orderLineECD && (
        <div className={classes["calendar-container"]} onClick={handleECDClick}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            title="Click to set an ECD for this Order Line."
          />
        </div>
      )}

      <ECDAdjustmentsConfirmationDialog
        isLoading={isLoading}
        tableHeader={ecdAdjustmentsTableHeader}
        customConfirmationMessage="Do you want to proceed?"
        forceProjectNameColumnDisplay={true}
        e2eProjectLineECDAdjustments={ecdAdjustments}
        visible={eCDAdjustmentsConfirmationDialogVisible}
        onCancel={() => setECDAdjustmentsConfirmationDialogVisible(false)}
        onConfirm={handleConfirm}
        isKeepButtonVisible={false}
        isCancelButtonVisible={true}
      />
    </>
  );
}
