import { Badge } from "primereact/badge";
import classes from "./ProjectSingleCollaboratorAvatarReassign.module.scss";
import clsx from "clsx";
import HandlerType from "../../enums/handler-type";
import { useEffect, useState } from "react";
import ProjectCollaboratorAssignmentDropdown from "../project-collaborator-assignment-dialog/project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import ProjectOrderlineStatus from "../../enums/project-orderline-status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleNotch,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowHideColumnProjectsOrderLine,
  updateShowHideColumnByTable,
} from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  projectDetailsOrderlneListColumnFlexHandler,
  projectDetailsOrderlneListColumnGmaWbHandler,
} from "../../../data/project-details-orderline-list-grid-columns";
import { HandlerEditState } from "../../../project-details-order-line-list/ProjectDetailsOrderLineList";
import { UpdateProjectLineHandlerRequestModel } from "../../../../../shared/models/UpdateProjectLineHandlerRequest.model";
import ProjectService from "../../../../../services/ProjectService";
import useToastr from "../../../../../hooks/useToastr";
import { updateOrderLineListHandlers } from "../../../../../features/projectManagement/projectManagementSlice";
import { Tooltip } from "primereact/tooltip";
interface ProjectSingleCollaboratorAvatarReassignProps {
  id: string;
  projectId: string;
  orderLineDescription: string;
  name: string;
  email: string;
  handlerType: HandlerType;
  orderLineStatus: ProjectOrderlineStatus;
  className?: string;
  handlerEditState: Array<HandlerEditState>;
  setHandlerEditState: React.Dispatch<
    React.SetStateAction<Array<HandlerEditState>>
  >;
  updateHandlers: () => void;
}
const getInitials = (name: string) => {
  return name
    ?.split(",")
    ?.map((value) => value.trim())
    ?.reverse()
    ?.map((value, index, array) =>
      index === 0 || index + 1 === array.length
        ? (value[0] ?? "").toUpperCase()
        : null
    )
    .join("");
};
const getHandlerClass = (handlerType: HandlerType) =>
  classes[HandlerType[handlerType]?.toLowerCase()];

const renderCollaboratorDisplayLabel = (
  handlerType: HandlerType
): string | null => {
  if (handlerType === HandlerType.OrderCreator) return null;

  switch (handlerType) {
    case HandlerType.FlexHandler:
      return "FLEX Handler";
    case HandlerType.GmaWBHandler:
      return "GMAWB Handler";
    case HandlerType.MilestoneHandler:
      return "Milestone Handler";
    case HandlerType.TaskHandler:
      return "Task Handler";
  }
};

interface ProjectUserSource {
  currentSkip: number;
  currentTake: number;
  currentSearchPhrase: string;
  data: E2EUserPaginatedModel[];
}

const initDropdownValues: ProjectUserSource = {
  currentSkip: 0,
  currentTake: 5,
  currentSearchPhrase: "",
  data: [],
};

const ProjectSingleCollaboratorAvatarReassign = (
  props: ProjectSingleCollaboratorAvatarReassignProps
) => {
  const columnWidthToAddOnEdit: number = 150;
  const [handlerDropdownOptions, setHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [currentHandler, setCurrentHandler] = useState<E2EUserPaginatedModel>();
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(true);
  const [isHandlerSaving, setIsHandlerSaving] = useState<boolean>(false);
  const selectOrderLineListGridColumns = useSelector(
    selectShowHideColumnProjectsOrderLine
  );
  const dispatch = useDispatch();

  const { showSuccess, showError } = useToastr();

  const isOnEditState = props.handlerEditState.some((handlerEdit) => {
    if (props.id === handlerEdit.orderLineId) {
      if (props.handlerType === HandlerType.FlexHandler)
        return handlerEdit.isFlexHandlerEdit;

      if (props.handlerType === HandlerType.GmaWBHandler)
        return handlerEdit.isGmawbHandlerEdit;
    }

    return false;
  });

  const isHandlerChanged = currentHandler?.displayName !== props.name;

  useEffect(() => {
    if (!isOnEditState) return;

    setIsDropdownLoading(true);
    GMAWBUsersService.getPaginatedUsers(
      0,
      5,
      props.handlerType,
      false,
      ""
    ).then((response) => {
      let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
      if (flexData?.some((data) => data.displayName === props.email)) {
        flexData = flexData?.filter(
          (data) => data.normalizedEmail !== props.email
        );
      }
      flexData?.unshift({
        displayName: props.name,
        normalizedEmail: props.email,
      });

      setHandlerDropdownOptions((current) => ({
        ...current,
        data: flexData,
      }));

      setCurrentHandler((current) => ({
        ...current,
        normalizedEmail: props.email,
        displayName: props.name,
      }));

      setIsDropdownLoading(false);
    });
  }, [isOnEditState]);

  const onHandlerClick = () => {
    if (props.orderLineStatus === ProjectOrderlineStatus.Completed) return;

    const lastEditStateCount = updateHandlerState(true);
    applyColumnAdjustOnEditHandler(lastEditStateCount);
  };

  const onConfirm = () => {
    if (currentHandler) {
      setIsHandlerSaving(true);
      let handlerTypeMessage = renderCollaboratorDisplayLabel(
        props.handlerType
      );

      const newHandler: UpdateProjectLineHandlerRequestModel = {
        e2eProjectId: props.projectId,
        e2eProjectLineId: [props.id],
        handlerInformation: currentHandler,
        handlerType: props.handlerType,
      };

      ProjectService.updateProjectLineHandler(newHandler)
        .then((response) => {
          if (response.isSuccess) {
            dispatch(
              updateOrderLineListHandlers({
                id: props.id,
                projectId: props.projectId,
                handlerName: currentHandler.displayName,
                handlerEmail: currentHandler.normalizedEmail,
                handlerType: props.handlerType,
              })
            );

            const lastEditStateCount = updateHandlerState(false);
            if (lastEditStateCount === 0)
              applyColumnAdjustOnEditHandler(lastEditStateCount);

            props.updateHandlers();
            
            showSuccess(
              `${handlerTypeMessage} Updated`,
              `${handlerTypeMessage} for order line <strong>'${props.orderLineDescription}'</strong> successfully reassigned to <strong>'${currentHandler.displayName}'</strong>.`
            );
          }
          if (!response.isSuccess) {
            showError(
              `${handlerTypeMessage} Reassignment Failed`,
              `Failed to reassign ${handlerTypeMessage}. Please try again or contact support if issue persists.`
            );
          }
        })
        .catch((error) => {
          console.error(error);
          showError(
            `${handlerTypeMessage} Reassignment Failed`,
            `Failed to reassign ${handlerTypeMessage}. Please try again or contact support if issue persists.`
          );
        })
        .finally(() => setIsHandlerSaving(false));
    }
  };

  const onCancel = () => {
    setCurrentHandler((current) => ({
      ...current,
      normalizedEmail: props.email,
      displayName: props.name,
    }));

    const lastEditStateCount = updateHandlerState(false);
    if (lastEditStateCount === 0)
      applyColumnAdjustOnEditHandler(lastEditStateCount);
  };

  const updateHandlerState = (isEdit: boolean): number => {
    const updatedHandlerState = props.handlerEditState.map((handlerState) => {
      if (props.id === handlerState.orderLineId) {
        if (props.handlerType === HandlerType.FlexHandler)
          handlerState.isFlexHandlerEdit = isEdit;

        if (props.handlerType === HandlerType.GmaWBHandler)
          handlerState.isGmawbHandlerEdit = isEdit;
      }

      return handlerState;
    });

    props.setHandlerEditState(updatedHandlerState);

    const lastEditStateCount = updatedHandlerState.filter((handlerState) => {
      if (props.handlerType === HandlerType.FlexHandler)
        return handlerState.isFlexHandlerEdit === true;
      if (props.handlerType === HandlerType.GmaWBHandler)
        return handlerState.isGmawbHandlerEdit === true;

      return [];
    }).length;

    return lastEditStateCount;
  };

  const applyColumnAdjustOnEditHandler = (lastEditStateCount: number) => {
    const showHideCopyOrderLineList = structuredClone(
      selectOrderLineListGridColumns
    );

    showHideCopyOrderLineList?.fields.map((field) => {
      if (
        (props.handlerType === HandlerType.FlexHandler &&
          field.id === projectDetailsOrderlneListColumnFlexHandler) ||
        (props.handlerType === HandlerType.GmaWBHandler &&
          field.id === projectDetailsOrderlneListColumnGmaWbHandler)
      ) {
        const getWidthAsNumber: number = Number(
          field.width.slice(0, field.width.indexOf("px"))
        );

        if (lastEditStateCount <= 1) {
          field.width =
            lastEditStateCount === 0
              ? `${getWidthAsNumber - columnWidthToAddOnEdit}px`
              : `${getWidthAsNumber + columnWidthToAddOnEdit}px`;
          field.widthNum =
            lastEditStateCount === 0
              ? field.widthNum - columnWidthToAddOnEdit
              : field.widthNum + columnWidthToAddOnEdit;
        }
      }

      return field;
    });

    dispatch(
      updateShowHideColumnByTable({
        key: showHideCopyOrderLineList?.key,
        newColumns: showHideCopyOrderLineList?.fields,
        frozenCount: showHideCopyOrderLineList?.frozenColumnCount,
      })
    );
  };

  return (
    <>
      {props.name && !isOnEditState && (
        <div className={classes["badge-container"]}>
          {
            <>
              <Tooltip
                autoHide={true}
                position="right"
                className={`${classes["tooltip"]}`}
                target={`.${renderCollaboratorDisplayLabel(
                  props.handlerType
                )?.replace(/\s/g, "")}-${props.id}`}
              >
                <div className={`${classes["tooltip-displayName"]}`}>
                  {props.name}
                </div>
                <div className={`${classes["tooltip-handlerLabel"]}`}>
                  {renderCollaboratorDisplayLabel(props.handlerType)}
                </div>
              </Tooltip>
              <span onClick={onHandlerClick}>
                <Badge
                  value={getInitials(props.name)}
                  className={clsx(
                    classes["badge"],
                    getHandlerClass(props.handlerType),
                    {
                      [classes["badge-editable"]]:
                        props.orderLineStatus !==
                        ProjectOrderlineStatus.Completed,
                    },
                    `${renderCollaboratorDisplayLabel(
                      props.handlerType
                    )?.replace(/\s/g, "")}-${props.id}`
                  )}
                  size="large"
                ></Badge>
              </span>
            </>
          }
        </div>
      )}
      {isOnEditState && (
        <div
          className={`${props.className} ${classes["edit-collaborator-container"]}`}
        >
          {isDropdownLoading && (
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          )}

          {!isDropdownLoading && (
            <>
              <ProjectCollaboratorAssignmentDropdown
                value={currentHandler}
                setValue={setCurrentHandler}
                dropdownOptions={handlerDropdownOptions}
                setDropdownOptions={setHandlerDropdownOptions}
                handlerType={HandlerType.FlexHandler}
                isGmaTcTask={false}
                isDisabled={isHandlerSaving}
              />
              <div className={classes["single-handler-reassignment-container"]}>
                <FontAwesomeIcon
                  onClick={
                    isHandlerSaving || !isHandlerChanged ? undefined : onConfirm
                  }
                  className={clsx(classes["confirm-button"], {
                    [classes["action-buttons-disabled"]]:
                      isHandlerSaving || !isHandlerChanged,
                  })}
                  icon={isHandlerSaving ? faCircleNotch : faCheck}
                  spin={isHandlerSaving}
                  opacity={isHandlerSaving || !isHandlerChanged ? 0.5 : 1.0}
                />
                <FontAwesomeIcon
                  onClick={isHandlerSaving ? undefined : onCancel}
                  className={clsx(classes["cancel-button"], {
                    [classes["action-buttons-disabled"]]: isHandlerSaving,
                  })}
                  icon={faClose}
                  opacity={isHandlerSaving ? 0.5 : 1.0}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default ProjectSingleCollaboratorAvatarReassign;
