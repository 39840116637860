import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import PmrE2EProjectDetailsOrderlineModel from "../../interfaces/pmr-e2e-project-details-orderline-model";
import classes from "./ProjectFlexProjectEcdEditDialog.module.scss"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar, CalendarDateTemplateParams } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { addDays, differenceInDays, format } from "date-fns";
import useToastr from "../../../../../hooks/useToastr";
import ProjectService from "../../../../../services/ProjectService";
import { UpdateProjectECDsModel } from "../../../../../shared/models/service-models/UpdateProjectECDsModel";
import clsx from "clsx";

interface ProjectFlexProjectEcdEditDialogProps {
    visible: boolean;
    onHide: () => void;
    selectedRows: PmrE2EProjectDetailsOrderlineModel[];
    reloadList: () => void;
    projectId: string;
}

const ProjectFlexProjectEcdEditDialog: React.FC<ProjectFlexProjectEcdEditDialogProps> = ({
    visible,
    onHide,
    selectedRows,
    reloadList,
    projectId,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newEcdDate, setNewEcdDate] = useState<Date>();
    const { showSuccess, showError } = useToastr();
    const calendarStartDate = new Date(2020, 0, 1);
    const calendarEndDate = new Date(2030, 11, 31);

    const hideModal = () => {
        onHide();
        setNewEcdDate(undefined);
    };

    const getLatestOrderLineECD = (): Date | null => {
        if (!selectedRows || selectedRows.length === 0) return null;

        const latestDate = selectedRows
            .filter((row: PmrE2EProjectDetailsOrderlineModel) => row.orderLineECD)
            .map((row: PmrE2EProjectDetailsOrderlineModel) => new Date(row.orderLineECD!))
            .filter(date => !isNaN(date.getTime()))
            .reduce((latest, current) => (current > latest ? current : latest), new Date(0));

        return latestDate ?? null;
    };

    const generateDisabledDates = (latestDate: Date | null | undefined) => {
        if (!latestDate || isNaN(latestDate.getTime())) {
            return [];
        }

        const daysDifference = differenceInDays(latestDate, calendarStartDate);

        return Array.from({ length: daysDifference }, (_, index) => addDays(calendarStartDate, index));
    };

    const latestOrderLineECD = getLatestOrderLineECD();

    const disabledDates = useMemo(() => generateDisabledDates(latestOrderLineECD), [latestOrderLineECD]);

    const dateTemplate = (e: CalendarDateTemplateParams) => {
        const dateToCompare = new Date(e.year, e.month, e.day);
        const isEarlierNewestOrderlineECD =
            latestOrderLineECD &&
            dateToCompare < new Date(
                latestOrderLineECD.getFullYear(),
                latestOrderLineECD.getMonth(),
                latestOrderLineECD.getDate()
            );

        return (
            <div title={isEarlierNewestOrderlineECD ? "FLEX Project ECDs cannot be earlier than the Order Line ECDs." : ""}>
                <span className={clsx(isEarlierNewestOrderlineECD && classes["not-allowed"])}>
                    {e.day}
                </span>
            </div>
        );
    };

    const renderBody = () => {

        return (
            <div>
                <p className={classes["input-instructions"]}>
                    Input a new FLEX Project ECDs for the following:
                </p>

                <div className={classes["table-container"]}>
                    <DataTable
                        className={classes["custom-table"]}
                        responsiveLayout="scroll"
                        value={selectedRows}
                        showGridlines
                    >
                        <Column
                            field="orderLineNumber"
                            header="Order Line Number"
                            style={{ width: "50%" }}
                        />
                        <Column
                            field="flexProjectECD"
                            header="Current FLEX Project ECD"
                            style={{ width: "50%" }}
                            body={(rowData) =>
                                rowData.flexProjectECD
                                    ? format(new Date(rowData.flexProjectECD), "dd MMM yyyy")
                                    : ""
                            }
                        />
                    </DataTable>
                </div>

                <div className={classes["new-ecd-container"]}>
                    <label htmlFor="new-ecd" className={classes["new-ecd-label"]}>
                        New FLEX Project ECD:
                    </label>
                    <Calendar
                        id="new-ecd"
                        dateTemplate={dateTemplate}
                        disabledDays={[0, 6]}
                        value={newEcdDate}
                        onChange={(e) => setNewEcdDate(e.value as Date)}
                        dateFormat="dd M yy"
                        showIcon
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange={`${calendarStartDate.getFullYear()}:${calendarEndDate.getFullYear()}`}

                        icon={
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                            />
                        }
                        className={classes["new-ecd-calendar"]}
                        placeholder="dd mmm yyyy"
                        disabledDates={disabledDates}
                        panelClassName={classes["calendar-panel"]}
                    />
                </div>
            </div>
        );

    };

    const confirmSave = async () => {
        try {
            const updatedFlexProjectECDsRequest: UpdateProjectECDsModel = {
                e2eProjectLinesIds: selectedRows.map(row => row.id),
                newECD: newEcdDate ? format(newEcdDate, "yyyy-MM-dd") : null
            };

            setIsSaving(true);
            const result = await ProjectService.updateProjectECDs(updatedFlexProjectECDsRequest);

            if (!result.isSuccess) {
                showError("Error", result.message);
                return;
            }

            hideModal();
            showSuccess(
                "FLEX Project ECDs Updated",
                `The new FLEX Project ECD has been successfully saved to your selected Order Lines.`
            );

            reloadList();
        } catch (error) {
            showError("Error", "An unexpected error occurred. Please try again later.");
        } finally {
            setIsSaving(false);
        }
    };

    const isConfirmButtonDisabled = () => {
        return !newEcdDate || isSaving
    }
    const renderFooter = () => (
        <>
            <hr className={classes["modal-footer-hr"]} />
            <div className={`${classes["dialog-footer"]} ${classes["center-items"]}`}>
                <button
                    className={`${classes["dialog-cancel-btn"]} ul-button -medium`}
                    onClick={hideModal}
                >
                    CANCEL
                </button>
                <button
                    className={`${classes["dialog-save-btn"]} ul-button -medium`}
                    disabled={isConfirmButtonDisabled()}
                    onClick={confirmSave}
                >
                    SAVE
                </button>
            </div>
        </>
    );

    return (
        <Dialog
            className={classes["custom-dialog"]}
            header={`Bulk-edit FLEX Project ECDs`}
            footer={renderFooter}
            draggable={false}
            visible={visible}
            onHide={hideModal}
            style={{ width: "30vw", maxHeight: "60vh", overflowY: "auto" }}
        >
            <hr className="modal-header-hr" />
            <div className="body-container">
                <div style={{ display: isSaving || isLoading ? "none" : "" }}>
                    {renderBody()}
                </div>

                {(isSaving || isLoading) && (
                    <div className={classes["loading-div"]}>
                        <div>
                            {isSaving && `Saving`} {isLoading && `Loading`} ......
                        </div>
                        <div>
                            <svg className="ul-progressCircle -medium">
                                <circle
                                    className="ul-progressCircle__outer"
                                    cx="50%"
                                    cy="50%"
                                    r="35%"
                                />
                                <circle
                                    className="ul-progressCircle__inner"
                                    cx="50%"
                                    cy="50%"
                                    r="35%"
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default ProjectFlexProjectEcdEditDialog;
