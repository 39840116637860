import {
  faFileInvoice,
  faStamp,
  faStopwatch,
  faUserCircle,
  faBarsProgress,
} from "@fortawesome/free-solid-svg-icons";
import useToastr from "../../../../../hooks/useToastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ProjectListHeaderActions.module.scss";
import {
  selectShowFlexTasks,
  updateShowFlexTasks,
  updateIsTimestampConfirmationLoading,
} from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  selectFlexTaskMilestonesList,
  selectFlexTaskOrderLinesList,
  selectProjectFlexTasksList,
  selectRefinerLineResult,
  selectSelectedMilestones,
  selectSelectedTaskOrderlines,
  unselectAllMilestones,
  unselectAllTaskOrderlines,
  upsertFlexTaskOrderLines,
} from "../../../../../features/projectManagement/projectManagementSlice";
import { Tooltip } from "primereact/tooltip";
import { uniqBy } from "lodash";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import useProjectTimestamp from "../../../../../hooks/useProjectTimestamp";
import ProjectStatus from "../../../shared/enums/project-status";
import { PmrE2EProjectFlexTaskMilestones } from "../../../shared/interfaces/pmr-e2e-project-milestone";
import PmrE2EProjectOrderlineModel from "../../../shared/interfaces/pmr-e2e-project-orderline-model";
import ProjectTaskStatus from "../../../shared/enums/project-task-status";
import TimestampElements from "../../../../project-management/shared/components/timestamp-elements/TimestampElements";
import TimestampConfirmationDialog from "../../../shared/components/timestamp-confirmation-dialog/TimestampConfirmationDialog";
import TimeEntryModal from "../../../time-entry-modal/TimeEntryModal";
import TimeEntryModalSourceType from "../../../shared/enums/time-entry-modal-source-type";
import PmrE2EProjectModel from "../../../shared/interfaces/pmr-e2e-project-model";
import { ECDAdjustmentsErrorType } from "../../enums/ecd-adjustments-error-type";
import { ProjectManagementPmrShowFlexTasks } from "../../../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import {
  coHandlerErrorHeader,
  coHandlerErrorMessage,
} from "../../../../../utils/constants/projects.constants";
import GenerateStatusReportDialog from "../generate-status-report-dialog/GenerateStatusReportDialog";
import { ENABLED_GENERATE_STATUS_REPORT } from "../../../../../utils/constants/feature-flag.constants";
import { PmrE2EProjectFullViewModel } from "../../interfaces/pmr-e2e-project-full-view-model";
import ProjectCollaboratorAssignmentDialog from "../project-collaborator-assignment-dialog/ProjectCollaboratorAssignmentDialog";
import ProjectCollaboratorBulkAssignmentDialog from "../project-collaborator-assignment-dialog-bulk/ProjectCollaboratorBulkAssignmentDialog";
import ProjectCollaboratorBulkAssignmentDetails from "../../interfaces/pmr-e2e-project-contributor-bulk-assignment-model";
import PmrE2EProjectTaskModel from "../../interfaces/pmr-e2e-project-task-model";
import ProjectService from "../../../../../services/ProjectService";
import ProjectCollaboratorBulkAssignmentDataModel from "../../interfaces/pmr-e2e-project-contributor-bulk-assignment-data-model";
import { formatDateWithTimezone } from "../../../../../shared/date-helper/DateHelper";
import { formatNumber } from "../../../../../utils/helpers/number.helpers";
import SubmitOption from "../../enums/submit-option";
import ProjectContributorBulkAssignmentErrorModal from "../project-contributor-bulk-assignment-error/ProjectContributorBulkAssignmentErrorModal";
import { ProjectContributorBulkAssignmentErrorModel } from "../project-contributor-bulk-assignment-error/ProjectContributorBulkAssignmentErrorModel";
import { ProgressSpinner } from "primereact/progressspinner";
import ProjectTaskStatusUpdateDialog from "../project-task-status-update-dialog/ProjectTaskStatusUpdateDialog";

interface SingleProjectViewPropsModel {
  projectName: string;
  projectFullViewData: PmrE2EProjectFullViewModel | undefined;
}
interface ProjectListHeaderActionsProps {
  project: PmrE2EProjectModel | null;
  filterByProject: boolean;
  singleProjectViewData?: SingleProjectViewPropsModel | null;
}

const ProjectListHeaderActions = (props: ProjectListHeaderActionsProps) => {
  const dispatch = useDispatch();
  const { showError } = useToastr();
  const mileStoneIdsFilter =
    props.project?.e2EProjectLineMilestoneGroupItems.map(
      (m) => m.e2EProjectLineMilestoneId
    ) || [];

  const selectedMilestonesSelector = useSelector(selectSelectedMilestones);
  const selectedMilestones =
    props == null || !props.filterByProject
      ? selectedMilestonesSelector
      : selectedMilestonesSelector.filter((milestoneItem) => {
          return mileStoneIdsFilter.includes(milestoneItem.id);
        }) || [];

  const taskOrderLineIdsFilter =
    props.project?.e2EProjectLineFlexTaskGroupItems.map(
      (m) => m.e2EProjectLineFlexTaskId
    ) || [];

  const selectedTaskOrderlinesSelector = useSelector(
    selectSelectedTaskOrderlines
  );

  const selectedTaskOrderlines =
    props == null || !props.filterByProject
      ? selectedTaskOrderlinesSelector
      : selectedTaskOrderlinesSelector.filter((taskOrderLineItem) => {
          return taskOrderLineIdsFilter.includes(
            taskOrderLineItem.e2EProjectLineFlexTaskId
          );
        }) || [];

  const showFlexTaskOnly = useSelector(selectShowFlexTasks);
  const flexTaskOrderLinesList = useSelector(selectFlexTaskOrderLinesList);
  const taskMilestoneList = useSelector(selectFlexTaskMilestonesList);
  const projectTimestampHook = useProjectTimestamp();
  const refinerLineResult = useSelector(selectRefinerLineResult);
  const [
    timestampConfirmationDialogVisible,
    setTimestampConfirmationDialogVisible,
  ] = useState(false);

  const [taskStatusUpdateDialogVisible, setTaskStatusUpdateDialogVisible] =
    useState<boolean>(false);

  const changeFlexTaskOnly = (props: InputSwitchChangeParams) => {
    const isFlexTaskOnly = props.value;
    const showFlexTask: ProjectManagementPmrShowFlexTasks = {
      isOn: isFlexTaskOnly,
      isFromClicked: true,
    };
    dispatch(updateShowFlexTasks(showFlexTask));
    if (isFlexTaskOnly) dispatch(unselectAllMilestones());
    else dispatch(unselectAllTaskOrderlines());
  };
  const [timeEntryModalVisible, setTimeEntryModalVisible] = useState(false);
  const [bulkContributorModalVisible, setBulkContributorModalVisible] =
    useState(false);

  const [bulkContributorIsValidating, setBulkContributorIsValidating] =
    useState(false);

  const [bulkContributorErrorDetail, setBulkContributorErrorDetail] = useState<
    ProjectContributorBulkAssignmentErrorModel[]
  >([]);

  const [
    bulkContributorErrorModalVisible,
    setBulkContributorErrorModalVisible,
  ] = useState(false);

  const [
    projectCollaboratorBulkAssignmentDetails,
    setProjectCollaboratorBulkAssignmentDetails,
  ] = useState<ProjectCollaboratorBulkAssignmentDetails[]>([]);

  const [
    generateStatusReportDialogVisible,
    setGenerateStatusReportDialogVisible,
  ] = useState(false);

  const [localSelectedTaskOrderline, setLocalSelectedTaskOrderline] = useState(
    new Array()
  );

  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);

  const isTimeEntrySyncing = projectFlexTasksList.some((taskList) =>
    taskList.tasks
      .filter((task) => !task.isAdHoc)
      .some((task) =>
        task.timeEntrySnycFlexTaskList.some((te) => te.isTimeEntrySyncing)
      )
  );

  const flexTaskToggleClass = clsx([classes["flexTaskToggle"]], {
    [classes["flexTaskToggle--checked"]]: showFlexTaskOnly.isOn,
  });

  //#region filtered resources
  const refinerResultFilterMilestone = (
    milestone: PmrE2EProjectFlexTaskMilestones
  ) => refinerLineResult.milestoneIds?.includes(milestone.id);

  const refinerResultFilterTask = (task: PmrE2EProjectOrderlineModel) =>
    refinerLineResult.flexTaskIds?.includes(task.e2EProjectLineFlexTaskId);

  const filteredTasks = refinerLineResult.flexTaskIds
    ? selectedTaskOrderlines.filter(refinerResultFilterTask)
    : selectedTaskOrderlines;

  const filteredMilestones = refinerLineResult.milestoneIds
    ? selectedMilestones.filter(refinerResultFilterMilestone)
    : selectedMilestones;
  //#endregion

  const nonCompletedMilestones = filteredMilestones.filter(
    (milestone) => milestone.status !== ProjectStatus.Completed
  );

  const nonCancelledMilestones = filteredMilestones.filter(
    (milestone) => milestone.status !== ProjectStatus.Cancelled
  );

  const nonCompletedTasks = filteredTasks.filter(
    (taskOrderline) => taskOrderline.status !== ProjectTaskStatus.Completed
  );

  const nonCancelledTasks = filteredTasks.filter(
    (taskOrderline) => taskOrderline.status !== ProjectTaskStatus.Cancelled
  );

  const adhocTasks = showFlexTaskOnly.isOn
    ? filteredTasks.filter((taskOrderline) => taskOrderline.isAdHoc)
    : filteredMilestones.filter((milestone) => milestone.isAdHoc);

  const isAddTimestampEnabled = (): boolean => {
    return showFlexTaskOnly.isOn
      ? nonCompletedTasks.length > 0 && nonCancelledTasks.length > 0
      : nonCompletedMilestones.length > 0 && nonCancelledMilestones.length > 0;
  };

  const isTaskStatusUpdateEnabled = (): boolean => {
    if (selectedTaskOrderlines.length == 0) return false;
    const hasCompletedCanceled =
      selectedTaskOrderlines.filter(
        (ol) =>
          ol.status == ProjectTaskStatus.Cancelled ||
          ol.status == ProjectTaskStatus.Completed
      ).length > 0;

    return showFlexTaskOnly.isOn && !hasCompletedCanceled;
  };

  const isBulkContributorAssignedEnabled = (): boolean => {
    return showFlexTaskOnly.isOn
      ? selectedTaskOrderlines.filter(
          (ol) =>
            ol.status != ProjectTaskStatus.Cancelled &&
            ol.status != ProjectTaskStatus.Completed
        ).length > 0
      : selectedMilestones.filter(
          (milestone) =>
            milestone.status != ProjectStatus.Cancelled &&
            milestone.status != ProjectStatus.Completed
        ).length > 0;
  };
  const handleBulkAssignContributor = async () => {
    setBulkContributorIsValidating(true);

    if (showFlexTaskOnly.isOn) {
      await bulkContributorAssignTaskLevel();
    } else {
      await bulkContributorAssignMilestoneLevel();
    }
    setBulkContributorIsValidating(false);
  };

  const bulkContributorAssignTaskLevel = async () => {
    let orderLinesToProcess: PmrE2EProjectOrderlineModel[] = [];

    let selectedTasks = structuredClone(selectedTaskOrderlines);
    const taskWithNoOrderLineDetails: any[] = [];
    selectedTasks.forEach((selectedTask) => {
      let taskExist = false;
      for (let flexTaskOrder of flexTaskOrderLinesList) {
        let orderLineDetail = flexTaskOrder.orderLines.find(
          (task) =>
            task.e2EProjectLineFlexTaskId ==
            selectedTask.e2EProjectLineFlexTaskId
        );

        if (orderLineDetail) {
          orderLinesToProcess.push(orderLineDetail);
          taskExist = true;
          break;
        }
      }

      if (!taskExist)
        taskWithNoOrderLineDetails.push(selectedTask.e2EProjectLineFlexTaskId);
    });
    let fetchingSuccess: boolean = true;
    if (taskWithNoOrderLineDetails.length > 0) {
      const request: any = {
        e2EProjectLineFlexTaskIds: taskWithNoOrderLineDetails,
      };
      await ProjectService.getProjectOrderline(request)
        .then((response) => {
          orderLinesToProcess.push(...response.data);
        })
        .catch((error) => {
          fetchingSuccess = false;
          showError(
            "Error",
            "An error occurred while updating Contributors. Please try again"
          );
        });
    }
    if (fetchingSuccess) {
      const coHandlerOrderlines = orderLinesToProcess.filter(
        (orderLine) =>
          orderLine.submitOption == SubmitOption.AuthorityReviewFlex &&
          orderLine?.e2EProjectLineMilestones?.find(
            (milestone) =>
              milestone.name === "UL: Submit to Authority/Authority Review"
          )
      );

      if (coHandlerOrderlines.length > 0) {
        const errorDetails = coHandlerOrderlines.map((orderLine) => {
          const ret: ProjectContributorBulkAssignmentErrorModel = {
            orderLineDescription: orderLine.orderLineDescription,
            taskOrMilestoneName: orderLine.flexTaskName,
            e2EProjectName: orderLine.e2EProjectName,
          };
          return ret;
        });
        setBulkContributorErrorDetail(errorDetails);
        setBulkContributorErrorModalVisible(true);
        return;
      }

      let selectedTasksWithDetails: ProjectCollaboratorBulkAssignmentDetails[] =
        orderLinesToProcess
          .filter(
            (ol) =>
              ol.orderLineStatus != ProjectTaskStatus.Completed &&
              ol.orderLineStatus != ProjectTaskStatus.Cancelled
          )
          .map((orderLine) => {
            const ret: ProjectCollaboratorBulkAssignmentDetails = {
              e2EProjectId: orderLine.e2EProjectId ?? "",
              e2EProjectLineId: orderLine.e2EProjectLineId ?? "",
              workBreakdownStructure: orderLine.workBreakdownStructure,
              e2EProjectLineFlexTaskId: orderLine.e2EProjectLineFlexTaskId,
            };

            return ret;
          });

      setProjectCollaboratorBulkAssignmentDetails(selectedTasksWithDetails);
      setBulkContributorModalVisible(true);
    }
    setBulkContributorIsValidating(false);
  };

  const bulkContributorAssignMilestoneLevel = async () => {
    let selectedMileStones = structuredClone(selectedMilestones);
    let milestonesToProcess: PmrE2EProjectFlexTaskMilestones[] = [];

    const milestoneWithNoDetails: any[] = [];

    selectedMileStones.forEach((selectedMilestone) => {
      let milestoneExist = false;
      for (let milestone of taskMilestoneList) {
        let milestoneDetail = milestone.milestones.find(
          (milestone) => milestone.id == selectedMilestone.id
        );

        if (milestoneDetail) {
          milestonesToProcess.push(milestoneDetail);
          milestoneExist = true;
          break;
        }
      }

      if (!milestoneExist) milestoneWithNoDetails.push(selectedMilestone);
    });
    let fetchingSuccess: boolean = true;
    if (milestoneWithNoDetails.length > 0) {
      let flexTaskIdParam: string[] = [];
      milestoneWithNoDetails.forEach((milestone) => {
        if (
          !flexTaskIdParam.find(
            (param) => param == milestone.e2EProjectLineFlexTaskId
          )
        ) {
          flexTaskIdParam.push(milestone.e2EProjectLineFlexTaskId);
        }
      });

      const request: any = {
        e2EProjectLineFlexTaskIds: flexTaskIdParam,
      };
      await ProjectService.getProjectMilestoneTask(request)
        .then((response) => {
          milestonesToProcess.push(...response.data.e2EProjectLineMilestones);
        })
        .catch((error) => {
          showError(
            "Error",
            "An error occurred while updating Contributors. Please try again"
          );
          fetchingSuccess = false;
        });
    }
    if (fetchingSuccess) {
      const coHandlerMilestones = milestonesToProcess.filter(
        (milestone) =>
          milestone.submitOption == SubmitOption.AuthorityReviewFlex &&
          milestone.name === "UL: Submit to Authority/Authority Review"
      );

      if (coHandlerMilestones.length > 0) {
        const errorDetails = coHandlerMilestones.map((milestone) => {
          const ret: ProjectContributorBulkAssignmentErrorModel = {
            orderLineDescription: milestone.orderLineDescription ?? "",
            taskOrMilestoneName: milestone.name ?? "",
            e2EProjectName: milestone.e2EProjectName ?? "",
          };
          return ret;
        });
        setBulkContributorErrorDetail(errorDetails);
        setBulkContributorErrorModalVisible(true);
        return;
      }
      let selectedTasksWithDetails: ProjectCollaboratorBulkAssignmentDetails[] =
        milestonesToProcess
          .filter(
            (milestone) =>
              milestone.status != ProjectStatus.Completed &&
              milestone.status != ProjectStatus.Cancelled
          )
          .map((milestone) => {
            const ret: ProjectCollaboratorBulkAssignmentDetails = {
              e2EProjectId: milestone.e2EProjectId ?? "",
              e2EProjectLineId: milestone.e2EProjectLineId ?? "",
              workBreakdownStructure:
                getWorkBreakdownStructureByMilestoneId(milestone.id) ?? "",
              e2EProjectLineFlexTaskId: milestone.e2EProjectLineFlexTaskId,
              e2EProjectLineMilestoneId: milestone.id,
            };

            return ret;
          });

      setProjectCollaboratorBulkAssignmentDetails(selectedTasksWithDetails);
      setBulkContributorModalVisible(true);
    }
    setBulkContributorIsValidating(false);
  };

  const getWorkBreakdownStructureByMilestoneId = (milestoneId: string) => {
    for (let taskMilestone of taskMilestoneList) {
      const targetMileStone = taskMilestone.milestones.find(
        (milestone) => milestone.id == milestoneId
      );
      if (targetMileStone) {
        return taskMilestone.workBreakdownStructure;
      }
    }
    return 0;
  };
  const onBulkContributorErrorModalClose = () => {
    setBulkContributorErrorModalVisible(false);
  };

  const handleTimestamp = async () => {
    let milestoneIds: string[] = [];
    let taskIds: string[] = [];

    dispatch(updateIsTimestampConfirmationLoading(true));

    if (showFlexTaskOnly.isOn) {
      let selectedTasks = structuredClone(selectedTaskOrderlines);
      if (refinerLineResult.flexTaskIds) {
        selectedTasks = selectedTasks.filter(refinerResultFilterTask);
      }

      taskIds = selectedTasks
        .filter(projectTimestampHook.isTaskTimestampable)
        .map(({ e2EProjectLineFlexTaskId }) => e2EProjectLineFlexTaskId);

      if (taskIds.length === 0) return;
    } else {
      let _selectedMilestones = structuredClone(selectedMilestones);
      if (refinerLineResult.milestoneIds) {
        _selectedMilestones = _selectedMilestones.filter(
          refinerResultFilterMilestone
        );
      }

      milestoneIds = _selectedMilestones
        .filter(projectTimestampHook.isMilestoneTimestampable)
        .map(({ id }) => id);

      if (milestoneIds.length === 0) return;
    }

    const ecdAdjustmentsResult =
      await projectTimestampHook.checkProjectLinesECDAdjustments({
        taskIds,
        milestoneIds,
        includeMilestonePredecessorsForTimestamping: true,
        isMilestoneLoaded: !showFlexTaskOnly.isOn,
      });

    const { data, isSuccess, message } = ecdAdjustmentsResult || {};
    const {
      ecdAdjustments = [],
      e2EProjectLineMilestoneIds = [],
      e2EProjectLineFlexTaskIds = [],
      hasProjectLineWithMultipleMilestones = false,
      hasJumpedMilestones = false,
    } = data || {};

    if (!isSuccess) {
      if (data?.errorType == ECDAdjustmentsErrorType.CoHandlerError) {
        data?.coHandlerErrorModel.forEach((item) => {
          showError(
            coHandlerErrorHeader,
            coHandlerErrorMessage.replace("@orderNumber", item.orderLineNumber)
          );

          dispatch(updateIsTimestampConfirmationLoading(false));
        });
      }
      return;
    }

    if (ecdAdjustments.length !== 0) return;

    const timestampResult = await projectTimestampHook.timestampMilestones({
      milestoneIds,
      taskIds,
      adjustECDProjectLineIds: [],
      isFromECDAdjustmentPrompt: false,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (timestampResult?.isSuccess) unselectAll();
  };
  const unselectAll = () => {
    dispatch(unselectAllMilestones());
    dispatch(unselectAllTaskOrderlines());
  };
  const handleECDAdjustmentsConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    projectTimestampHook.hideECDAdjustmentsDialog();
    const result = await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      taskIds: projectTimestampHook.currentTaskIds,
      adjustECDProjectLineIds,
      isFromECDAdjustmentPrompt: true,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (result?.isSuccess) unselectAll();
  };

  const handleMassTimestampConfirm = async () => {
    const adjustECDProjectLineIds =
      projectTimestampHook.e2eProjectLineECDAdjustments.map(
        (line) => line.e2EProjectLineId
      );

    if (adjustECDProjectLineIds.length > 0) {
      projectTimestampHook.showECDAdjustmentsDialog();
    } else {
      await projectTimestampHook.timestampMilestones({
        milestoneIds: projectTimestampHook.currentMilestoneIds,
        taskIds: projectTimestampHook.currentTaskIds,
        adjustECDProjectLineIds: adjustECDProjectLineIds,
        isFromECDAdjustmentPrompt: false,
        includeMilestonePredecessorsForTimestamping: true,
        isTimestampedThroughTasks: showFlexTaskOnly.isOn,
      });
    }
  };

  const handleTimestampConfirm = async () => {
    setTimestampConfirmationDialogVisible(false);

    if (!projectTimestampHook.currentECDAdjustmentsResult?.isSuccess) {
      showError(
        "Unable to complete timestamp",
        projectTimestampHook.currentECDAdjustmentsResult?.message
      );
      return;
    }

    projectTimestampHook.setMilestonesTimestampingStatus(
      projectTimestampHook.currentMilestoneIds,
      true
    );

    const timestampResult = await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      taskIds: projectTimestampHook.currentTaskIds,
      adjustECDProjectLineIds: [],
      isFromECDAdjustmentPrompt: false,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (timestampResult?.isSuccess) unselectAll();
  };

  const isTimeEntryEnabled = (): boolean => {
    return showFlexTaskOnly.isOn
      ? nonCompletedTasks.length > 0 &&
          nonCancelledTasks.length > 0 &&
          !isTimeEntrySyncing &&
          adhocTasks.length == 0
      : nonCompletedMilestones.length > 0 &&
          nonCancelledMilestones.length > 0 &&
          !isTimeEntrySyncing &&
          adhocTasks.length == 0;
  };

  const handleTimeEntry = async () => {
    if (showFlexTaskOnly.isOn) {
      setLocalSelectedTaskOrderline(nonCompletedTasks);
    } else {
      const tasks = uniqBy(nonCompletedMilestones, "e2EProjectLineFlexTaskId");
      setLocalSelectedTaskOrderline(tasks);
    }
    setTimeEntryModalVisible(true);
  };

  const onCollaboratorClose = () => {
    setBulkContributorModalVisible(false);
  };

  const handleBulkTaskStatusUpdate = async () => {
    setTaskStatusUpdateDialogVisible(true);
  };

  const handleUpdateTaskStatusSave = async () => {
    setTaskStatusUpdateDialogVisible(false);
  };

  return (
    <>
      <div className={`${classes["project-header-actions-container"]}`}>
        <div> FLEX Tasks only </div>
        <div>
          <InputSwitch
            id="checked"
            checked={showFlexTaskOnly.isOn}
            className={flexTaskToggleClass}
            onChange={changeFlexTaskOnly}
          ></InputSwitch>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className={`add-timestamp-target`}>
          <button
            disabled={!isAddTimestampEnabled()}
            onClick={handleTimestamp}
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["add-timestamp"]} ${classes["green"]} `}
          >
            <span>
              <FontAwesomeIcon icon={faStamp} />
            </span>
          </button>
          <Tooltip
            target={`.add-timestamp-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Add Timestamp</strong>
            </div>
            <div>
              Click to mark the selected Task or Milestone as Completed and
              advance to the next phase.
            </div>
          </Tooltip>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className={`timeentry-target`}>
          <button
            disabled={!isTimeEntryEnabled()}
            onClick={handleTimeEntry}
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["edit-timeentry"]} timeentry-target`}
          >
            <span>
              <FontAwesomeIcon icon={faStopwatch} />
            </span>
          </button>
          <Tooltip
            target={`.timeentry-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Time Entry</strong>
            </div>
            <div>
              Click to log hours onto Time Entry for selected order lines.
            </div>
            {adhocTasks.length > 0 && (
              <div>
                <b>Note: Ad hoc tasks are not available for time entry.</b>
              </div>
            )}
          </Tooltip>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className="bulkassign-target">
          {bulkContributorIsValidating && (
            <div className={`${classes["spinner__container"]}`}>
              <ProgressSpinner
                strokeWidth="5"
                animationDuration="5s"
                className={`${classes["bulk-assign-contributor-spinner"]}`}
              />
            </div>
          )}

          {!bulkContributorIsValidating && (
            <>
              <button
                disabled={!isBulkContributorAssignedEnabled()}
                type="button"
                onClick={handleBulkAssignContributor}
                className={`ul-button ${classes["btn-icon"]} ${classes["bulk-assign"]} ${classes["dark-blue"]} `}
              >
                <FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>
              </button>
              <Tooltip
                target={`.bulkassign-target`}
                className="custom-tooltip"
                my="left top"
                at="left bottom"
              >
                <div>
                  <strong>Bulk Assign Task and Milestone Handlers.</strong>
                </div>
              </Tooltip>
            </>
          )}
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className={`update-task-status-target`}>
          <button
            disabled={!isTaskStatusUpdateEnabled()}
            onClick={handleBulkTaskStatusUpdate}
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["update-task-status"]}`}
          >
            <span>
              <FontAwesomeIcon icon={faBarsProgress} />
            </span>
          </button>
          <Tooltip
            target={`.update-task-status-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Update Status</strong>
            </div>
          </Tooltip>
        </div>

        <div className={`${classes["vl"]}`}></div>
        <div className="generatestatusreport-target">
          <button
            type="button"
            onClick={() => {
              if (ENABLED_GENERATE_STATUS_REPORT)
                setGenerateStatusReportDialogVisible(true);
            }}
            className={`ul-button ${classes["btn-icon"]} ${classes["generate-report"]} ${classes["blue"]} `}
            disabled={!ENABLED_GENERATE_STATUS_REPORT}
          >
            <FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon>
          </button>
          <Tooltip
            target={`.generatestatusreport-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Generate Status Report</strong>
            </div>
            {ENABLED_GENERATE_STATUS_REPORT && (
              <div>Click to generate status reports.</div>
            )}
            {!ENABLED_GENERATE_STATUS_REPORT && (
              <div>Feature coming soon...</div>
            )}
          </Tooltip>
        </div>
      </div>

      {timeEntryModalVisible && (
        <TimeEntryModal
          timeEntryModalSourceType={TimeEntryModalSourceType.TimeEntry}
          closeTimeEntryModal={() => setTimeEntryModalVisible(false)}
          visible={timeEntryModalVisible}
          e2eProjectLineFlexTaskIds={localSelectedTaskOrderline.map(
            (x) => x.e2EProjectLineFlexTaskId
          )}
        />
      )}

      <TimestampElements
        projectTimestampHook={projectTimestampHook}
        onECDAdjustmentsConfirm={handleECDAdjustmentsConfirm}
        onMassTimestampConfirm={handleMassTimestampConfirm}
      />

      <TimestampConfirmationDialog
        visible={timestampConfirmationDialogVisible}
        onCancel={() => setTimestampConfirmationDialogVisible(false)}
        onConfirm={handleTimestampConfirm}
        e2eProjectLineIds={projectTimestampHook.currentProjectLineIds}
      />
      {bulkContributorModalVisible && (
        <ProjectCollaboratorBulkAssignmentDialog
          onClose={onCollaboratorClose}
          show={bulkContributorModalVisible}
          projectCollaboratorBulkAssignmentDetails={
            projectCollaboratorBulkAssignmentDetails
          }
        />
      )}

      {bulkContributorErrorModalVisible && (
        <ProjectContributorBulkAssignmentErrorModal
          onClose={onBulkContributorErrorModalClose}
          visible={bulkContributorErrorModalVisible}
          projectContributorBulkAssignmentErrorModel={
            bulkContributorErrorDetail
          }
          isFlexTaskOnly={showFlexTaskOnly.isOn}
        />
      )}

      {generateStatusReportDialogVisible && (
        <GenerateStatusReportDialog
          onClose={() => setGenerateStatusReportDialogVisible(false)}
          visible={generateStatusReportDialogVisible}
        />
      )}

      <ProjectTaskStatusUpdateDialog
        visible={taskStatusUpdateDialogVisible}
        closeModal={handleUpdateTaskStatusSave}
        selectedTaskOrderlines={selectedTaskOrderlines}
      />
    </>
  );
};

export default ProjectListHeaderActions;
