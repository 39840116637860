import { Badge } from "primereact/badge";
import classes from "./ProjectCollaboratorAvatar.module.scss";
import PmrE2EProjectHandler from "../../interfaces/pmr-e2e-project-handlers";
import React, { useRef } from "react";
import clsx from "clsx";
import HandlerType from "../../enums/handler-type";
import PmrE2EProjectTaskHandler from "../../interfaces/pmr-e2e-project-task-handlers";
import { Tooltip } from "primereact/tooltip";
import _ from "lodash";
import { OverlayPanel } from "primereact/overlaypanel";
import SubmitOption from "../../enums/submit-option";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
interface ProjectCollaboratorAvatarProps {
  collaborator: PmrE2EProjectHandler[] | PmrE2EProjectTaskHandler[];
  onDoubleClick?: () => void;
  submitOption?: SubmitOption;
  isAdHoc?: boolean;
}
const getInitials = (name: string) => {
  if (name == "php.coesupport@ul.com" || name == "PHP COE Support") return "TC";

  return name
    ?.split(",")
    ?.map((value) => value.trim())
    ?.reverse()
    ?.map((value, index, array) =>
      index === 0 || index + 1 === array.length
        ? (value[0] ?? "").toUpperCase()
        : null
    )
    .join("");
};

const getHandlerClass = (handlerType: HandlerType) =>
  classes[HandlerType[handlerType]?.toLowerCase()];

const renderCollaboratorDisplayLabel = (
  handlerType: HandlerType
): string | null => {
  if (handlerType === HandlerType.OrderCreator) return null;

  switch (handlerType) {
    case HandlerType.FlexHandler:
      return "FLEX Handler";
    case HandlerType.GmaWBHandler:
      return "GMAWB Handler";
    case HandlerType.MilestoneHandler:
      return "Milestone Handler";
    case HandlerType.TaskHandler:
      return "Task Handler";
  }
};

const ProjectCollaboratorAvatar = (props: ProjectCollaboratorAvatarProps) => {
  const collaborators: PmrE2EProjectHandler[] | PmrE2EProjectTaskHandler[] =
    props?.collaborator || [];

  const overlayRef = useRef<OverlayPanel>(null);

  const groupCollaborators = _(collaborators)
    .groupBy((collab) => collab.userEmail?.toLowerCase())
    .map((value, key) => {
      return {
        id: _.uniqueId(
          `collaborators-${value[0].userEmail
            ?.replace(/[, @.]+/g, "")
            .trim()
            .toLowerCase()}`
        ),
        userEmail: key,
        displayName: value[0].displayName,
        handlerTypes: structuredClone(value)
          .sort((a, b) => a.handlerType - b.handlerType)
          .map((handler) =>
            renderCollaboratorDisplayLabel(handler.handlerType)
          ),
        hierarchyHandlerType: structuredClone(value)
          .filter(
            (collaborators) =>
              collaborators?.handlerType !== HandlerType.OrderCreator
          )
          .sort((a, b) => a?.handlerType - b?.handlerType)[0]?.handlerType,
      };
    })
    .sort((a, b) => a.hierarchyHandlerType - b.hierarchyHandlerType)
    .value();

  const badgeTemplate = (item: any, isGroupBadge: boolean) => {
    return (
      <>
        <Tooltip
          position="right"
          mouseTrack
          className={`${classes["tooltip"]}`}
          target={
            isGroupBadge
              ? `.badge-item-grouped-${item.id}`
              : `.badge-item-${item.id}`
          }
        >
          {!isGroupBadge && (
            <div className={`${classes["tooltip-displayName"]}`}>
              {item.displayName}
            </div>
          )}
          <div className={`${classes["tooltip-handlerLabel"]}`}>
            {item.handlerTypes?.map((handlerType: any) => (
              <div>{handlerType}</div>
            ))}
          </div>
        </Tooltip>
        {!isGroupBadge && (
          <Badge
            value={getInitials(item.displayName)}
            className={clsx(
              classes["badge"],
              getHandlerClass(item.hierarchyHandlerType),
              `badge-item-${item.id}`
            )}
            size="large"
          />
        )}

        {isGroupBadge && (
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Badge
              value={getInitials(item.displayName)}
              className={clsx(
                classes["badge"],
                getHandlerClass(item.hierarchyHandlerType),
                `badge-item-grouped-${item.id}`
              )}
              size="large"
            />
            <span className={`${classes["tooltip-displayName"]}`}>
              {item.displayName}
            </span>
          </div>
        )}
      </>
    );
  };

  const InlineBlankCollaboratorPlaceholder = () => {
      return (
      <>
        <FontAwesomeIcon className={clsx(
          classes["badge"],
          classes["blank-collaborator-icon"]
        )} icon={faCircleUser} />
      </>
      )
  }

  return (
    <>
      <div
        className={classes["badge-container"]}
        onDoubleClick={props.onDoubleClick}
        style={{
          cursor:
            props.onDoubleClick &&
            props.submitOption != SubmitOption.AuthorityReviewFlex
              ? "pointer"
              : "default",
        }}
      >
        {props.collaborator.length === 0 && props.isAdHoc && (
          <InlineBlankCollaboratorPlaceholder/>
        )}

        {groupCollaborators?.map((item, key) => {
          if (key >= 5) return <></>;
          return (
            <React.Fragment key={`${key}-${item.id}`}>
              {badgeTemplate(item, false)}
            </React.Fragment>
          );
        })}

        {groupCollaborators?.length > 5 && (
          <React.Fragment>
            <OverlayPanel
              className={`${classes["overlay-panel-container"]}`}
              showCloseIcon
              ref={overlayRef}
              dismissable
            >
              <div className={`${classes["overlay-panel-extra-handlers"]}`}>
                {groupCollaborators?.slice(5).map((item, key) => {
                  return (
                    <React.Fragment key={`${key}-${item.id}`}>
                      {badgeTemplate(item, true)}
                    </React.Fragment>
                  );
                })}
              </div>
            </OverlayPanel>
            <span
              onClick={(e) => overlayRef.current?.toggle(e)}
              style={{ cursor: "pointer" }}
            >
              <Badge
                className={`${classes["extrahandler"]}`}
                value={`+${groupCollaborators.length - 5}`}
                size="large"
              ></Badge>
            </span>
          </React.Fragment>
        )}
      </div>
    </>
  );
};
export default ProjectCollaboratorAvatar;
