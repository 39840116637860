import * as yup from "yup";
import ProjectTaskStatus from "../../enums/project-task-status";

const invalidCharacters = /^[^\\*?"<>|]*$/;
const handlerSchema = yup.object().shape({
  normalizedEmail: yup.string(),
  displayName: yup.string(),
});

const adHocTaskProjectAssociationDetailsSchema = yup.object().shape({
  flexTaskStatus: yup
    .number()
    .label("FLEX Task Status")
    .required("Required field; please select a Status for this new ad hoc task")
    .nullable()
    .test(
      "is-not-nan",
      "Required field; please select a Status for this new ad hoc task",
      (value) => value !== null
    ),
  onHoldReason: yup
    .string()
    .label("On Hold Reason")
    .nullable()
    .when("flexTaskStatus", (flexTaskStatus, schema) => {
      if (flexTaskStatus === ProjectTaskStatus.OnHold)
        return schema.required(
          "Required field; please select an On Hold Reason for this ad hoc task"
        );
      return schema;
    }),
  startDate: yup
    .date()
    .nullable()
    .label("Start Date")
    .when("minStartDate", (minStartDate, schema) => {
      if (minStartDate) {
        return schema.min(
          yup.ref("minStartDate"),
          "Start Date can't be earlier than order booked date."
        );
      }

      return schema;
    }),
  dueDate: yup
    .date()
    .nullable()
    .label("Due Date")
    .min(yup.ref("startDate"), "Due date can't be earlier than the start date"),
});

export const adHocTaskSchema = yup
  .object()
  .shape({
    taskName: yup
      .string()
      .matches(invalidCharacters, 'Remove invalid characters: \\ * ? " < > |')
      .max(50, "Task Name cannot exceed 50 characters")
      .label("Task Name")
      .required("Required field; please input a Task Name"),
    predefinedTaskTypeId: yup.string().nullable(),
    taskHandler: handlerSchema.nullable().label("Task Handler"),
    shortDescription: yup
      .string()
      .max(50, "Short Description cannot exceed 50 characters")
      .label("Short Description"),
    milestoneName: yup
      .string()
      .matches(invalidCharacters, 'Remove invalid characters: \\ * ? " < > |')
      .trim()
      .label("Milestone Name")
      .required("Required field; please input a Milestone Name"),
    milestoneHandler: handlerSchema.nullable().label("Milestone Handler"),
    orderLineAssociations: yup
      .array()
      .min(1, "Required field; must have at least 1 selected FLEX Project")
      .required()
      .label("FLEX Project Association")
      .of(yup.string()),
    predecessor: yup.array().label("Predecessor").nullable(),
    customerVisibility: yup.number().label("Customer Visibility").required(),
    comment: yup.string().label("Comment"),
    minStartDate: yup.date().nullable(),
    flexProjectsAssociationDetails: yup
      .array()
      .of(adHocTaskProjectAssociationDetailsSchema),
  })
  .concat(adHocTaskProjectAssociationDetailsSchema);
