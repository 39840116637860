import { useEffect, useState } from "react";
import classes from "./Pagination.module.scss";

type PaginationProps = {
  currentPage: number;
  pageSize: number;
  pageSizes?: number[];
  totalRecords: number;
  onPageSizeChange: (size: number) => void;
  onPageChange: (pageInfo: PageChangeInfo) => void;
  statSlot?: JSX.Element;
};

interface PageChangeInfo {
  skip: number;
  page: number;
  pageSize: number;
}

const Pagination: React.FC<PaginationProps> = ({
  onPageSizeChange,
  pageSizes = [50, 100, 200, 500, 1000],
  totalRecords,
  onPageChange,
  currentPage,
  pageSize,
  statSlot,
}) => {
  const [manualPageInput, setManualPageInput] = useState(0);
  const totalPages = Math.ceil(totalRecords / pageSize);

  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  useEffect(() => {
    if (totalRecords === 0) setManualPageInput(0);
    else setManualPageInput(currentPage);
  }, [currentPage, totalRecords]);

  const firstIndex = () => {
    const result = (currentPage - 1) * pageSize + 1;

    return totalRecords === 0 ? 0 : result;
  };

  const lastIndex = () => {
    if (totalRecords === 0) return 0;

    let last = pageSize * currentPage;

    if (totalRecords < last) return totalRecords;

    return last;
  };

  const getSkip = (page: number, pageSize: number) => {
    return page * pageSize;
  };

  const handleFirstPage = () => {
    const newPage = 1;

    onPageChange({
      skip: getSkip(newPage, pageSize),
      page: newPage,
      pageSize,
    });
  };

  const handlePreviousPage = () => {
    const newPage = currentPage - 1;

    onPageChange({
      skip: getSkip(newPage, pageSize),
      page: newPage,
      pageSize,
    });
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;

    onPageChange({
      skip: getSkip(newPage, pageSize),
      page: newPage,
      pageSize,
    });
  };

  const handleLastPage = () => {
    const newPage = totalPages;

    onPageChange({
      skip: getSkip(newPage, pageSize),
      page: newPage,
      pageSize,
    });
  };

  const handleManualPageChange = (e: any) => {
    setManualPageInput(e.target.value);
  };

  const handleManualPageBlur = (e: any) => {
    const newPage = e.target.value;

    if (newPage < 0 || newPage > totalPages) return;

    onPageChange({
      skip: getSkip(newPage, pageSize),
      page: newPage,
      pageSize,
    });
  };

  const handlePageSizeChange = (e: any) => {
    const newPageSize = +e.target.value;
    onPageSizeChange(newPageSize);
  };

  return (
    <div className={`ul-pagination`}>
      <div className="ul-pagination__itemsPerPage">
        <label>Items per page</label>
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          className="ul-select ul-pagination__select -records"
          id="itemsPerPage"
        >
          {pageSizes.map((data) => {
            return (
              <option key={data} value={data}>
                {data}
              </option>
            );
          })}
        </select>
      </div>
      <div
        className={`${classes["ul-pagination__stats"]} ul-pagination__stats`}
      >
        <span>
          {firstIndex()} - {lastIndex()} of {totalRecords}
        </span>
        {statSlot}
      </div>
      <div className="ul-pagination__pager">
        <button
          type="button"
          className="ul-button -app-secondary -small"
          disabled={!hasPreviousPage}
          onClick={handleFirstPage}
        >
          <i className="material-icons ul-button__icon">first_page</i>
        </button>
        <button
          type="button"
          className="ul-button -app-secondary -small"
          disabled={!hasPreviousPage}
          onClick={handlePreviousPage}
        >
          <i className="material-icons">navigate_before</i>
        </button>
        <input
          type="number"
          aria-label="page-number"
          className="ul-textbox ul-pagination__jumpTo"
          value={manualPageInput}
          onChange={handleManualPageChange}
          onBlur={handleManualPageBlur}
          min={totalRecords === 0 ? 0 : 1}
          max={totalPages}
        />
        <span className="ul-pagination__pagerTotal">
          of {totalPages} page(s)
        </span>
        <button
          type="button"
          className="ul-button -app-secondary -small"
          disabled={!hasNextPage}
          onClick={handleNextPage}
        >
          <i className="material-icons ul-button__icon">navigate_next</i>
        </button>
        <button
          type="button"
          className="ul-button -app-secondary -small"
          disabled={!hasNextPage}
          onClick={handleLastPage}
        >
          <i className="material-icons">last_page</i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
