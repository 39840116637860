/* eslint-disable react-hooks/exhaustive-deps */
import { Field, FieldProps, useFormikContext } from "formik";
import moment from "moment";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import ProjectTaskStatus from "../../../enums/project-task-status";
import {
  AdhocStatusDropdown,
  CalculateAdhocStatusDropdown,
} from "../../../utils/AdhocUtils";
import { adHocTaskSchema } from "../ad-hoc-task-schema";
import classes from "./FlexProjectAssociationsDataTable.module.scss";
import { Dropdown } from "primereact/dropdown";
import PmrE2EOnHoldReason from "../../../interfaces/pmr-e2e-project-onhold-reason";

type Props = {
  holdReasonOptions: PmrE2EOnHoldReason[];
};

const FlexProjectAssociationsDataTable = (props: Props) => {
  const { setFieldValue, values } =
    useFormikContext<yup.InferType<typeof adHocTaskSchema>>();
  const { flexProjectsAssociationDetails } = values;
  const [flexTaskStatusDropdownOptions, setFlexTaskStatusDropdownOptions] =
    useState<Record<number, AdhocStatusDropdown[]>>({});

  useEffect(() => {
    for (
      let i = 0;
      i < (values.flexProjectsAssociationDetails?.length || 0);
      i++
    ) {
      const association = values.flexProjectsAssociationDetails![i];
      const newStatusOptions = CalculateAdhocStatusDropdown({
        startDate: association.startDate,
        taskHandler: values.taskHandler,
      });

      setFlexTaskStatusDropdownOptions((previousOptions) => ({
        ...previousOptions,
        [i]: newStatusOptions,
      }));

      if (
        !newStatusOptions.some(
          (option) => option.value === association.flexTaskStatus
        )
      ) {
        setFieldValue(
          `flexProjectsAssociationDetails[${i}].flexTaskStatus`,
          newStatusOptions[0].value
        );
      }
    }
  }, [
    values.taskHandler,
    JSON.stringify(
      values.flexProjectsAssociationDetails?.map(
        (association) => association.startDate
      )
    ),
  ]);

  useEffect(() => {
    if (values.predecessor?.length) {
      const removedStartDatesAssociations =
        values.flexProjectsAssociationDetails?.map((association) => ({
          ...association,
          startDate: null,
        }));

      setFieldValue(
        "flexProjectsAssociationDetails",
        removedStartDatesAssociations
      );
    }
  }, [JSON.stringify(values.predecessor)]);

  useEffect(() => {
    for (
      let i = 0;
      i < (values.flexProjectsAssociationDetails?.length || 0);
      i++
    ) {
      const association = values.flexProjectsAssociationDetails![i];
      if (association.flexTaskStatus !== ProjectTaskStatus.OnHold) {
        setFieldValue(
          `flexProjectsAssociationDetails[${i}].onHoldReason`,
          null
        );
      }
    }
  }, [
    JSON.stringify(
      values.flexProjectsAssociationDetails?.map(
        (association) => association.flexTaskStatus
      )
    ),
  ]);

  const handleStartDateChange = (e: CalendarChangeParams, rowIndex: number) => {
    setFieldValue(
      `flexProjectsAssociationDetails[${rowIndex}].startDate`,
      e.value
    );

    if (!e.value) return;

    const startDate = moment(e.value as Date)
      .startOf("day")
      .toDate();
    const today = moment(new Date()).startOf("day").toDate();

    if (startDate > today) {
      setFieldValue(
        `flexProjectsAssociationDetails[${rowIndex}]flexTaskStatus`,
        ProjectTaskStatus.NotStarted
      );
    } else {
      setFieldValue(
        `flexProjectsAssociationDetails[${rowIndex}]flexTaskStatus`,
        ProjectTaskStatus.InProgress
      );
    }
  };

  const isStartDateDisabled = values.predecessor?.length! > 0;

  return (
    <DataTable
      className={classes["datatable"]}
      value={flexProjectsAssociationDetails}
      size="small"
      showGridlines
    >
      <Column
        header="FLEX Project Name"
        style={{ maxWidth: "200px" }}
        className={classes["no-wrap-text"]}
        body={(rowData) => (
          <span title={rowData.flexProjectName}>{rowData.flexProjectName}</span>
        )}
      />
      <Column
        header="Order Line Number"
        style={{ maxWidth: "200px" }}
        className={classes["no-wrap-text"]}
        body={(rowData) => (
          <span title={rowData.orderLineDescription}>
            {rowData.orderLineDescription}
          </span>
        )}
      />
      <Column
        header="Start Date"
        style={{ maxWidth: "8rem" }}
        body={(rowData, { rowIndex }) => (
          <Field name={`flexProjectsAssociationDetails[${rowIndex}].startDate`}>
            {({ field, meta }: FieldProps) => (
              <Calendar
                {...field}
                minDate={
                  values.flexProjectsAssociationDetails![rowIndex]
                    ?.orderBookedDate
                    ? new Date(
                        values.flexProjectsAssociationDetails![
                          rowIndex
                        ].orderBookedDate
                      )
                    : undefined
                }
                showButtonBar
                onChange={(e) => handleStartDateChange(e, rowIndex)}
                monthNavigator
                yearNavigator
                yearRange={"2018:2030"}
                className={classNames(
                  meta.error && meta.touched && "p-invalid"
                )}
                readOnlyInput
                disabled={isStartDateDisabled}
              />
            )}
          </Field>
        )}
      />
      <Column
        header="Due Date"
        style={{ maxWidth: "8rem" }}
        body={(rowData, { rowIndex }) => (
          <Field name={`flexProjectsAssociationDetails[${rowIndex}].dueDate`}>
            {({ field, meta }: FieldProps) => (
              <Calendar
                {...field}
                showButtonBar
                monthNavigator
                yearNavigator
                yearRange={"2018:2030"}
                className={classNames(
                  meta.error && meta.touched && "p-invalid"
                )}
                minDate={new Date()}
                readOnlyInput
              />
            )}
          </Field>
        )}
      />
      <Column
        header="FLEX Task Status"
        style={{ maxWidth: "12rem" }}
        body={(rowData, { rowIndex }) => (
          <Field
            name={`flexProjectsAssociationDetails[${rowIndex}].flexTaskStatus`}
          >
            {({ field, meta }: FieldProps) => (
              <Dropdown
                {...field}
                style={{ width: "100%" }}
                placeholder="-- SELECT --"
                disabled={flexTaskStatusDropdownOptions[rowIndex]?.length <= 1}
                inputId={field.name}
                className={classNames(
                  meta.touched && meta.error && "p-invalid"
                )}
                options={flexTaskStatusDropdownOptions[rowIndex]}
                optionLabel="label"
                optionValue="value"
              />
            )}
          </Field>
        )}
      />
      <Column
        header="On Hold Reason"
        body={(rowData, { rowIndex }) => (
          <Field
            name={`flexProjectsAssociationDetails[${rowIndex}].onHoldReason`}
          >
            {({ field, meta }: FieldProps) => (
              <Dropdown
                inputId={field.name}
                {...field}
                onChange={(e) =>
                  setFieldValue(
                    `flexProjectsAssociationDetails[${rowIndex}].onHoldReason`,
                    e.value
                  )
                }
                style={{ width: "100%" }}
                name={"onHoldReason"}
                className={classNames(
                  meta.touched && meta.error && "p-invalid"
                )}
                disabled={rowData.flexTaskStatus !== ProjectTaskStatus.OnHold}
                placeholder="--SELECT--"
                options={props.holdReasonOptions}
                optionValue="taskStatusReason"
                optionLabel="taskStatusReason"
                panelClassName={classes["on-hold-reason-dropdown-panel"]}
                scrollHeight="300px"
              />
            )}
          </Field>
        )}
      />
    </DataTable>
  );
};

export default FlexProjectAssociationsDataTable;
