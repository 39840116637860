import {
  faChevronDown,
  faChevronRight,
  faFolderPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as axios from "axios";
import { flatten, get, max, min } from "lodash";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Column, ColumnBodyOptions } from "primereact/column";
import {
  DataTable,
  DataTableColReorderParams,
  DataTableColumnResizeEndParams,
  DataTableRowExpansionTemplate,
  DataTableRowToggleParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { calculateOrderlinesTaskStatus } from "../../../features/projectManagement/helpers/status";
import {
  checkProjectFlexTask,
  expandProjectFlexTasksByMilestoneId,
  refreshOrderlineRefiners,
  selectFlexTaskMilestonesList,
  selectIsTimeEntryModalSyncing,
  selectProjectFlexTasksList,
  selectRefinerLineResult,
  selectSelectedMilestones,
  selectSelectedTaskOrderlines,
  selectSelectRefreshProjectId,
  setExpandedProjectFlexTasks,
  updateRefreshProjectId,
  updateSelectedMilestones,
  updateSelectedTaskOrderlines,
  updateTaskOrderlineSyncingStatus,
  upsertProjectFlexTasks,
  selectProjects,
  selectedProjectManagementViewProjects,
} from "../../../features/projectManagement/projectManagementSlice";
import {
  selectRefinerState,
  selectShowFlexTasks,
  selectShowHideColumnProjectsTableTask,
  selectTimeEntryUpdateTaskOrderlines,
  updateShowHideColumnByTable,
  updateTimeEntryUpdateTaskOrderlines,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import usePmrShiftSelect, {
  ComponentWithIndices,
} from "../../../hooks/usePmrShiftSelect";
import ProjectService from "../../../services/ProjectService";
import { formatDateWithTimezone } from "../../../shared/date-helper/DateHelper";
import { AssignCoHandlerModel } from "../../../shared/models/service-models/AssignCOHandler.model";
import { GMATCTaskName } from "../../../utils/constants/flex-task-name.constants";
import { formatNumber } from "../../../utils/helpers/number.helpers";
import { GridColumnModel } from "../data/project-grid-columns";
import {
  projectTaskColumnCollaborator,
  projectTaskColumnName,
  projectTaskColumnStatus,
  projectTaskCompletionDate,
  projectTaskDueDate,
  projectTaskMyHours,
  projectTaskStartDate,
  projectTaskTotalHours,
} from "../data/project-task-grid-columns";
import ProjectMilestoneList from "../project-milestone-list/ProjectMilestoneList";
import ProjectOrderLineList from "../project-order-line-list/ProjectOrderLineList";
import AdHocTaskFormDialog from "../shared/components/ad-hoc-task-form-dialog/AdHocTaskFormDialog";
import ProjectAssignCoHandlerDialog from "../shared/components/project-assign-cohandler-dialog/ProjectAssignCoHandlerDialog";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import ProjectTaskStatusTemplate from "../shared/components/project-task-status-template/ProjectTaskStatusTemplate";
import ProjectStatus from "../shared/enums/project-status";
import ProjectTaskStatus from "../shared/enums/project-task-status";
import { E2EProjectLineMilestoneGroupItemModel } from "../shared/interfaces/pmr-e2e-project-milestone-group-item-model";
import { E2EProjectLineFlexTaskGroupItemModel } from "../shared/interfaces/pmr-e2e-project-task-group-item-model";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../shared/interfaces/pmr-e2e-project-task-model";
import classes from "./ProjectTaskList.module.scss";
import useTimeEntrySync from "../../../hooks/useTimeEntrySync";
interface ProjectTaskListProps extends ComponentWithIndices {
  projectId: string;
  projectStatus: ProjectStatus;
  projectName: string;
  isActive: boolean;
}

const ProjectTaskList = (props: ProjectTaskListProps) => {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const projects = useSelector(selectedProjectManagementViewProjects);
  const [loading, setLoading] = useState(false);
  const [loadingFromRefreshProject, setLoadingFromRefreshProject] =
    useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const selectProjectsColumns = useSelector(
    selectShowHideColumnProjectsTableTask
  );
  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);
  const projectTasks =
    projectFlexTasksList.find((list) => list.e2eProjectId === props.projectId)
      ?.tasks || [];
  const [columns, setColumns] = useState<GridColumnModel[]>([]);
  const datatableRef = useRef<any>(null);

  const dispatch = useDispatch();
  const selectedMilestones = useSelector(selectSelectedMilestones);
  const selectedTaskOrderlines = useSelector(selectSelectedTaskOrderlines);
  const taskMilestoneList = useSelector(selectFlexTaskMilestonesList);
  const updatedTaskOrderlinesFromTimeEntry = useSelector(
    selectTimeEntryUpdateTaskOrderlines
  );
  const isFlexTaskOnly = useSelector(selectShowFlexTasks);
  const [isProjectListLoaded, setIsProjectListLoaded] = useState(false);
  const [e2EProjectMilestoneid, setE2EProjectMilestoneid] = useState(
    urlSearch.get("e2EProjectMilestoneId")
  );
  const [hasGMATCTask, setHasGMATCTask] = useState<boolean | null>(null);
  const shiftSelect = usePmrShiftSelect(props.depthIndices, projectTasks);

  const refinerState = useSelector(selectRefinerState);
  const refinerLineResult = useSelector(selectRefinerLineResult);
  const flexTaskNamesRefiner: { label: string }[] =
    refinerState.value?.orderLines?.flexTaskNames ?? [];

  const [showAssignCoHandlerDialog, setShowAssignCoHandlerDialog] =
    useState<boolean>(false);

  const [assignCoHandlerParam, setAssignCoHandlerParam] = useState<any>();

  const [adHocTaskDialogVisible, setAdHocTaskDialogVisible] =
    useState<boolean>(false);

  const refreshProjectId = useSelector(selectSelectRefreshProjectId);
  const timeEntrySyncHook = useTimeEntrySync();
  const isTimeEntryModalSyncing = useSelector(selectIsTimeEntryModalSyncing);

  useEffect(() => {
    if (refreshProjectId) {
      if (refreshProjectId == props.projectId) {
        getList(true);
        dispatch(updateRefreshProjectId(""));
      }
    }
  }, [refreshProjectId]);

  useEffect(() => {
    getList();
  }, [isFlexTaskOnly.isOn, props.isActive]);

  useEffect(() => {
    if (shiftSelect.rangeItems.length > 0) {
      dispatch(
        updateSelectedTaskOrderlines(
          shiftSelect.rangeItems
            .map((item) => item.e2EProjectLineFlexTaskGroupItems)
            .flat()
        )
      );
      dispatch(
        updateSelectedMilestones(
          shiftSelect.rangeItems
            .map((item) =>
              item.e2EProjectLineMilestoneGroupItems.map(
                getSelectedChildMilestoneOrderlineState
              )
            )
            .flat()
        )
      );
    }
  }, [shiftSelect.rangeItems]);

  useEffect(() => {
    if (updatedTaskOrderlinesFromTimeEntry) {
      checkIfHasTimeEntryUpdate();
    }
  }, [updatedTaskOrderlinesFromTimeEntry]);

  useEffect(() => {
    if (!!e2EProjectMilestoneid && isProjectListLoaded) {
      dispatch(
        expandProjectFlexTasksByMilestoneId({
          e2eProjectMilestoneId: e2EProjectMilestoneid,
        })
      );

      setE2EProjectMilestoneid(null);
    }
  }, [isProjectListLoaded]);

  useEffect(() => {
    setColumns(selectProjectsColumns!.fields);
  }, [selectProjectsColumns]);

  useEffect(() => {
    if (datatableRef.current) {
      datatableRef.current.restoreColumnWidths();
      datatableRef.current.reset();
    }
  }, [columns]);

  const checkIfHasTimeEntryUpdate = () => {
    const updatedE2EProjectLineFlexTaskIds =
      updatedTaskOrderlinesFromTimeEntry.map(
        (task) => task.e2EProjectLineFlexTaskId
      );
    const e2EProjectLineFlexTaskIds = flatten(
      projectTasks.map((task) => task.e2EProjectLineFlexTaskIds)
    );
    const hasAnUpdate = e2EProjectLineFlexTaskIds.some(
      (id) => updatedE2EProjectLineFlexTaskIds.indexOf(id) > -1
    );
    if (hasAnUpdate) {
      getList();
      dispatch(updateTimeEntryUpdateTaskOrderlines([]));
    }
  };

  const getListCancelSource = useRef<any>(null);

  const getList = async (isRefreshProject: boolean = false) => {
    setLoading(true);

    //Note that isRefreshProject / LoadingFromRefreshProject is used to destroy the lower level component, so it will force to reload the data.
    if (isRefreshProject) setLoadingFromRefreshProject(true);

    const request = {
      projectId: props.projectId,
      isMilestoneListLoaded: !isFlexTaskOnly.isOn,
    };

    if (getListCancelSource.current) {
      getListCancelSource.current.cancel();
    }

    getListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const response = await ProjectService.getProjectTasksByProject(
        request,
        getListCancelSource.current
      );

      if (response.isSuccess) {
        let records = response?.data?.e2EProjectLineFlexTasks?.map(
          (task: PmrE2EProjectTaskModel, index: number) => {
            setHasGMATCTask(
              task.flexTaskName.toLowerCase() === GMATCTaskName.toLowerCase()
            );
            task.workBreakdownStructure = index + 1;
            task.isChecked = false;
            task.timeEntrySnycFlexTaskList = task.e2EProjectLineFlexTaskIds.map(
              (id: string) => {
                return {
                  e2EProjectLineFlexTaskId: id,
                  isTimeEntrySyncing: false,
                };
              }
            );

            processExpandOnLoadList(task, index, response, isRefreshProject);
            processTimeEntrySyncOnLoadList(task);

            return task;
          }
        );

        setHasGMATCTask(
          response?.data?.e2EProjectLineFlexTasks.some(
            (tasks: PmrE2EProjectTaskModel) =>
              tasks.flexTaskName.toLowerCase() === GMATCTaskName.toLowerCase()
          )
        );

        dispatch(
          upsertProjectFlexTasks({
            e2eProjectId: props.projectId,
            tasks: records,
            isTaskMutated: projectFlexTasksList.find((item) => {
              return item.e2eProjectId === props.projectId;
            })?.isTaskMutated,
          } as PmrE2EProjectTaskModelList)
        );
        setIsProjectListLoaded(true);
      }
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Task request cancelled");
        return;
      }
    }
    setLoading(false);
    if (isRefreshProject) setLoadingFromRefreshProject(false);
  };

  const processExpandOnLoadList = (
    task: PmrE2EProjectTaskModel,
    index: number,
    response: any,
    isRefreshProject: boolean
  ) => {
    const isTaskMutated = projectFlexTasksList.find((item) => {
      return item.e2eProjectId === props.projectId;
    })?.isTaskMutated;
    if (isFlexTaskOnly.isFromClicked || isRefreshProject || isTaskMutated) {
      const prevTaskDetailsFromRedux = projectTasks.find(
        (x) => x.workBreakdownStructure === task.workBreakdownStructure
      );
      if (prevTaskDetailsFromRedux)
        task.isExpand = prevTaskDetailsFromRedux.isExpand;
    } else {
      task.isExpand =
        index ===
        response.data.e2EProjectLineFlexTasks.findIndex(
          (task: PmrE2EProjectTaskModel) =>
            calculateOrderlinesTaskStatus(task) === ProjectTaskStatus.InProgress
        );
    }
  };

  const processTimeEntrySyncOnLoadList = (task: PmrE2EProjectTaskModel) => {
    const prevTaskDetailsFromRedux = projectTasks.find(
      (x) => x.workBreakdownStructure === task.workBreakdownStructure
    );

    if (!prevTaskDetailsFromRedux) {
      for (let timeEntrySnycFlexTask of task.timeEntrySnycFlexTaskList) {
        timeEntrySnycFlexTask.isTimeEntrySyncing = true;
        timeEntrySyncHook.syncTimeEntryForEachTask([
          timeEntrySnycFlexTask.e2EProjectLineFlexTaskId,
        ]);
      }
    }
  };

  const processTimeEntrySyncOnRowExpand = (
    rowData: PmrE2EProjectTaskModel,
    currentExpandState: boolean
  ) => {
    const currentlyIsExpand = currentExpandState;
    if (currentlyIsExpand) return;
    const task = projectTasks?.find(
      (task) => task.workBreakdownStructure === rowData.workBreakdownStructure
    );
    if (task) {
      for (let id of rowData.e2EProjectLineFlexTaskIds) {
        const te = task.timeEntrySnycFlexTaskList.find(
          (x) => x.e2EProjectLineFlexTaskId == id
        );
        if (!te?.isTimeEntrySyncing && !isTimeEntryModalSyncing) {
          dispatch(
            updateTaskOrderlineSyncingStatus({
              projectId: props.projectId,
              workBreakdownStructure: rowData.workBreakdownStructure,
              e2EProjectLineFlexTaskId: id,
            })
          );
          timeEntrySyncHook.syncTimeEntryForEachTask([id]);
        }
      }
    }
  };

  const handleRowExpand = (
    rowData: PmrE2EProjectTaskModel,
    currentExpandState: boolean
  ) => {
    processTimeEntrySyncOnRowExpand(rowData, currentExpandState);
    const expandedRows = getExpandedRows();
    const newExpandedRowsState = {
      ...expandedRows,
      [rowData.workBreakdownStructure]: !currentExpandState,
    };

    dispatch(
      setExpandedProjectFlexTasks({
        e2eProjectId: props.projectId,
        expandedRows: newExpandedRowsState,
        isTaskMutated: true,
      })
    );
  };

  const getExpandedRows = () => {
    return projectTasks.reduce((result, task) => {
      if (task.isExpand) {
        result[task.workBreakdownStructure] = true;
      }
      return result;
    }, {} as Record<string, boolean>);
  };

  const handleRowCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectTaskModel
  ) => {
    dispatch(
      checkProjectFlexTask({
        e2eProjectId: props.projectId,
        workBreakdownStructure: rowData.workBreakdownStructure,
        checked: event.checked,
      })
    );
  };

  const openAdHocTaskFormDialog = () => {
    setAdHocTaskDialogVisible(true);
  };

  const isAdhocSyncing = projects.find(
    (x) => x.id === props.projectId
  )?.isAdhocSyncing;
  const isAdHocTaskDisabled = props.projectStatus === ProjectStatus.Completed;
  const isAdHocTaskVisible =
    process.env.REACT_APP_FEATUREFLAG_ADD_AD_HOC_TASK_VISIBLE === "true";

  const taskNameHeaderTemplate = (
    <div className={classes["task-name-header"]}>
      Task Name
      {isAdhocSyncing && (
        <button
          disabled={true}
          title="Setting up ad hoc tasks in GMAWB project..."
          style={{ opacity: 0.5 }}
        >
          <FontAwesomeIcon
            className={classes["adhoc-task-icon"]}
            icon={faFolderPlus}
          />
        </button>
      )}
      {!isAdhocSyncing && isAdHocTaskVisible && (
        <button
          disabled={isAdHocTaskDisabled}
          onClick={openAdHocTaskFormDialog}
          title="Add Adhoc Task"
        >
          <FontAwesomeIcon
            className={classes["adhoc-task-icon"]}
            icon={faFolderPlus}
          />
        </button>
      )}
    </div>
  );

  const tableColumnHeader = (col: GridColumnModel) => {
    if (col.id === projectTaskColumnName) {
      return taskNameHeaderTemplate;
    }

    return (
      <div>
        <span title={col.value}>{col.value}</span>
      </div>
    );
  };

  const tableColumnBody = (
    rowData: PmrE2EProjectTaskModel,
    col: ColumnBodyOptions
  ) => {
    return (
      <div
        title={get(rowData, col.field)}
        className={"text-container-ellipsis"}
      >
        {get(rowData, col.field)}
      </div>
    );
  };
  const isTagGroupLevelSelected = (rowData: PmrE2EProjectTaskModel) => {
    if (isFlexTaskOnly.isOn) return isAllChildTaskOrderlinesSelected(rowData);
    return isAllChildMilestoneOrderlinesSelected(rowData);
  };

  const isAllChildTaskOrderlinesSelected = (
    rowData: PmrE2EProjectTaskModel
  ): boolean => {
    const selectedTaskOrderlineIds = selectedTaskOrderlines.map(
      (taskOrderline) => taskOrderline.e2EProjectLineFlexTaskId
    );
    let rowTaskOrderlineIds = rowData.e2EProjectLineFlexTaskGroupItems
      .filter(
        (item) =>
          ![ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
            item.flexTaskStatus
          )
      )
      .map((item) => item.e2EProjectLineFlexTaskId);

    if (refinerLineResult.flexTaskIds !== null) {
      rowTaskOrderlineIds = rowTaskOrderlineIds.filter((item) =>
        refinerLineResult.flexTaskIds?.includes(item)
      );
    }

    if (selectedTaskOrderlineIds.length <= 0 || rowTaskOrderlineIds.length <= 0)
      return false;

    return rowTaskOrderlineIds.every((taskOrderline) =>
      selectedTaskOrderlineIds.includes(taskOrderline)
    );
  };
  const isAllChildMilestoneOrderlinesSelected = (
    rowData: PmrE2EProjectTaskModel
  ): boolean => {
    let isSelected = false;
    const selectedMilestoneIds = selectedMilestones.map(
      (milestoneOrderline) => milestoneOrderline.id
    );
    let rowMilestoneOrderlineIds = rowData.e2EProjectLineMilestoneGroupItems
      .filter(
        (item) =>
          ![ProjectStatus.Completed, ProjectStatus.Cancelled].includes(
            item.status
          )
      )
      .map((item) => item.e2EProjectLineMilestoneId);

    if (refinerLineResult.milestoneIds !== null) {
      rowMilestoneOrderlineIds = rowMilestoneOrderlineIds.filter((item) =>
        refinerLineResult.milestoneIds?.includes(item)
      );
    }

    if (
      selectedMilestoneIds.length <= 0 ||
      rowMilestoneOrderlineIds.length <= 0
    )
      return false;

    isSelected = rowMilestoneOrderlineIds.every((milestoneOrderline) =>
      selectedMilestoneIds.includes(milestoneOrderline)
    );
    return isSelected;
  };

  const handleRowParentTaskCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectTaskModel
  ) => {
    if (isFlexTaskOnly.isOn)
      return handleChildTaskOrderlineCheck(event, rowData);
    return handleChildMilestoneOrderlineCheck(event, rowData);
  };

  const handleChildTaskOrderlineCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectTaskModel
  ) => {
    rowData.e2EProjectLineFlexTaskGroupItems
      .filter(
        (item) =>
          ![ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
            item.flexTaskStatus
          )
      )
      .forEach((taskOrderline: E2EProjectLineFlexTaskGroupItemModel) => {
        dispatch(
          updateSelectedTaskOrderlines({
            isChecked: event.checked,
            taskOrderline: getSelectedChildTaskOrderlineState(taskOrderline),
          })
        );
      });
  };

  const getSelectedChildTaskOrderlineState = (
    taskOrderline: E2EProjectLineFlexTaskGroupItemModel
  ) => {
    return {
      e2EProjectLineFlexTaskId: taskOrderline.e2EProjectLineFlexTaskId,
      status: taskOrderline.flexTaskStatus,
      isRevenueRecognition: taskOrderline.isRevenueRecognition,
      isAdHoc: taskOrderline.isAdhoc,
    };
  };

  const handleChildMilestoneOrderlineCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectTaskModel
  ) => {
    rowData.e2EProjectLineMilestoneGroupItems
      .filter(
        (item) =>
          ![ProjectStatus.Completed, ProjectStatus.Cancelled].includes(
            item.status
          )
      )
      .forEach((milestoneOrderline: E2EProjectLineMilestoneGroupItemModel) => {
        dispatch(
          updateSelectedMilestones({
            milestone:
              getSelectedChildMilestoneOrderlineState(milestoneOrderline),
            isChecked: event.checked,
          })
        );
      });
  };

  const getSelectedChildMilestoneOrderlineState = (
    milestoneOrderline: E2EProjectLineMilestoneGroupItemModel
  ) => {
    return {
      id: milestoneOrderline.e2EProjectLineMilestoneId,
      status: milestoneOrderline.status,
      e2EProjectLineId: milestoneOrderline.e2EProjectLineId,
      e2EProjectLineFlexTaskId: milestoneOrderline.e2EProjectLineFlexTaskId,
      isRevenueRecognition: milestoneOrderline.isRevenueRecognition,
      isLastMilestone: milestoneOrderline.isLastMilestone,
      isAdHoc: milestoneOrderline.isAdhoc,
    };
  };

  const nameColumnBodyTemplate = (
    rowData: PmrE2EProjectTaskModel,
    col: ColumnBodyOptions
  ) => {
    const isCheckboxDisabled =
      rowData.e2EProjectLineFlexTaskGroupItems.length > 0
        ? rowData.e2EProjectLineFlexTaskGroupItems.every((item) =>
            [ProjectTaskStatus.Cancelled, ProjectTaskStatus.Completed].includes(
              item.flexTaskStatus
            )
          )
        : false;
    return (
      <div className={`${classes["task-name-container"]}`}>
        <div className={`${classes["left"]}`}>
          <Checkbox
            disabled={isCheckboxDisabled}
            checked={!isCheckboxDisabled && isTagGroupLevelSelected(rowData)}
            onChange={(event: CheckboxChangeParams) => {
              shiftSelect.register(
                [...props.depthIndices, col.rowIndex],
                event
              );
              handleRowParentTaskCheck(event, rowData);
              handleRowCheck(event, rowData);
            }}
            className={`${classes["task-checkbox"]}`}
          />
          {!rowData.isExpand && (
            <span
              className={`${classes["task-row-expander"]} ${
                "isNotExpanded-" + col.rowIndex
              } task-expander-${col.rowIndex}`}
              onClick={() => handleRowExpand(rowData, rowData.isExpand)}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`${classes["task-row-expander"]}`}
              />
            </span>
          )}
          {rowData.isExpand && (
            <span
              className={`${classes["task-row-expander"]} ${
                "isExpanded-" + col.rowIndex
              } task-expander-${col.rowIndex}`}
              onClick={(event: any) =>
                handleRowExpand(rowData, rowData.isExpand)
              }
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`${classes["task-row-expander"]}`}
              />
            </span>
          )}
          {rowData.isAdHoc && "*"}
          {get(rowData, col.field)}
        </div>
      </div>
    );
  };

  const statusColumnBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    const status = calculateOrderlinesTaskStatus(rowData);

    return (
      <ProjectTaskStatusTemplate
        tooltip="Status determined based on underlying FLEX Task Statuses."
        status={status}
        isAdhoc={rowData.isAdHoc}
      ></ProjectTaskStatusTemplate>
    );
  };

  const collaboratorColumnBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    return (
      <ProjectCollaboratorAvatar
        collaborator={rowData[projectTaskColumnCollaborator]}
      ></ProjectCollaboratorAvatar>
    );
  };

  const startDateBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    const dates = rowData.e2EProjectLineFlexTaskGroupItems
      .map((item) => item.startDate as Date)
      .filter((date) => date !== null);

    if (dates.length === 0) return "";

    const startDate = min(dates);
    if (!startDate) return "";
    return formatDateWithTimezone(startDate);
  };

  const dueDateBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    const dates = rowData.e2EProjectLineFlexTaskGroupItems
      .map((item) => item.dueDate as Date)
      .filter((date) => date !== null);

    if (dates.length === 0) return "";

    const dueDate = max(dates);
    if (!dueDate) return "";
    return formatDateWithTimezone(dueDate);
  };

  const completionDateBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    const items = rowData.e2EProjectLineFlexTaskGroupItems;

    const taskStatuses = items.map(item => item.flexTaskStatus);
    const validDates = items
      .map(item => item.completionDate as Date)
      .filter(date => !!date);

    if (
      validDates.length === 0 ||
      taskStatuses.some(status =>
        [ProjectTaskStatus.InProgress, ProjectTaskStatus.NotScheduled, ProjectTaskStatus.OnHold].includes(status)
      )
    ) {
      return "";
    }

    if (taskStatuses.every(status => status === ProjectTaskStatus.Cancelled)) {
      return "";
    }

    if (taskStatuses.every(status =>
      [ProjectTaskStatus.Cancelled, ProjectTaskStatus.Completed].includes(status)
    )) {
      const completedDates = items
        .filter(item => item.flexTaskStatus === ProjectTaskStatus.Completed)
        .map(item => item.completionDate as Date)
        .filter(date => !!date);

      if (completedDates.length > 0) {
        const completionDate = max(completedDates);
        return formatDateWithTimezone(completionDate);
      }
    }

    return "";
  };

  const totalHoursBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    return (
      <div style={{ opacity: rowData.isAdHoc ? ".35" : "1" }}>
        {formatNumber(rowData?.totalHours)}
      </div>
    );
  };

  const myHoursBodyTemplate = (rowData: PmrE2EProjectTaskModel) => {
    return (
      <div style={{ opacity: rowData.isAdHoc ? ".35" : "1" }}>
        {formatNumber(rowData?.myHours)}
      </div>
    );
  };

  const processColumnBodyTemplates = (col: GridColumnModel) => {
    if (col.id === projectTaskColumnName)
      return nameColumnBodyTemplate.bind(col);
    if (col.id === projectTaskColumnStatus)
      return statusColumnBodyTemplate.bind(col);
    if (col.id === projectTaskColumnCollaborator)
      return collaboratorColumnBodyTemplate.bind(col);
    if (col.id === projectTaskStartDate) return startDateBodyTemplate.bind(col);
    if (col.id === projectTaskDueDate) return dueDateBodyTemplate.bind(col);
    if (col.id === projectTaskCompletionDate)
      return completionDateBodyTemplate.bind(col);
    if (col.id === projectTaskTotalHours)
      return totalHoursBodyTemplate.bind(col);
    if (col.id === projectTaskMyHours) return myHoursBodyTemplate.bind(col);

    return tableColumnBody.bind(col);
  };

  const dynamicColumns = columns.map((col: GridColumnModel) => {
    const isCollaborator = col.id === projectTaskColumnCollaborator;
    return (
      <Column
        field={col.id}
        header={tableColumnHeader(col)}
        hidden={!col.isShown}
        style={
          col.isFlexWidth
            ? {
                flexGrow: 1,
                flexBasis: col.width,
                width: col.width,
                minWidth: 80,
                padding: "0.3rem",
              }
            : {
                width: col.width,
                minWidth: col.isFrozen
                  ? col.width
                  : isCollaborator
                  ? col.widthNum
                  : 50,
                padding: "0.3rem",
              }
        }
        body={processColumnBodyTemplates(col)}
        sortable={col.orderBy != ""}
        align={col.align || "left"}
        reorderable={!col.isColumnNotReordable && !col.isFrozen}
      />
    );
  });

  const openAssignCoHandlerDialog = (
    rowData: any,
    isMilestoneLevel: boolean,
    showTimeStampError: boolean,
    index?: number
  ) => {
    if (isMilestoneLevel) {
      const targetMilestone = taskMilestoneList
        .find(
          (x) =>
            x.projectId == rowData.e2EProjectId &&
            x.workBreakdownStructure == rowData.workBreakdownStructure
        )
        ?.milestones.find((x) => x.id == rowData.id);

      let assignCoHandlerParam: AssignCoHandlerModel = {
        e2EProjectLineFlexTaskId:
          index != null
            ? rowData?.e2EProjectLineFlexTaskId[index]
            : rowData?.e2EProjectLineFlexTaskId,
        workBreakDownStructure: rowData?.workBreakdownStructure,
        projectId: rowData.e2EProjectId,
        e2EProjectLineId:
          index != null
            ? rowData?.e2EProjectLineId[index]
            : rowData.e2EProjectLineId,
        collaborators: rowData?.collaborator,
        isAssignCoHandlerMailboxNotRequired:
          targetMilestone?.isAssignCoHandlerMailboxNotRequired,
        projectNumber:
          index != null
            ? rowData?.projectNumber[index]
            : rowData?.projectNumber,
        e2EProjectLineMilestoneId:
          index != null ? rowData.id[index] : rowData.id,
        isMilestonelevel: isMilestoneLevel,
        showTimeStampError: showTimeStampError,
      };
      setAssignCoHandlerParam(assignCoHandlerParam);
    } else {
      const targetMilestone = rowData.e2EProjectLineMilestones.find(
        (milestone: any) =>
          milestone.name == "UL: Submit to Authority/Authority Review"
      );

      if (!targetMilestone) return;

      let assignCoHandlerParam: AssignCoHandlerModel = {
        e2EProjectLineFlexTaskId: rowData?.e2EProjectLineFlexTaskId,
        workBreakDownStructure: rowData?.workBreakdownStructure,
        projectId: rowData.e2EProjectId,
        e2EProjectLineId:
          rowData.e2EProjectLineCollaborators[0]?.e2EProjectLineId,
        collaborators: rowData?.collaborator,

        projectNumber: rowData?.projectNumber,
        e2EProjectLineMilestoneId: targetMilestone.id,
        isMilestonelevel: isMilestoneLevel,
        isAssignCoHandlerMailboxNotRequired:
          rowData?.isAssignCoHandlerMailboxNotRequired,
        showTimeStampError: showTimeStampError,
      };
      setAssignCoHandlerParam(assignCoHandlerParam);
    }

    setShowAssignCoHandlerDialog(true);
  };

  const rowDetailsTemplate = (
    params: PmrE2EProjectTaskModel,
    options: DataTableRowExpansionTemplate
  ) => {
    const taskNameToMatch = "Project Completion".toLowerCase();
    const isProjectCompletionCompleted = projectFlexTasksList
      .filter((flexTasks) => flexTasks.e2eProjectId === props.projectId)
      .every((flexTask) =>
        flexTask.tasks
          .filter(
            (tasks) => tasks.flexTaskName.toLowerCase() === taskNameToMatch
          )
          .every((tasks) =>
            tasks.e2EProjectLineFlexTaskGroupItems.every(
              (groupFlexTaskItem) =>
                groupFlexTaskItem.flexTaskStatus === ProjectTaskStatus.Completed
            )
          )
      );

    return (
      <>
        {!loadingFromRefreshProject && (
          <>
            {isFlexTaskOnly.isOn && (
              <ProjectOrderLineList
                projectId={props.projectId}
                workBreakdownStructure={params.workBreakdownStructure}
                e2EProjectLineFlexTaskIds={params.e2EProjectLineFlexTaskIds}
                getTaskList={() => getList()}
                hasGMATCTask={hasGMATCTask}
                depthIndices={[...props.depthIndices, options.index]}
                openAssignCoHandlerDialog={openAssignCoHandlerDialog}
              ></ProjectOrderLineList>
            )}
            {!isFlexTaskOnly.isOn && (
              <ProjectMilestoneList
                e2eProjectId={props.projectId}
                workBreakdownStructure={params.workBreakdownStructure}
                e2EProjectLineFlexTaskIds={params.e2EProjectLineFlexTaskIds}
                flexTaskName={params.flexTaskName}
                getTaskList={() => getList()}
                isProjectCompletionCompleted={isProjectCompletionCompleted}
                hasGMATCTask={hasGMATCTask}
                depthIndices={[...props.depthIndices, options.index]}
                openAssignCoHandlerDialog={openAssignCoHandlerDialog}
                isAdHoc={params.isAdHoc}
              ></ProjectMilestoneList>
            )}
          </>
        )}
      </>
    );
  };

  const onColReorder = (e: DataTableColReorderParams) => {
    if (e.dropIndex < 2) {
      datatableRef.current.reset();
    } else {
      let prevNumber = 0;
      let pwqColumns = columns.map((col, i) => {
        const cols: any = e.columns;
        const orderNo = cols.findIndex((fi: any) => fi.props.field === col.id);

        return {
          ...col,
          orderNo,
        };
      });

      pwqColumns?.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      pwqColumns = pwqColumns
        .map((col) => {
          if (col.orderNoShowHide! > 0) prevNumber++;

          const orderNoShowHide = col.orderNoShowHide! > 0 ? prevNumber : 0;

          return {
            ...col,
            orderNoShowHide,
          };
        })
        .sort((col) => col.orderNoShowHide);

      dispatch(
        updateShowHideColumnByTable({
          key: selectProjectsColumns?.key,
          newColumns: pwqColumns,
          subHeader: "Task",
        })
      );
    }
  };

  const onColResize = (e: DataTableColumnResizeEndParams) => {
    let newColumns: any = columns.map((col, i) => {
      if (e.column.props.field == col.id) {
        return {
          ...col,
          width: e.element.offsetWidth,
        };
      } else {
        return col;
      }
    });
    dispatch(
      updateShowHideColumnByTable({
        key: selectProjectsColumns?.key,
        newColumns: newColumns,
        subHeader: "Task",
      })
    );
  };

  const onAssignCoHandlerClose = () => {
    setShowAssignCoHandlerDialog(false);
  };

  const handleNewAdhocTaskCreated = async () => {
    setAdHocTaskDialogVisible(false);
    getList(true);
    dispatch(refreshOrderlineRefiners(null));
  };

  return (
    <>
      <div className={`${classes["table-container"]}`}>
        <div className={`${classes["project-task-list-grid-container"]}`}>
          <DataTable
            ref={datatableRef}
            dataKey="workBreakdownStructure"
            emptyMessage="--- No results found. Please try different search criteria or check your spelling. ---"
            value={
              flexTaskNamesRefiner.length > 0
                ? projectTasks.filter((task) =>
                    flexTaskNamesRefiner
                      .map((refiner) => refiner.label)
                      .includes(task.flexTaskName)
                  )
                : projectTasks
            }
            loading={loading}
            selection={selectedRecords}
            onSelectionChange={(event: DataTableSelectionChangeParams) =>
              setSelectedRecords(event.value)
            }
            expandedRows={getExpandedRows()}
            onRowToggle={(event: DataTableRowToggleParams) => {
              dispatch(
                setExpandedProjectFlexTasks({
                  e2eProjectId: props.projectId,
                  expandedRows: event.data,
                })
              );
            }}
            rowExpansionTemplate={rowDetailsTemplate}
            scrollable
            resizableColumns
            size="small"
            scrollDirection="both"
            columnResizeMode="fit"
            reorderableColumns
            onColReorder={onColReorder}
            onColumnResizeEnd={onColResize}
            rowClassName={(rowData) => {
              return classes["data-row-bg"];
            }}
          >
            {dynamicColumns}
          </DataTable>

          <ProjectAssignCoHandlerDialog
            assignCoHandlerParam={assignCoHandlerParam}
            onClose={onAssignCoHandlerClose}
            show={showAssignCoHandlerDialog}
          />
        </div>
      </div>

      <AdHocTaskFormDialog
        e2eProjectName={props.projectName}
        e2eProjectId={props.projectId}
        visible={adHocTaskDialogVisible}
        onHide={() => setAdHocTaskDialogVisible(false)}
        onNewTaskCreated={handleNewAdhocTaskCreated}
      />
    </>
  );
};
export default ProjectTaskList;
