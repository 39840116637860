import { Button } from "primereact/button";
import ULModal from "../../../../shared/ul-modal/ULModal";
import { Field, FieldProps, Form, FormikProvider, useFormik } from "formik";
import {
  InputNumberAutoFillSync,
  InvoicePriceTemplateInput,
  defaultInvoicePriceAmountProps,
  invoicePriceValidationSchema,
} from "../invoice-price-template/InvoicePriceTemplate";
import { InputNumber } from "primereact/inputnumber";
import PmrMultiSelectCurrencies from "../../shared/components/pmr-multiselect-currencies/PmrMultiSelectCurrencies";
import classes from "./InvoicePriceModalBulk.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrencies,
  updateRefreshInvoiceWarnings,
} from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import { useState } from "react";
import clsx from "clsx";
import UpdateProjectlineCurrencyModel from "../../../../shared/models/currency-models/UpdateProjectlineCurrencyModel";
import { updateOrderLineInvoice } from "../../../../features/projectManagement/projectManagementSlice";
import CurrencyService from "../../../../services/CurrencyService";
import useToastr from "../../../../hooks/useToastr";
import * as yup from "yup";
import { E2EInvoicePriceBulkModel } from "../../../../shared/models/E2EInvoicePriceBulkModel.model";

interface InvoicePriceModalBulkProps {
  visible: boolean;
  onHide: () => void;
  data: E2EInvoicePriceBulkModel[];
}

interface InvoicePriceModalForm {
  inputs: ({
    e2eProjectLineId: string;
  } & InvoicePriceTemplateInput)[];
}

const InvoicePriceAmountInput = ({
  field,
  meta,
  form,
  id,
  disabled,
  autoFocus,
}: FieldProps & {
  id: string;
  disabled: boolean;
  autoFocus: boolean;
}) => (
  <InputNumberAutoFillSync
    {...field}
    autoFocus={autoFocus}
    onChange={(event) => form.getFieldHelpers(field.name).setValue(event.value)}
    inputChange={field.onChange}
    inputId={id}
    style={{ width: "100%" }}
    className={clsx(meta.error && "p-invalid")}
    disabled={disabled}
  />
);

const InvoicePriceCurrencyInput = ({
  field,
  meta,
  form,
  id,
  disabled,
}: FieldProps & {
  id: string;
  disabled: boolean;
}) => (
  <PmrMultiSelectCurrencies
    {...field}
    style={{
      width: "100%",
    }}
    inputId={id}
    className={clsx(meta.error && "p-invalid")}
    disabled={disabled}
  />
);

export default function InvoicePriceBulkModal(
  props: InvoicePriceModalBulkProps
) {
  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSuccess, showError, showInfo } = useToastr();

  // const hasEdit = props.project.e2EProjectLineMilestoneGroupItems.some(
  //   (milestone) => milestone.name === ulPayment
  // );

  const onHide = () => {
    formik.resetForm();
    props.onHide();
  };

  const onSubmit = async (values: InvoicePriceModalForm) => {
    if (!formik.isValid) return;
    setIsSubmitting(true);

    try {
      const request: UpdateProjectlineCurrencyModel[] = values.inputs.map(
        ({ invoicePriceCurrency, ...input }) => {
          const selectedCurrency = currencies.find(
            (currency) => currency.id === invoicePriceCurrency
          );

          return {
            ...input,
            invoiceCurrencyId: selectedCurrency?.id,
            invoiceCurrencyCode: selectedCurrency?.code,
          } as UpdateProjectlineCurrencyModel;
        }
      );

      await CurrencyService.updateProjectlineCurrency(request);
      dispatch(updateOrderLineInvoice(request));
      formik.resetForm();
      onHide();

      if (isSingleInput) {
        showInfo(
          "Invoice Price Saved",
          `Invoice price for Order Line <strong>${firstLine.orderLineNumber}</strong> has been saved. You may now proceed with task completion.`
        );
      } else {
        showInfo(
          "Invoice Price Saved",
          `Invoice prices for all selected Order Lines have been saved. You may now proceed with task completion.`
        );
      }
    } catch (e) {
      console.warn(e);
      showError(
        "Invoice Price Update Failed",
        "Unable to save invoice price. Please try again. Contact support if issue persists."
      );
    } finally {
      setIsSubmitting(false);
      dispatch(updateRefreshInvoiceWarnings(true));
    }
  };

  const formik = useFormik<InvoicePriceModalForm>({
    initialValues: {
      inputs: props.data.map((item) => {
        let invoicePriceCurrency: string | null = null;
        //Comment, can be used in the future. setting default currency
        // if (!!item.invoiceCurrencyCode) {
        //   invoicePriceCurrency =
        //     currencies.find(
        //       (currency) => currency.code === item.invoiceCurrencyCode
        //     )?.id ?? null;
        // } else if (!!item.currency) {
        //   invoicePriceCurrency =
        //     currencies.find((currency) => currency.code === item.currency)
        //       ?.id ?? null;
        // }
        // console.log({
        //   e2eProjectLineId: item.id,
        //   invoicePriceAmount: item.invoicePriceAmount ?? null,
        //   invoicePriceCurrency,
        // });
        return {
          e2eProjectLineId: item.e2EProjectLineId,
          invoicePriceAmount: null,
          invoicePriceCurrency,
        };
      }),
    },
    onSubmit,
    validationSchema: yup.object().shape({
      inputs: yup.array().of(invoicePriceValidationSchema),
    }),
    enableReinitialize: true,
    isInitialValid: false,
  });

  const inputs = formik.values.inputs;
  const isSingleInput = props.data.length === 1;
  const firstLine = props.data[0];
  const modalHeader = (
    <>
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {isSingleInput
          ? "Invoice Price Required"
          : "Invoice Price Required for Multiple Order Lines"}
      </div>
    </>
  );
  const modalBody = isSingleInput ? (
    <>
      Please enter an Invoice Price for Order Line{" "}
      <b>{firstLine.orderLineNumber}</b> to complete this task or milestone:
    </>
  ) : (
    <>
      Several Order Lines in your selection require an Invoice Price to proceed.
      <br /> <br /> Please enter the required information to complete the
      selected tasks:
    </>
  );

  return (
    <ULModal
      visible={
        props.visible
        //&& hasEdit
      }
      onHide={onHide}
      header={modalHeader}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="default-cancel-button"
            onClick={onHide}
            label="CANCEL"
          />
          <Button
            className="default-save-button"
            form="invoice-price-form-modal"
            type="submit"
            disabled={!formik.isValid}
            loading={isSubmitting}
            label="SAVE"
          />
        </div>
      }
    >
      <p className={classes["modal-body"]}>{modalBody}</p>
      <FormikProvider value={formik}>
        <Form
          className={clsx(classes["invoice-price-form"], "p-datatable")}
          id="invoice-price-form-modal"
        >
          {!isSingleInput && (
            <table className={clsx("p-datatable-table")}>
              <thead className="p-datatable-thead">
                <tr>
                  <th>
                    <div className="p-column-header-content">GMAWB Project</div>
                  </th>
                  <th>
                    <div className="p-column-header-content">
                      Order Line Number
                    </div>
                  </th>
                  <th>Invoice Price</th>
                </tr>
              </thead>
              <tbody className="p-datatable-tbody">
                {inputs.map((input, key) => {
                  const fieldName = `inputs[${key}]`;
                  const invoicePriceAmountId = `invoicePriceAmount-${key}`;
                  const invoicePriceCurrencyId = `invoicePriceCurrency-${key}`;
                  const line = props.data.find(
                    (item) => item.e2EProjectLineId === input.e2eProjectLineId
                  );

                  return (
                    <tr>
                      <td>{line?.e2EProjectName}</td>
                      <td>{line?.orderLineNumber}</td>
                      <td className={clsx("d-flex")}>
                        <div className={classes["invoice-price-field"]}>
                          <Field name={`${fieldName}.invoicePriceAmount`}>
                            {(fieldProps: FieldProps) => (
                              <InvoicePriceAmountInput
                                {...fieldProps}
                                disabled={isSubmitting}
                                id={invoicePriceAmountId}
                                autoFocus={false}
                              />
                            )}
                          </Field>
                        </div>

                        <div className={classes["invoice-price-field"]}>
                          <Field name={`${fieldName}.invoicePriceCurrency`}>
                            {(fieldProps: FieldProps) => (
                              <InvoicePriceCurrencyInput
                                {...fieldProps}
                                id={invoicePriceCurrencyId}
                                disabled={isSubmitting}
                              />
                            )}
                          </Field>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {isSingleInput && (
            <>
              <div className={classes["invoice-price-field"]}>
                <label htmlFor="invoicePriceAmount">Invoice Amount</label>
                <Field name={`inputs[0].invoicePriceAmount`}>
                  {(fieldProps: FieldProps) => (
                    <InvoicePriceAmountInput
                      {...fieldProps}
                      disabled={isSubmitting}
                      id="invoicePriceAmount"
                      autoFocus={true}
                    />
                  )}
                </Field>
              </div>

              <div className={classes["invoice-price-field"]}>
                <label htmlFor="invoicePriceCurrency">Currency</label>
                <Field name={`inputs[0].invoicePriceCurrency`}>
                  {(fieldProps: FieldProps) => (
                    <InvoicePriceCurrencyInput
                      {...fieldProps}
                      id="invoicePriceCurrency"
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
              </div>
            </>
          )}
        </Form>
      </FormikProvider>
    </ULModal>
  );
}
