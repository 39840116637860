import ProjectMilestoneStatus from "../../pages/project-management-pmr/shared/enums/project-milstone-status";

export interface IProjectMilestoneStatus {
  name: string;
  code: number;
  disabled: boolean;
  tooltipMessage: string;
}
export const getMilestoneStatusList = (): IProjectMilestoneStatus[] => {
  let items = [];
  items.push({
    name: "Not Scheduled",
    code: ProjectMilestoneStatus.NotScheduled,
    disabled: false,
    tooltipMessage: "",
  });
  items.push({
    name: "In Progress",
    code: ProjectMilestoneStatus.InProgress,
    disabled: false,
    tooltipMessage: "",
  });
  items.push({
    name: "On Hold",
    code: ProjectMilestoneStatus.OnHold,
    disabled: false,
    tooltipMessage: "",
  });
  items.push({
    name: "Cancelled",
    code: ProjectMilestoneStatus.Cancelled,
    disabled: false,
    tooltipMessage: "Cannot cancel tasks with logged time entry.",
  });

  return items;
};
