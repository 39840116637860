import moment from "moment";
import { E2EUserPaginatedModel } from "../../../../shared/models/E2EUserPaginatedModel";
import ProjectTaskStatus from "../enums/project-task-status"

interface FieldsToWatchCalculateAdhocStatus {
    startDate?: Date | string | null;
    taskHandler?: E2EUserPaginatedModel | null;
}

export interface AdhocStatusDropdown {
    value: ProjectTaskStatus;
    label: string;
}


const statusesToCheck: ProjectTaskStatus[] = [
    ProjectTaskStatus.NotScheduled,
    ProjectTaskStatus.NotStarted,
    ProjectTaskStatus.AwaitingAssignment,
    ProjectTaskStatus.InProgress,
]

export const CalculateAdhocStatusDropdown = (props: FieldsToWatchCalculateAdhocStatus): AdhocStatusDropdown[] => {

    let statuses: AdhocStatusDropdown[] = [
        { label: GetLabelFromStatus(ProjectTaskStatus.NotScheduled), value: ProjectTaskStatus.NotScheduled },
    ];

    const startDate = props.startDate ? moment(props.startDate).startOf("day").toDate() : null;
    const today = moment(new Date()).startOf("day").toDate();

    if((startDate !== null && startDate > today) && props.taskHandler) {
        statuses = CheckStatusesToRemove(statuses, statusesToCheck);
        statuses.unshift({label: GetLabelFromStatus(ProjectTaskStatus.NotStarted), value: ProjectTaskStatus.NotStarted});
    }
    else if((startDate !== null && startDate <= today) && props.taskHandler) {
        statuses = CheckStatusesToRemove(statuses, statusesToCheck);
        statuses.unshift({label: GetLabelFromStatus(ProjectTaskStatus.InProgress), value: ProjectTaskStatus.InProgress});
        statuses.push({label: GetLabelFromStatus(ProjectTaskStatus.OnHold), value: ProjectTaskStatus.OnHold});
    }
    else if(startDate != null && !props.taskHandler){
        statuses = CheckStatusesToRemove(statuses, statusesToCheck);
        statuses.unshift({label: GetLabelFromStatus(ProjectTaskStatus.AwaitingAssignment), value: ProjectTaskStatus.AwaitingAssignment});
    }
    
    return statuses;
}

const CheckStatusesToRemove = (statuses: AdhocStatusDropdown[], statusesToCheck: ProjectTaskStatus[]) => 
    statuses.filter(status => !statusesToCheck.includes(status.value));


const GetLabelFromStatus = (status: ProjectTaskStatus): string => {
    switch(status){
        case ProjectTaskStatus.AwaitingAssignment:
            return "Awaiting Assignment";
        case ProjectTaskStatus.Cancelled:
            return "Cancelled";
        case ProjectTaskStatus.InProgress:
            return "In Progress";
        case ProjectTaskStatus.Completed:
            return "Completed";
        case ProjectTaskStatus.NotStarted:
            return "Not Started";
        case ProjectTaskStatus.OnHold:
            return "On Hold";
        default: 
            return "Not Scheduled"
    }
}
