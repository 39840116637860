import PmrRefiners from "../shared/components/pmr-refiners/PmrRefiners";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRefinerState,
  selectRefreshInvoiceWarnings,
  selectShowFlexTasks,
  updateIsRefinerLoading,
  updateRefinerState,
  updateRefreshInvoiceWarnings,
  updateRequireInvoicePrices,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  removeRefreshOrderlineRefiners,
  selectRefreshOrderlineRefiners,
  updateFavoritesModel,
} from "../../../features/projectManagement/projectManagementSlice";
import ProjectLevelRefiner from "./project-level-refiner/ProjectLevelRefiner";
import OrderLineLevelRefiner from "./orderline-level-refiner/OrderLineLevelRefiner";
import {
  selectedProjectManagementViewProjects,
  updateRefinerLineResult,
} from "../../../features/projectManagement/projectManagementSlice";
import * as axios from "axios";
import OrderLineRefinersService from "../../../services/OrderLineRefinersService";
import { transformOptionLabelValueToValue } from "../../../utils/helpers/object.helpers";
import ProjectService from "../../../services/ProjectService";

export interface ProjectManagementRefinersPmrProps {}

const ProjectManagementRefinersPmr = () => {
  const showFlexTask = useSelector(selectShowFlexTasks);
  const refinerState = useSelector(selectRefinerState);
  const projectsDatatable = useSelector(selectedProjectManagementViewProjects);
  const refreshOrderlineRefiners = useSelector(selectRefreshOrderlineRefiners);
  const refreshInvoiceWarnings = useSelector(selectRefreshInvoiceWarnings);

  const formikInstance = useRef<FormikProps<any>>(null);
  const dispatch = useDispatch();
  const projectLevelRefinerRef = useRef<any>(null),
    orderLineLevelRefinerRef = useRef<any>(null);
  const applyOrderLineRefinersCancelSource = useRef<any>(null);
  const getRequireInvoicePriceCancelSource = useRef<any>(null);
  const [searchStringValue, setSearchStringValue] = useState<string>();

  const initialValues = {
    searchString: "",
    // TODO: api required input
    projects: {
      soldTos: [], // list of object:" interface { partySiteNumber, partyName }
      projectNames: [], // list of id
      projectCreators: [], // list of string
      projectStatuses: [], // list of numbers
      createdDateUTC: {
        from: null,
        to: null,
      },
      contributors: [],
    },
    orderLines: {
      completionDate: {
        from: null,
        to: null,
      },
      contributors: [],
      dueDate: {
        from: null,
        to: null,
      },
      flexHandlerRegions: [],
      flexProjectNumbers: [],
      flexProjects: [],
      flexProjectECD: {
        from: null,
        to: null,
      },
      flexProjectStatuses: [],
      flexTaskNames: [],
      flexTaskStatuses: [],
      gmawbMilestoneNames: [],
      gmawbMilestoneStatuses: [],
      orderlineECD: {
        from: null,
        to: null,
      },
      orderLineDescriptions: [],
      orderlineStatuses: [],
      orderNumbers: [],
      pocCountries: [],
      pointOfSales: [],
      startDate: {
        from: null,
        to: null,
      },
    },
  };

  //#region Sync to Redux
  const syncFormikToRedux = (formValues: any) => {
    dispatch(
      updateRefinerState({
        value: formValues,
        updateForm: false, // avoids infinte loop on useEffect
      })
    );

    dispatch(
      updateFavoritesModel({
        property: "refiners",
        value: formValues,
      })
    );
  };

  /**
   * Handles outside component updates
   */
  useEffect(() => {
    if ((refinerState.updateForm ?? true) && formikInstance) {
      // fix non-existing refiner from favorites
      const { projects, orderLines, searchString } = refinerState.value;

      formikInstance.current?.setValues({
        ...formikInstance.current.values,
        projects,
        orderLines: {
          ...orderLines,
          contributors: [],
        },
      });

      setSearchStringValue(searchString ?? "");
    }
  }, [refinerState, formikInstance]);
  //#endregion

  const applyOrderLineRefiners = async () => {
    dispatch(updateIsRefinerLoading(true));

    if (applyOrderLineRefinersCancelSource.current) {
      applyOrderLineRefinersCancelSource.current.cancel();
    }

    applyOrderLineRefinersCancelSource.current = (
      axios as any
    ).CancelToken.source();

    let request = {
      showFlexTask: showFlexTask.isOn,
      e2eProjectIds: projectsDatatable.map((project: any) => project.id ?? ""),
      contributors: [],
      searchString: refinerState?.value?.searchString ?? "",
    };

    var { orderLines, projects } = transformOptionLabelValueToValue(
      refinerState.value
    );

    if (orderLines) {
      request = {
        ...orderLines,
        ...request,
      };
    }

    if (projects?.contributors) {
      request.contributors = projects?.contributors;
    }

    try {
      const response =
        await OrderLineRefinersService.getFilteredTaskAndMilestone(
          request,
          applyOrderLineRefinersCancelSource.current
        );

      dispatch(updateRefinerLineResult(response.data));
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        return;
      }
    }

    dispatch(updateIsRefinerLoading(false));
    dispatch(removeRefreshOrderlineRefiners(null));
  };

  const getRequireInvoicePrice = async () => {
    if (getRequireInvoicePriceCancelSource.current) {
      getRequireInvoicePriceCancelSource.current.cancel();
    }
    getRequireInvoicePriceCancelSource.current = (
      axios as any
    ).CancelToken.source();

    try {
      const response = await ProjectService.getRequireInvoicePrice(
        {
          projectIds: projectsDatatable.map((project: any) => project.id ?? ""),
        },
        getRequireInvoicePriceCancelSource.current
      );
      dispatch(updateRequireInvoicePrices(response.data));
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        return;
      }
    } finally {
      dispatch(updateRefreshInvoiceWarnings(false));
    }
  };

  useEffect(() => {
    if (projectsDatatable.length <= 0) return;
    applyOrderLineRefiners();
    getRequireInvoicePrice();
  }, [projectsDatatable]);

  useEffect(() => {
    if (!refreshOrderlineRefiners) return;

    applyOrderLineRefiners();
  }, [refreshOrderlineRefiners]);

  useEffect(() => {
    if (!refreshInvoiceWarnings) return;

    getRequireInvoicePrice();
  }, [refreshInvoiceWarnings]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      innerRef={formikInstance}
    >
      <PmrRefiners
        onSubmit={syncFormikToRedux}
        globalSearch={{
          name: "searchString",
          value: searchStringValue,
        }}
      >
        <ProjectLevelRefiner ref={projectLevelRefinerRef} refinerGroup />
        <OrderLineLevelRefiner ref={orderLineLevelRefinerRef} refinerGroup />
      </PmrRefiners>
    </Formik>
  );
};
export default ProjectManagementRefinersPmr;
