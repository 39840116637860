import clsx from "clsx";
import classes from "./ProjectTaskStatusUpdateDialog.module.scss";
import {
  updateFlexTaskOrderLineStatus,
  updateProjectFlexTaskStatusList,
  upsertSelectedTaskOrderlines,
} from "../../../../../features/projectManagement/projectManagementSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
  IProjectTaskStatus,
  getFlexTaskStatusList,
} from "../../../../../utils/constants/flex-task-status.constants";
import ProjectTaskStatus from "../../enums/project-task-status";
import { OnHoldReasonsModel } from "../../../../../shared/models/service-models/OnHoldReasons.model";
import ProjectService from "../../../../../services/ProjectService";
import { OnHoldReasonType } from "../../../../project-management/shared/enums/OnHoldReasonType";
import IProjectTaskStatusUpdateModel, {
  ITaskToUpdate,
  PmrUpdateProjectLineStatusModel,
} from "../../interfaces/pmr-e2e-project-task-status-update.model";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import { updateRefreshInvoiceWarnings } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";

interface ProjectTaskStatusUpdateDialogProps {
  closeModal: () => void;
  visible: boolean;
  selectedTaskOrderlines: ITaskToUpdate[];
}

const ProjectTaskStatusUpdateDialog = (
  props: ProjectTaskStatusUpdateDialogProps
) => {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToastr();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flexTaskStatus, setFlexTaskStatus] = useState<IProjectTaskStatus[]>(
    []
  );

  const [selectedFlexTaskStatus, setSelectedFlexTaskStatus] =
    useState<IProjectTaskStatus>();
  const [isOnHoldFlexProjectReasonShow, setIsOnHoldFlexProjectReasonShow] =
    useState(false);
  const [selectedFlexTaskReason, setSelectedFlexTaskReason] =
    useState<OnHoldReasonsModel | null>(null);
  const [onHoldReasonList, setOnHoldReasonList] = useState<
    OnHoldReasonsModel[]
  >([]);

  useEffect(() => {
    if (props.visible) {
      loadStatusList();
    }
  }, [props.visible]);

  const loadStatusList = () => {
    const status = handleCheckTasksStatus(props.selectedTaskOrderlines);
    const defaultFlexStatus = getFlexTaskStatusList(false);

    setFlexTaskStatus(
      defaultFlexStatus.filter((flexStatus) => {
        const { code } = flexStatus;

        switch (status) {
          case ProjectTaskStatus.NotScheduled:
          case ProjectTaskStatus.Cancelled:
            return code === ProjectTaskStatus.Cancelled;

          case ProjectTaskStatus.InProgress:
            return code !== ProjectTaskStatus.InProgress;

          case ProjectTaskStatus.OnHold:
            return code !== ProjectTaskStatus.OnHold;
        }
      })
    );
  };

  const isConfirmButtonDisabled = () => {
    const isValid =
      selectedFlexTaskStatus?.code == ProjectTaskStatus.OnHold
        ? selectedFlexTaskReason != null
        : selectedFlexTaskStatus != undefined;
    return isValid;
  };

  const cleanUp = () => {
    setFlexTaskStatus([]);
    setSelectedFlexTaskStatus(undefined);
    setIsOnHoldFlexProjectReasonShow(false);
    setSelectedFlexTaskReason(null);
    setOnHoldReasonList([]);
    props.closeModal();
  };

  const handleCheckTasksStatus = (tasks: ITaskToUpdate[]) => {
    let status: ProjectTaskStatus = ProjectTaskStatus.Cancelled;

    if (tasks.every((task) => task.status === ProjectTaskStatus.NotScheduled))
      status = ProjectTaskStatus.NotScheduled;
    else if (tasks.every((task) => task.status === ProjectTaskStatus.OnHold))
      status = ProjectTaskStatus.OnHold;
    else if (
      tasks.every((task) => task.status === ProjectTaskStatus.InProgress)
    )
      status = ProjectTaskStatus.InProgress;

    return status;
  };

  const handleOnHoldReasonLoad = async () => {
    var result = await ProjectService.getOnHoldReasonList(
      OnHoldReasonType.Task
    );

    if (result.isSuccess) setOnHoldReasonList(result.data);
  };

  const onFlexTaskOnHoldReasonChange = (e: DropdownChangeParams) => {
    setSelectedFlexTaskReason(e.value);
  };

  const onFlexStatusChange = (e: DropdownChangeParams) => {
    setSelectedFlexTaskStatus(e.value);

    if (e?.value?.code == ProjectTaskStatus.OnHold) {
      setIsOnHoldFlexProjectReasonShow(true);
      setSelectedFlexTaskReason(null);
      if (onHoldReasonList.length == 0) handleOnHoldReasonLoad();
    } else {
      setIsOnHoldFlexProjectReasonShow(false);
    }
  };

  const handleUpdateFlexTask = (
    onHoldReason?: PmrE2EOnHoldReason,
    result?: Array<PmrUpdateProjectLineStatusModel>
  ) => {
    result?.forEach((item: PmrUpdateProjectLineStatusModel) => {
      dispatch(
        updateFlexTaskOrderLineStatus({
          projectId: item.e2EProjectId,
          e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
          newStatus: item.status,
          onHoldReason,
        })
      );

      dispatch(
        updateProjectFlexTaskStatusList({
          projectId: item.e2EProjectId,
          e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
          newStatus: item.status,
        })
      );
    });

    const updatedOrderLines = props.selectedTaskOrderlines.map((task) => {
      const updatedTask = result?.find(
        (draft) =>
          draft.e2EProjectLineFlexTaskId === task.e2EProjectLineFlexTaskId
      );
      return {
        e2EProjectLineFlexTaskId: task.e2EProjectLineFlexTaskId,
        status: updatedTask?.status,
        isRevenueRecognition: task.isRevenueRecognition,
        isAdHoc: task.isAdhoc,
      };
    });

    dispatch(
      upsertSelectedTaskOrderlines(
        updatedOrderLines?.filter(
          (res) => res.status != ProjectTaskStatus.Cancelled
        )
      )
    );

    dispatch(updateRefreshInvoiceWarnings(true));
  };

  const handleUpdateTaskStatusSave = async () => {
    setIsSaving(true);
    const taskIds = props.selectedTaskOrderlines.map(
      (task) => task.e2EProjectLineFlexTaskId
    );

    var updateModel: IProjectTaskStatusUpdateModel = {
      flexTaskIds: taskIds,
      status: selectedFlexTaskStatus!.code,
      statusDetailId: selectedFlexTaskReason?.statusDetailId,
      statusDetail: selectedFlexTaskReason?.statusReason,
      isBulkUpdate: true,
    };

    var result = await ProjectService.flexTaskStatusUpdate(updateModel);

    result.isSuccess
      ? showSuccess("Bulk-Status Update Completed", result.message)
      : showError("Bulk-Update Incomplete", result.message);

    if (result?.data) {
      var reason: PmrE2EOnHoldReason = {
        taskStatusDetailId: updateModel.statusDetailId,
        taskStatusReason: updateModel.statusDetail,
      };

      var data = result.data as Array<PmrUpdateProjectLineStatusModel>;
      handleUpdateFlexTask(reason, data);
    }
    cleanUp();
    setIsSaving(false);
  };
  const renderFooter = (
    <>
      <hr className="modal-footer-hr" />
      <div className={`${classes["dialog-footer"]} ${classes["center-items"]}`}>
        <button
          className={`ul-button -medium ${classes["dialog-cancel-btn"]}`}
          disabled={isSaving}
          onClick={cleanUp}
        >
          CANCEL
        </button>
        <button
          className={`ul-button -medium ${classes["dialog-save-btn"]} confirm-button-target`}
          disabled={!isConfirmButtonDisabled() || isSaving}
          onClick={handleUpdateTaskStatusSave}
        >
          CONFIRM
        </button>
      </div>
    </>
  );

  const renderBody = () => {
    return (
      <>
        <div>
          <Dropdown
            value={selectedFlexTaskStatus}
            options={flexTaskStatus}
            optionLabel="name"
            onChange={onFlexStatusChange}
            className={classes["custom-dropdown"]}
            style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
            placeholder="-- Select --"
            disabled={isLoading}
            showClear
          />
          <div style={{ marginBottom: "3rem" }}>
            <small>
              <i>
                Note: Selected status will apply to all selected orderlines.
              </i>
            </small>

            {selectedFlexTaskStatus?.code == ProjectTaskStatus.Cancelled && (
              <div>
                <strong>Cancelled Status:</strong> Order Lines will permanently
                remove the task from the order line's lifecycle.
              </div>
            )}
          </div>
        </div>
        {isOnHoldFlexProjectReasonShow && (
          <>
            <div style={{ marginTop: "2rem" }}>
              Please select a reason from the list below:{" "}
              <span className={classes["required"]}>*</span>
            </div>
            <div style={{ marginTop: ".5rem", marginBottom: ".5rem" }}></div>
            <div>
              <Dropdown
                value={selectedFlexTaskReason}
                options={onHoldReasonList}
                optionLabel="statusReason"
                onChange={onFlexTaskOnHoldReasonChange}
                className={classes["custom-dropdown"]}
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                placeholder="Select order line 'On Hold' reason"
                showClear
              />
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <Dialog
        className={classes["custom-dialog"]}
        header="Change FLEX Task Status"
        footer={renderFooter}
        draggable={false}
        visible={props.visible}
        onHide={cleanUp}
        style={{ width: "35vw" }}
      >
        <hr className="modal-header-hr" />
        <div className={classes["body-container"]}>
          <div
            style={{
              display: isSaving || isLoading ? "none" : "",
            }}
          >
            {renderBody()}
          </div>
          {(isSaving || isLoading) && (
            <div className={classes["loading-div"]}>
              <div>
                <svg className="ul-progressCircle -medium">
                  <circle
                    className="ul-progressCircle__outer"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                  <circle
                    className="ul-progressCircle__inner"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
export default ProjectTaskStatusUpdateDialog;
