import { faFlask, faLink } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProjectFlexTasksList,
  selectedProjectManagementViewProjects,
  updateMilestoneDataUponRemoveTimestamp,
} from "../../../../../features/projectManagement/projectManagementSlice";
import useProjectTimestamp from "../../../../../hooks/useProjectTimestamp";
import NewGridActionPopover from "../../../../../shared/new-grid-action-popover/NewGridActionPopover";
import TimestampElements from "../../../../project-management/shared/components/timestamp-elements/TimestampElements";
import GenerateLinkModal, {
  GenerateLinkSelectedProject,
} from "../../../generate-link-modal/GenerateLinkModal";
import TimeEntryModal from "../../../time-entry-modal/TimeEntryModal";
import {
  AddTimestampTooltip,
  LogWorkHoursTooltip,
  OpportunitiesDocumentsDisabledTooltip,
  ECMProjectNodeIDDisabledTooltip,
  ECMProjectNodeIDTooltip,
  OpportunitiesDocumentsTooltip,
  checkingAvailabilityTooltip,
} from "../../constants/ProjectOrderLineKebabActionTooltip";
import ProjectStatus from "../../enums/project-status";
import ProjectTaskStatus from "../../enums/project-task-status";
import launchEcm from "../../icons/launchEcm.png";
import launchFlex from "../../icons/launchFlex.png";
import TimeEntryModalSourceType from "../../enums/time-entry-modal-source-type";
import useToastr from "../../../../../hooks/useToastr";
import ProjectTimestampService from "../../../../../services/ProjectTimestampService";
import { appInsights } from "../../../../../services/appInsights";
import { v4 as uuidv4 } from "uuid";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import RemoveTimestampConfirmationDialog from "../remove-timestamp-confirmation-dialog/RemoveTimestampConfirmationDialog";
import ProjectService from "../../../../../services/ProjectService";
import { ECDAdjustmentsErrorType } from "../../enums/ecd-adjustments-error-type";
import { CoHandlerErrorStatus } from "../../enums/co-handler-error-status";
import {
  coHandlerErrorHeader,
  coHandlerErrorMessage,
} from "../../../../../utils/constants/projects.constants";

const ProjectOrderLineKebabAction = (props: any) => {
  const [actionList, setActionList] = useState([]);
  const projectTimestampHook = useProjectTimestamp();
  const [timeEntryModalVisible, setTimeEntryModalVisible] = useState(false);
  const projectManagementViewProjects = useSelector(
    selectedProjectManagementViewProjects
  );
  const [selectedProject, setSelectedProject] =
    useState<GenerateLinkSelectedProject | null>(null);
  const [
    removeTimestampConfirmationDialogVisible,
    setRemoveTimestampConfirmationDialogVisible,
  ] = useState<boolean>(false);
  const [
    removeTimeStampConfirmationButtonLoading,
    setRemoveTimestampConfirmationButtonLoading,
  ] = useState<boolean>(false);
  const { showError, showSuccess } = useToastr();
  const dispatch = useDispatch();
  const [
    isEcmOpportunityDocumentsDisabled,
    setIsEcmOpportunityDocumentsDisabled,
  ] = useState(true);
  const [isCheckingOpportunityNumber, setIsCheckingOpportunityNumber] =
    useState(false);

  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);

  const isTimeEntrySyncing = projectFlexTasksList.some((taskList) =>
    taskList.tasks.some((task) =>
      task.timeEntrySnycFlexTaskList.some((te) => te.isTimeEntrySyncing)
    )
  );
  useEffect(() => {
    let isMounted = true;
    if (props.rowData && props.rowData.flexProjectId) {
      setIsCheckingOpportunityNumber(true);
      ProjectService.getProjectDetailsFlexDetails(
        props.rowData.flexProjectId
      ).then((result: any) => {
        if (isMounted) {
          if (result.isSuccess) {
            const projectDetails = result.data;
            setIsEcmOpportunityDocumentsDisabled(
              !projectDetails.opportunityNumber
            );
          }
          setIsCheckingOpportunityNumber(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [props.rowData]);
  useEffect(() => {
    if (props.rowData) {
      const status = props.rowData.status;
      const isAddTimestampDisabled = props.isMilestoneListLoaded
        ? status === ProjectStatus.Completed ||
          status === ProjectStatus.Cancelled
        : status === ProjectTaskStatus.Completed ||
          status === ProjectTaskStatus.Cancelled;
      const isLogWorkHoursDisabled = props.isMilestoneListLoaded
        ? status === ProjectStatus.Completed ||
          status === ProjectStatus.Cancelled ||
          props.rowData.isAdHoc ||
          isTimeEntrySyncing
        : status === ProjectTaskStatus.Completed ||
          status === ProjectTaskStatus.Cancelled ||
          props.rowData.isAdHoc ||
          isTimeEntrySyncing;

      const actions: any = [
        {
          textDisplay: "Add Timestamp",
          action: onAddTimestamp,
          isDisabled: isAddTimestampDisabled,
          isShowTooltip: isAddTimestampDisabled,
          tooltip: AddTimestampTooltip,
        },
        {
          textDisplay: "Log Work Hours",
          action: onLogWorkHours,
          isDisabled: isLogWorkHoursDisabled,
          isShowTooltip: isLogWorkHoursDisabled,
          tooltip: LogWorkHoursTooltip,
          hasLineBreak: true,
        },
        {
          textDisplay: "Generate Link",
          icon: faLink,
          action: () => onGenerateLink(),
        },
        {
          textDisplay: "FLEX",
          action: onLaunchFlex,
          imageSource: launchFlex,
        },
        {
          textDisplay: "Labware",
          icon: faFlask,
          action: onLaunchLabware,
        },
        {
          textDisplay: "ECM Project Documents",
          action: onLaunchEcm,
          imageSource: launchEcm,
          isDisabled: (() => {
            const lineInfo = getProjectLineInfo();
            return !lineInfo || !lineInfo.projectNodeId;
          })(),
          isShowTooltip: true,
          tooltip: (() => {
            const lineInfo = getProjectLineInfo();
            if (!lineInfo || !lineInfo.projectNodeId) {
              return ECMProjectNodeIDDisabledTooltip;
            }
            return ECMProjectNodeIDTooltip;
          })(),
        },
        {
          textDisplay: "ECM Opportunity Documents",
          action: onLaunchEcmOpportunityDocuments,
          imageSource: launchEcm,
          isDisabled:
            isEcmOpportunityDocumentsDisabled || isCheckingOpportunityNumber,
          isShowTooltip: true,
          tooltip: isCheckingOpportunityNumber
            ? checkingAvailabilityTooltip
            : isEcmOpportunityDocumentsDisabled
            ? OpportunitiesDocumentsDisabledTooltip
            : OpportunitiesDocumentsTooltip,
        },
      ];

      setActionList(actions);
    }
  }, [
    props.rowData,
    isEcmOpportunityDocumentsDisabled,
    isCheckingOpportunityNumber,
    isTimeEntrySyncing,
  ]);

  const onAddTimestamp = async () => {
    let milestoneIds: string[] = [];
    let taskIds: string[] = [];

    if (props.isMilestoneListLoaded) {
      if (!projectTimestampHook.isMilestoneTimestampable(props.rowData)) return;
      milestoneIds = [props.rowData.id];
    }

    if (!props.isMilestoneListLoaded) {
      if (!projectTimestampHook.isTaskTimestampable(props.rowData)) return;
      taskIds = [props.rowData.e2EProjectLineFlexTaskId];
    }
    
    dismissPopOver();

    const result = await projectTimestampHook.checkProjectLinesECDAdjustments({
      milestoneIds,
      taskIds,
      includeMilestonePredecessorsForTimestamping: false,
      isMilestoneLoaded: props.isMilestoneListLoaded,
    });

    if (result?.isSuccess && result?.data?.ecdAdjustments.length === 0) {
      await projectTimestampHook.timestampMilestones({
        milestoneIds,
        taskIds,
        adjustECDProjectLineIds: [],
        isFromECDAdjustmentPrompt: false,
        includeMilestonePredecessorsForTimestamping: false,
        isTimestampedThroughTasks: !props.isMilestoneListLoaded,
      });
    } else {
      if (result?.data.errorType == ECDAdjustmentsErrorType.CoHandlerError) {
        if (result?.data.coHandlerErrorModel.length == 1) {
          const coHandlerError = result.data.coHandlerErrorModel[0];

          if (
            coHandlerError.coHandlerErrorStatus ==
            CoHandlerErrorStatus.Unassigned
          ) {
            if (props.rowData.openAssignCoHandlerDialog)
              props.rowData.openAssignCoHandlerDialog(
                props.rowData,
                true,
                true
              );
          } else {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                coHandlerError.orderLineNumber
              )
            );
          }
        } else {
          result.data.coHandlerErrorModel.forEach((item) => {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                item.orderLineNumber
              )
            );
          });
        }
      }
    }
  };

  const onRemoveTimeStampConfirm = () => {
    setRemoveTimestampConfirmationButtonLoading(true);
    const logPrefix = `ProjectOrderLineKebabActions - onRemoveTimestamp() - ${uuidv4()}`;
    ProjectTimestampService.removeTimestampMIlestones({
      milestoneIds: [props.rowData.id],
    })
      .then((result) => {
        if (result.isSuccess) {
          result.data?.forEach((updatedResult) => {
            dispatch(
              updateMilestoneDataUponRemoveTimestamp({
                projectId: props.rowData.e2EProjectId,
                milestoneId: props.rowData.id,
                newMilestoneStatus: updatedResult.updatedMilestone?.status,
                newCompletionDate:
                  updatedResult.updatedMilestone?.completionDate,
                predecessorMilestoneId: updatedResult?.predecessorMilestoneId,
                predecessorTaskStatus: updatedResult?.predecessorTaskStatus,
                successorMilestoneId: updatedResult?.successorMilestoneId,
                successorTaskStatus: updatedResult?.successorTaskStatus,
              })
            );
          });

          setRemoveTimestampConfirmationDialogVisible(false);
          showSuccess(
            "Success!",
            "Order Line(s)' timestamp has been removed successfully"
          );
        }
        setRemoveTimestampConfirmationButtonLoading(false);
      })
      .catch((error) => {
        appInsights?.trackTrace({
          message: `${logPrefix} - registerValidSW - ${JSON.stringify(error)}`,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const onRemoveTimeStampCancel = () => {
    setRemoveTimestampConfirmationDialogVisible(false);
  };

  const onLogWorkHours = () => {
    setTimeEntryModalVisible(true);
  };

  const dismissPopOver = () => {
    if (!props.popOverRef?.current) return;
    props.popOverRef.current.closePopUp();
  };

  const onGenerateLink = () => {
    const { flexProjectNumber, orderLineDescription, e2EProjectId } =
      props.rowData;
    const selectedProjectDetails = projectManagementViewProjects.find(
      (item) => item.id === e2EProjectId
    );

    setSelectedProject({
      flexProjectNumber,
      orderLineDescription,
      e2EProjectId,
      e2EProjectName: selectedProjectDetails?.name ?? "",
    });
    dismissPopOver();
  };

  const onLaunchFlex = () => {
    window
      .open(
        `${process.env.REACT_APP_FLEX_PROJECT_URL}${props.rowData.flexProjectId}`,
        "_blank"
      )
      ?.focus();
    dismissPopOver();
  };

  const onLaunchLabware = () => {
    window
      .open(
        `${process.env.REACT_APP_LW_URL}?fid=${props.rowData.flexProjectId}&sid=250`,
        "_blank"
      )
      ?.focus();
    dismissPopOver();
  };

  const onLaunchEcmOpportunityDocuments = () => {
    ProjectService.getProjectDetailsFlexDetails(
      props.rowData.flexProjectId
    ).then((result: any) => {
      if (result.isSuccess) {
        const projectDetails = result.data;
        const opportunityNumber = projectDetails.opportunityNumber;
        const filterParam = encodeURIComponent(
          `{"name":"${opportunityNumber}"}`
        );

        const dynamicUrl = `${process.env.REACT_APP_ECM_OPPORTUNITIES_NODE}?filter=${filterParam}`;

        window.open(dynamicUrl, "_blank")?.focus();
      }
    });
  };

  const getProjectLineInfo = () => {
    const project = projectManagementViewProjects.find(
      (item) => item.id === props.rowData.e2EProjectId
    );
    if (!project) return;
    const line = (project as any).e2EProjectLineFlexTaskGroupItems.find(
      (item: any) =>
        item.e2EProjectLineFlexTaskId === props.rowData.e2EProjectLineFlexTaskId
    );

    return line;
  };

  const onLaunchEcm = () => {
    const line = getProjectLineInfo();
    let ecmNotFound = !line || !line?.projectNodeId;
    dismissPopOver();

    if (ecmNotFound) {
      showError(
        "Error",
        "Unable to process. Cannot locate project folder in ECM."
      );
      return;
    }
    window
      .open(
        `${process.env.REACT_APP_ECM_BASE_URL}/otcs/cs.exe/app/nodes/${line.projectNodeId}`,
        "_blank"
      )
      ?.focus();
  };

  const handleECDAdjustmentsConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    projectTimestampHook.hideECDAdjustmentsDialog();
    await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      adjustECDProjectLineIds,
      isFromECDAdjustmentPrompt: true,
      includeMilestonePredecessorsForTimestamping: false,
      isTimestampedThroughTasks: !props.isMilestoneListLoaded,
    });
  };

  return (
    <>
      <NewGridActionPopover
        ref={props.popOverRef}
        actionList={actionList}
        isDisable={false}
      ></NewGridActionPopover>

      <TimestampElements
        projectTimestampHook={projectTimestampHook}
        onECDAdjustmentsConfirm={handleECDAdjustmentsConfirm}
      />
      {timeEntryModalVisible && (
        <TimeEntryModal
          closeTimeEntryModal={() => setTimeEntryModalVisible(false)}
          visible={timeEntryModalVisible}
          e2eProjectLineFlexTaskIds={[props.rowData.e2EProjectLineFlexTaskId]}
          timeEntryModalSourceType={TimeEntryModalSourceType.TimeEntry}
        />
      )}

      <GenerateLinkModal
        data={selectedProject}
        visible={!!selectedProject}
        onHide={() => setSelectedProject(null)}
      />

      <RemoveTimestampConfirmationDialog
        confirmLoading={removeTimeStampConfirmationButtonLoading}
        visible={removeTimestampConfirmationDialogVisible}
        onCancel={onRemoveTimeStampCancel}
        onConfirm={onRemoveTimeStampConfirm}
      />
    </>
  );
};

export default ProjectOrderLineKebabAction;
