import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect } from "react";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import classes from "./OnHoldReasonDialog.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";

type Props = {
  visible: boolean;
  onClose: () => void;
  setSelectedOnHoldReason: any;
  handleSaveOnHoldReason: () => void;
  isSaveLoading: boolean;
  selectedReason: any | null;
};

const OnHoldReasonDialog = (props: Props) => {
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [onHoldReasonList, setOnHoldReasonList] = useState();
  const [selectedOnHoldReason, setSelectedOnHoldReason] =
    useState<PmrE2EOnHoldReason>();

  const getOnHoldReasonList = async () => {
    const reasonList = await ProjectService.getTaskOnHoldStatus();
    setOnHoldReasonList(reasonList);
    setIsModalLoading(false);
  };

  useEffect(() => {
    setSelectedOnHoldReason(props.selectedReason);
  }, [props.selectedReason]);

  const onReasonChange = (e: { value: PmrE2EOnHoldReason }) => {
    setSelectedOnHoldReason(e.value);
    props.setSelectedOnHoldReason(e.value);
  };

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={props.onClose}
        />
      </div>
      <div>
        <Button
          className={`${classes["button-save"]}`}
          label="CONFIRM"
          onClick={props.handleSaveOnHoldReason}
          disabled={!selectedOnHoldReason}
          loading={props.isSaveLoading}
        />
      </div>
    </div>
  );

  useEffect(() => {
    setIsModalLoading(true);
    if (props.visible) getOnHoldReasonList();
  }, [props.visible]);

  return (
    <Dialog
      header="Select a Reason for Holding"
      style={{ width: "30vw" }}
      visible={props.visible}
      onHide={props.onClose}
      footer={footer}
      closable={false}
      draggable={false}
      resizable={false}
      className={classes["onhold-reason-dialog"]}
    >
      {isModalLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading On-Hold Reasons...</div>
        </div>
      )}

      {!isModalLoading && (
        <div className={classes["content"]}>
          <div>
            <p>
              Please select a reason from the list below to put this task On
              Hold.
            </p>
          </div>

          <Dropdown
            className={classes["reason-dropdown"]}
            value={selectedOnHoldReason}
            onChange={onReasonChange}
            options={onHoldReasonList}
            optionLabel="taskStatusReason"
            placeholder="Reason for On Hold"
          />

          <div>
            <p>
              <i>
                Note: A reason must be selected to proceed. Please select a
                reason or cancel to revert status change.
              </i>
            </p>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default OnHoldReasonDialog;
