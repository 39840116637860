import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { uniqWith } from "lodash";
import ProjectTaskStatus from "../../pages/project-management-pmr/shared/enums/project-task-status";
import PmrE2EProjectOrderlineModel from "../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-orderline-model";
import { E2EProjectLineFlexTaskGroupItemModel } from "../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-task-group-item-model";
import {
  E2EProjectLineFlexTaskModel,
  E2EProjectLineMilestoneModel,
} from "../../shared/models/E2EProject.model";
import { TimestampResultModel } from "../../shared/models/service-models/TimestampResultModel";
import { calculateOrderlinesTaskStatus } from "./helpers/status";
import {
  ProjectManagement,
  ProjectManagementWrapper,
} from "./projectManagementInterfaces";
import HandlerType from "../../pages/project-management-pmr/shared/enums/handler-type";
import PmrE2EProjectHandler from "../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-handlers";
import { E2EUserPaginatedModel } from "../../shared/models/E2EUserPaginatedModel";
import { PmrE2EProjectFlexTaskMilestones } from "../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-milestone";
import ContributorsHelper from "./helpers/ContributorsHelper";
import ProjectOrderlineStatus from "../../pages/project-management-pmr/shared/enums/project-orderline-status";
import { formatNumber } from "../../utils/helpers/number.helpers";
import { ProjectShowHideColumnsEnum } from "../../pages/project-management-pmr/shared/enums/project-show-hide-columns";
import { projectListcolumns } from "../../pages/project-management-pmr/data/project-grid-columns";
import { projectTaskListColumns } from "../../pages/project-management-pmr/data/project-task-grid-columns";
import { projectOrderLineColumns } from "../../pages/project-management-pmr/data/project-order-line-grid-columns";
import { projectDetailsOrderlneListColumns } from "../../pages/project-management-pmr/data/project-details-orderline-list-grid-columns";
import { InvoicePriceTemplateInput } from "../../pages/project-management-pmr/project-details-order-line-list/invoice-price-template/InvoicePriceTemplate";
import PmrE2EProjectDetailsOrderlineModel from "../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-details-orderline-model";
import UpdateProjectlineCurrencyModel from "../../shared/models/currency-models/UpdateProjectlineCurrencyModel";

const contributorsHelper = ContributorsHelper();

const formatDate = (date: string | undefined) => {
  if (!date) return "";

  return moment(date)!.format("DD MMM yyyy");
};

const initialState: ProjectManagement = {
  isTimeEntryModalSyncing: false,
  selectAll: false,
  showFlexTasks: true,
  selectedView: {
    code: 2,
    name: "GMA WB Project",
  },
  favoritesModel: {
    viewBy: {},
    isFlexTaskOnly: false,
    refiners: {},
    recordsPerPage: 50,
    columns: [
      {
        key: ProjectShowHideColumnsEnum.Project,
        title: "Project",
        fields: projectListcolumns,
        maxFrozenColumnCount: 4,
        frozenColumnCount: 1,
        hasShowAndFreezeSelection: true,
        displayFrozenColumnDropdown: false,
      },
      {
        key: ProjectShowHideColumnsEnum.Table,
        title: "Table",
        subHeader: "Task",
        fields: projectTaskListColumns,
        maxFrozenColumnCount: 4,
        frozenColumnCount: 2,
        hasShowAndFreezeSelection: true,
        displayFrozenColumnDropdown: false,
      },
      {
        key: ProjectShowHideColumnsEnum.Table,
        title: "Table",
        subHeader: "Order Line",
        fields: projectOrderLineColumns,
        maxFrozenColumnCount: 4,
        frozenColumnCount: 1,
        hasShowAndFreezeSelection: true,
        displayFrozenColumnDropdown: false,
      },
      {
        key: ProjectShowHideColumnsEnum.OrderLine,
        title: "Order Line Details",
        fields: projectDetailsOrderlneListColumns,
        maxFrozenColumnCount: 7,
        frozenColumnCount: 0,
        hasShowAndFreezeSelection: true,
        displayFrozenColumnDropdown: true,
      },
    ],
  },
  projects: [],
  projectManagementViewProjects: [],
  isLoading: false,
  request: {
    skip: 1,
    take: 50,
  },
  paginationModel: {
    totalPages: 0,
    totalRecords: 0,
    firstIndex: 0,
    lastIndex: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  projectFlexTasksList: [],
  flexTaskMilestonesList: [],
  flexTaskOrderLinesList: [],
  selectedMilestones: [],
  selectedTaskOrderlines: [],
  projectOrderlineDetailList: [],
  forceReloadProject: false,
  refinerLineResult: {
    flexTaskIds: null,
    milestoneIds: null,
    lineIds: null,
    flexProjectIds: null,
  },
  recentMilestoneChanges: [],
  refreshProjectId: "",
  refreshOrderlineRefiners: "",
  expandedRows: {},
  refreshFlexTaskId: "",
};

export const projectManagementSlice = createSlice({
  name: "ProjectManagement",
  initialState: initialState as ProjectManagement,
  reducers: {
    updateRefreshProjectId: (state, action) => {
      state.refreshProjectId = action.payload;
    },

    updateSelectAll: (state, action) => {
      state.selectAll = action.payload;
    },
    updateShowFlexTasks: (state, action) => {
      state.showFlexTasks = action.payload;
    },
    updateViewBy: (state, action) => {
      state.selectedView = action.payload;
    },
    updateFavoritesModel: (state, action) => {
      let newItem = JSON.parse(JSON.stringify(state.favoritesModel));

      newItem[action.payload.property] = action.payload.value;
      state.favoritesModel = newItem;
    },
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProjectList: (state, action) => {
      state.projects = action.payload.projects;
      state.paginationModel = action.payload.paginationModel;

      //update favorites properties
      let newItem = JSON.parse(JSON.stringify(state.favoritesModel));
      newItem.recordsPerPage = state.request.take;
      state.favoritesModel = newItem;

      state.isLoading = false;
    },
    updateProjectManagementViewProjects: (state, action) => {
      state.projectManagementViewProjects = action.payload;
    },

    updateProjectManagementViewProjectsAdhocStatus: (state, action) => {
      const newProjectManagementViewProjects = [
        ...state.projectManagementViewProjects,
      ].map((item) => {
        if (item.id == action.payload) {
          item.isAdhocSyncing = false;
        }
        return item;
      });
      state.projectManagementViewProjects = newProjectManagementViewProjects;
    },

    updateRequest: (state, action) => {
      let newItem = JSON.parse(JSON.stringify(state.request));

      newItem[action.payload.property] = action.payload.value;
      state.request = newItem;
    },
    updateSelectedMilestones: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selectedMilestones = [
          ...new Set(state.selectedMilestones.concat(action.payload)),
        ];
        return;
      }

      if (action.payload.isChecked) {
        const isExist = state.selectedMilestones.some(
          (line) => line.id === action.payload.milestone.id
        );
        if (isExist) return;
        state.selectedMilestones.push(action.payload.milestone);
        return;
      } else {
        state.selectedMilestones = state.selectedMilestones.filter(
          (line) => line.id !== action.payload.milestone.id
        );
      }
    },
    unselectAllMilestones: (state) => {
      state.selectedMilestones = [];
    },
    updateSelectedTaskOrderlines: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selectedTaskOrderlines = [
          ...new Set(state.selectedTaskOrderlines.concat(action.payload)),
        ];
        return;
      }

      if (action.payload.isChecked) {
        const isExist = state.selectedTaskOrderlines.some(
          (line) =>
            line.e2EProjectLineFlexTaskId ===
            action.payload.taskOrderline.e2EProjectLineFlexTaskId
        );
        if (isExist) return;
        state.selectedTaskOrderlines.push(action.payload.taskOrderline);
        return;
      } else {
        state.selectedTaskOrderlines = state.selectedTaskOrderlines.filter(
          (line) =>
            line.e2EProjectLineFlexTaskId !==
            action.payload.taskOrderline.e2EProjectLineFlexTaskId
        );
      }
    },
    upsertSelectedTaskOrderlines: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selectedTaskOrderlines = action.payload;
      }
    },
    unselectAllTaskOrderlines: (state) => {
      state.selectedTaskOrderlines = [];
    },
    upsertProjectFlexTasks: (state, action) => {
      const newProjectFlexTasksList = [...state.projectFlexTasksList].filter(
        (list: any) => !(list.e2eProjectId === action.payload.e2eProjectId)
      );
      newProjectFlexTasksList.push(action.payload);

      state.projectFlexTasksList = newProjectFlexTasksList;
    },
    expandProjectFlexTasks: (state, action) => {
      const projectFlexTasksList = [...state.projectFlexTasksList].map(
        (list) => {
          if (list.e2eProjectId !== action.payload.e2eProjectId) return list;

          const newList = {
            ...list,
            tasks: list.tasks.map((task) => ({
              ...task,
              isExpand:
                action.payload.expandedRows[`${task.workBreakdownStructure}`],
            })),
          };

          return newList;
        }
      );

      state.projectFlexTasksList = projectFlexTasksList;
    },
    checkProjectFlexTask: (state, action) => {
      const projectFlexTasksList = [...state.projectFlexTasksList].map(
        (list) => {
          if (list.e2eProjectId !== action.payload.e2eProjectId) return list;

          const newList = {
            ...list,
            tasks: list.tasks.map((task) => ({
              ...task,
              isChecked:
                task.workBreakdownStructure ===
                action.payload.workBreakdownStructure
                  ? action.payload.checked
                  : task.isChecked,
            })),
          };

          return newList;
        }
      );

      state.projectFlexTasksList = projectFlexTasksList;
    },
    setExpandedProjectFlexTasks: (state, action) => {
      const projectFlexTasksList = [...state.projectFlexTasksList].map(
        (list) => {
          if (list.e2eProjectId !== action.payload.e2eProjectId) return list;

          const newList = {
            ...list,
            tasks: list.tasks.map((task) => ({
              ...task,
              isExpand:
                action.payload.expandedRows[task.workBreakdownStructure],
            })),
            isTaskMutated: action.payload.isTaskMutated,
          };

          return newList;
        }
      );

      state.projectFlexTasksList = projectFlexTasksList;
    },
    upsertFlexTaskMilestones: (state, action) => {
      const newFlexTaskMilestonesList = [
        ...state.flexTaskMilestonesList,
      ].filter(
        (list: any) =>
          !(
            list.projectId === action.payload.projectId &&
            list.workBreakdownStructure ===
              action.payload.workBreakdownStructure
          )
      );

      newFlexTaskMilestonesList.push(action.payload);

      state.flexTaskMilestonesList = newFlexTaskMilestonesList;
    },
    upsertFlexTaskOrderLines: (state, action) => {
      const newFlexTaskOrderLinesList = [
        ...state.flexTaskOrderLinesList,
      ].filter(
        (list: any) =>
          !(
            list.projectId === action.payload.projectId &&
            list.workBreakdownStructure ===
              action.payload.workBreakdownStructure
          )
      );

      newFlexTaskOrderLinesList.push(action.payload);

      state.flexTaskOrderLinesList = newFlexTaskOrderLinesList;
    },
    updateFlexTaskOrderLineStatus: (state, action) => {
      state.flexTaskOrderLinesList.forEach((list) => {
        if (list.projectId !== action.payload.projectId) return;

        list.orderLines.forEach((orderLine) => {
          if (
            orderLine.e2EProjectLineFlexTaskId ===
            action.payload.e2EProjectLineFlexTaskId
          ) {
            orderLine.status = action.payload.newStatus;
            orderLine.flexProjectTaskHoldReason = action.payload.onHoldReason;
          }
        });
      });
    },
    updateProjectFlexTaskStatusList: (state, action) => {
      state.projectFlexTasksList.forEach((projectList) => {
        if (projectList.e2eProjectId !== action.payload.projectId) return;

        projectList.tasks.forEach((task) => {
          task.e2EProjectLineFlexTaskGroupItems.forEach((orderline) => {
            if (
              orderline.e2EProjectLineFlexTaskId ===
              action.payload.e2EProjectLineFlexTaskId
            ) {
              orderline.flexTaskStatus = action.payload.newStatus;
            }
          });
        });
      });
    },
    updateMilestoneStatus: (state, action) => {
      state.flexTaskMilestonesList.forEach((list) => {
        if (list.projectId !== action.payload.projectId) return;

        list.milestones.forEach((milestone) => {
          if (milestone.id === action.payload.e2EProjectLineMilestoneId) {
            milestone.status = action.payload.newStatus;
            milestone.onHoldReason = action.payload.onHoldReason;
            milestone.onHoldReasonId = action.payload.onHoldReasonId;
          }
        });
      });
    },
    updateGroupMilestoneStatus: (state, action) => {
      state.projectFlexTasksList.forEach((projectList) => {
        if (projectList.e2eProjectId !== action.payload.projectId) return;

        projectList.tasks.forEach((task) => {
          task.e2EProjectLineMilestoneGroupItems.forEach((milestone) => {
            if (
              milestone.e2EProjectLineMilestoneId ===
              action.payload.e2EProjectLineMilestoneId
            ) {
              milestone.status = action.payload.newStatus;
            }
          });
        });
      });
    },
    updateFlexTaskOrderLineProjectLineStatusToCompleted: (state, action) => {
      state.flexTaskOrderLinesList.forEach((list) => {
        list.orderLines.forEach((orderLine) => {
          if (orderLine.e2EProjectLineId === action.payload.e2EProjectLineId) {
            orderLine.e2EProjectLineStatus = ProjectOrderlineStatus.Completed;
          }
        });
      });
    },
    upsertFlexTaskMilestoneProjectLineStatusToCompleted: (state, action) => {
      state.flexTaskMilestonesList.forEach((list) => {
        list.milestones.forEach((milestone) => {
          if (milestone.e2EProjectLineId === action.payload.e2EProjectLineId) {
            milestone.e2EProjectLineStatus = ProjectOrderlineStatus.Completed;
          }
        });
      });
    },
    updateMilestonesFromEditMilestoneDateResult: (state, action) => {
      const updatedMilestones =
        action.payload as E2EProjectLineMilestoneModel[];

      state.flexTaskMilestonesList.forEach((list) => {
        list.milestones.forEach((milestone) => {
          const updatedMilestone = updatedMilestones.find(
            (updatedMilestone) => milestone.id === updatedMilestone.id
          );

          if (!updatedMilestone) return;

          milestone.startDate = formatDate(updatedMilestone.formattedStartDate);
          milestone.dueDate = formatDate(updatedMilestone.formattedDueDate);
          milestone.duration = (
            Math.round(updatedMilestone.durationInDays! * 100) / 100
          ).toString();
          if(milestone.isAdHoc && milestone.isAdHoc === true) {
            milestone.status = updatedMilestone.status!;
          }
        });
      });
    },
    updateProjectContributorsFromTimestampResult: (state, action) => {
      const timestampResult = action.payload as TimestampResultModel;

      const getHandlerFromFlexTaskOrderlines = (
        projectId: string,
        taskIds: string[],
        workBreakdownStructure: number
      ) => {
        const handlerList: any = [];
        state.flexTaskOrderLinesList.forEach((project) => {
          if (project.projectId !== projectId) return;

          project.orderLines.forEach((orderLine) => {
            if (
              project.workBreakdownStructure === workBreakdownStructure &&
              taskIds.includes(orderLine.e2EProjectLineFlexTaskId)
            ) {
              orderLine.collaborator.forEach((collaborator) => {
                if (collaborator.handlerType === HandlerType.TaskHandler) {
                  handlerList.push(collaborator);
                }
              });
            }
          });
        });

        return handlerList;
      };

      const processNewTaskHandler = (
        newTaskHandler: any,
        flexTaskId: string
      ) => {
        // update contributors on orderline level
        state.flexTaskOrderLinesList.forEach((flexTaskOrderline) => {
          flexTaskOrderline.orderLines.forEach((orderline) => {
            if (orderline.e2EProjectLineFlexTaskId === flexTaskId) {
              const isHandlerExists = orderline.collaborator.findIndex(
                (collaborator) =>
                  collaborator.handlerType === HandlerType.TaskHandler &&
                  collaborator.isActive
              );

              if (isHandlerExists !== -1) {
                orderline.collaborator[isHandlerExists] = newTaskHandler;
              } else {
                orderline.collaborator.push(newTaskHandler);
              }
            }
          });
        });

        //update contributors on milestone level
        state.flexTaskMilestonesList.forEach((flexMilestone) => {
          flexMilestone.milestones.forEach((milestone) => {
            if (milestone.e2EProjectLineFlexTaskId === flexTaskId) {
              const isHandlerExists = milestone.collaborator.findIndex(
                (collaborator) =>
                  collaborator.handlerType === HandlerType.TaskHandler &&
                  collaborator.isActive
              );

              if (isHandlerExists !== -1) {
                milestone.collaborator[isHandlerExists] = newTaskHandler;
              } else {
                milestone.collaborator.push(newTaskHandler);
              }
            }
          });
        });
      };

      if (timestampResult?.updatedFlexTasks) {
        timestampResult?.updatedFlexTasks.forEach(
          (updatedTask: E2EProjectLineFlexTaskModel) => {
            if (updatedTask.e2EProjectLineFlexTaskCollaborators) {
              updatedTask.e2EProjectLineFlexTaskCollaborators.forEach(
                (collaborator: any) => {
                  if (
                    collaborator.handlerType === HandlerType.TaskHandler &&
                    collaborator.isActive
                  ) {
                    const newTaskHandler = collaborator;
                    processNewTaskHandler(newTaskHandler, updatedTask.id!);
                  }
                }
              );
            }
          }
        );
      }

      //update collaborators in task level
      state.projectFlexTasksList.forEach((flextask) => {
        const projectId = flextask.e2eProjectId;
        flextask.tasks.forEach((task) => {
          const handlerList = getHandlerFromFlexTaskOrderlines(
            projectId,
            task.e2EProjectLineFlexTaskIds,
            task.workBreakdownStructure
          );

          const distinctHandlerList =
            contributorsHelper.distinctContributorList(handlerList);

          if (distinctHandlerList?.length > 0) {
            task.e2EProjectLineFlexTaskCollaborators = distinctHandlerList;
          }
        });
      });
    },

    updateProjectsInfoFromTimestampResult: (state, action) => {
      const timestampResult = action.payload as TimestampResultModel;

      state.flexTaskMilestonesList.forEach((list) => {
        list.milestones.forEach((milestone) => {
          const updatedMilestone = timestampResult.updatedMilestones.find(
            (updatedMilestone) => milestone.id === updatedMilestone.id
          );

          const updateTaskHandler = timestampResult.updatedFlexTasks
            .find(
              (updateTask) =>
                milestone.e2EProjectLineFlexTaskId === updateTask.id
            )
            ?.e2EProjectLineFlexTaskCollaborators?.filter(
              (collaborator: any) =>
                collaborator.handlerType === HandlerType.TaskHandler &&
                collaborator.isActive
            );

          if (updateTaskHandler) {
            updateTaskHandler.forEach((handler) => {
              if (
                milestone.e2EProjectLineFlexTaskId !==
                handler.e2EProjectLineFlexTaskId
              )
                return;

              const newHandler: PmrE2EProjectHandler = {
                displayName: handler.displayName!,
                userEmail: handler.userEmail!,
                assignOrder: handler.assignOrder!,
                isActive: true,
                handlerType: HandlerType.TaskHandler,
              };

              const isHandlerExists = milestone.collaborator.findIndex(
                (collaborator) =>
                  collaborator.handlerType === HandlerType.TaskHandler &&
                  collaborator.isActive
              );

              if (isHandlerExists !== -1) {
                milestone.collaborator[isHandlerExists] = newHandler;
              } else {
                milestone.collaborator.push(newHandler);
              }
            });
          }

          if (updatedMilestone) {
            milestone.status = updatedMilestone.status!;
            milestone.startDate = formatDate(
              updatedMilestone.formattedStartDate
            );
            milestone.dueDate = formatDate(updatedMilestone.formattedDueDate);
            milestone.completionDate = updatedMilestone.completionDate
              ? moment(updatedMilestone.completionDate).format("DD MMM yyyy")
              : "";
            milestone.predecessorTaskStatuses = updatedMilestone.predecessorTaskStatus;
          }
        });
      });

      const updateTask = (
        oldTask:
          | E2EProjectLineFlexTaskGroupItemModel
          | PmrE2EProjectOrderlineModel,
        updatedTask: E2EProjectLineFlexTaskModel | undefined
      ) => {
        if (updatedTask) {
          oldTask.flexTaskStatus =
            updatedTask.flexTaskStatus as ProjectTaskStatus;
          oldTask.startDate = formatDate(updatedTask.formattedStartDate);
          oldTask.dueDate = formatDate(updatedTask.formattedDueDate);
          oldTask.completionDate = updatedTask.completionDate
            ? moment(updatedTask.completionDate).format("DD MMM yyyy")
            : "";
        }
      };

      for (let list of state.projectFlexTasksList) {
        let isProjectHasUpdate: boolean = false;

        list.tasks.forEach((task) => {
          task.e2EProjectLineFlexTaskGroupItems.forEach((taskItem) => {
            const updatedTask = timestampResult.updatedFlexTasks.find(
              (updatedTask) =>
                taskItem.e2EProjectLineFlexTaskId === updatedTask.id
            );
            if (updatedTask) {
              isProjectHasUpdate = true;
              updateTask(taskItem, updatedTask);
            }
          });
        });

        if (!isProjectHasUpdate) continue;

        const newFirstInProgressTaskIndex = list.tasks.findIndex(
          (task) =>
            calculateOrderlinesTaskStatus(task) === ProjectTaskStatus.InProgress
        );

        list.tasks.forEach((task, index) => {
          if (index === newFirstInProgressTaskIndex) {
            task.isExpand = true;
            return;
          }

          const isCompleted =
            calculateOrderlinesTaskStatus(task) === ProjectTaskStatus.Completed;

          const isTimestamped = task.e2EProjectLineFlexTaskGroupItems.some(
            (item) =>
              timestampResult.timestampedFlexTaskIds.includes(
                item.e2EProjectLineFlexTaskId
              )
          );

          if (isTimestamped && isCompleted) task.isExpand = false;
        });
      }

      state.flexTaskOrderLinesList.forEach((list) => {
        list.orderLines.forEach((taskItem) => {
          const updatedTask = timestampResult.updatedFlexTasks.find(
            (updatedTask) =>
              taskItem.e2EProjectLineFlexTaskId === updatedTask.id
          );

          updateTask(taskItem, updatedTask);

          if (updatedTask) {
            taskItem.status = updatedTask.flexTaskStatus as ProjectTaskStatus;
          }
        });
      });

      state.projectManagementViewProjects?.forEach((project) => {
        const updatedProject = timestampResult.updatedProjects?.find(
          (p) => project.id === p.id
        );

        if (updatedProject) {
          project.status = updatedProject.status;
        }

        const updatedProjectLine = timestampResult?.updatedProjectLines?.find(
          (line) => line?.e2EProjectId === project?.id
        );

        if (
          updatedProjectLine &&
          updatedProjectLine?.orderLineStatus ===
            ProjectOrderlineStatus.Completed
        ) {
          (project as any).hasCompletedProjectLine = true;
        }
      });
    },
    setFlexTaskMilestonesTimestampingStatus: (state, action) => {
      state.flexTaskMilestonesList.forEach((list) => {
        list.milestones.forEach((milestone) => {
          if (action.payload.milestoneIds.includes(milestone.id)) {
            milestone.isTimestamping = action.payload.isTimestamping;
          }
        });
      });
    },
    setFlexTaskOrderLinesTimestampingStatus: (state, action) => {
      state.flexTaskOrderLinesList.forEach((list) => {
        list.orderLines.forEach((orderLine) => {
          if (
            action.payload.taskIds.includes(orderLine.e2EProjectLineFlexTaskId)
          ) {
            orderLine.isTimestamping = action.payload.isTimestamping;
          }
        });
      });
    },
    upsertOrderlineDetailsList: (state, action) => {
      const newProjectOrderlineDetailList = [
        ...state.projectOrderlineDetailList,
      ].filter((list: any) => !(list.projectId === action.payload.projectId));

      newProjectOrderlineDetailList.push(action.payload);

      state.projectOrderlineDetailList = newProjectOrderlineDetailList;
    },
    upsertOrderlineDetailsListFromUpdateStatus: (state, action) => {
      state.projectOrderlineDetailList.forEach((item) => {
        item.orderlineDetailList.forEach((line) => {
          if (line.id == action.payload.id) {
            if (action.payload.fulFillmentStatusDetail)
              line.fulFillmentStatusDetail =
                action.payload.fulFillmentStatusDetail;
            if (action.payload.fulFillmentStatusDetailId)
              line.fulFillmentStatusDetailId =
                action.payload.fulFillmentStatusDetailId;
            if (action.payload.flexWritebackStatusId)
              line.flexWritebackStatusId = action.payload.flexWritebackStatusId;
            if (action.payload.orderLineStatus)
              line.orderLineStatus = action.payload.orderLineStatus;
            if (action.payload.e2EFlexProjectStatus)
              line.e2EFlexProjectStatus = action.payload.e2EFlexProjectStatus;
          }
        });
      });
    },
    updateOrderLineInvoice: (state, action) => {
      const payloads = action.payload as UpdateProjectlineCurrencyModel[];
      const lines = state.projectOrderlineDetailList
        .flatMap((project) => project.orderlineDetailList)
        .filter((line) =>
          payloads.map((payload) => payload.e2eProjectLineId).includes(line.id)
        );

      lines.forEach((line: any) => {
        const payload = payloads.find(
          (item) => item.e2eProjectLineId === line.id
        );
        if (!payload) return;

        Object.entries(payload).forEach(([key, value]) => {
          line[key] = value;
        });
      });
    },
    updateOrderlineECD: (state, action) => {
      state.projectOrderlineDetailList.forEach((list) => {
        list.orderlineDetailList.forEach((orderline) => {
          if (orderline.id !== action.payload.id) return;
          orderline.orderLineECD = action.payload.newOrderLineECD;
        });
      });
    },
    updateIsMismatchECD: (state, action) => {
      const params = action.payload;
      state.projectManagementViewProjects?.forEach((project) => {
        if (project.id == params.e2eProjectId)
          project.isMismatchECD = params.e2EProjectHasMismatchECD;
      });

      state.projectOrderlineDetailList.forEach((list) => {
        list.orderlineDetailList.forEach((orderline) => {
          if (orderline.id == params.e2EProjectLineId) {
            orderline.isMismatchECD = params.isMismatchECD;

            if (orderline.isMismatchECD)
              orderline.orderLineECD = params.newECD
                ? moment(params.newECD).format("DD MMM yyyy")
                : null;
          }
        });
      });
    },

    updateE2EProjectsStatuses: (state, action) => {
      state.projectManagementViewProjects?.forEach((project) => {
        const updatedProject = action.payload?.find(
          (p: any) => project.id === p.id
        );

        if (updatedProject) {
          project.status = updatedProject.status;
        }
      });
    },

    updateProjectFlexTaskCollaboratorList: (state, action) => {
      const {
        projectId,
        workBreakdownStructure,
        taskHandler,
        milestoneHandler,
        e2EProjectLineMilestoneId,
      } = action.payload;

      const updateCollaborator = (
        collaborator: PmrE2EProjectHandler,
        handler: E2EUserPaginatedModel
      ) => {
        collaborator.displayName = handler.displayName;
        collaborator.userEmail = handler.normalizedEmail;
        collaborator.assignOrder += 1;
      };

      const distintList = (list: PmrE2EProjectHandler[]) => {
        return uniqWith(
          list,
          (
            comparable: PmrE2EProjectHandler,
            comparator: PmrE2EProjectHandler
          ) => {
            return (
              comparable.userEmail === comparator.userEmail &&
              comparable.handlerType === comparator.handlerType
            );
          }
        );
      };

      const getContributorsList = (
        flexTaskList: any[],
        handlerType: HandlerType
      ) => {
        const task = e2EProjectLineMilestoneId ? "milestones" : "orderLines";
        const collaboratorList: PmrE2EProjectHandler[] = [];

        flexTaskList.forEach((flexTask) => {
          if (
            flexTask.projectId === projectId &&
            flexTask.workBreakdownStructure === workBreakdownStructure
          ) {
            flexTask[task].forEach((task: any) => {
              const taskHandlers = task.collaborator.filter(
                (handler: PmrE2EProjectHandler) =>
                  handler.handlerType === handlerType
              );

              collaboratorList.push(...taskHandlers);
            });
          }
        });

        return collaboratorList;
      };

      state.projectFlexTasksList.forEach((projectList) => {
        if (projectList.e2eProjectId !== projectId) return;

        projectList.tasks.forEach((task) => {
          if (task.workBreakdownStructure !== workBreakdownStructure) return;

          task.e2EProjectLineFlexTaskCollaborators.forEach((collaborator) => {
            switch (collaborator.handlerType) {
              case HandlerType.TaskHandler:
                if (taskHandler?.id)
                  updateCollaborator(collaborator, taskHandler);
                break;
            }
          });

          if (taskHandler?.id) {
            task.e2EProjectLineFlexTaskCollaborators =
              task.e2EProjectLineFlexTaskCollaborators.filter(
                (handler) => handler.handlerType !== HandlerType.TaskHandler
              );

            const taskCollaboratorList = getContributorsList(
              e2EProjectLineMilestoneId
                ? state.flexTaskMilestonesList
                : state.flexTaskOrderLinesList,
              HandlerType.TaskHandler
            );

            task.e2EProjectLineFlexTaskCollaborators.push(
              ...distintList(taskCollaboratorList)
            );
          }

          if (milestoneHandler?.id) {
            task.e2EProjectLineFlexTaskCollaborators =
              task.e2EProjectLineFlexTaskCollaborators.filter(
                (handler) =>
                  handler.handlerType !== HandlerType.MilestoneHandler
              );

            const milestoneCollaboratorList = getContributorsList(
              state.flexTaskMilestonesList,
              HandlerType.MilestoneHandler
            );

            task.e2EProjectLineFlexTaskCollaborators.push(
              ...distintList(milestoneCollaboratorList)
            );
          }
        });
      });
    },
    updateFlexTaskOrderLineContributor: (state, action) => {
      const {
        projectId,
        projectNumber,
        workBreakdownStructure,
        e2EProjectLineFlexTaskId,
        taskHandler,
        e2EProjectLineId,
      } = action.payload;

      const processOrderLineHandlers = (
        orderLine: PmrE2EProjectOrderlineModel,
        handlerType: HandlerType,
        handler: E2EUserPaginatedModel
      ) => {
        const isHandlerExists = orderLine.collaborator.some(
          (handler) => handler.handlerType === handlerType
        );

        if (isHandlerExists) {
          orderLine.collaborator.forEach(
            (collaborator: PmrE2EProjectHandler) => {
              if (collaborator.handlerType === handlerType) {
                contributorsHelper.updateCollaborator(collaborator, handler);
              }
            }
          );
        } else {
          const newHandler: PmrE2EProjectHandler = {
            displayName: handler.displayName,
            userEmail: handler.normalizedEmail,
            assignOrder: 1,
            isActive: true,
            handlerType: handlerType,
          };

          orderLine.collaborator.push(newHandler);
        }
      };

      //update collaborators in orderline
      state.flexTaskOrderLinesList.forEach((list) => {
        if (list.projectId !== projectId) return;

        list.orderLines.forEach((orderLine) => {
          if (
            list.workBreakdownStructure === workBreakdownStructure &&
            orderLine.e2EProjectLineFlexTaskId === e2EProjectLineFlexTaskId
          ) {
            if (taskHandler?.id) {
              processOrderLineHandlers(
                orderLine,
                HandlerType.TaskHandler,
                taskHandler
              );
            }
          }
        });
      });
    },
    updateFlexTaskMilestoneOrderLineContributor: (state, action) => {
      const {
        projectId,
        workBreakdownStructure,
        milestoneHandler,
        taskHandler,
        e2EProjectLineMilestoneId,
        e2EProjectLineId,
      } = action.payload;

      const listOfAfftectedMilestone = [
        "Customer: Send All Documents to UL",
        "Customer: Send All Samples to UL",
        "UL: Review and Prepare Application Package",
        "UL: Submit to Authority/Authority Review",
        "UL: Submit Samples to Test Lab/In-Country Testing",
        "UL: Payment",
        "UL: Sample Return",
      ];

      const processMilestoneHandlers = (
        milestone: PmrE2EProjectFlexTaskMilestones,
        handlerType: HandlerType,
        handler: E2EUserPaginatedModel
      ) => {
        const isHandlerExists = milestone.collaborator.some(
          (handler) => handler.handlerType === handlerType
        );

        if (isHandlerExists) {
          milestone.collaborator.forEach(
            (collaborator: PmrE2EProjectHandler) => {
              if (collaborator.handlerType === handlerType) {
                contributorsHelper.updateCollaborator(collaborator, handler);
              }
            }
          );
        } else {
          const newHandler: PmrE2EProjectHandler = {
            displayName: handler.displayName,
            userEmail: handler.normalizedEmail,
            assignOrder: 1,
            isActive: true,
            handlerType: handlerType,
          };

          milestone.collaborator.push(newHandler);
        }
      };

      //update collaborators in milestone level
      state.flexTaskMilestonesList.forEach((list) => {
        if (list.projectId !== projectId) return;

        list.milestones.forEach((milestone) => {
          if (
            taskHandler?.id &&
            milestone.e2EProjectLineId === e2EProjectLineId &&
            list.workBreakdownStructure === workBreakdownStructure
          ) {
            processMilestoneHandlers(
              milestone,
              HandlerType.TaskHandler,
              taskHandler
            );
          }

          if (
            milestoneHandler?.id &&
            milestone.id === e2EProjectLineMilestoneId &&
            list.workBreakdownStructure === workBreakdownStructure
          ) {
            processMilestoneHandlers(
              milestone,
              HandlerType.MilestoneHandler,
              milestoneHandler
            );
          }
        });
      });
    },
    updateFlexTaskCoHandler: (state, action) => {
      const {
        projectId,
        workBreakdownStructure,
        taskHandler,
        e2EProjectLineMilestoneId,
        e2EProjectLineId,
        isAssignCoHandlerMailboxNotRequired,
        e2EProjectLineFlexTaskId,
        isMilestoneLevel,
      } = action.payload;

      if (isMilestoneLevel) {
        state.flexTaskMilestonesList.forEach((milestones) => {
          if (milestones.projectId === projectId) {
            milestones.milestones.forEach((milestone) => {
              if (milestone.id == e2EProjectLineMilestoneId)
                milestone.isAssignCoHandlerMailboxNotRequired =
                  isAssignCoHandlerMailboxNotRequired;
            });
          }
        });
        const processMilestoneHandlers = (
          milestone: PmrE2EProjectFlexTaskMilestones,
          handlerType: HandlerType,
          handler: E2EUserPaginatedModel
        ) => {
          const isHandlerExists = milestone.collaborator.some(
            (handler) => handler.handlerType === handlerType
          );

          if (isHandlerExists) {
            milestone.collaborator.forEach(
              (collaborator: PmrE2EProjectHandler) => {
                if (collaborator.handlerType === handlerType) {
                  contributorsHelper.updateCollaborator(collaborator, handler);
                }
              }
            );
          } else {
            const newHandler: PmrE2EProjectHandler = {
              displayName: handler.displayName,
              userEmail: handler.normalizedEmail,
              assignOrder: 1,
              isActive: true,
              handlerType: handlerType,
            };

            milestone.collaborator.push(newHandler);
          }
        };

        //update collaborators in milestone level
        state.flexTaskMilestonesList.forEach((list) => {
          if (list.projectId !== projectId) return;

          list.milestones.forEach((milestone) => {
            if (
              taskHandler?.id &&
              milestone.e2EProjectLineId === e2EProjectLineId &&
              list.workBreakdownStructure === workBreakdownStructure
            ) {
              processMilestoneHandlers(
                milestone,
                HandlerType.TaskHandler,
                taskHandler
              );
              processMilestoneHandlers(
                milestone,
                HandlerType.MilestoneHandler,
                taskHandler
              );
            }
          });
        });
      } else {
        state.flexTaskOrderLinesList.forEach((orderLines) => {
          if (orderLines.projectId === projectId) {
            orderLines.orderLines.forEach((orderLine) => {
              if (orderLine.id == e2EProjectLineId)
                orderLine.isAssignCoHandlerMailboxNotRequired =
                  isAssignCoHandlerMailboxNotRequired;
            });
          }
        });
        const processOrderLineHandlers = (
          orderLine: PmrE2EProjectOrderlineModel,
          handlerType: HandlerType,
          handler: E2EUserPaginatedModel
        ) => {
          const isHandlerExists = orderLine.collaborator.some(
            (handler) => handler.handlerType === handlerType
          );

          if (isHandlerExists) {
            orderLine.collaborator.forEach(
              (collaborator: PmrE2EProjectHandler) => {
                if (collaborator.handlerType === handlerType) {
                  contributorsHelper.updateCollaborator(collaborator, handler);
                }
              }
            );
          } else {
            const newHandler: PmrE2EProjectHandler = {
              displayName: handler.displayName,
              userEmail: handler.normalizedEmail,
              assignOrder: 1,
              isActive: true,
              handlerType: handlerType,
            };

            orderLine.collaborator.push(newHandler);
          }
        };

        //update collaborators in orderline
        state.flexTaskOrderLinesList.forEach((list) => {
          if (list.projectId !== projectId) return;

          list.orderLines.forEach((orderLine) => {
            if (
              list.workBreakdownStructure === workBreakdownStructure &&
              orderLine.e2EProjectLineFlexTaskId === e2EProjectLineFlexTaskId
            ) {
              if (taskHandler?.id) {
                processOrderLineHandlers(
                  orderLine,
                  HandlerType.TaskHandler,
                  taskHandler
                );
              }
            }
          });
        });
      }
    },
    upsertProjectFlexTasksContributors: (state, action) => {
      state.projectFlexTasksList.forEach((flexTask) => {
        if (flexTask.e2eProjectId === action.payload.e2eProjectId) {
          flexTask.tasks.forEach((task) => {
            const newTask = action.payload.tasks.find(
              (newtask: any) => newtask.flexTaskName === task.flexTaskName
            );
            if (task.flexTaskName === newTask.flexTaskName) {
              task.e2EProjectLineFlexTaskCollaborators =
                newTask.e2EProjectLineFlexTaskCollaborators;

                if(task.isAdHoc){
                  task.e2EProjectLineFlexTaskGroupItems = 
                  newTask.e2EProjectLineFlexTaskGroupItems;
                } 
            }
          });
        }
      });
    },
    updateProjectLevelContributors: (state, action) => {
      const { e2eProjectId, projectHandlers } = action.payload;
      state.projectManagementViewProjects.forEach((project) => {
        if (project.id === e2eProjectId) {
          project.e2EProjectHandlers = [];

          projectHandlers.forEach((handler: PmrE2EProjectHandler) => {
            project.e2EProjectHandlers?.push(handler);
          });
        }
      });
    },
    updateMilestoneDataUponRemoveTimestamp: (state, action) => {
      state.flexTaskMilestonesList.forEach((flexTask) => {
        if (flexTask.projectId !== action.payload.projectId) return;

        flexTask.milestones.forEach((milestone) => {
          if (milestone.id === action.payload?.predecessorMilestoneId) {
            milestone.successorTaskStatus =
              action.payload?.newMilestoneStatus?.toString();
          }
          if (milestone.id === action.payload?.successorMilestoneId) {
            milestone.predecessorTaskStatus =
              action.payload?.newMilestoneStatus?.toString();
          }

          if (milestone.id !== action.payload.milestoneId) return;

          milestone.status = action.payload.newMilestoneStatus;
          milestone.completionDate = action.payload.newCompletionDate;
          milestone.predecessorTaskStatus =
            action.payload?.predecessorTaskStatus?.toString();
          milestone.successorTaskStatus =
            action.payload?.successorTaskStatus?.toString();
        });
      });
    },
    expandProjectFlexTasksByMilestoneId: (state, action) => {
      state.projectFlexTasksList.forEach((flexTask) => {
        flexTask.tasks.forEach((task) => {
          task.isExpand = false;
          task.e2EProjectLineMilestoneGroupItems.forEach((milestone) => {
            if (
              milestone.e2EProjectLineMilestoneId ===
              action.payload.e2eProjectMilestoneId
            ) {
              task.isExpand = true;
              return;
            }
          });
        });
      });
    },
    updateForceReloadProject: (state, action) => {
      state.forceReloadProject = action.payload;
    },
    updateMilestoneRecentChange: (state, action) => {
      state.recentMilestoneChanges = action.payload;
    },
    updateRefinerLineResult: (state, action) => {
      state.refinerLineResult = action.payload;
    },
    updateAllProjectLevels: (state, action) => {
      const projectSync = action.payload;

      const updatedFlexTasks = projectSync?.tasks;
      const updatedTasklines = projectSync.taskLines;
      const updatedE2EProjectLineFlexTaskId = updatedTasklines[0]?.id || null;
      state.projectFlexTasksList.forEach((task) => {
        if (task.e2eProjectId === projectSync?.e2eProjectId) {
          task.tasks.forEach((t) => {
            const item = updatedFlexTasks.find(
              (f: any) => f.sortOrder === t.sortOrder
            );

            if (item) {
              for (let te of t.timeEntrySnycFlexTaskList) {
                if (!updatedE2EProjectLineFlexTaskId) continue;
                if (
                  te.e2EProjectLineFlexTaskId == updatedE2EProjectLineFlexTaskId
                )
                  te.isTimeEntrySyncing = false;
              }
              if (
                t.myHours === item?.myHours &&
                t.totalHours === item.totalHours
              ) {
                return;
              }

              t.myHours = item.myHours;
              t.totalHours = item.totalHours;
            }
          });
        }
      });

      updatedTasklines.forEach((element: any) => {
        const flexTasksOrderlines = state.flexTaskOrderLinesList.find(
          (orderline) =>
            orderline.projectId === projectSync.e2eProjectId &&
            orderline.workBreakdownStructure === element.sortOrder
        )?.orderLines;

        if (flexTasksOrderlines) {
          flexTasksOrderlines.forEach((line) => {
            if (line.e2EProjectLineFlexTaskId === element.id) {
              const formattedMyHours = formatNumber(element.myHours);
              const formattedTotalHours = formatNumber(element.totalHours);
              if (line.myHours !== formattedMyHours) {
                line.myHours = formattedMyHours;
              }
              if (line.totalHours !== formattedTotalHours) {
                line.totalHours = formattedTotalHours;
              }
            }
          });
        }
      });
    },

    updateTaskOrderlineSyncingStatus: (state, action) => {
      const { projectId, workBreakdownStructure, e2EProjectLineFlexTaskId } =
        action.payload;

      state.projectFlexTasksList.forEach((p) => {
        if (p.e2eProjectId === projectId) {
          p.tasks.forEach((task) => {
            for (let te of task.timeEntrySnycFlexTaskList) {
              if (te.e2EProjectLineFlexTaskId == e2EProjectLineFlexTaskId)
                te.isTimeEntrySyncing = true;
            }
          });
        }
      });
    },

    updateTimeEntryModalSyncing: (state, action) => {
      state.isTimeEntryModalSyncing = action.payload;
    },
    updateE2EProjectLineFlexTaskGroupItemsDates: (state, action) => {
      const updatedTasks = action.payload as E2EProjectLineFlexTaskModel[];

      state.projectFlexTasksList.forEach((projectList) => {
        projectList.tasks.forEach((task) => {
          task.e2EProjectLineFlexTaskGroupItems.forEach((taskItem) => {
            const updatedTask = updatedTasks.find(
              (task) => task.id === taskItem.e2EProjectLineFlexTaskId
            );

            if (updatedTask) {
              taskItem.startDate = formatDate(updatedTask.formattedStartDate);
              taskItem.dueDate = formatDate(updatedTask.formattedDueDate);

              if(updatedTask.isAdHoc && updatedTask.isAdHoc === true) {
                taskItem.flexTaskStatus = updatedTask.flexTaskStatus!;
              }
            }
          });
        });
      });
    },
    updateE2EProjectLineFlexTasksComment: (state, action) => {
      state.flexTaskOrderLinesList.forEach((list) => {
        list.orderLines.forEach((taskItem) => {
          if (taskItem.flexProjectTaskId === action.payload.flexProjectTaskId) {
            taskItem.comment = action.payload.comment;
            taskItem.commentedBy = action.payload.commentedBy;
          }
        });
      });
    },
    setExpandedRowsProjectList(
      state,
      action: PayloadAction<{ [key: string]: boolean }>
    ) {
      state.expandedRows = action.payload;
    },
    toggleRowExpansionProjectList(
      state,
      action: PayloadAction<{ id: string }>
    ) {
      const { id } = action.payload;
      const isExpanded = state.expandedRows[id];
      if (isExpanded) {
        const { [id]: _, ...rest } = state.expandedRows;
        state.expandedRows = rest;
      } else {
        state.expandedRows[id] = true;
      }
    },
    refreshOrderlineRefiners: (state, action) => {
      state.refreshOrderlineRefiners = new Date().getTime().toString();
    },
    removeRefreshOrderlineRefiners: (state, action) => {
      state.refreshOrderlineRefiners = "";
    },
    updateOrderLineListHandlers: (state, action) => {
      const { id, projectId, handlerName, handlerEmail, handlerType } =
        action.payload;

      const orderLineList = state.projectOrderlineDetailList.filter(
        (orderLine) => orderLine.projectId === projectId
      );
      orderLineList[0].orderlineDetailList.map((orderLine) => {
        if (orderLine.id === id) {
          if (handlerType === HandlerType.FlexHandler) {
            orderLine.flexHandler = handlerName;
            orderLine.flexHandlerEmail = handlerEmail;
          }
          if (handlerType === HandlerType.GmaWBHandler) {
            orderLine.gmaWBHandler = handlerName;
            orderLine.gmaWBHandlerEmail = handlerEmail;
          }
        }

        return orderLine;
      });
    },
    updateProjectECD: (state, action) => {
      const project = state.projectOrderlineDetailList.find(
        (line) => line.projectId === action.payload.projectId
      );

      project?.orderlineDetailList.forEach((line) => {
        line.flexProjectECD = action.payload.newECD;
      });
    },
    updateRefreshFlexTaskId: (state, action) => {
      state.refreshFlexTaskId = action.payload;
    }
  },
});

export const {
  updateRefreshProjectId,
  updateSelectAll,
  updateShowFlexTasks,
  updateViewBy,
  updateFavoritesModel,
  updateProjectList,
  updateLoader,
  updateRequest,
  updateSelectedMilestones,
  updateSelectedTaskOrderlines,
  upsertSelectedTaskOrderlines,
  upsertFlexTaskMilestones,
  updateProjectsInfoFromTimestampResult,
  unselectAllMilestones,
  unselectAllTaskOrderlines,
  upsertProjectFlexTasks,
  upsertFlexTaskOrderLines,
  updateFlexTaskOrderLineProjectLineStatusToCompleted,
  upsertFlexTaskMilestoneProjectLineStatusToCompleted,
  expandProjectFlexTasks,
  checkProjectFlexTask,
  setExpandedProjectFlexTasks,
  setFlexTaskMilestonesTimestampingStatus,
  updateProjectManagementViewProjects,
  updateFlexTaskOrderLineStatus,
  setFlexTaskOrderLinesTimestampingStatus,
  upsertOrderlineDetailsList,
  upsertOrderlineDetailsListFromUpdateStatus,
  updateProjectFlexTaskStatusList,
  updateFlexTaskOrderLineContributor,
  updateFlexTaskMilestoneOrderLineContributor,
  updateFlexTaskCoHandler,
  updateProjectFlexTaskCollaboratorList,
  updateProjectContributorsFromTimestampResult,
  upsertProjectFlexTasksContributors,
  updateProjectLevelContributors,
  updateMilestoneDataUponRemoveTimestamp,
  updateE2EProjectsStatuses,
  expandProjectFlexTasksByMilestoneId,
  updateOrderlineECD,
  updateForceReloadProject,
  updateIsMismatchECD,
  updateMilestonesFromEditMilestoneDateResult,
  updateMilestoneRecentChange,
  updateRefinerLineResult,
  updateAllProjectLevels,
  updateTimeEntryModalSyncing,
  updateTaskOrderlineSyncingStatus,
  updateE2EProjectLineFlexTaskGroupItemsDates,
  updateE2EProjectLineFlexTasksComment,
  setExpandedRowsProjectList,
  toggleRowExpansionProjectList,
  refreshOrderlineRefiners,
  removeRefreshOrderlineRefiners,
  updateOrderLineListHandlers,
  updateOrderLineInvoice,
  updateGroupMilestoneStatus,
  updateMilestoneStatus,
  updateProjectECD,
  updateProjectManagementViewProjectsAdhocStatus,
  updateRefreshFlexTaskId,
} = projectManagementSlice.actions;

export const selectSelectAll = (state: ProjectManagementWrapper) =>
  state.projectManagement.selectAll;
export const selectShowFlexTasks = (state: ProjectManagementWrapper) =>
  state.projectManagement.showFlexTasks;
export const selectSelectedViewBy = (state: ProjectManagementWrapper) =>
  state.projectManagement.selectedView;

export const selectSelectFavoritesModel = (state: ProjectManagementWrapper) =>
  state.projectManagement.favoritesModel;

export const selectProjects = (state: ProjectManagementWrapper) =>
  state.projectManagement.projects;
export const selectPaginationModel = (state: ProjectManagementWrapper) =>
  state.projectManagement.paginationModel;

export const selectRequest = (state: ProjectManagementWrapper) =>
  state.projectManagement.request;

export const selectIsLoading = (state: ProjectManagementWrapper) =>
  state.projectManagement.isLoading;

export const selectSelectedMilestones = (state: ProjectManagementWrapper) => {
  const { refinerLineResult } = state.projectManagement;
  if (refinerLineResult.milestoneIds === null)
    return state.projectManagement.selectedMilestones;

  return state.projectManagement.selectedMilestones.filter((item) =>
    refinerLineResult.milestoneIds?.includes(item.id)
  );
};
export const selectUnfilteredSelectedMilestones = (
  state: ProjectManagementWrapper
) => state.projectManagement.selectedMilestones;

export const selectSelectedTaskOrderlines = (
  state: ProjectManagementWrapper
) => {
  const { refinerLineResult } = state.projectManagement;
  if (refinerLineResult.flexTaskIds === null)
    return state.projectManagement.selectedTaskOrderlines;

  return state.projectManagement.selectedTaskOrderlines.filter((item) =>
    refinerLineResult.flexTaskIds?.includes(item.e2EProjectLineFlexTaskId)
  );
};
export const selectUnfilteredSelectedTaskOrderlines = (
  state: ProjectManagementWrapper
) => state.projectManagement.selectedTaskOrderlines;

export const selectFlexTaskMilestonesList = (
  state: ProjectManagementWrapper
) => {
  const { refinerLineResult } = state.projectManagement;
  if (refinerLineResult.milestoneIds === null)
    return state.projectManagement.flexTaskMilestonesList;

  return state.projectManagement.flexTaskMilestonesList.map((project) => ({
    ...project,
    milestones: project.milestones.filter((milestone) =>
      refinerLineResult.milestoneIds?.includes(milestone.id)
    ),
  }));
};

export const selectRefinerLineResult = (state: ProjectManagementWrapper) =>
  state.projectManagement.refinerLineResult;

export const selectUnfilteredFlexTaskMilestonesList = (
  state: ProjectManagementWrapper
) => state.projectManagement.flexTaskMilestonesList;

export const selectFlexTaskOrderLinesList = (
  state: ProjectManagementWrapper
) => {
  const { refinerLineResult } = state.projectManagement;
  if (refinerLineResult.flexTaskIds === null)
    return state.projectManagement.flexTaskOrderLinesList;

  return state.projectManagement.flexTaskOrderLinesList.map((project) => ({
    ...project,
    orderLines: project.orderLines.filter((line) =>
      refinerLineResult.flexTaskIds?.includes(line.e2EProjectLineFlexTaskId)
    ),
  }));
};

export const selectUnfilteredFlexTaskOrderLinesList = (
  state: ProjectManagementWrapper
) => state.projectManagement.flexTaskOrderLinesList;

export const selectProjectFlexTasksList = (state: ProjectManagementWrapper) => {
  const { refinerLineResult } = state.projectManagement;
  if (
    refinerLineResult.flexTaskIds === null &&
    refinerLineResult.milestoneIds === null
  )
    return state.projectManagement.projectFlexTasksList;

  return state.projectManagement.projectFlexTasksList.map((project) => ({
    ...project,
    tasks: project.tasks.filter((line) => {
      if (refinerLineResult.flexTaskIds)
        return line.e2EProjectLineFlexTaskGroupItems.some((task) =>
          refinerLineResult.flexTaskIds?.includes(task.e2EProjectLineFlexTaskId)
        );
      if (refinerLineResult.milestoneIds)
        return line.e2EProjectLineMilestoneGroupItems.some((milestone) =>
          refinerLineResult.milestoneIds?.includes(
            milestone.e2EProjectLineMilestoneId
          )
        );
      return true;
    }),
  }));
};
export const selectUnfilteredProjectFlexTasksList = (
  state: ProjectManagementWrapper
) => state.projectManagement.projectFlexTasksList;

export const selectedProjectManagementViewProjects = (
  state: ProjectManagementWrapper
) => state.projectManagement.projectManagementViewProjects;

export const selectOrderlineDetailList = (state: ProjectManagementWrapper) => {
  const { refinerLineResult } = state.projectManagement;
  if (refinerLineResult.lineIds === null)
    return state.projectManagement.projectOrderlineDetailList;
  return state.projectManagement.projectOrderlineDetailList.map((project) => ({
    ...project,
    orderlineDetailList: project.orderlineDetailList.filter((line) =>
      refinerLineResult?.lineIds?.includes(line.id)
    ),
  }));
};

export const selectForceReloadProject = (state: ProjectManagementWrapper) =>
  state.projectManagement.forceReloadProject;

export const selectRecentMilestoneChanges = (state: ProjectManagementWrapper) =>
  state.projectManagement.recentMilestoneChanges;

export const selectIsTimeEntryModalSyncing = (
  state: ProjectManagementWrapper
) => state.projectManagement.isTimeEntryModalSyncing;

export const selectSelectRefreshProjectId = (state: ProjectManagementWrapper) =>
  state.projectManagement.refreshProjectId;

export const selectExpandedRowsProjectList = (
  state: ProjectManagementWrapper
) => state.projectManagement.expandedRows;

export const selectRefreshOrderlineRefiners = (
  state: ProjectManagementWrapper
) => state.projectManagement.refreshOrderlineRefiners;

export const selectRefreshFlexTaskId = (
  state :ProjectManagementWrapper
) => state.projectManagement.refreshFlexTaskId;

export default projectManagementSlice.reducer;
