import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { SetStateAction, useEffect, useState } from "react";
import { authProvider } from "../../../../../providers/authProvider";
import { AddRemarksModel } from "../../../../../shared/models/service-models/AddRemarks.model";
import { RemarksHistoryModel } from "../../../../../shared/models/service-models/RemarksHistoryModel";
import classes from "./RemarksHistoryDialog.module.scss";
import RemarksTypesEnum from "./RemarksTypeEnum";

type Props = {
  e2eProjectLineId: string;
  header: string;
  isOpen: boolean;
  onAdd: (reuestModel: any) => Promise<void>;
  isModalLoading: boolean;
  remarkType: RemarksTypesEnum;
  list: RemarksHistoryModel[];
  closeDialog: () => void;
  disableAddButtonWhenCommentIsEmpty?: boolean;
  isAddDisabled?: boolean;
};

export default function RemarksHistoryDialog({
  e2eProjectLineId,
  header,
  isOpen,
  onAdd,
  isModalLoading,
  remarkType,
  list,
  closeDialog,
  disableAddButtonWhenCommentIsEmpty = true,
  isAddDisabled = false,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [inputPlaceHolder, setInputPlaceHolder] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isMaxRemarks, setIsMaxRemarks] = useState(false);

  useEffect(() => {
    setIsLoading(isModalLoading);
  }, [isModalLoading]);

  useEffect(() => {
    setRemarks("");
    setInputPlaceHolder(`Add a ${header.toLowerCase()}`);
  }, [isOpen]);

  useEffect(() => {
    setIsMaxRemarks(remarks.length === 250);
  }, [remarks]);

  const onHide = () => {
    closeDialog();
  };

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CLOSE"
          onClick={onHide}
        />
      </div>
    </div>
  );

  const dialogStyle = {
    width: "64rem",
  };

  const userInfo = authProvider.getAccountInfo();

  const handleAdd = async () => {
    setIsAdding(true);
    const payload: AddRemarksModel = {
      displayName: userInfo?.account.name,
      userEmail: userInfo?.account.idToken.email,
      projectLineRemarksType: remarkType,
      e2EProjectLineId: e2eProjectLineId,
      value: remarks,
    };
    await onAdd(payload);

    setRemarks("");
    setIsAdding(false);
  };

  const getInitials = (name: string) => {
    if (name == "php.coesupport@ul.com" || name == "PHP COE Support")
      return "TC";

    return name
      ?.split(",")
      ?.map((value) => value.trim())
      ?.reverse()
      ?.map((value, index, array) =>
        index === 0 || index + 1 === array.length
          ? (value[0] ?? "").toUpperCase()
          : null
      )
      .join("");
  };

  const onChangeRemarks = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setRemarks(event.target.value);
  };
  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["dialog"]}
      header={header}
      footer={footer}
      visible={isOpen}
      style={dialogStyle}
      modal
      onHide={onHide}
      contentClassName={classes["content"]}
    >
      {isLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
        </div>
      )}
      {!isLoading && (
        <div className={classes["container"]}>
          <InputTextarea
            disabled={isLoading || isAdding || isAddDisabled}
            className={classes["remarks-text-area"]}
            value={remarks}
            onChange={onChangeRemarks}
            placeholder={inputPlaceHolder}
            maxLength={250}
          />
          <div className={classes["button-area"]}>
            <div>
              <small>
                {remarks.length} / 250
                {isMaxRemarks && (
                  <span className={classes["text-error"]}>
                    {" "}
                    You have reached the allowed maximum characters.
                  </span>
                )}
                {remarks && remarks.trim().length == 0 && (
                  <span className={classes["text-error"]}>
                    {" "}
                    Please type a message to continue.
                  </span>
                )}
              </small>
            </div>
            <Button
              loading={isAdding}
              className={`${classes["button-add"]}`}
              label="ADD"
              onClick={handleAdd}
              disabled={
                isLoading ||
                (disableAddButtonWhenCommentIsEmpty &&
                  (!remarks || remarks?.trim().length == 0)) ||
                isAdding ||
                isAddDisabled
              }
            />
          </div>
          <h3> {header} History</h3>
          <div className={classes["value-container-outer"]}>
            <div className={classes["value-container-wrapper"]}>
              {list.map((item) => {
                return (
                  <>
                    <div className={classes["value-container"]}>
                      <div className={classes["value-header"]}>
                        <Badge
                          value={getInitials(item.displayName)}
                          className={classes["badge"]}
                          size="large"
                        />
                        <p>
                          {item.user} - {item.dateTimeDisplay}
                        </p>
                      </div>
                      <div className={classes["value-content"]}>
                        {item.value}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}
