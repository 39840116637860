import clsx from "clsx";
import classes from "./ProjectMilestoneStatusTemplate.module.scss";
import ProjectStatus from "../../enums/project-status";
import PmrE2EProjectFlexTaskMilestonesGroup from "../../interfaces/pmr-e2e-project-milestone-group";
import ProjectStatusTemplate from "../project-status-template/ProjectStatusTemplate";
import { PmrE2EProjectFlexTaskMilestones } from "../../interfaces/pmr-e2e-project-milestone";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import {
  getMilestoneStatusList,
  IProjectMilestoneStatus,
} from "../../../../../utils/constants/milestone-status.constants";
import ProjectMilestoneStatus from "../../enums/project-milstone-status";
import { Tooltip } from "primereact/tooltip";
import ProjectService from "../../../../../services/ProjectService";
import useToastr from "../../../../../hooks/useToastr";
import { useDispatch } from "react-redux";
import {
  updateFlexTaskOrderLineStatus,
  updateGroupMilestoneStatus,
  updateMilestoneStatus,
  updateProjectFlexTaskStatusList,
} from "../../../../../features/projectManagement/projectManagementSlice";
import { ENABLED_MILESTONE_MANUAL_STATUS_UPDATE } from "../../../../../utils/constants/feature-flag.constants";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { Button } from "primereact/button";
import OnHoldReasonDialog from "../onhold-reason-dialog/OnHoldReasonDialog";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import { MilestoneStatusUpdateRequestModel } from "../../../../../shared/models/MilestoneStatusUpdateRequest.model";
import { updateRefreshInvoiceWarnings } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
interface ProjectMilestoneStatusTemplateProps {
  status: ProjectMilestoneStatus;
  milestoneData: PmrE2EProjectFlexTaskMilestonesGroup;
  index: number;
  isLoading: boolean;
  tooltip: string;
  e2EProjectId: string;
  e2EProjectLineMilestoneId: string;
  containerStyle?: React.CSSProperties | undefined;
  totalHours: number;
  orderLineDescription: string;
  milestoneName: string;
  isAdhoc: boolean;
  resetList(): void;
}
const ProjectMilestoneStatusTemplate = (
  props: ProjectMilestoneStatusTemplateProps
) => {
  const dispatch = useDispatch();
  const milestoneStatusList = getMilestoneStatusList();

  const [isEditStatus, setIsEditStatus] = useState(false);
  const statusOptionsRef = useRef<HTMLDivElement>(null);
  const [currentStatus, setCurrentStatus] = useState<
    IProjectMilestoneStatus | undefined
  >(milestoneStatusList.find((x) => x.code === props.status));
  const [activeOptions, setActiveOptions] =
    useState<IProjectMilestoneStatus[]>(milestoneStatusList);
  const [updatedStatus, setUpdatedStatus] = useState<IProjectMilestoneStatus>();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] =
    useState(false);

  const loadingIndicator = (
    <div className="text-center w-100">
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  const [showOnHoldReason, setShowOnHoldReason] = useState<boolean>(false);
  const [onHoldReason, setOnHoldReason] = useState<PmrE2EOnHoldReason>();

  const { showSuccess, showInfo } = useToastr();

  useEffect(() => {
    if (updatedStatus?.code !== undefined) handleStatusChange();
  }, [updatedStatus]);

  useEffect(() => {
    if (props.status !== undefined)
      setCurrentStatus(
        milestoneStatusList.find((x) => x.code === props.status)
      );
  }, [props.status]);

  useEffect(() => {
    if (currentStatus) handleStatusOptions();

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [currentStatus]);
  if (props.isLoading) return loadingIndicator;
  const filteredOptions = () => {
    return activeOptions;
  };
  const onStatusChange = async (e: any) => {
    setCurrentStatus(e.value);
    setUpdatedStatus(e.value);
  };
  const statusOptionTemplate = (option: IProjectMilestoneStatus) => {
    if (option.code == currentStatus?.code) {
      option.tooltipMessage = "This is the current status.";
    } else if (
      option.code === ProjectMilestoneStatus.Cancelled &&
      !option.disabled
    )
      option.tooltipMessage = "";

    const tooltipId = `status-tooltip-${option.code}`;
    const cancelledTooltip = "status-tooltip-3";
    const isDefaultCursor = tooltipId === cancelledTooltip;

    return (
      <div
        id={tooltipId}
        className={`${classes["line-item-div"]} status-${option.code} ${
          option.disabled ? classes.disabled : ""
        } ${isDefaultCursor ? classes.defaultCursor : ""}`}
        style={{ pointerEvents: "auto" }}
      >
        <span>{option.name}</span>
        <Tooltip target={`#${tooltipId}`} content={option.tooltipMessage} />
      </div>
    );
  };
  const handleStatusClick = (status: ProjectMilestoneStatus) => {
    if (!ENABLED_MILESTONE_MANUAL_STATUS_UPDATE) return;
    if (
      status === ProjectMilestoneStatus.Cancelled ||
      status === ProjectMilestoneStatus.Completed ||
      status === ProjectMilestoneStatus.AwaitingAssignment
    )
      return;

    setCurrentStatus(activeOptions.find((data) => data.code === status));
    setIsEditStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    if (
      // updatedStatus?.code !== ProjectMilestoneStatus.OnHold &&
      // updatedStatus?.code !== ProjectMilestoneStatus.Cancelled &&
      statusOptionsRef.current &&
      !statusOptionsRef.current.contains(event.target)
    ) {
      setIsEditStatus(false);
    }
  };
  const getClass = () => {
    if (props.isAdhoc) {
      switch (props.status) {
        case ProjectMilestoneStatus.NotScheduled:
          return classes["notscheduled"];
        case ProjectMilestoneStatus.InProgress:
          return classes["inprogress"];
        case ProjectMilestoneStatus.OnHold:
          return classes["onhold"];
        case ProjectMilestoneStatus.Cancelled:
          return classes["cancelled"];
        case ProjectMilestoneStatus.Completed:
          return classes["completed"];
        case ProjectMilestoneStatus.AwaitingAssignment:
          return classes["awaitingassignment"];
        case ProjectMilestoneStatus.NotStarted:
          return classes["notstarted"];
        default:
          return "disabled";
      }
    } else {
      switch (props.status) {
        case ProjectMilestoneStatus.NotStarted:
        case ProjectMilestoneStatus.AwaitingAssignment:
        case ProjectMilestoneStatus.NotScheduled:
          return classes["notscheduled"];
        case ProjectMilestoneStatus.InProgress:
          return classes["inprogress"];
        case ProjectMilestoneStatus.OnHold:
          return classes["onhold"];
        case ProjectMilestoneStatus.Cancelled:
          return classes["cancelled"];
        case ProjectMilestoneStatus.Completed:
          return classes["completed"];
        default:
          return "disabled";
      }
    }
  };

  const getDisplay = () => {
    if (props.isAdhoc) {
      switch (props.status) {
        case ProjectMilestoneStatus.NotScheduled:
          return "Not Scheduled";
        case ProjectMilestoneStatus.InProgress:
          return "In Progress";
        case ProjectMilestoneStatus.OnHold:
          return "On Hold";
        case ProjectMilestoneStatus.Cancelled:
          return "Cancelled";
        case ProjectMilestoneStatus.Completed:
          return "Completed";
        case ProjectMilestoneStatus.AwaitingAssignment:
          return "Awaiting Assignment";
        case ProjectMilestoneStatus.NotStarted:
          return "Not Started";
        default:
          return "Not defined";
      }
    } else {
      switch (props.status) {
        case ProjectMilestoneStatus.AwaitingAssignment:
        case ProjectMilestoneStatus.NotStarted:
        case ProjectMilestoneStatus.NotScheduled:
          return "Not Scheduled";
        case ProjectMilestoneStatus.InProgress:
          return "In Progress";
        case ProjectMilestoneStatus.OnHold:
          return "On Hold";
        case ProjectMilestoneStatus.Cancelled:
          return "Cancelled";
        case ProjectMilestoneStatus.Completed:
          return "Completed";
        default:
          return "Not defined";
      }
    }
  };

  const onClosCancelConfirmation = () => {
    setIsCancelConfirmationVisible(false);
    setIsEditStatus(false);
  };

  const handleConfirmCancellation = (isCancel: boolean) => {
    if (isCancel) handleUpdateStatus();

    setIsEditStatus(false);
    setIsCancelConfirmationVisible(false);
  };

  const handleStatusChange = async () => {
    if (updatedStatus?.code === ProjectMilestoneStatus.OnHold) {
      setShowOnHoldReason(true);
    } else if (updatedStatus?.code === ProjectMilestoneStatus.Cancelled) {
      //TEMP:Cancelled not yet fully implemented
      // reset();
      //TODO:
      setIsCancelConfirmationVisible(true);
    } else {
      await handleUpdateStatus();
    }
  };
  const handleStatusOptions = () => {
    switch (currentStatus?.code) {
      case ProjectMilestoneStatus.NotScheduled:
        setActiveOptions(() => {
          let newList = structuredClone(milestoneStatusList);
          const allowedInList = [
            ProjectMilestoneStatus.NotScheduled,
            ProjectMilestoneStatus.Cancelled,
          ];
          newList = newList.filter((status) =>
            allowedInList.includes(status.code)
          );
          newList.forEach((status) => {
            if (status.code == currentStatus?.code)
              status.tooltipMessage = "This is the current status.";

            if (status.code === ProjectMilestoneStatus.Cancelled)
              status.disabled = true;
          });
          return newList;
        });
        checkIsMilestoneHasTimeEntry();
        break;

      case ProjectMilestoneStatus.InProgress:
        setActiveOptions(() => {
          let newList = structuredClone(milestoneStatusList);
          const allowedInList = [
            ProjectMilestoneStatus.InProgress,
            ProjectMilestoneStatus.OnHold,
            ProjectMilestoneStatus.Cancelled,
          ];
          newList = newList.filter((status) =>
            allowedInList.includes(status.code)
          );
          newList.forEach((status) => {
            if (status.code === ProjectMilestoneStatus.Cancelled)
              status.disabled = true;

            if (status.code == currentStatus?.code)
              status.tooltipMessage = "This is the current status.";
          });
          return newList;
        });
        checkIsMilestoneHasTimeEntry();
        break;

      case ProjectMilestoneStatus.OnHold:
        setActiveOptions(() => {
          let newList = structuredClone(milestoneStatusList);
          const allowedInList = [
            ProjectMilestoneStatus.InProgress,
            ProjectMilestoneStatus.OnHold,
            ProjectMilestoneStatus.Cancelled,
          ];
          newList = newList.filter((status) =>
            allowedInList.includes(status.code)
          );
          newList.forEach((status) => {
            if (status.code == currentStatus?.code)
              status.tooltipMessage = "This is the current status.";

            if (status.code === ProjectMilestoneStatus.Cancelled)
              status.disabled = true;

            if (status.code == ProjectMilestoneStatus.InProgress)
              status.disabled = true;
          });
          return newList;
        });
        checkPredecessorIsCompleted();
        checkIsMilestoneHasTimeEntry();
        break;

      case ProjectMilestoneStatus.AwaitingAssignment:
        setActiveOptions([]);
        break;

      default:
        setActiveOptions(() => {
          let newList = structuredClone(milestoneStatusList);
          newList.forEach((status) => {
            status.tooltipMessage = ``;
            if (status.code == currentStatus?.code)
              status.tooltipMessage = "This is the current status.";
          });
          return newList;
        });
        break;
    }
  };
  const checkPredecessorIsCompleted = async () => {
    const result = await ProjectService.getIsMilestonePredecessorsCompleted(
      props.e2EProjectLineMilestoneId
    );
    if (result.data) {
      setActiveOptions((prev) => {
        let newList = structuredClone(prev);
        newList.forEach((status) => {
          if (status.code == ProjectMilestoneStatus.InProgress)
            status.disabled = false;
        });
        return newList;
      });
    }
  };

  const checkIsMilestoneHasTimeEntry = async () => {
    const result = await ProjectService.checkIsMilestoneHasTimeEntry(
      props.e2EProjectLineMilestoneId
    );
    const hasNoTimeEntry = !result.data;
    if (hasNoTimeEntry) {
      setActiveOptions((prev) => {
        let newList = structuredClone(prev);
        newList.forEach((status) => {
          if (status.code == ProjectMilestoneStatus.Cancelled)
            status.disabled = false;
        });
        return newList;
      });
    } else {
      setActiveOptions((prev) => {
        let newList = structuredClone(prev);
        newList.forEach((status) => {
          if (status.code == ProjectMilestoneStatus.Cancelled) {
            status.disabled = true;
            status.tooltipMessage =
              "Cannot cancel tasks with logged time entry.";
          }
        });
        return newList;
      });
    }
  };

  const handleToaster = (status: number, isTaskAlsoUpdated: boolean) => {
    if (status === ProjectStatus.Cancelled) {
      showInfo(
        "Order Line Milestone Cancelled",
        `<b>${props.milestoneName}</b> in order line <b>${props.orderLineDescription}</b> has been cancelled.`
      );
    } else if (status === ProjectStatus.OnHold) {
      const parentFlexTaskNameMessage = isTaskAlsoUpdated
        ? `<b>${props.milestoneData.flexTaskName}</b> has also been updated`
        : "";

      showInfo(
        "Milestone Updated to 'On Hold'",
        `<b>${props.milestoneName}</b> for order line <b>${props.orderLineDescription}</b> has been placed
         On Hold. Reason: <b>${onHoldReason?.taskStatusReason}</b>. ${parentFlexTaskNameMessage}.
        `
      );
    } else {
      showSuccess(
        "Success",
        `The Milestone status has been updated to '${updatedStatus?.name}' successfully.`
      );
    }
  };

  const handleUpdateStatus = async () => {
    setIsSaveLoading(true);
    const milestoneModel: MilestoneStatusUpdateRequestModel = {
      e2EProjectId: props.e2EProjectId,
      e2EProjectLineMilestoneId: props.e2EProjectLineMilestoneId,
      status: updatedStatus?.code,
    };

    if (updatedStatus?.code === ProjectStatus.OnHold) {
      milestoneModel.reasonId = onHoldReason?.taskStatusDetailId;
      milestoneModel.reasonDetail = onHoldReason?.taskStatusReason;
    }

    const result = await ProjectService.updateMilestoneStatus(milestoneModel);

    if (result.isSuccess) {
      const {
        flexUpdateProjectTaskStatuses = [],
        milestoneUpdateStatuses = [],
      } = result.data;

      handleToaster(
        updatedStatus!.code,
        flexUpdateProjectTaskStatuses.length > 0
      );

      milestoneUpdateStatuses.forEach((item: any) => {
        dispatch(
          updateMilestoneStatus({
            projectId: item.e2EProjectId,
            e2EProjectLineMilestoneId: item.e2EProjectLineMilestoneId,
            newStatus: item.status,
            onHoldReason: item?.onHoldReason,
            onHoldReasonId: item?.onHoldReasonId,
          })
        );

        dispatch(
          updateGroupMilestoneStatus({
            projectId: item.e2EProjectId,
            e2EProjectLineMilestoneId: item.e2EProjectLineMilestoneId,
            newStatus: item.status,
          })
        );
      });

      flexUpdateProjectTaskStatuses.forEach((item: any) => {
        dispatch(
          updateFlexTaskOrderLineStatus({
            projectId: item.e2EProjectId,
            e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
            newStatus: item.status,
            onHoldReason,
          })
        );

        dispatch(
          updateProjectFlexTaskStatusList({
            projectId: item.e2EProjectId,
            e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
            newStatus: item.status,
          })
        );
      });

      reset();
      setIsSaveLoading(false);

      if (updatedStatus?.code === ProjectStatus.OnHold) {
        setOnHoldReason(undefined);
        setShowOnHoldReason(false);
      }

      dispatch(updateRefreshInvoiceWarnings(true));
    }
  };
  const reset = () => {
    handleStatusOptions();
    setIsEditStatus(false);
    setCurrentStatus(undefined);
    setUpdatedStatus(undefined);
    props.resetList();
  };

  const cancelBodyTemplate = (
    <>
      Confirming will permanently remove this milestone from the order line's
      project lifecycle.
      <br /> <br />
      Proceed?
    </>
  );

  const cancelFooterTemplate = (
    <div className={classes["footer"]}>
      <Button
        onClick={() => handleConfirmCancellation(false)}
        className={classes["button-cancel"]}
      >
        CANCEL
      </Button>
      <Button
        onClick={() => handleConfirmCancellation(true)}
        className={classes["button-save"]}
      >
        CONFIRM
      </Button>
    </div>
  );

  const handleOnHoldDialogClose = () => {
    setShowOnHoldReason(false);
    setOnHoldReason(undefined);
    reset();
    showInfo(
      "Status change cancelled",
      "The Task remains in its previous state."
    );
  };
  return (
    <div style={props.containerStyle}>
      <ConfirmationModalV2
        className={classes["cancel-modal"]}
        style={{ width: "30vw" }}
        visible={isCancelConfirmationVisible}
        onClose={onClosCancelConfirmation}
        headerDisplay="Confirm Milestone Cancellation"
        confirmationMessageHTML={cancelBodyTemplate}
        customFooter={cancelFooterTemplate}
      ></ConfirmationModalV2>
      {isSaveLoading && (
        <div className={classes["indicator-container"]}>{loadingIndicator}</div>
      )}

      {!isSaveLoading &&
        (isEditStatus ? (
          <span ref={statusOptionsRef}>
            <Dropdown
              value={currentStatus}
              options={filteredOptions()}
              onChange={onStatusChange}
              optionLabel="name"
              className={classes["custom-dropdown"]}
              style={{ fontSize: "20px" }}
              optionDisabled="disabled"
              itemTemplate={statusOptionTemplate}
            />
          </span>
        ) : (
          <span
            title={props.tooltip}
            className={clsx(classes["custom-badge"], getClass())}
            onClick={() => handleStatusClick(props.status)}
          >
            {getDisplay()}
          </span>
        ))}

      <OnHoldReasonDialog
        visible={showOnHoldReason}
        onClose={handleOnHoldDialogClose}
        setSelectedOnHoldReason={setOnHoldReason}
        handleSaveOnHoldReason={handleUpdateStatus}
        isSaveLoading={isSaveLoading}
        selectedReason={onHoldReason}
      />
    </div>
  );
};
export default ProjectMilestoneStatusTemplate;
