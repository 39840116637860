import { useSelector } from "react-redux";
import { selectCurrencies } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  Dropdown,
  DropdownItemTemplateType,
  DropdownProps,
} from "primereact/dropdown";
import usePmrCurrency from "../../../../../hooks/usePmrCurrency";
import classes from "./PmrMultiSelectCurrencies.module.scss";
import clsx from "clsx";

export default function PmrMultiSelectCurrencies(props: DropdownProps) {
  const currencies = useSelector(selectCurrencies);

  const { loadCurrencies } = usePmrCurrency();
  const itemTemplate: DropdownItemTemplateType = (options) => {
    return (
      <div title={options.label} className={classes["text-overflow-ellipsis"]}>
        {options.label}
      </div>
    );
  };
  return (
    <Dropdown
      {...props}
      className={clsx(classes["dropdown-wrapper-currencies"], props.className)}
      panelClassName={classes["dropdown-panel-currencies"]}
      itemTemplate={itemTemplate}
      onShow={() => loadCurrencies()}
      options={currencies.map((currency) => ({
        label: [currency?.code, currency?.country]
          .filter((item) => !!item)
          .join(" - "),
        value: currency.id,
      }))}
      placeholder="-- Select Currency --"
      onBlur={() => {}} // removes formik warning
      filter
    />
  );
}
