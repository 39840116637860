import { faCalendarAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import {
  Calendar,
  CalendarChangeParams,
  CalendarDateTemplateParams,
} from "primereact/calendar";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateProjectECD } from "../../../../../features/projectManagement/projectManagementSlice";
import useToastr from "../../../../../hooks/useToastr";
import ProjectService from "../../../../../services/ProjectService";
import { enumerateDaysBetweenDates } from "../../../../../shared/date-helper/DateHelper";
import classes from "./FLEXProjectECDTemplate.module.scss";
import { classNames } from "primereact/utils";

type Props = {
  e2eProjectId: string;
  orderLineDescription: string;
  currentECD: Date | null;
  newestOrderlineECD: Date | null;
  e2eProjectLineId: string;
  isEditable: boolean;
  reloadList: () => void;
};

const FLEXProjectECDTemplate = (props: Props) => {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useToastr();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [adjustedECD, setAdjustedECD] = useState<Date | null>(null);
  const calendarStartDate = new Date(2020, 0, 1);
  const calendarEndDate = new Date(2030, 11, 31);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setAdjustedECD(null);
  }, [props.currentECD]);

  const datesEarlierThanNewestOrderlineECD = useMemo(() => {
    if (!props.newestOrderlineECD) return [];

    const datesResult = enumerateDaysBetweenDates(
      calendarStartDate,
      props.newestOrderlineECD
    );

    return datesResult;
  }, [props.newestOrderlineECD]);

  const dateTemplate = (e: CalendarDateTemplateParams) => {
    const isEarlierNewestOrderlineECD =
      props.newestOrderlineECD !== null &&
      new Date(e.year, e.month, e.day) <
        new Date(
          props.newestOrderlineECD.getFullYear(),
          props.newestOrderlineECD.getMonth(),
          props.newestOrderlineECD.getDate()
        );

    const title = isEarlierNewestOrderlineECD
      ? "FLEX Project ECDs cannot be earlier than the Order Line ECD"
      : "";

    return (
      <span
        className={clsx(isEarlierNewestOrderlineECD && classes["not-allowed"])}
        title={title}
      >
        {e.day}
      </span>
    );
  };

  const handleECDClick = () => {
    if (!props.isEditable) return;

    setAdjustedECD(props.currentECD);
    setIsEditMode(true);

    setTimeout(() => {
      setIsCalendarVisible(true);
    });
  };

  const handleECDChangeError = () => {
    showError(
      "Update Error",
      `An error occurred while updating the FLEX Project ECD for order line <b>'${props.orderLineDescription}'</b>. Please try again.`
    );
    setAdjustedECD(props.currentECD);
  };

  const handleECDChange = async (e: CalendarChangeParams) => {
    setAdjustedECD(e.value as Date);
    setIsCalendarVisible(false);
    setIsSaving(true);

    try {
      const result = await ProjectService.updateProjectECD({
        e2EProjectLineId: props.e2eProjectLineId,
        newECD: e.value ? moment(e.value as Date).format("YYYY-MM-DD") : null,
      });

      if (!result.isSuccess) {
        return handleECDChangeError();
      }

      showSuccess(
        "FLEX Project ECD Updated",
        `The FLEX Project ECD has been successfully updated for order line <b>'${props.orderLineDescription}'</b>.`
      );

      dispatch(
        updateProjectECD({
          projectId: props.e2eProjectId,
          newECD: e.value,
        })
      );
      setIsEditMode(false);
      props.reloadList();
    } catch (error) {
      handleECDChangeError();
    } finally {
      setIsSaving(false);
    }
  };

  const disabledDates = [
    props.currentECD,
    ...datesEarlierThanNewestOrderlineECD,
  ].filter((d) => !!d) as Date[];

  const ecd = adjustedECD || props.currentECD;

  if (isSaving) {
    return <FontAwesomeIcon icon={faSpinner} spin={true} />;
  }

  return (
    <>
      {isEditMode && (
        <Calendar
          dateTemplate={dateTemplate}
          dateFormat="dd M yy"
          disabledDays={[0, 6]}
          disabledDates={disabledDates}
          visible={isCalendarVisible}
          onVisibleChange={(e) => {
            if (e.visible) setIsCalendarVisible(true);
            if (e.type === "outside") setIsCalendarVisible(false);
          }}
          value={adjustedECD!}
          onChange={handleECDChange}
          monthNavigator={true}
          yearNavigator={true}
          yearRange={`${calendarStartDate.getFullYear()}:${calendarEndDate.getFullYear()}`}
          panelClassName={classes["calendar-panel"]}
          onHide={() => setIsEditMode(false)}
        />
      )}

      {!isEditMode && (
        <div
          title={
            !props.isEditable
              ? "ECD of a completed FLEX Project cannot be updated/edited"
              : undefined
          }
          className={classNames(
            classes["calendar-container"],
            !props.isEditable && classes["not-allowed"]
          )}
          onClick={handleECDClick}
        >
          <FontAwesomeIcon
            className={classes["calendar-icon"]}
            icon={faCalendarAlt}
            title={
              props.isEditable
                ? "Click to set an ECD for this FLEX Project."
                : undefined
            }
          />

          {ecd && moment(ecd).format("DD MMM yyyy")}
        </div>
      )}
    </>
  );
};

export default FLEXProjectECDTemplate;
