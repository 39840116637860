import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./ProjectCollaboratorBulkAssignmentDialog.module.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import ProjectCollaboratorBulkAssignmentDropdown from "./project-collaborator-assignment-dropdown/ProjectCollaboratoBulkAssignmentDropdown";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EProjectTaskHandler from "../../interfaces/pmr-e2e-project-task-handlers";
import HandlerType from "../../enums/handler-type";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  updateFlexTaskOrderLineContributor,
  updateFlexTaskMilestoneOrderLineContributor,
  upsertProjectFlexTasksContributors,
  updateProjectLevelContributors,
} from "../../../../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../../interfaces/pmr-e2e-project-task-model";
import ProjectCollaboratorBulkAssignmentDetails from "../../interfaces/pmr-e2e-project-contributor-bulk-assignment-model";

interface ProjectCollaboratorBulkAssignmentDialogProps {
  onClose: () => void;
  show: boolean;
  projectCollaboratorBulkAssignmentDetails: ProjectCollaboratorBulkAssignmentDetails[];
}

interface ProjectCollaboratorsDetails {
  e2eProjectId?: string;
  e2EProjectLineFlexTaskId?: string;
  e2EProjectLineMilestoneId?: string;
  taskHandlerDetails?: E2EUserPaginatedModel;
  milestoneHandlerDetails?: E2EUserPaginatedModel;
}

export interface ProjectUserSource {
  currentSkip: number;
  currentTake: number;
  currentSearchPhrase: string;
  data: E2EUserPaginatedModel[];
}

interface DropdownLoading {
  taskHandlerDropdownLoading: boolean;
  milestoneHandlerDropdownLoading: boolean;
}

const ProjectCollaboratorBulkAssignmentDialog = (
  props: ProjectCollaboratorBulkAssignmentDialogProps
) => {
  const initDropdownValues: ProjectUserSource = {
    currentSkip: 0,
    currentTake: 5,
    currentSearchPhrase: "",
    data: [],
  };
  const showFlexTasks = useSelector(selectShowFlexTasks);
  const { showSuccess, showError } = useToastr();
  const dispatch = useDispatch();

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const [taskHandlerDropdownOptions, setTaskHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [milestoneHandlerDropdownOptions, setMilestoneHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);

  const [dropdownLoading, setDropdownLoading] = useState<DropdownLoading>({
    taskHandlerDropdownLoading: showFlexTasks.isOn,
    milestoneHandlerDropdownLoading: !showFlexTasks.isOn,
  });

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const [taskHandler, setTaskHandler] = useState<E2EUserPaginatedModel>();
  const [milestoneHandler, setMilestoneHandler] =
    useState<E2EUserPaginatedModel>();

  useEffect(() => {
    if (props.show) {
      setIsModalLoading(true);
      if (showFlexTasks.isOn) {
        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          HandlerType.TaskHandler,
          null,
          ""
        ).then((response) => {
          let taskHandlerData: E2EUserPaginatedModel[] = structuredClone(
            response?.data
          );

          setTaskHandlerDropdownOptions((current) => ({
            ...current,
            data: taskHandlerData,
          }));

          setDropdownLoading((current) => ({
            ...current,
            taskHandlerDropdownLoading: false,
          }));
        });
      }

      if (!showFlexTasks.isOn) {
        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          HandlerType.MilestoneHandler,
          null,
          ""
        ).then((response) => {
          let milestoneHandler: E2EUserPaginatedModel[] = structuredClone(
            response?.data
          );

          setMilestoneHandlerDropdownOptions((current) => ({
            ...current,
            data: milestoneHandler,
          }));

          setDropdownLoading((current) => ({
            ...current,
            milestoneHandlerDropdownLoading: false,
          }));
        });
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (
      !dropdownLoading.taskHandlerDropdownLoading &&
      !dropdownLoading.milestoneHandlerDropdownLoading
    ) {
      setIsModalLoading(false);
    }
  }, [dropdownLoading]);

  const dialogStyle = {
    width: "25rem",
    height: showFlexTasks.isOn ? "27rem" : "32rem",
  };

  const handleUpdateFlexTaskLevelContributors = async (
    isMilestoneListLoaded: boolean,
    item: ProjectCollaboratorBulkAssignmentDetails
  ) => {
    const response = await ProjectService.getProjectTasksByProject({
      projectId: item.e2EProjectId,
      isMilestoneListLoaded: isMilestoneListLoaded,
    });

    if (response.isSuccess) {
      let records = response?.data?.e2EProjectLineFlexTasks?.map(
        (task: PmrE2EProjectTaskModel, index: number) => {
          task.workBreakdownStructure = index + 1;
          task.isChecked = false;
          return task;
        }
      );

      dispatch(
        upsertProjectFlexTasksContributors({
          e2eProjectId: item.e2EProjectId,
          tasks: records,
        } as PmrE2EProjectTaskModelList)
      );
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);

    let payload: ProjectCollaboratorsDetails[] = [];

    for (let item of props.projectCollaboratorBulkAssignmentDetails) {
      const payloadDetail = {
        e2eProjectId: item.e2EProjectId,
        e2EProjectLineFlexTaskId: item?.e2EProjectLineFlexTaskId,
        e2EProjectLineMilestoneId: item?.e2EProjectLineMilestoneId,
        taskHandlerDetails: taskHandler,
        milestoneHandlerDetails: milestoneHandler,
      };
      payload.push(payloadDetail);
    }
    await GMAWBUsersService.saveContributorsBulk({
      items: payload,
    })
      .then((updateResult) => {
        showSuccess("Success", updateResult.message);

        props.projectCollaboratorBulkAssignmentDetails.forEach((item) => {
          if (item.e2EProjectLineMilestoneId) {
            dispatch(
              updateFlexTaskMilestoneOrderLineContributor({
                projectId: item.e2EProjectId,
                workBreakdownStructure: item.workBreakdownStructure,
                e2EProjectLineMilestoneId: item.e2EProjectLineMilestoneId,
                e2EProjectLineId: item.e2EProjectLineId,
                taskHandler,
                milestoneHandler,
              })
            );
          } else {
            dispatch(
              updateFlexTaskOrderLineContributor({
                projectId: item.e2EProjectId,
                workBreakdownStructure: item.workBreakdownStructure,
                e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
                taskHandler,
                e2EProjectLineId: item.e2EProjectLineId,
              })
            );
          }

          handleUpdateFlexTaskLevelContributors(false, item);
        });
      })
      .catch((error) => {
        showError(
          "Error",
          "An error occurred while updating Contributors. Please try again"
        );
      })
      .finally(() => {
        setIsSaveLoading(false);
        props.onClose();
      });
  };

  const header = (
    <div>
      <span>Assign Contributors</span>
    </div>
  );
  const isSaveDisabled = () => {
    return (
      isModalLoading || (showFlexTasks.isOn ? !taskHandler : !milestoneHandler)
    );
  };

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={props.onClose}
          disabled={isModalLoading}
        />
      </div>
      <div className={`${classes["button-save-wrapper"]}`}>
        <Button
          className={`${classes["button-save"]}`}
          label="SAVE"
          onClick={handleSave}
          disabled={isSaveDisabled()}
          loading={isSaveLoading}
        />
      </div>
    </div>
  );

  const onHide = () => {
    setTaskHandlerDropdownOptions(initDropdownValues);
    setMilestoneHandlerDropdownOptions(initDropdownValues);
    setTaskHandler(undefined);
    setMilestoneHandler(undefined);
    setIsModalLoading(false);
    setIsSaveLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["assign-collaborator-dialog"]}
      header={header}
      footer={footer}
      visible={props.show}
      style={dialogStyle}
      modal
      onHide={onHide}
      contentClassName={classes["content"]}
    >
      {isModalLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading Contributors.</div>
        </div>
      )}
      {!isModalLoading && [
        showFlexTasks.isOn && (
          <div className={classes["dropdown"]}>
            <Tooltip
              position="right"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target=".task-handler-modal-tooltip"
            >
              <div className={classes["tooltip-content"]}>
                The assigned Task Handler is specific to this FLEX Task and will
                be reflected in the FLEX system under "Assigned to" for their
                FLEX Project.
              </div>
            </Tooltip>
            <span>
              Task Handler
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`${classes["icon"]} task-handler-modal-tooltip`}
              />
            </span>
            <ProjectCollaboratorBulkAssignmentDropdown
              value={taskHandler}
              dropdownOptions={taskHandlerDropdownOptions}
              setValue={setTaskHandler}
              setDropdownOptions={setTaskHandlerDropdownOptions}
              handlerType={HandlerType.TaskHandler}
              isDisabled={false}
            />
          </div>
        ),
        !showFlexTasks.isOn && (
          <div className={classes["dropdown"]}>
            <Tooltip
              position="right"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target=".milestone-handler-modal-tooltip"
            >
              <div className={classes["tooltip-content"]}>
                Assigning the Milestone Handler applies specifically to this
                GMAWB Milestone within the order line.
              </div>
            </Tooltip>

            <span>
              Milestone Handler
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`${classes["icon"]} milestone-handler-modal-tooltip`}
              />
            </span>
            <ProjectCollaboratorBulkAssignmentDropdown
              value={milestoneHandler}
              dropdownOptions={milestoneHandlerDropdownOptions}
              setValue={setMilestoneHandler}
              setDropdownOptions={setMilestoneHandlerDropdownOptions}
              handlerType={HandlerType.MilestoneHandler}
              isDisabled={false}
            />
          </div>
        ),
      ]}
    </Dialog>
  );
};

export default ProjectCollaboratorBulkAssignmentDialog;
