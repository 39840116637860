import {
  faInfoCircle,
  faTable,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import {
  DropdownChangeParams,
  DropdownItemTemplateType,
} from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshBulkRow,
  patchProjectLine,
  selectApplicant,
  selectE2EProjectId,
  selectE2EProjectList,
  selectE2EProjectName,
  selectProductOwner,
  selectProjectLines,
  selectProjectWorkQueue,
  updateE2EProjectId,
  updateE2EProjectName,
  updateProjectLines,
  resetInitialProjectLines,
  selectInitialE2EProjectLines,
  selectOriginalProjectName,
} from "../../../features/projectWorkQueue/projectWorkQueueSlice";
import { useE2EProjectNameValidator } from "../../../hooks/useE2EProjectName";
import usePrimeReactDataTable from "../../../hooks/usePrimeReactDataTable";
import useToastr from "../../../hooks/useToastr";
import ProjectService from "../../../services/ProjectService";
import ReferenceService from "../../../services/ReferenceService";
import Dropdown from "../../../shared/components/dropdown/Dropdown";
import ConfirmationModal from "../../../shared/confirmation-modal/ConfirmationModal";
import ModelSearch from "../../../shared/model-search/ModelSearch";
import {
  E2EProjectLineModel,
  E2EProjectLineTechnologyModel,
  E2EProjectModel,
} from "../../../shared/models/E2EProject.model";
import { E2EProjectModelVariantModel } from "../../../shared/models/E2EProjectModelVariant.model";
import { IPModel } from "../../../shared/models/IPModel.model";
import ShowHideColumnsPrimeDataTable from "../../../shared/show-hide-columns-prime-datatable/ShowHideColumnsPrimeDataTable";
import { pendingProjectCreatedSuccessMessageKey } from "../../project-management/ProjectManagement";
import {
  GridColumnModel,
  projectWorkQueueGridCDPColumns,
} from "../../project-management/data/project-work-queue-cdp-grid-columns";
import CDPCloseModal from "../cdp-close-modal/CDPCloseModal";
import CDPDeleteModal from "../cdp-delete-modal/CDPDeleteModal";
import classes from "./ProjectWorkQueueModal.module.scss";
import CompareDeliveryPathModal from "./compare-delivery-path-modal/CompareDeliveryPathModal";
import { uniq } from "lodash";
import { Tooltip } from "primereact/tooltip";
import ProjectOrderlineStatus from "../../project-management-pmr/shared/enums/project-orderline-status";
import ProjectStatus from "../../project-management-pmr/shared/enums/project-status";
import ECDAdjustmentsConfirmationDialog from "../../project-management-pmr/shared/components/ecd-adjustments-confirmation-dialog/ECDAdjustmentsConfirmationDialog";
import { E2EProjectLineECDAdjustmentModel } from "../../../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import { ENABLED_DM_INTEGRATION } from "../../../utils/constants/feature-flag.constants";

export interface ProjectWorkQueueModalProps {
  certificateSchemeList: any;
  visible: boolean;
  closeModal(isFromCancel: boolean): void;
  handleAddOrderLine?: any;
  isExistingGMAWBProject: boolean;
  setIsCdpSaved?: any;
  isEditGMAWBProject?: boolean;
}

export interface ProjectWorkQueueSavingStatus {
  isSaving: boolean;
  isRedirectToProjectsPage?: boolean;
}

export const DmIntegrationTemplate = (notificationMessage: string) => (
  <>
    <p className="ul-alert__message">{notificationMessage}</p>
    <i>
      <b>Note:</b> DM integrations are currently processing. Icons will be
      enabled shortly.
    </i>
  </>
);

const ProjectWorkQueueModal = (props: ProjectWorkQueueModalProps) => {
  const clv = useRef<any>(null);
  const dispatch = useDispatch();
  const projectName = useSelector(selectE2EProjectName);
  const selectedExistingProjectId = useSelector(selectE2EProjectId);
  const {
    errorMessage: projectNameErrorMessage,
    isValid: isProjectNameValid,
    isValidating: isProjectNameValidating,
  } = useE2EProjectNameValidator(projectName, props?.isEditGMAWBProject);
  const e2eProject = useSelector(selectProjectWorkQueue);
  const e2eProjectLines = useSelector(selectProjectLines);
  const applicantInfo = useSelector(selectApplicant);
  const productOwnerInfo = useSelector(selectProductOwner);
  const [technologyList, setTechnologyList] = useState<any>(null);
  const [selectedProjectServiceLineIds, setSelectedProjectServiceLineIds] =
    useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [savingStatus, setSavingStatus] =
    useState<ProjectWorkQueueSavingStatus>({
      isSaving: false,
      isRedirectToProjectsPage: false,
    });
  const [isSaveDisable, setIsSaveDisable] = useState(false);
  const { showSuccess, showError } = useToastr();
  const [columns, setColumns] = useState(projectWorkQueueGridCDPColumns);
  const [pwqFrozenCount, setpwqFrozenCount] = useState(8);
  const [selectedProjectLine, setSelectedProjectLine] =
    useState<E2EProjectLineModel>();
  const [modelSearchDialogVisible, setModelSearchDialogVisible] =
    useState(false);
  const [
    selectedModelSelectionProjectLineIds,
    setSelectedModelSelectionProjectLineIds,
  ] = useState<string[]>([]);
  const [
    clearAllModelsConfirmationModalVisible,
    setClearAllModelsConfirmationModalVisible,
  ] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCdpCloseModal, setShowCdpCloseModal] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<E2EProjectLineModel[]>(
    []
  );
  const [projectLinesToDelete, setProjectLinesToDelete] = useState<
    E2EProjectLineModel[]
  >([]);
  const [isBulkIconDisabled, setIsBulkIconDisabled] = useState<boolean>(true);
  const [isInformationConfirmed, setIsInformationConfirmed] = useState(false);
  const [hasUserTriedToSave, setHasUserTriedToSave] = useState(false);
  const projectList = useSelector(selectE2EProjectList);
  const [isMapTechnology, setIsMapTechnology] = useState(false);
  const [ecdAdjustmentsDialogVisible, setECDAdjustmentsDialogVisible] =
    useState(false);
  const [e2eProjectLineECDAdjustments, setE2EProjectLineECDAdjustments] =
    useState<E2EProjectLineECDAdjustmentModel[]>([]);
  const [currentProjectLineIds, setCurrentProjectLineIds] = useState<string[]>(
    []
  );
  const initialProjectLines = useSelector(selectInitialE2EProjectLines);
  const originalProjectName = useSelector(selectOriginalProjectName);
  const [isCdpDataChanged, setIsCdpDataChanged] = useState(false);
  const [isProjectNameChanged, setIsProjectNameChanged] = useState(false);

  usePrimeReactDataTable(clv, columns);

  useEffect(() => {
    loadTechnologies();
  }, []);

  useEffect(() => {
    if (!props.visible) {
      setSelectedProjectServiceLineIds([]);
      setIsInformationConfirmed(false);
      setHasUserTriedToSave(false);
    } else {
      setCurrentData();
    }
  }, [props.visible]);

  useEffect(() => {
    if (isMapTechnology) {
      setCurrentTechnologies();
    }
  }, [isMapTechnology]);

  useEffect(() => {
    checkIfCdpDataIsChanged();
  }, [e2eProjectLines]);

  const checkIfCdpDataIsChanged = () => {
    if (e2eProjectLines) {
      let areCdpValuesSame = false;
      const isProjectNameSame = projectName === originalProjectName;
      setIsProjectNameChanged(!isProjectNameSame);

      for (let index = 0; index < e2eProjectLines?.length; index++) {
        const line = e2eProjectLines[index];
        const initialLineValue = initialProjectLines?.find(
          (initialLine) => initialLine.id == line.id
        );

        if (line && initialLineValue) {
          const initialTechnologies =
            initialLineValue?.e2EProjectLineTechnologies?.map(
              (tech) => tech.technologyId
            );
          areCdpValuesSame =
            line.schemeId === initialLineValue.schemeId &&
            line.applicationTypeId === initialLineValue.applicationTypeId &&
            line.additionalInfoId === initialLineValue.additionalInfoId &&
            line.deliveryPathId === initialLineValue.deliveryPathId &&
            JSON.stringify(line.technologies) ===
              JSON.stringify(initialTechnologies) &&
            JSON.stringify(line.baseModel) ===
              JSON.stringify(initialLineValue.baseModel) &&
            JSON.stringify(line.modelVariants) ===
              JSON.stringify(initialLineValue.modelVariants);

          if (!areCdpValuesSame) {
            setIsCdpDataChanged(!areCdpValuesSame);
            break;
          }
        }
      }

      if (areCdpValuesSame) setIsCdpDataChanged(false);
    }
  };

  const setCurrentData = async () => {
    if (props?.isEditGMAWBProject && e2eProjectLines) {
      e2eProjectLines.forEach(async (line) => {
        let parameter = { ...line };
        await mapSchemes(
          line,
          parameter,
          "schemeId",
          line.schemeId,
          line.schemeId !== "" || line.schemeId !== null
        );
      });
    }
  };

  const setCurrentTechnologies = () => {
    if (props?.isEditGMAWBProject && e2eProjectLines) {
      e2eProjectLines.map((line) => {
        const currentTechnologies = line?.e2EProjectLineTechnologies?.map(
          (tech) => tech.technologyId
        );

        const patch = {
          flexProjectLineId: line.flexProjectLineId,
          technologies: line.segment == "Wireless" ? currentTechnologies : [],
        };

        dispatch(patchProjectLine(patch));

        return {
          ...line,
          ...patch,
        };
      });
    }
    setIsMapTechnology(false);
  };

  const loadTechnologies = async () => {
    await ReferenceService.getTechnologyList()
      .then((response) => {
        setTechnologyList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectionChange = (e: any) => {
    if (e.type === "all") setIsBulkIconDisabled(true);
    else setIsBulkIconDisabled(false);

    setSelectedRecords(e.value as E2EProjectLineModel[]);

    if (e.value.length > 0) {
      const index = e.value.length - 1;
      const rowData = e.value[index] as E2EProjectLineModel;

      applyAllDefaultValues(rowData, e2eProjectLines ?? []);
    } else {
      if (e2eProjectLines!.length > 1) {
        const rowData = e2eProjectLines![1];

        applyAllDefaultValues(rowData, e2eProjectLines ?? []);
      }
    }

    setSelectedProjectServiceLineIds(
      e.value?.map((x: E2EProjectLineModel) => x.flexProjectLineId) || []
    );
  };

  const applyAllDefaultValues = (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[]
  ) => {
    lines.map((m: E2EProjectLineModel) => {
      if (isBulkRow(m) && isBulkRow(data)) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          schemeId: data.schemeId,
          schemeName: data.schemeName,
          applicationTypeId: data.applicationTypeId,
          applicationTypeList: data.applicationTypeList,
          deliveryPathId: data.deliveryPathId,
          deliveryPathList: data.deliveryPathList,
          additionalInfoId: data.additionalInfoId,
          additionalInfoList: data.additionalInfoList,
          technologies: data.technologies,
          e2eTemplateList: data.e2eTemplateList,
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });
  };

  const isBulkRow = (row: E2EProjectLineModel): boolean => {
    return row.flexProjectLineId === "0";
  };

  const isLineSelected = (row: E2EProjectLineModel) =>
    selectedProjectServiceLineIds.find((f) => f === row.flexProjectLineId) ||
    selectedRows!.length === 0;

  const shouldBulkRowUpdateLine = (
    destination: E2EProjectLineModel,
    isNewValueNotEmpty: boolean
  ): boolean => {
    const isChecked =
      selectedProjectServiceLineIds.some(
        (f) => f === destination.flexProjectLineId
      ) || selectedRows!.length === 0;

    return (
      (isChecked && isNewValueNotEmpty) || destination.flexProjectLineId === "0"
    );
  };

  const handlerDropDownChange = async (
    e: DropdownChangeParams,
    field: string,
    data: E2EProjectLineModel
  ) => {
    setIsSaveDisable(true);
    const isNewValueNotEmpty = !!e.value;

    if (data.flexProjectLineId !== "0") {
      setSelectedProjectServiceLineIds([data.flexProjectLineId]);
    }

    e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (m.flexProjectLineId === data.flexProjectLineId) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: e.value,
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    let parameter = { ...data };

    if (field === "schemeId") {
      await mapSchemes(data, parameter, field, e.value, isNewValueNotEmpty);
    } else if (field === "applicationTypeId") {
      const applicationTypeId = e.value;
      parameter.applicationTypeId = applicationTypeId;

      const list = mapApplicationType(data, applicationTypeId, field);

      await getDeliveryPathList(parameter, list ?? [], isNewValueNotEmpty);
    } else if (field === "deliveryPathId") {
      const deliveryPathId = e.value;
      parameter.deliveryPathId = deliveryPathId;

      const list = mapDeliveryPath(data, deliveryPathId, field);

      await getAdditionalInfoList(parameter, list ?? [], isNewValueNotEmpty);
    } else if (field === "additionalInfoId") {
      const additionalInfoId = e.value;
      parameter.additionalInfoId = additionalInfoId;

      const list = mapAdditionalInfo(data, additionalInfoId, field);

      await getLeadTime(parameter, list ?? []);
    } else if (field === "technologies") {
      mapTechnologies(data, field, e.value);
    } else {
      setIsSaveDisable(false);
    }
  };

  const mapSchemes = async (
    data: E2EProjectLineModel,
    parameter: E2EProjectLineModel,
    field: string,
    value: any,
    isNewValueNotEmpty: boolean = true
  ) => {
    setIsSaveDisable(true);
    const schemeId = value;
    const schemeName = data.certificateSchemeList?.find(
      (scheme) => scheme.id == schemeId
    )?.value;
    parameter.schemeId = schemeId;
    parameter.schemeName = schemeName;

    const list = e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        const schemeAvailable = m.certificateSchemeList?.find(
          (a) => a.id === schemeId
        );

        if (schemeAvailable) {
          const newSegment =
            m.flexProjectLineId === "0"
              ? "Wireless"
              : m.certificateSchemeList?.find((a: any) => a.id === schemeId)
                  ?.segment;
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: schemeId,
            schemeName: schemeName,
            segment: newSegment,
            applicationTypeId:
              props?.isEditGMAWBProject && schemeId
                ? m.applicationTypeId
                : null,
            deliveryPathId:
              props?.isEditGMAWBProject && schemeId ? m.deliveryPathId : null,
            additionalInfoId:
              props?.isEditGMAWBProject && schemeId ? m.additionalInfoId : null,
            e2EProjectTemplateId:
              props?.isEditGMAWBProject && schemeId
                ? m.e2EProjectTemplateId
                : null,
            leadTime: props?.isEditGMAWBProject && schemeId ? m.leadTime : 0,
            technologies:
              newSegment == "Wireless" && schemeId ? m.technologies : [],
          };

          dispatch(patchProjectLine(patch));

          return {
            ...m,
            ...patch,
          };
        }

        if (isBulkRow(m)) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            leadTime: props?.isEditGMAWBProject && schemeId ? m.leadTime : 0,
            [field]: schemeId,
            schemeName: schemeName,
            technologies:
              m.segment == "Wireless" && schemeId ? m.technologies : [],
          };

          dispatch(patchProjectLine(patch));

          return {
            ...m,
            ...patch,
          };
        }

        return { ...m };
      } else if (m.flexProjectLineId === data.flexProjectLineId) {
        const newSegment = m.certificateSchemeList?.find(
          (a: any) => a.id === schemeId
        )?.segment;
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: schemeId,
          schemeName: schemeName,
          segment: newSegment,
          applicationTypeId:
            props?.isEditGMAWBProject && schemeId ? m.applicationTypeId : null,
          deliveryPathId:
            props?.isEditGMAWBProject && schemeId ? m.deliveryPathId : null,
          additionalInfoId:
            props?.isEditGMAWBProject && schemeId ? m.additionalInfoId : null,
          e2EProjectTemplateId:
            props?.isEditGMAWBProject && schemeId
              ? m.e2EProjectTemplateId
              : null,
          leadTime: props?.isEditGMAWBProject && schemeId ? m.leadTime : 0,
          technologies:
            newSegment == "Wireless" && schemeId ? m.technologies : [],
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    await getApplicationTypeList(parameter, list ?? [], isNewValueNotEmpty);
  };

  const mapApplicationType = (
    data: E2EProjectLineModel,
    applicationTypeId: any,
    field: string
  ) => {
    const list = e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        const applicationTypeAvailable = m.applicationTypeList?.find(
          (a) => a.id === applicationTypeId
        );

        if (applicationTypeAvailable) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: applicationTypeId,
            deliveryPathId:
              props?.isEditGMAWBProject && applicationTypeId
                ? m.deliveryPathId
                : null,
            additionalInfoId:
              props?.isEditGMAWBProject && applicationTypeId
                ? m.additionalInfoId
                : null,
            e2EProjectTemplateId:
              props?.isEditGMAWBProject && applicationTypeId
                ? m.e2EProjectTemplateId
                : null,
            leadTime:
              props?.isEditGMAWBProject && applicationTypeId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return {
            ...m,
            ...patch,
          };
        }

        if (isBulkRow(m)) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: applicationTypeId,
            leadTime:
              props?.isEditGMAWBProject && applicationTypeId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return { ...m, ...patch };
        }

        return { ...m };
      } else if (m.flexProjectLineId === data.flexProjectLineId) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: applicationTypeId,
          deliveryPathId:
            props?.isEditGMAWBProject && applicationTypeId
              ? m.deliveryPathId
              : null,
          additionalInfoId:
            props?.isEditGMAWBProject && applicationTypeId
              ? m.additionalInfoId
              : null,
          e2EProjectTemplateId:
            props?.isEditGMAWBProject && applicationTypeId
              ? m.e2EProjectTemplateId
              : null,
          leadTime:
            props?.isEditGMAWBProject && applicationTypeId ? m.leadTime : 0,
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    return list;
  };

  const mapDeliveryPath = (
    data: E2EProjectLineModel,
    deliveryPathId: any,
    field: string
  ) => {
    const list = e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        const deliveryPathAvailable = m.deliveryPathList?.find(
          (a) => a.id === deliveryPathId
        );

        if (deliveryPathAvailable) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: deliveryPathId,
            additionalInfoId:
              props?.isEditGMAWBProject && deliveryPathId
                ? m.additionalInfoId
                : null,
            e2EProjectTemplateId:
              props?.isEditGMAWBProject && deliveryPathId
                ? m.e2EProjectTemplateId
                : null,
            leadTime:
              props?.isEditGMAWBProject && deliveryPathId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return {
            ...m,
            ...patch,
          };
        }

        if (isBulkRow(m)) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: deliveryPathId,
            leadTime:
              props?.isEditGMAWBProject && deliveryPathId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return { ...m, ...patch };
        }

        return { ...m };
      } else if (m.flexProjectLineId === data.flexProjectLineId) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: deliveryPathId,
          additionalInfoId:
            props?.isEditGMAWBProject && deliveryPathId
              ? m.additionalInfoId
              : null,
          e2EProjectTemplateId:
            props?.isEditGMAWBProject && deliveryPathId
              ? m.e2EProjectTemplateId
              : null,
          leadTime:
            props?.isEditGMAWBProject && deliveryPathId ? m.leadTime : 0,
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    return list;
  };

  const mapAdditionalInfo = (
    data: E2EProjectLineModel,
    additionalInfoId: any,
    field: string
  ) => {
    const list = e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        const additionalInfoAvailable = m.additionalInfoList?.find(
          (a) => a.id === additionalInfoId
        );

        if (additionalInfoAvailable) {
          const patch = {
            flexProjectLineId: m.flexProjectLineId,
            [field]: additionalInfoId,
            e2EProjectTemplateId:
              props?.isEditGMAWBProject && additionalInfoId
                ? m.e2EProjectTemplateId
                : null,
            leadTime:
              props?.isEditGMAWBProject && additionalInfoId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return {
            ...m,
            ...patch,
          };
        }

        if (isBulkRow(m)) {
          const patch = {
            [field]: additionalInfoId,
            flexProjectLineId: m.flexProjectLineId,
            leadTime:
              props?.isEditGMAWBProject && additionalInfoId ? m.leadTime : 0,
          };

          dispatch(patchProjectLine(patch));

          return { ...m, ...patch };
        }

        return { ...m };
      } else if (m.flexProjectLineId === data.flexProjectLineId) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: additionalInfoId,
          e2EProjectTemplateId:
            props?.isEditGMAWBProject && additionalInfoId
              ? m.e2EProjectTemplateId
              : null,
          leadTime:
            props?.isEditGMAWBProject && additionalInfoId ? m.leadTime : 0,
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    return list;
  };

  const mapTechnologies = (
    data: E2EProjectLineModel,
    field: string,
    value: any
  ) => {
    e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: value,
          e2EProjectLineTechnologies: (value ?? []).map(
            (technology: any) =>
              ({
                technologyId: technology,
                technologyName: technologyList.find(
                  (techItem: any) => techItem.id === technology
                ).value,
                isSelected: true,
              } as E2EProjectLineTechnologyModel)
          ),
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      } else if (m.flexProjectLineId === data.flexProjectLineId) {
        const patch = {
          flexProjectLineId: m.flexProjectLineId,
          [field]: value,
          e2EProjectLineTechnologies: (value ?? []).map(
            (technology: any) =>
              ({
                technologyId: technology,
                technologyName: technologyList.find(
                  (techItem: any) => techItem.id === technology
                ).value,
                isSelected: true,
              } as E2EProjectLineTechnologyModel)
          ),
        };

        dispatch(patchProjectLine(patch));

        return {
          ...m,
          ...patch,
        };
      }

      return { ...m };
    });

    setIsSaveDisable(false);
  };

  const handleGmaWbProjectChange = async (e: DropdownChangeParams) => {
    const e2eProjectId = e.value;
    const e2eProject = e2eProjectLines?.find(
      (project) => project.id == e2eProjectId
    );
    dispatch(updateE2EProjectId(e.value));
    dispatch(updateE2EProjectName(e2eProject?.name));
  };

  const getApplicationTypeList = async (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[],
    isNewValueNotEmpty: boolean
  ) => {
    let parameter = { ...data };

    if (data.flexProjectLineId === "0") {
      const list = await Promise.all(
        lines.map(async (rowData: E2EProjectLineModel) => {
          if (shouldBulkRowUpdateLine(rowData, isNewValueNotEmpty)) {
            const applicationTypeList =
              await ReferenceService.getApplicationTypeByCertificateSchemeId(
                rowData.schemeId
              )
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }

                  return [];
                })
                .catch((error) => {
                  console.error(error);
                });

            const applicationTypeId = applicationTypeList.find(
              (f: any) => f.id !== ""
            )?.id;
            const isApplicationTypeAvailable = applicationTypeList?.find(
              (a: any) => a.id === rowData.applicationTypeId
            );

            const patch = {
              flexProjectLineId: rowData.flexProjectLineId,
              applicationTypeId:
                props?.isEditGMAWBProject && isApplicationTypeAvailable
                  ? rowData.applicationTypeId
                  : applicationTypeId,
              applicationTypeList: applicationTypeList,
            };

            dispatch(patchProjectLine(patch));

            return {
              ...rowData,
              ...patch,
            };
          } else {
            return { ...rowData };
          }
        })
      );

      getDeliveryPathList(parameter, list, isNewValueNotEmpty);
    } else {
      await ReferenceService.getApplicationTypeByCertificateSchemeId(
        data.schemeId
      )
        .then((response) => {
          if (response.data.length > 0) {
            const applicationTypeList = response.data;
            const isApplicationTypeAvailable = applicationTypeList?.find(
              (a: any) => a.id === data.applicationTypeId
            );
            const applicationTypeId =
              props?.isEditGMAWBProject && isApplicationTypeAvailable
                ? data.applicationTypeId
                : response.data[0].id;

            parameter.applicationTypeId = applicationTypeId;

            const list = lines.map((m: E2EProjectLineModel) => {
              if (m.flexProjectLineId === data.flexProjectLineId) {
                const patch = {
                  flexProjectLineId: m.flexProjectLineId,
                  applicationTypeId: applicationTypeId,
                  applicationTypeList: applicationTypeList,
                };

                dispatch(patchProjectLine(patch));

                return {
                  ...m,
                  ...patch,
                };
              }

              return { ...m };
            });

            getDeliveryPathList(parameter, list, isNewValueNotEmpty);
          } else {
            getDeliveryPathList(parameter, lines, isNewValueNotEmpty);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsSaveDisable(false);
        });
    }
  };

  const getDeliveryPathList = async (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[],
    isNewValueNotEmpty: boolean
  ) => {
    let parameter = { ...data };

    if (data.flexProjectLineId === "0") {
      const list = await Promise.all(
        lines.map(async (rowData: E2EProjectLineModel) => {
          if (shouldBulkRowUpdateLine(rowData, isNewValueNotEmpty)) {
            const deliveryPathList =
              await ReferenceService.getDeliveryPathByCertificateScheme(rowData)
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }

                  return [];
                })
                .catch((error) => {
                  console.error(error);
                });

            const deliveryPathId = deliveryPathList.find(
              (f: any) => f.id !== ""
            )?.id;
            const isDeliveryPathAvailable = deliveryPathList?.find(
              (d: any) => d.id === rowData.deliveryPathId
            );

            const patch = {
              flexProjectLineId: rowData.flexProjectLineId,
              deliveryPathId:
                props?.isEditGMAWBProject && isDeliveryPathAvailable
                  ? rowData.deliveryPathId
                  : deliveryPathId,
              deliveryPathList: deliveryPathList,
            };

            dispatch(patchProjectLine(patch));

            return {
              ...rowData,
              ...patch,
            };
          }

          return { ...rowData };
        })
      );

      getAdditionalInfoList(parameter, list, isNewValueNotEmpty);
    } else {
      await ReferenceService.getDeliveryPathByCertificateScheme(data)
        .then((response) => {
          if (response.data.length > 0) {
            const deliveryPathList = response.data;
            const isDeliveryPathAvailable = deliveryPathList?.find(
              (d: any) => d.id === data.deliveryPathId
            );
            const deliveryPathId =
              props?.isEditGMAWBProject && isDeliveryPathAvailable
                ? data.deliveryPathId
                : response.data[0].id;

            parameter.deliveryPathId = deliveryPathId;

            const list = lines.map((m: E2EProjectLineModel) => {
              if (m.flexProjectLineId === data.flexProjectLineId) {
                const patch = {
                  flexProjectLineId: m.flexProjectLineId,
                  deliveryPathId: deliveryPathId,
                  deliveryPathList: deliveryPathList,
                };

                dispatch(patchProjectLine(patch));

                return {
                  ...m,
                  ...patch,
                };
              }

              return { ...m };
            });

            getAdditionalInfoList(parameter, list, isNewValueNotEmpty);
          } else {
            getAdditionalInfoList(parameter, lines, isNewValueNotEmpty);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsSaveDisable(false);
        });
    }
  };

  const getAdditionalInfoList = async (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[],
    isNewValueNotEmpty: boolean
  ) => {
    let parameter = { ...data };

    if (data.flexProjectLineId === "0") {
      const list = await Promise.all(
        lines.map(async (rowData: E2EProjectLineModel) => {
          if (shouldBulkRowUpdateLine(rowData, isNewValueNotEmpty)) {
            const additionalInfoList =
              await ReferenceService.getAdditionalInfoByCertificateScheme(
                rowData
              )
                .then((response) => {
                  if (response.data) {
                    return response.data;
                  }

                  return [];
                })
                .catch((error) => {
                  console.error(error);
                });

            const additionalInfoId = additionalInfoList.find(
              (f: any) => f.id !== ""
            )?.id;
            const isAdditionalInfoAvailable = additionalInfoList?.find(
              (a: any) => a.id === rowData.additionalInfoId
            );

            const patch = {
              flexProjectLineId: rowData.flexProjectLineId,
              additionalInfoId:
                props?.isEditGMAWBProject && isAdditionalInfoAvailable
                  ? rowData.additionalInfoId
                  : additionalInfoId,
              additionalInfoList: additionalInfoList,
            };

            dispatch(patchProjectLine(patch));

            return {
              ...rowData,
              ...patch,
            };
          } else {
            return { ...rowData };
          }
        })
      );
      getLeadTime(parameter, list);
    } else {
      await ReferenceService.getAdditionalInfoByCertificateScheme(data)
        .then((response) => {
          const additionalInfoList = response.data;
          const isAdditionalInfoAvailable = additionalInfoList?.find(
            (a: any) => a.id === data.additionalInfoId
          );
          const additionalInfoId =
            props?.isEditGMAWBProject && isAdditionalInfoAvailable
              ? data.additionalInfoId
              : response.data[0]?.id;

          parameter.additionalInfoId = additionalInfoId;

          const list = lines.map((m: E2EProjectLineModel) => {
            if (m.flexProjectLineId === data.flexProjectLineId) {
              const patch = {
                flexProjectLineId: data.flexProjectLineId,
                additionalInfoId: additionalInfoId,
                additionalInfoList: additionalInfoList,
              };

              dispatch(patchProjectLine(patch));

              return {
                ...m,
                ...patch,
              };
            }

            return { ...m };
          });

          if (!response.data.length) {
            parameter.additionalInfoId = null;
          }

          getLeadTime(parameter, list);
        })
        .catch((error) => {
          console.error(error);
          setIsSaveDisable(false);
        });
    }
  };

  const getLeadTime = async (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[]
  ) => {
    let parameter = { ...data };

    if (data.flexProjectLineId === "0") {
      const list = await Promise.all(
        lines.map(async (rowData: E2EProjectLineModel) => {
          if (isLineSelected(rowData) || rowData.flexProjectLineId === "0") {
            const leadTime = await ReferenceService.getLeadTime(rowData)
              .then((response) => {
                if (response.data) {
                  return response.data;
                }

                return [];
              })
              .catch((error) => {
                console.error(error);
              });

            const patch = {
              flexProjectLineId: rowData.flexProjectLineId,
              leadTime: leadTime?.leadTime ?? 0,
            };

            dispatch(patchProjectLine(patch));

            return {
              ...rowData,
              ...patch,
            };
          }

          return { ...rowData };
        })
      );

      getE2ETemplateByFlexProject(parameter, list);
    } else {
      await ReferenceService.getLeadTime(data)
        .then((response) => {
          if (response.data) {
            const leadTime = response.data.leadTime;

            const list = lines.map((m: E2EProjectLineModel) => {
              if (m.flexProjectLineId === data.flexProjectLineId) {
                const patch = {
                  flexProjectLineId: m.flexProjectLineId,
                  leadTime,
                };

                dispatch(patchProjectLine(patch));

                return {
                  ...m,
                  ...patch,
                };
              }

              return { ...m };
            });

            getE2ETemplateByFlexProject(parameter, list);
          } else {
            getE2ETemplateByFlexProject(parameter, lines);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsSaveDisable(false);
        });
    }

    await dispatch(refreshBulkRow());
  };

  const getE2ETemplateByFlexProject = async (
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[]
  ) => {
    if (data.flexProjectLineId === "0") {
      await Promise.all(
        lines.map(async (rowData: E2EProjectLineModel) => {
          if (isLineSelected(rowData) || isBulkRow(rowData)) {
            const e2eTemplateList = await ProjectService.getE2ETemplate(rowData)
              .then((response) => {
                if (response.data) {
                  return response.data;
                }

                return [];
              })
              .catch((error) => {
                console.error(error);
              });

            let preselectTemplateItem = null;
            if (e2eTemplateList && e2eTemplateList.length > 0) {
              preselectTemplateItem = e2eTemplateList[0].id;
            }

            const patch = {
              flexProjectLineId: rowData.flexProjectLineId,
              e2EProjectTemplateId: preselectTemplateItem,
              e2eTemplateList,
            };

            dispatch(patchProjectLine(patch));

            return {
              ...rowData,
              ...patch,
            };
          }

          return { ...rowData };
        })
      );

      setIsSaveDisable(false);
      setIsMapTechnology(true);
    } else {
      ProjectService.getE2ETemplate(data)
        .then((response) => {
          let e2eTemplateList: any[];

          if (response.data) {
            e2eTemplateList = response.data;
          }

          const list = lines.map((m: E2EProjectLineModel) => {
            if (m.flexProjectLineId === data.flexProjectLineId) {
              let preselectTemplateItem = null;
              if (e2eTemplateList && e2eTemplateList.length > 0) {
                preselectTemplateItem = e2eTemplateList[0].id;
              }

              const patch = {
                flexProjectLineId: m.flexProjectLineId,
                e2EProjectTemplateId: preselectTemplateItem,
                e2eTemplateList,
              };

              dispatch(patchProjectLine(patch));

              return {
                ...m,
                ...patch,
              };
            }

            return { ...m };
          });

          applyAllDefaultValues(data, list);
          setIsSaveDisable(false);
          setIsMapTechnology(true);
        })
        .catch((error) => {
          console.error(error);
          setIsSaveDisable(false);
        });
    }
  };

  const handleSchemeCheckbox = async (e: any, data: E2EProjectLineModel) => {
    let parameter = { ...data };
    const list = e2eProjectLines?.map((m: E2EProjectLineModel) => {
      if (isBulkRow(data) && (isLineSelected(m) || isBulkRow(m))) {
        return {
          ...m,
          certificateSchemeList: e.checked
            ? props.certificateSchemeList
            : m.initialCertificateSchemeList,
          isShowAllScheme: e.checked,
        };
      } else if (m.flexProjectLineId == data.flexProjectLineId) {
        return {
          ...m,
          certificateSchemeList: e.checked
            ? props.certificateSchemeList
            : data.initialCertificateSchemeList,
          isShowAllScheme: e.checked,
        };
      } else {
        return { ...m };
      }
    });

    if (e.checked === false) {
      setIsSaveDisable(true);

      if (data.flexProjectLineId === "0") {
        list?.forEach(async (f) => {
          f.schemeId = f.initialCertificateSchemeList?.find(
            (a) => a.selected
          )?.id;
        });

        await getApplicationTypeList(parameter, list ?? [], e);
      } else {
        parameter.schemeId = data.initialCertificateSchemeList?.find(
          (a) => a.selected
        )?.id;
        await getApplicationTypeList(parameter, list ?? [], e);
      }
    }

    dispatch(updateProjectLines(list ?? []));
  };

  const scrollFirstElementWithErrorIntoView = () => {
    const firstElementWithError =
      document.getElementsByClassName("p-invalid")[0];

    if (!!firstElementWithError) {
      firstElementWithError.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const handleCreateProject = async (isRedirectToProjectsPage: boolean) => {
    setHasUserTriedToSave(true);

    if (
      hasBlankDetails ||
      hasMissingTechnology ||
      !(
        props.isExistingGMAWBProject ||
        (isProjectNameValid && !isProjectNameValidating)
      )
    ) {
      setTimeout(scrollFirstElementWithErrorIntoView, 100);
      return;
    }

    setSavingStatus({
      isRedirectToProjectsPage,
      isSaving: true,
    });

    const project = getCurrentProjectData();

    try {
      const response = props.isExistingGMAWBProject
        ? await ProjectService.addProjectLines(project)
        : await ProjectService.createProject(project);

      if (response.isSuccess) {


        let successPrompt = ENABLED_DM_INTEGRATION
          ? "GMAWB Project created; R2C Checklist generated and integrated successfully."
          : `The new GMAWB project <b>'${projectName}'</b> has been successfully created.`;

        const notificationMessage = props.isExistingGMAWBProject
          ? "Order lines successfully added."
          : successPrompt;

        if (isRedirectToProjectsPage) {
          sessionStorage.setItem(
            pendingProjectCreatedSuccessMessageKey,
            notificationMessage
          );
          window.parent.postMessage("redirectToProjectsPage", "*");
          localStorage.setItem("isFromSaveProjectPwq", "true");
        } else {
          props.setIsCdpSaved(true);
          props.closeModal(false);

          if (ENABLED_DM_INTEGRATION) {
            showSuccess("Success", "", () =>
              DmIntegrationTemplate(notificationMessage)
            );
          } else {
            showSuccess("Project Successfully Created", notificationMessage);
          }
        }
      } else {
        showError("System Error", response.message);
      }
    } catch (e) {
      showError(
        "Connection Error",
        "Unable to connect to the server. Please check your internet connection or try again later."
      );
    }

    setSavingStatus({ isSaving: false });
  };

  const getCurrentProjectData = (isEditProject: boolean = false) => {
    const project = { ...e2eProject };

    if (!props.isExistingGMAWBProject && !isEditProject) project.id = null;

    project.e2EProjectLines = e2eProjectLines!
      .filter((f) => f.flexProjectLineId !== "0")
      .map((projectLine) => {
        let modelInfo: IPModel[] = [];
        if (projectLine.baseModel) {
          modelInfo.push({
            ...projectLine.baseModel,
          });
        }

        if (projectLine.modelVariants && projectLine.modelVariants.length > 0) {
          modelInfo.push(
            ...projectLine.modelVariants.map((item) => ({
              ...item.modelVariant,
            }))
          );
        }

        return {
          ...projectLine,
          e2EProjectId: props.isExistingGMAWBProject ? project?.id : null,
          endDate: projectLine.endDate ? projectLine.endDate : null,
          e2EProjectLineTechnologies: (projectLine?.technologies ?? []).map(
            (technology: any) =>
              ({
                technologyId: technology,
                technologyName: technologyList.find(
                  (techItem: any) => techItem.id === technology
                ).value,
                isSelected: true,
              } as E2EProjectLineTechnologyModel)
          ),
          e2EProjectLineModelInfo: modelInfo as any,
        };
      });

    return project;
  };

  const isSelectable = (rowData: E2EProjectLineModel) => rowData.isSelectable;

  const isRowSelectable = (event: any) =>
    event.data ? isSelectable(event.data) : true;

  const rowClassName = (rowData: E2EProjectLineModel) => {
    return clsx({
      [classes["checkbox-hide"]]: !isSelectable(rowData),
      "p-highlight": rowData.isRecentlyAdded,
    });
  };

  const selectedRows = e2eProjectLines?.filter((x) =>
    selectedProjectServiceLineIds.includes(x.flexProjectLineId)
  );

  const hasMissingTechnology = e2eProjectLines?.find(
    (f) =>
      f.flexProjectLineId !== "0" &&
      f.segment?.toLocaleLowerCase() === "wireless" &&
      (f.technologies?.length ?? 0) === 0
  )
    ? true
    : false;

  const hasBlankDetails = e2eProjectLines!
    .filter((f) => f.flexProjectLineId !== "0")
    .some(
      (a) =>
        !a.schemeId ||
        !a.e2EProjectTemplateId ||
        !a.deliveryPathId ||
        !a.additionalInfoId ||
        !a.applicationTypeId
    );

  const multiSelectItemTemplate = (item: any) => (
    <div title={item.value} className={clsx(classes["text-overflow-ellipsis"])}>
      {item.value}
    </div>
  );

  const renderCertificateScheme = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions,
    isDisabled: boolean
  ) => {
    const isValid = !!data.schemeId;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    const selectedOption = data.certificateSchemeList?.find(
      (option) => option.id === data.schemeId
    );
    const tooltip = selectedOption ? selectedOption.value : "";

    return (
      <>
        {tooltip && (
          <Tooltip
            target={`.${className}`}
            content={tooltip}
            style={{
              fontSize: "0.8em",
              padding: "5px",
              margin: "-20px",
            }}
            my="left top"
            at="right bottom"
          />
        )}
        <Dropdown
          className={className}
          style={{ width: "100%" }}
          filter
          showClear
          filterBy="value"
          value={data.schemeId}
          options={data.certificateSchemeList ?? []}
          onChange={(e) => handlerDropDownChange(e, "schemeId", data)}
          optionLabel="value"
          optionValue="id"
          placeholder="Select Certificate Scheme"
          disabled={isDisabled}
          itemTemplate={multiSelectItemTemplate}
          panelClassName={classes["dd-fixed-width"]}
        />
      </>
    );
  };

  const renderIsShowAllScheme = (
    data: E2EProjectLineModel,
    isDisabled: boolean
  ) => {
    return (
      <Checkbox
        disabled={data.isShowAllSchemeDisabled || isDisabled}
        checked={data.isShowAllScheme}
        onChange={(e) => handleSchemeCheckbox(e, data)}
      />
    );
  };

  const renderApplicationType = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions,
    isDisabled: boolean
  ) => {
    const isValid = !!data.applicationTypeId;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    return (
      (data.applicationTypeList?.length ?? 0) > 0 && (
        <Dropdown
          className={className}
          style={{ width: "100%" }}
          filter
          showClear
          filterBy="value"
          value={data.applicationTypeId}
          options={data.applicationTypeList ?? []}
          onChange={(e) => handlerDropDownChange(e, "applicationTypeId", data)}
          optionLabel="value"
          optionValue="id"
          placeholder="Select Application Type"
          disabled={isDisabled}
        />
      )
    );
  };

  const renderAdditionalInfo = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions,
    isDisabled: boolean
  ) => {
    const isValid = !!data.additionalInfoId;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    return (
      (data.additionalInfoList?.length ?? 0) > 0 && (
        <Dropdown
          className={className}
          filter
          showClear
          filterBy="value"
          value={data.additionalInfoId}
          options={data.additionalInfoList ?? []}
          onChange={(e) => handlerDropDownChange(e, "additionalInfoId", data)}
          optionLabel="value"
          optionValue="id"
          placeholder="Select Additional Info"
          disabled={isDisabled}
        />
      )
    );
  };

  const renderDeliveryPath = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions,
    isDisabled: boolean
  ) => {
    const isValid = !!data.deliveryPathId;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    const selectedOption = data.deliveryPathList?.find(
      (option) => option.id === data.deliveryPathId
    );
    const tooltip = selectedOption ? selectedOption.value : "";

    return (
      (data.deliveryPathList?.length ?? 0) > 0 && (
        <>
          {tooltip && (
            <Tooltip
              target={`.${className}`}
              content={tooltip || ""}
              style={{
                fontSize: "0.8em",
                padding: "5px",
                margin: "-20px",
              }}
              my="left top"
              at="right bottom"
            />
          )}
          <Dropdown
            className={className}
            style={{ width: "100%" }}
            filter
            showClear
            filterBy="value"
            value={data.deliveryPathId}
            options={data.deliveryPathList ?? []}
            onChange={(e) => handlerDropDownChange(e, "deliveryPathId", data)}
            optionLabel="value"
            optionValue="id"
            placeholder="Select Delivery Path"
            disabled={isDisabled}
            itemTemplate={multiSelectItemTemplate}
            panelClassName={classes["dd-fixed-width"]}
          />

          {data.flexProjectLineId !== "0" && (
            <CompareDeliveryPathModal
              data={data}
              projectLines={e2eProjectLines ?? []}
              selectedLineIds={selectedProjectServiceLineIds}
              getAdditionalInfoList={getAdditionalInfoList}
            />
          )}

          {data.flexProjectLineId === "0" && (
            <div className={`${classes["filler"]}`}>
              <CompareDeliveryPathModal
                data={data}
                projectLines={e2eProjectLines ?? []}
                selectedLineIds={[]}
                getAdditionalInfoList={getAdditionalInfoList}
              />
            </div>
          )}
        </>
      )
    );
  };

  const renderTechnology = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions,
    isDisabled: boolean
  ) => {
    const isValid = !!data.technologies?.length;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    return (
      data.segment === "Wireless" && (
        <MultiSelect
          className={className}
          value={data.technologies}
          options={technologyList}
          onChange={(e) => handlerDropDownChange(e, "technologies", data)}
          optionLabel="value"
          optionValue="id"
          disabled={data.segment !== "Wireless" || isDisabled}
          placeholder="Select Technology"
          showClear
          filter
        />
      )
    );
  };

  const renderE2ETemplate = (
    data: E2EProjectLineModel,
    options: ColumnBodyOptions
  ) => {
    const isValid = !!data.e2EProjectTemplateId;
    const className = clsx(
      !isValid && hasUserTriedToSave && !isBulkRow(data) && "p-invalid",
      `${options.field}-dropdown-${options.rowIndex}`
    );

    return (
      data.flexProjectLineId !== "0" && (
        <Dropdown
          className={className}
          style={{ width: "100%" }}
          filter
          showClear
          filterBy="name"
          value={data.e2EProjectTemplateId}
          options={data.e2eTemplateList}
          onChange={(e) =>
            handlerDropDownChange(e, "e2EProjectTemplateId", data)
          }
          disabled={true}
          optionLabel="name"
          optionValue="id"
          placeholder="Select GMAWB Template"
        ></Dropdown>
      )
    );
  };

  const projectsDropdown = (
    <Dropdown
      showFilterClear
      placeholder="-- Select Project Name --"
      filter
      filterBy={"name"}
      className={classes["project-name__input"]}
      value={selectedExistingProjectId}
      options={projectList?.filter(
        (project) => project.status !== ProjectStatus.Completed
      )}
      optionLabel="name"
      optionValue="id"
      onChange={(e) => handleGmaWbProjectChange(e)}
    />
  );

  const handleUpdateProjectName = (value: string) => {
    dispatch(updateE2EProjectName(value));
    checkIfCdpDataIsChanged();
  };

  const projectNameInput = (
    <>
      <InputText
        value={projectName}
        onChange={(e) => handleUpdateProjectName(e.target.value)}
        onBlur={(e) => handleUpdateProjectName(e.target.value.trim())}
        className={`${classes["project-name__input"]} ${
          !isProjectNameValid ? "p-invalid" : ""
        }`}
        maxLength={150}
      />
      {!isProjectNameValid && (
        <label className={classes["project-name__error-message"]}>
          {projectNameErrorMessage}
        </label>
      )}
    </>
  );

  const projectNameTemplate = () => {
    return (
      <div className={classes["project-name__input-container"]}>
        <label className={clsx(classes["project-name__label"])}>
          GMAWB Project Name
          <span className={classes["required-field"]}> *</span>
        </label>
        {props.isExistingGMAWBProject ? projectsDropdown : projectNameInput}
      </div>
    );
  };

  const renderModelName = (data: E2EProjectLineModel, isDisabled: boolean) => (
    <>
      {data.flexProjectLineId !== "0" && (
        <div className={classes["single-line__select-models"]}>
          <div className={classes["models"]}>{renderModels(data)}</div>
          <button
            onClick={() => handleSingleLineBtnApplyModelsClick(data)}
            className={classes["single-line__btn-select-models"]}
            disabled={isDisabled}
          >
            <FontAwesomeIcon className={classes["model-icon"]} icon={faTable} />
          </button>
        </div>
      )}
    </>
  );

  const renderProductType = (data: E2EProjectLineModel) => {
    const modelsProductTypes = uniq([
      data.baseModel?.productType,
      ...(data.modelVariants?.map(
        (model) => model?.modelVariant?.productType
      ) || []),
    ])
      .filter((model) => !!model)
      .join(", ");

    return (
      <span className={classes["text-ellipsis"]} title={modelsProductTypes}>
        {modelsProductTypes}
      </span>
    );
  };

  const renderModelPsn = (data: E2EProjectLineModel) => (
    <span title={data.baseModel?.partySiteNumber}>
      {data.baseModel?.partySiteNumber}
    </span>
  );

  const renderHeaderModelName = (headerText: string) => {
    return (
      <div>
        <span className={classes["model-text"]}>{headerText}</span>
        <button
          onClick={() => handleBtnClearAllModelsClick()}
          className={classes["clear-all-model"]}
        >
          Clear all
        </button>
      </div>
    );
  };

  const renderHeaderDeleteTemplate = () => {
    return (
      <div
        className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
        title="Bulk Remove Order Lines"
      >
        <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
          <Button
            className={`${classes["delete-icon-container"]} p-button-rounded p-button-secondary p-button-text`}
            disabled={
              isBulkIconDisabled ||
              selectedRows!.length === 0 ||
              selectedRows!.length === e2eProjectLines!.length - 1 ||
              e2eProjectLines!.length === 1
            }
            onClick={() => handleDeleteModal(selectedRows ?? [])}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={`${classes["delete-icon"]} fa-lg`}
              style={{}}
            />
          </Button>
        </div>
      </div>
    );
  };

  const updateColumns = (frozenCount: number, fields: GridColumnModel[]) => {
    const _fields = fields.map((f: GridColumnModel) => {
      return {
        ...f,
      };
    });

    setColumns(_fields);
  };

  const onColResize = (e: any) => {
    let newColumns = columns.map((col, i) => {
      if (e.column.props.header == col.value) {
        return {
          ...col,
          width: e.element.clientWidth + "px",
        };
      }
      return { ...col };
    });

    updateColumns(pwqFrozenCount, newColumns);
  };

  const handleMultipleLinesBtnApplyModelsClick = () => {
    if (!selectedProjectServiceLineIds.length) return;
    setSelectedProjectLine(selectedRows![0]);
    setSelectedModelSelectionProjectLineIds(selectedProjectServiceLineIds);
    setModelSearchDialogVisible(true);
  };

  const handleUpdateOrderline = async () => {
    await props.handleAddOrderLine(e2eProjectLines);
  };

  const tableColumnBody = (
    rowData: any,
    col: GridColumnModel,
    options: ColumnBodyOptions
  ) => {
    let body: any = null;
    const isDisabled =
      props?.isEditGMAWBProject &&
      rowData?.orderLineStatus === ProjectOrderlineStatus.Completed;

    switch (col.id) {
      case "certificateScheme":
        body = () => renderCertificateScheme(rowData, options, isDisabled!);
        break;
      case "isShowAllScheme":
        body = () => renderIsShowAllScheme(rowData, isDisabled!);
        break;
      case "applicationType":
        body = () => renderApplicationType(rowData, options, isDisabled!);
        break;
      case "additionalInfo":
        body = () => renderAdditionalInfo(rowData, options, isDisabled!);
        break;
      case "deliveryPath":
        body = () => renderDeliveryPath(rowData, options, isDisabled!);
        break;
      case "technology":
        body = () => renderTechnology(rowData, options, isDisabled!);
        break;
      case "e2eTemplate":
        body = () => renderE2ETemplate(rowData, options);
        break;
      case "modelName":
        body = () => renderModelName(rowData, isDisabled!);
        break;
      case "baseModel":
        body = () => renderProductType(rowData);
        break;
      case "modelPsn":
        body = () => renderModelPsn(rowData);
        break;
      default:
        break;
    }

    if (body) {
      return body(rowData, col);
    } else {
      return (
        <div
          title={rowData[col.id]}
          className={`text-overflow-ellipsis ${options.column}-${options.rowIndex}`}
          style={col.id === "leadTime" ? { textAlign: "center" } : {}}
        >
          {rowData[col.id]}
        </div>
      );
    }
  };

  const getColumnStyle = (col: GridColumnModel) => {
    return {
      width: col.width,
      justifyContent: ["isShowAllScheme", "leadTime"].includes(col.id)
        ? "center"
        : "flex-start",
      minWidth: col.width,
    };
  };

  const renderDynamicColumns = () =>
    columns.map((col: GridColumnModel) => (
      <Column
        key={col.value}
        columnKey={col.value}
        field={col.id}
        header={
          col.id === "modelName"
            ? renderHeaderModelName(col.value)
            : renderHeader(col)
        }
        body={(rowData, options) => tableColumnBody(rowData, col, options)}
        className={col.orderNo === pwqFrozenCount ? classes["last-frozen"] : ""}
        hidden={!col.isShown}
        style={getColumnStyle(col)}
      />
    ));

  const renderHeader = (column: GridColumnModel) => (
    <>
      <span>{column.value}</span>
      {column.required && <span className={classes["required-field"]}> *</span>}
    </>
  );

  const headerContent = () => {
    const headerTitle = props?.isEditGMAWBProject
      ? "Edit GMAWB Project"
      : "Choose Delivery Path";

    return (
      <>
        <div>
          <h2 className={classes["dialog-header__title"]}>{headerTitle}</h2>
          {!savingStatus.isSaving && (
            <div className={classes["dialog-header__info"]}>
              <div className={classes["dialog-header__info__left"]}>
                {projectNameTemplate()}

                <div>
                  <div
                    className={classes["dialog-header__info__left__field-info"]}
                  >
                    <h5>FLEX Sold To: {applicantInfo.companyName}</h5>
                    <FontAwesomeIcon
                      className={classes["icon"]}
                      icon={faInfoCircle}
                      title="This is the Sold To Customer Name"
                    ></FontAwesomeIcon>
                  </div>

                  <div
                    className={classes["dialog-header__info__left__field-info"]}
                  >
                    <h5>Party Site Number: {applicantInfo.partySiteNumber}</h5>
                    <FontAwesomeIcon
                      className={classes["icon"]}
                      icon={faInfoCircle}
                      title="This is the Sold To PSN"
                    ></FontAwesomeIcon>
                  </div>
                </div>

                <div>
                  <div
                    className={classes["dialog-header__info__left__field-info"]}
                  >
                    <h5>FLEX Ship To: {productOwnerInfo.companyName}</h5>
                    <FontAwesomeIcon
                      className={classes["icon"]}
                      icon={faInfoCircle}
                      title="This is the Ship To Customer Name"
                    ></FontAwesomeIcon>
                  </div>

                  <div
                    className={classes["dialog-header__info__left__field-info"]}
                  >
                    <h5>
                      Party Site Number: {productOwnerInfo.partySiteNumber}
                    </h5>
                    <FontAwesomeIcon
                      className={classes["icon"]}
                      icon={faInfoCircle}
                      title="This is the Ship To PSN"
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className={classes["dialog-header__info__right"]}>
                <Button
                  onClick={handleMultipleLinesBtnApplyModelsClick}
                  className={classes["btn-select-model"]}
                >
                  Select Model
                </Button>
                {
                  <ShowHideColumnsPrimeDataTable
                    fields={columns}
                    frozenColumnCount={pwqFrozenCount}
                    updateColumns={updateColumns}
                    setColumns={setColumns}
                    maxFrozenColumnCount={8}
                    hasShowAndFreezeSelection={false}
                  />
                }
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const confirmationCheckbox = (
    <div className={classes["confirmation-checkbox-container"]}>
      <Checkbox
        checked={isInformationConfirmed}
        disabled={savingStatus.isSaving}
        onChange={(e) => {
          setIsInformationConfirmed(e.checked);
        }}
      />
      <div>
        By proceeding with the selected workflow, I acknowledge and confirm that
        all information that will be used in this project is accurate, complete,
        and true to the best of my knowledge.
      </div>
    </div>
  );

  const btnAddAdditionalOrderLines = (
    <Button
      className="p-button-outlined"
      disabled={savingStatus.isSaving}
      label="ADD ORDERLINE(S)"
      onClick={handleUpdateOrderline}
    ></Button>
  );

  const isSaveDisabled = isLoading || isSaveDisable || !isInformationConfirmed;
  const isSaveEditDisabled =
    isLoading ||
    isSaveDisable ||
    savingStatus.isSaving ||
    (!isCdpDataChanged && !isProjectNameChanged);

  const btnSaveAndCreateNew = (
    <Button
      className={classes["btn-save-and-create-new"]}
      disabled={isSaveDisabled || savingStatus.isSaving}
      label="SAVE AND CREATE NEW"
      onClick={() => handleCreateProject(false)}
    />
  );

  const btnSaveAndContinue = (
    <Button
      className={classes["btn-save-and-continue"]}
      disabled={isSaveDisabled || savingStatus.isSaving}
      label="SAVE AND CONTINUE"
      onClick={() => handleCreateProject(true)}
    />
  );

  const btnCancelEditProject = (
    <Button
      className={classes["btn-cancel-edit"]}
      disabled={isLoading || savingStatus.isSaving}
      label="CANCEL"
      onClick={() => handleCancelEditProject()}
    />
  );

  const btnSaveEditChanges = (
    <Button
      className={classes["btn-save-edit-changes"]}
      disabled={isSaveEditDisabled}
      label="SAVE CHANGES"
      onClick={() => handleSaveEditProject()}
      loading={savingStatus.isSaving}
    />
  );

  const handleCancelEditProject = () => {
    setShowCdpCloseModal(true);
  };

  const getProjectDataOnEdit = () => {
    let project = getCurrentProjectData(true) as E2EProjectModel;
    project?.e2EProjectLines?.forEach((line) => {
      line?.e2EProjectLineTechnologies?.forEach((technology) => {
        technology.e2EProjectLineId = line.id;
      });
      line?.e2EProjectLineModelInfo?.forEach((model) => {
        model.e2EProjectLineId = line.id;
      });
    });
    return project;
  };

  const handleSaveEditProject = async () => {
    setHasUserTriedToSave(true);

    if (
      hasBlankDetails ||
      hasMissingTechnology ||
      !(
        props.isExistingGMAWBProject ||
        (isProjectNameValid && !isProjectNameValidating)
      )
    ) {
      setTimeout(scrollFirstElementWithErrorIntoView, 100);
      return;
    }

    setIsLoading(true);

    if (isProjectNameChanged && !isCdpDataChanged) {
      handleEcdAdjustmentOnConfirm([]);
      return;
    }

    const projectData = getProjectDataOnEdit();
    try {
      const response = await ProjectService.computeAdjustedEcdOnEditProject(
        projectData
      );

      if (response && response?.isSuccess) {
        setE2EProjectLineECDAdjustments(response?.data?.ecdAdjustments || []);
        setCurrentProjectLineIds(response?.data?.e2EProjectLineIds);

        if (response?.data?.ecdAdjustments.length > 0) {
          setECDAdjustmentsDialogVisible(true);
        } else {
          handleEcdAdjustmentOnConfirm([]);
        }
      }
    } catch (e) {
      showError(
        "Project Update Failed",
        "Cannot compute Adjusted Ecd for the selected order lines."
      );
    }
  };

  const handleEcdAdjustmentOnCancel = () => {
    setECDAdjustmentsDialogVisible(false);
    setIsLoading(false);
  };

  const handleEcdAdjustmentOnConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    const project = getProjectDataOnEdit();

    let response: any = null;
    let errorHeader: string = "";

    setECDAdjustmentsDialogVisible(false);
    setSavingStatus({
      isRedirectToProjectsPage: true,
      isSaving: true,
    });

    try {
      response = await ProjectService.saveEditProjectDetails({
        e2EProjectModel: project,
        e2EProjectLineIds: adjustECDProjectLineIds,
      });
      errorHeader =
        response?.message?.toLowerCase().includes("unexpected error") ||
        response?.message?.toLowerCase().includes("failed to update")
          ? "System Error"
          : response?.message?.toLowerCase().includes("unable to connect")
          ? "Connection Error"
          : "Project Update Failed";

      if (response?.isSuccess) {
        showSuccess(
          "Project Updated Successfully",
          "GMAWB Project updated successfully with integrated template changes."
        );

        props.closeModal(false);
      } else {
        showError(errorHeader, response?.message);
      }
    } catch (e) {
      showError(
        "Connection Error",
        "Unable to connect to the server. Please check your internet connection or try again later."
      );
    }

    setSavingStatus({ isSaving: false });
    setIsLoading(false);
    dispatch(resetInitialProjectLines());
  };

  const footerContent = (
    <div className={classes["footer-section"]}>
      {!props?.isEditGMAWBProject && confirmationCheckbox}
      <div className={classes["footer__actions"]}>
        {!props?.isEditGMAWBProject && btnAddAdditionalOrderLines}
        {!props?.isEditGMAWBProject && btnSaveAndCreateNew}
        {!props?.isEditGMAWBProject && btnSaveAndContinue}
        {props?.isEditGMAWBProject && btnCancelEditProject}
        {props?.isEditGMAWBProject && btnSaveEditChanges}
      </div>
    </div>
  );

  const onColReorder = (e: any) => {
    if (e.dropIndex === 0 || e.dropIndex === columns.length + 1) {
      clv.current.reset();
    } else {
      let pwqColumns = columns.map((col, i) => {
        const orderNo = e.columns.findIndex(
          (fi: any) => fi.props.field === col.id
        );

        return {
          ...col,
          orderNo: orderNo,
        };
      });

      pwqColumns.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      updateColumns(pwqFrozenCount, pwqColumns);
    }
  };

  const handleApplyModels = (models: IPModel[], isClose: boolean) => {
    const list = e2eProjectLines?.map((e) =>
      selectedModelSelectionProjectLineIds.includes(e.flexProjectLineId)
        ? {
            ...e,
            baseModel: models[0],
            modelVariants: models
              .filter((m: IPModel, i) => i > 0)
              .map((x) => ({ modelVariant: x } as E2EProjectModelVariantModel)),
          }
        : e
    );

    dispatch(updateProjectLines(list ?? []));
    checkIfCdpDataIsChanged();
    if (isClose) setModelSearchDialogVisible(false);
  };

  const handleSingleLineBtnApplyModelsClick = (line: E2EProjectLineModel) => {
    setSelectedModelSelectionProjectLineIds([line.flexProjectLineId]);
    setModelSearchDialogVisible(true);
    setSelectedProjectLine(line);
  };

  const renderModels = (line: E2EProjectLineModel) => {
    let models: IPModel[] = [];

    if (line.baseModel) models.push(line.baseModel);

    if (line.modelVariants?.length)
      models = [...models, ...line.modelVariants.map((x) => x.modelVariant)];

    const maxNumberOfModelsToDisplay = 3;
    const numberOfHiddenModels = models.length - 3;

    return (
      <>
        <div className="ul-grid__column">
          <div className="ul-grid__row -wrap">
            {models
              .filter((x, i) => i < maxNumberOfModelsToDisplay)
              .map((m) => (
                <span
                  title={m.modelName}
                  className={`ul-grid__column -x25 ${classes["model-names-container"]}`}
                >
                  {m.modelName}
                </span>
              ))}
            {numberOfHiddenModels > 0 && (
              <div className={classes["hidden-models-count"]}>
                + {numberOfHiddenModels}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleBtnClearAllModelsClick = () => {
    let projectLinesWithModels = e2eProjectLines!.filter(
      (x) => x.flexProjectLineId !== "0" && x.baseModel && x.modelVariants
    );
    if (projectLinesWithModels.length === 0) return;

    setClearAllModelsConfirmationModalVisible(true);
  };

  const handleClearAllModelsConfirm = () => {
    const list = e2eProjectLines?.map((x) => {
      return { ...x, baseModel: undefined, modelVariants: [] };
    });

    dispatch(updateProjectLines(list ?? []));

    setClearAllModelsConfirmationModalVisible(false);
  };

  const handleClearAllModelsCancel = () => {
    setClearAllModelsConfirmationModalVisible(false);
  };

  const clearAllModelsConfirmationMessage = (
    <>
      Are you sure you want to delete all models associated to all order lines?
      NOTE: You must save your workflow for all changes (additions/deletions) to
      take effect.
    </>
  );

  const handleDeleteModal = (projectLines: E2EProjectLineModel[]) => {
    setShowDeleteModal(true);
    setProjectLinesToDelete(projectLines);
  };

  const actionTemplate = (data: E2EProjectLineModel) => {
    return (
      <div
        className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
        title="Remove Order Line"
      >
        <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
          <div className={data.isSelectable ? "" : classes["delete-icon-hide"]}>
            <Button
              className={`${classes["delete-icon-container"]} p-button-rounded p-button-secondary p-button-text`}
              onClick={() => handleDeleteModal([data])}
              disabled={
                e2eProjectLines!.length === 1 || props?.isEditGMAWBProject
              }
            >
              <FontAwesomeIcon
                icon={faTrash}
                className={`${classes["delete-icon"]} fa-lg`}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteProjectLines = (projectLinesToBeDeleted: any[]) => {
    const ids = projectLinesToBeDeleted.map((p) => p.flexProjectLineId);

    let list = e2eProjectLines!.filter(
      (p) => !ids.includes(p.flexProjectLineId)
    );

    if (list.length <= 2) {
      list = list.filter((p) => p.flexProjectLineId !== "0");
    }

    showSuccess("Success!", "Order Line(s) removed successfully.");

    dispatch(updateProjectLines(list ?? []));
    setShowDeleteModal(false);
  };

  const onHideModal = () => {
    setShowCdpCloseModal(true);
  };

  const renderDialogBody = () => {
    return (
      <div className={`${classes["dialog__body"]}`}>
        <DataTable
          ref={clv}
          style={{ minHeight: "100%" }}
          className={classes["projects-table"]}
          scrollHeight={"calc(100vh - 13.65rem)"}
          reorderableColumns
          scrollable
          scrollDirection="both"
          value={e2eProjectLines ?? []}
          columnResizeMode="expand"
          responsiveLayout="scroll"
          loading={isLoading}
          selection={selectedRows}
          onSelectionChange={handleSelectionChange}
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
          selectionMode="checkbox"
          showGridlines
          onColReorder={onColReorder}
          resizableColumns
          onColumnResizeEnd={onColResize}
        >
          <Column
            headerStyle={{
              width: "33px",
              maxWidth: "33px",
              minWidth: "33px",
            }}
            style={{ width: "33px", maxWidth: "33px", minWidth: "33px" }}
            reorderable={false}
            selectionMode="multiple"
            alignFrozen="left"
            frozen
            key="checkbox"
            columnKey="checkbox"
          ></Column>

          {!props?.isEditGMAWBProject && (
            <Column
              headerStyle={{ width: "30px", maxWidth: "30px" }}
              style={{ width: "30px", maxWidth: "30px", mindWidth: "30px" }}
              header={renderHeaderDeleteTemplate}
              body={actionTemplate}
              reorderable={false}
              alignFrozen="left"
              frozen
              key={"delete"}
              columnKey={"delete"}
              headerClassName={`${classes["delete-header"]}`}
            />
          )}
          {renderDynamicColumns()}
        </DataTable>
      </div>
    );
  };

  const renderDialogLoader = () => {
    return (
      <div className={classes["dialog__loader"]}>
        <div className={classes["dialog__loader-div"]}>
          <span>
            {ENABLED_DM_INTEGRATION
              ? "Saving GMAWB Project and Generating R2C Checklist..."
              : "Saving GMAWB Project"}
          </span>
          <div className={classes["loader-container"]}>
            <svg className="ul-progressCircle -medium">
              <circle
                className="ul-progressCircle__outer"
                cx="50%"
                cy="50%"
                r="35%"
              />
              <circle
                className="ul-progressCircle__inner"
                cx="50%"
                cy="50%"
                r="35%"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  const getPreSelectModel = () => {
    if (
      selectedProjectLine &&
      selectedProjectLine.baseModel &&
      selectedProjectLine.modelVariants
    ) {
      return [
        selectedProjectLine.baseModel,
        ...selectedProjectLine?.modelVariants.map((x) => x.modelVariant),
      ];
    } else if (selectedProjectLine && selectedProjectLine.baseModel) {
      return [selectedProjectLine.baseModel];
    }
    return [];
  };
  return (
    <>
      <Dialog
        className={classes["dialog"]}
        header={headerContent}
        draggable={false}
        visible={props.visible}
        onHide={onHideModal}
        style={{ width: "95vw", height: "90vh" }}
        footer={footerContent}
      >
        {!savingStatus?.isSaving ? renderDialogBody() : renderDialogLoader()}
      </Dialog>

      <ModelSearch
        preSelectedModels={getPreSelectModel()}
        productType={selectedProjectLine?.baseModel?.productType}
        partySiteNumber={applicantInfo.partySiteNumber}
        onClose={() => {
          setSelectedProjectLine(undefined);
          setModelSearchDialogVisible(false);
        }}
        visible={modelSearchDialogVisible}
        hideApplyButton={false}
        shouldPopulateWorkbenchModelForm={false}
        onSelectedModelsApply={handleApplyModels}
      />

      <ConfirmationModal
        visible={clearAllModelsConfirmationModalVisible}
        onCancel={handleClearAllModelsCancel}
        onConfirm={handleClearAllModelsConfirm}
        headerDisplay={`Delete Model Confirmation`}
        confirmationMessageHTML={clearAllModelsConfirmationMessage}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />

      <CDPDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDeleteProjectLines={handleDeleteProjectLines}
        selectedProjectLines={projectLinesToDelete}
      />

      <CDPCloseModal
        showCdpCloseModal={showCdpCloseModal}
        setShowCloseModal={setShowCdpCloseModal}
        isSaveDisable={isSaveDisable}
        handleCreateProject={handleCreateProject}
        closeCPDModal={props.closeModal}
        hasBlankDetails={hasBlankDetails}
        hasMissingTechnology={hasMissingTechnology}
      />

      <ECDAdjustmentsConfirmationDialog
        onCancel={handleEcdAdjustmentOnCancel}
        onConfirm={handleEcdAdjustmentOnConfirm}
        e2eProjectLineECDAdjustments={e2eProjectLineECDAdjustments}
        visible={ecdAdjustmentsDialogVisible}
        e2eProjectLineIds={currentProjectLineIds}
      />
    </>
  );
};

export default ProjectWorkQueueModal;
