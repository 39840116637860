import { useSelector } from "react-redux";
import {
  selectSelectedMilestones,
  selectSelectedTaskOrderlines,
} from "../../../../features/projectManagement/projectManagementSlice";
import { selectShowFlexTasks } from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import ProjectTaskStatus from "../../shared/enums/project-task-status";
import ProjectStatus from "../../shared/enums/project-status";

export default function ProjectListSelectionCounter() {
  const selectedTasks = useSelector(selectSelectedTaskOrderlines).filter(
    (item) =>
      ![ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
        item.status
      )
  );
  const selectedMilestones = useSelector(selectSelectedMilestones).filter(
    (item) =>
      ![ProjectStatus.Completed, ProjectStatus.Cancelled].includes(item.status)
  );
  const viewToggler = useSelector(selectShowFlexTasks);

  const selectedItemCount = viewToggler.isOn
    ? selectedTasks.length
    : selectedMilestones.length;

  if (selectedItemCount <= 0) return <></>;

  return (
    <span className="ul-label -warning plr-10 font-weight-semi-bold">
      {selectedItemCount} item(s) selected
    </span>
  );
}
