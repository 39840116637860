import clsx from "clsx";
import classes from "./ProjectTaskOrderlineStatusTemplate.module.scss";
import ProjectTaskStatus from "../../enums/project-task-status";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect, useRef } from "react";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import useToastr from "../../../../../hooks/useToastr";

import OnHoldReasonDialog from "../onhold-reason-dialog/OnHoldReasonDialog";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import FlexProjectStatus from "../../enums/flex-project-status";
import moment from "moment";
import {
  getFlexTaskStatusList,
  IProjectTaskStatus,
} from "../../../../../utils/constants/flex-task-status.constants";
import IProjectTaskStatusUpdateModel from "../../interfaces/pmr-e2e-project-task-status-update.model";

interface ProjectTaskOrderlineStatusTemplateProps {
  status: ProjectTaskStatus;
  tooltip?: string;
  orderlineNumber?: string;
  handleOrderlineStatusChange: any;
  E2EProjectLineId: string;
  E2EProjectLineFlexTaskId: string;
  isLoading?: boolean;
  containerStyle?: React.CSSProperties | undefined;
  totalHours: number;
  startDate: Date;
  isAdHoc: boolean | null;
  taskName?: string;
}
const ProjectTaskOrderlineStatusTemplate = (
  props: ProjectTaskOrderlineStatusTemplateProps
) => {
  const statusOptionsRef = useRef<HTMLDivElement>(null);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [activeOptions, setActiveOptions] = useState<IProjectTaskStatus[]>(
    getFlexTaskStatusList(props.isAdHoc)
  );

  const { showInfo, showError } = useToastr();
  const [currentStatus, setCurrentStatus] = useState<IProjectTaskStatus>();

  const [updatedStatus, setUpdatedStatus] = useState<IProjectTaskStatus>();
  const [isOnHoldReasonVisible, setIsOnHoldReasonVisible] = useState(false);
  const [selectedOnHoldReason, setSelectedOnHoldReason] =
    useState<PmrE2EOnHoldReason>();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] =
    useState(false);

  const onClosCancelConfirmation = () => {
    setIsCancelConfirmationVisible(false);
    setIsEditStatus(false);
  };

  const onCancelOnHoldReason = () => {
    setIsOnHoldReasonVisible(false);
    setSelectedOnHoldReason(undefined);
    setIsEditStatus(false);

    showInfo(
      "Status change cancelled",
      "The Task remains in its previous state."
    );
  };

  const onStatusChange = async (e: any) => {
    setUpdatedStatus(e.value);
  };

  const handleUpdateStatus = async () => {
    setIsSaveLoading(true);
    const taskToUpdate: IProjectTaskStatusUpdateModel = {
      flexTaskIds: [props.E2EProjectLineFlexTaskId],
      status: updatedStatus?.code!,
      statusDetailId: selectedOnHoldReason?.taskStatusDetailId,
      statusDetail: selectedOnHoldReason?.taskStatusReason,
      isBulkUpdate: false,
    };

    const result = await ProjectService.flexTaskStatusUpdate(taskToUpdate);

    if (result.isSuccess) {
      props.handleOrderlineStatusChange(
        selectedOnHoldReason,
        result,
        props.taskName,
        props.orderlineNumber,
        updatedStatus?.code
      );
    } else {
      showError("Update Incomplete", result.message);
    }

    setIsOnHoldReasonVisible(false);
    setIsEditStatus(false);
    setCurrentStatus(undefined);
    setUpdatedStatus(undefined);
    setSelectedOnHoldReason(undefined);
    setIsSaveLoading(false);
  };

  const handleStatusChange = async () => {
    if (
      updatedStatus?.code == undefined ||
      currentStatus?.code == updatedStatus.code
    )
      return;

    if (updatedStatus?.code === ProjectTaskStatus.OnHold) {
      setIsEditStatus(true);
      setIsOnHoldReasonVisible(true);
    } else if (updatedStatus?.code === ProjectTaskStatus.Cancelled) {
      setIsCancelConfirmationVisible(true);
    } else {
      await handleUpdateStatus();
    }
  };

  const getDisplay = () => {
    if (props.isAdHoc) {
      switch (props.status) {
        case ProjectTaskStatus.NotStarted:
          return "Not Started";
        case ProjectTaskStatus.AwaitingAssignment:
          return "Awaiting Assignment";
        case ProjectTaskStatus.NotScheduled:
          return "Not Scheduled";
        case ProjectTaskStatus.InProgress:
          return "In Progress";
        case ProjectTaskStatus.OnHold:
          return "On Hold";
        case ProjectTaskStatus.Cancelled:
          return "Cancelled";
        case ProjectTaskStatus.Completed:
          return "Completed";
        default:
          return "Not defined";
      }
    } else {
      switch (props.status) {
        case ProjectTaskStatus.NotStarted:
        case ProjectTaskStatus.AwaitingAssignment:
        case ProjectTaskStatus.NotScheduled:
          return "Not Scheduled";
        case ProjectTaskStatus.InProgress:
          return "In Progress";
        case ProjectTaskStatus.OnHold:
          return "On Hold";
        case ProjectTaskStatus.Cancelled:
          return "Cancelled";
        case ProjectTaskStatus.Completed:
          return "Completed";
        default:
          return "Not defined";
      }
    }
  };

  const getClass = () => {
    if (props.isAdHoc) {
      switch (props.status) {
        case ProjectTaskStatus.NotStarted:
          return classes["notstarted"];
        case ProjectTaskStatus.AwaitingAssignment:
          return classes["awaitingassignment"];
        case ProjectTaskStatus.NotScheduled:
          return classes["notscheduled"];
        case ProjectTaskStatus.InProgress:
          return classes["inprogress"];
        case ProjectTaskStatus.OnHold:
          return classes["onhold"];
        case ProjectTaskStatus.Cancelled:
          return classes["cancelled"];
        case ProjectTaskStatus.Completed:
          return classes["completed"];
        default:
          return "disabled";
      }
    } else {
      switch (props.status) {
        case ProjectTaskStatus.NotStarted:
        case ProjectTaskStatus.AwaitingAssignment:
        case ProjectTaskStatus.NotScheduled:
          return classes["notscheduled"];
        case ProjectTaskStatus.InProgress:
          return classes["inprogress"];
        case ProjectTaskStatus.OnHold:
          return classes["onhold"];
        case ProjectTaskStatus.Cancelled:
          return classes["cancelled"];
        case ProjectTaskStatus.Completed:
          return classes["completed"];
        default:
          return "disabled";
      }
    }
  };

  const statusOptionTemplate = (option: IProjectTaskStatus) => {
    if (option.code == currentStatus?.code) {
      option.tooltipMessage = "This is the current status.";
    } else if (
      props.totalHours > 0 &&
      option.code === ProjectTaskStatus.Cancelled
    ) {
      option.disabled = true;
      option.tooltipMessage = "Cannot cancel tasks with logged time entry.";
    } else {
      option.disabled = false;
      option.tooltipMessage = "";
    }

    return (
      <div
        className={`status-${option.code} ${option.disabled ? "disabled" : ""}`}
      >
        <span style={{ pointerEvents: "auto" }}>{option.name}</span>
        <Tooltip
          target={`.status-${option.code}`}
          content={option.tooltipMessage}
        />
      </div>
    );
  };

  const cancelBodyTemplate = (
    <>
      Confirming will permanently remove this task from the order line's project
      lifecycle.
      <br /> <br />
      Proceed?
    </>
  );

  const cancelFooterTemplate = (
    <div className={classes["footer"]}>
      <Button
        onClick={() => handleConfirmCancellation(false)}
        className={classes["button-cancel"]}
      >
        CANCEL
      </Button>
      <Button
        onClick={() => handleConfirmCancellation(true)}
        className={classes["button-save"]}
      >
        CONFIRM
      </Button>
    </div>
  );

  const handleConfirmCancellation = (isCancel: boolean) => {
    if (isCancel) handleUpdateStatus();

    setIsEditStatus(false);
    setIsCancelConfirmationVisible(false);
  };

  const handleStatusOptions = () => {
    let isTaskCommenced = false;
    if (props.isAdHoc) {
      const startDate = moment(props.startDate).startOf("day").toDate();
      const today = moment(new Date()).startOf("day").toDate();
      isTaskCommenced = startDate > today;
    } else {
      if (props.startDate) isTaskCommenced = true;
    }

    return activeOptions.filter((flexStatus) => {
      const { code } = flexStatus;

      switch (props.status) {
        case ProjectTaskStatus.NotScheduled:
          if (props.isAdHoc) {
            return (
              code === ProjectTaskStatus.NotStarted ||
              code === ProjectTaskStatus.OnHold ||
              code === ProjectTaskStatus.Cancelled
            );
          } else {
            return (
              code === ProjectTaskStatus.NotScheduled ||
              code === ProjectTaskStatus.OnHold ||
              code === ProjectTaskStatus.Cancelled
            );
          }
        case ProjectTaskStatus.NotStarted:
          return (
            code === ProjectTaskStatus.NotStarted ||
            code === ProjectTaskStatus.OnHold ||
            code === ProjectTaskStatus.Cancelled
          );

        case ProjectTaskStatus.InProgress:
          return (
            code === ProjectTaskStatus.InProgress ||
            code === ProjectTaskStatus.Cancelled ||
            code === ProjectTaskStatus.OnHold
          );

        case ProjectTaskStatus.OnHold:
          if (isTaskCommenced) {
            return (
              code === ProjectTaskStatus.OnHold ||
              code === ProjectTaskStatus.InProgress ||
              code === ProjectTaskStatus.Cancelled
            );
          } else {
            if (props.isAdHoc) {
              return (
                code === ProjectTaskStatus.OnHold ||
                code === ProjectTaskStatus.NotStarted ||
                code === ProjectTaskStatus.Cancelled
              );
            } else {
              return (
                code === ProjectTaskStatus.OnHold ||
                code === ProjectTaskStatus.NotScheduled ||
                code === ProjectTaskStatus.Cancelled
              );
            }
          }
      }
    });
  };

  const handleStatusClick = (status: ProjectTaskStatus) => {
    if (
      status === ProjectTaskStatus.Cancelled ||
      status === ProjectTaskStatus.Completed ||
      status == ProjectTaskStatus.AwaitingAssignment
    )
      return;

    setCurrentStatus(activeOptions.find((data) => data.code === status));
    setIsEditStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    if (
      updatedStatus?.code !== ProjectTaskStatus.OnHold &&
      updatedStatus?.code !== ProjectTaskStatus.Cancelled &&
      statusOptionsRef.current &&
      !statusOptionsRef.current.contains(event.target)
    ) {
      setIsEditStatus(false);
    }
  };

  useEffect(() => {
    if (updatedStatus?.code !== undefined) handleStatusChange();
  }, [updatedStatus]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [currentStatus]);

  const loadingIndicator = (
    <div className="text-center w-100">
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  if (props.isLoading) return loadingIndicator;

  const filteredOptions = () => {
    if (!props.isAdHoc) return handleStatusOptions();
    else {
      const startDate = moment(props.startDate).startOf("day").toDate();
      const today = moment(new Date()).startOf("day").toDate();
      const isNotStarted = startDate > today;

      return activeOptions.filter((opt) =>
        isNotStarted
          ? opt.code !== ProjectTaskStatus.InProgress &&
            opt.code !== ProjectTaskStatus.NotScheduled
          : opt.code !== ProjectTaskStatus.NotStarted &&
            opt.code !== ProjectTaskStatus.NotScheduled
      );
    }
  };

  return (
    <div style={props.containerStyle}>
      <ConfirmationModalV2
        className={classes["cancel-modal"]}
        style={{ width: "30vw" }}
        visible={isCancelConfirmationVisible}
        onClose={onClosCancelConfirmation}
        headerDisplay="Confirm Task Cancellation"
        confirmationMessageHTML={cancelBodyTemplate}
        customFooter={cancelFooterTemplate}
      ></ConfirmationModalV2>

      <OnHoldReasonDialog
        visible={isOnHoldReasonVisible}
        onClose={onCancelOnHoldReason}
        setSelectedOnHoldReason={setSelectedOnHoldReason}
        handleSaveOnHoldReason={handleUpdateStatus}
        isSaveLoading={isSaveLoading}
        selectedReason={selectedOnHoldReason}
      />

      {isEditStatus ? (
        <span ref={statusOptionsRef}>
          <Dropdown
            value={currentStatus}
            options={filteredOptions()}
            onChange={onStatusChange}
            optionLabel="name"
            className={classes["custom-dropdown"]}
            style={{ fontSize: "20px" }}
            optionDisabled="disabled"
            itemTemplate={statusOptionTemplate}
          />
        </span>
      ) : (
        <span
          title={props.tooltip}
          className={clsx(classes["custom-badge"], getClass())}
          onClick={() => handleStatusClick(props.status)}
        >
          {getDisplay()}
        </span>
      )}
    </div>
  );
};
export default ProjectTaskOrderlineStatusTemplate;
