import PmrE2EProjectDetailsOrderlineModel, {
  PmrE2EProjectDetailsOrderlineList,
  PmrE2EProjectLineUpdateStatusResult,
  PmrE2EProjectLineUpdateStatusResultModel,
  getDisplay,
  isRowUpdating,
} from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-details-orderline-model";
import useToastr from "./useToastr";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrderlineDetailList,
  updateFlexTaskOrderLineProjectLineStatusToCompleted,
  upsertFlexTaskMilestoneProjectLineStatusToCompleted,
  upsertOrderlineDetailsList,
  upsertOrderlineDetailsListFromUpdateStatus,
} from "../features/projectManagement/projectManagementSlice";
import ProjectOrderlineFlexWritebackStatus from "../pages/project-management-pmr/shared/enums/project-orderline-flex-writeback-status";
import ProjectOrderlineStatus from "../pages/project-management-pmr/shared/enums/project-orderline-status";
import FlexProjectStatus from "../pages/project-management-pmr/shared/enums/flex-project-status";
import { authProvider } from "../providers/authProvider";
import { uniqBy } from "lodash";

const useProjectOrderlineDetail = (projectId?: string) => {
  const dispatch = useDispatch();
  const { showSuccess, showError, showInfo } = useToastr();
  const userInfo = authProvider.getAccountInfo();
  const orderlineDetailList = useSelector(selectOrderlineDetailList);
  const orderLines =
    orderlineDetailList.find((list) => list.projectId === projectId)
      ?.orderlineDetailList || [];

  // #region FlexProjectStatusUpdate
  const applySignalrFlexProjectStatusUpdate = (
    result: PmrE2EProjectLineUpdateStatusResultModel
  ) => {
    if (!result.isSuccessFlexProjectStatusUpdate) {
      console.warn(
        "Warning",
        `FlexProjectStatusUpdate-false - result:${result}`
      );
      return;
    }

    const lines = result.data || [];

    if (lines.length == 0) return;

    const line = lines[0];
    if (
      userInfo?.account?.idToken?.preferred_username?.toLowerCase() ===
      result.triggeredBy
    ) {
      if (line.orderLineStatus == ProjectOrderlineStatus.Completed)
        handleCompletedFlexProjectStatusToast(lines);
      else if (line.orderLineStatus == ProjectOrderlineStatus.InProgress)
        handleInProgressFlexProjectStatusToast(lines);
      else if (line.orderLineStatus == ProjectOrderlineStatus.OnHold)
        handleOnHoldFlexProjectStatusToast(lines);
    }

    for (let item of lines) {
      dispatch(
        upsertOrderlineDetailsListFromUpdateStatus({
          id: item.id,
          fulFillmentStatusDetail: item.fulFillmentStatusDetail,
          fulFillmentStatusDetailId: item.fulFillmentStatusDetail,
          flexWritebackStatusId: item.flexWritebackStatusId,
          orderLineStatus: item.orderLineStatus,
          e2EFlexProjectStatus: item.e2EFlexProjectStatus,
        })
      );
      if (line.orderLineStatus == ProjectOrderlineStatus.Completed) {
        dispatch(
          updateFlexTaskOrderLineProjectLineStatusToCompleted({
            e2EProjectLineId: item.id,
            e2EProjectLineStatus: item.orderLineStatus,
          })
        );
        dispatch(
          upsertFlexTaskMilestoneProjectLineStatusToCompleted({
            e2EProjectLineId: item.id,
            e2EProjectLineStatus: item.orderLineStatus,
          })
        );
      }
    }
  };
  const handleOnHoldFlexProjectStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;
    const line = lines[0];
    if (!isMultiple) {
      header = `Status Updated`;
      body = `Order Line and FLEX Project Statuses for '<strong>${line?.orderLineNumber}</strong>' have been set to '<strong>On Hold</strong>'.`;
    } else {
      header = `Multiple Statuses Updated`;
      body = `Order Line and FLEX Project Statuses for selected order lines have been set to '<strong>On Hold</strong>'.`;
    }
    if (header && body) showSuccess(header, body);
  };
  const handleInProgressFlexProjectStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;
    const line = lines[0];
    if (!isMultiple) {
      header = `Status Updated`;
      body = `Order Line and FLEX Project Statuses for '<strong>${line?.orderLineNumber}</strong>' have been set to '<strong>In Progress</strong>'.`;
    } else {
      header = `Multiple Statuses Updated`;
      body = `Order Line and FLEX Project Statuses for selected order lines have been set to '<strong>In Progress</strong>'.`;
    }
    if (header && body) showSuccess(header, body);
  };
  const handleCompletedFlexProjectStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;
    const line = lines[0];
    if (!isMultiple) {
      header = `FLEX Project Completed`;
      body = `'<strong>${line?.orderLineNumber}</strong>' has been marked as "Completed". FLEX Project Status' has been successfully marked as "Completed".`;
    } else {
      header = `FLEX Project(s) Completed`;
      body = `The following order lines have been successfully marked as Completed. Respective FLEX Project status(es) has also been marked as "Completed": ${listOrderLineNumbers(
        lines
      )}`;
    }

    if (header && body) showSuccess(header, body);
  };
  // #endregion

  // #region ProjectLinetatusUpdate
  const applySignalrFlexProjectLineStatusUpdate = (
    result: PmrE2EProjectLineUpdateStatusResultModel
  ) => {
    const lines = result.data || [];

    if (lines.length == 0) return;

    const line = lines[0];
    if (
      userInfo?.account?.idToken?.preferred_username?.toLowerCase() ===
      result.triggeredBy
    ) {
      if (line.orderLineStatus == ProjectOrderlineStatus.Completed)
        handleCompletedOrderlineStatusToast(lines);
      else if (line.orderLineStatus == ProjectOrderlineStatus.InProgress)
        handleInProgressOrderlineStatusToast(lines);
      else if (line.orderLineStatus == ProjectOrderlineStatus.OnHold)
        handleOnHoldOrderlineStatusToast(lines);
    }

    for (let item of lines) {
      dispatch(
        upsertOrderlineDetailsListFromUpdateStatus({
          id: item.id,
          fulFillmentStatusDetail: item.fulFillmentStatusDetail,
          fulFillmentStatusDetailId: item.fulFillmentStatusDetail,
          flexWritebackStatusId: item.flexWritebackStatusId,
          orderLineStatus: item.orderLineStatus,
          e2EFlexProjectStatus: item.e2EFlexProjectStatus,
        })
      );
      if (line.orderLineStatus == ProjectOrderlineStatus.Completed) {
        dispatch(
          updateFlexTaskOrderLineProjectLineStatusToCompleted({
            e2EProjectLineId: item.id,
            e2EProjectLineStatus: item.orderLineStatus,
          })
        );
        dispatch(
          upsertFlexTaskMilestoneProjectLineStatusToCompleted({
            e2EProjectLineId: item.id,
            e2EProjectLineStatus: item.orderLineStatus,
          })
        );
      }
    }
  };

  const handleCompletedOrderlineStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;
    if (!isMultiple) {
      const line = lines[0];
      header = `Order Line Queued for Project Completion`;
      body = `'<strong>${line?.orderLineNumber}</strong>' has been marked as "Completed", and queued for FLEX Project Completion.`;
    } else {
      const line = lines[0];
      header = `Order Lines Queued for Project Completion`;
      body = `The following order lines have been successfully marked as 'Completed', and queued for FLEX Project Completion: ${listOrderLineNumbers(
        lines
      )}`;
    }
    if (header && body) showInfo(header, body);
  };

  const handleOnHoldOrderlineStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;

    if (!isMultiple) {
      const line = lines[0];
      header = `Status Updated`;
      body = `Order Line Status for '<strong>${line?.orderLineNumber}</strong>' has been set to '<strong>On Hold</strong>'.`;
    } else {
      const line = lines[0];
      header = `Multiple Statuses Updated`;
      body = `Order Line Status for selected order lines have been set to '<strong>On Hold</strong>'.`;
    }
    if (header && body) showSuccess(header, body);
  };

  const handleInProgressOrderlineStatusToast = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const isMultiple = lines.length > 1;
    let header = ``;
    let body = ``;

    if (!isMultiple) {
      const line = lines[0];
      header = `Status Updated`;
      body = `Order Line Status for '<strong>${line?.orderLineNumber}</strong>' has been set to '<strong>In Progress</strong>'.`;
    } else {
      const line = lines[0];
      header = `Multiple Statuses Updated`;
      body = `Order Line Status for selected order lines have been set to '<strong>In Progress</strong>'.`;
    }
    if (header && body) showSuccess(header, body);
  };

  const listOrderLineNumbers = (
    lines: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    return `<br /> ${lines.map(
      (line) => `<br /> '<strong>${line.orderLineNumber}</strong>'`
    )}`;
  };
  // #endregion

  // #region ProjectLineStatusUpdate Writeback Inprogress
  const applySignalrOrderlineStatusWritebackInprogress = (
    results: PmrE2EProjectLineUpdateStatusResult[]
  ) => {
    const lines = results || [];
    for (let item of lines) {
      dispatch(
        upsertOrderlineDetailsListFromUpdateStatus({
          id: item.id,
          flexWritebackStatusId: item.flexWritebackStatusId,
        })
      );
    }
  };
  // #endregion

  // #region JapanForOrderLineCompletion
  const applySignalrJapanOrderLineForCompletion = (
    result: PmrE2EProjectLineUpdateStatusResultModel
  ) => {
    if (
      userInfo?.account?.idToken?.preferred_username?.toLowerCase() !==
      result.triggeredBy
    )
      return;
    const lines = result.data || [];

    if (lines.length == 0) return;

    for (let line of lines) {
      const header = `All Order Line Tasks Completed`;
      const body = `All tasks for order line '<strong>${line?.orderLineNumber}</strong>' have been marked as 'Completed'. You may now update its Order Line Status under the Order Line Details tab as 'Completed'`;
      showSuccess(header, body);
    }
  };
  // #endregion

  // #region JapanForFlexProjectCompletion
  const applySignalrJapanFlexProjectForCompletion = (
    result: PmrE2EProjectLineUpdateStatusResultModel
  ) => {
    if (
      userInfo?.account?.idToken?.preferred_username?.toLowerCase() !==
      result.triggeredBy
    )
      return;

    const lines = result.data || [];

    if (lines.length == 0) return;

    const uniqByE2EFlexProject = uniqBy(lines, "projectNumber");

    for (let line of uniqByE2EFlexProject) {
      const header = `All Order Lines Completed`;
      const body = `All order lines for the FLEX Project '<strong>${line?.projectNumber}</strong>' have been marked as 'Completed'. You may now update its FLEX Project Status in the FLEX System as 'Completed'`;
      showSuccess(header, body);
    }

    for (let line of lines) {
      dispatch(
        upsertOrderlineDetailsListFromUpdateStatus({
          id: line.id,
          flexWritebackStatusId: ProjectOrderlineFlexWritebackStatus.Success,
          orderLineStatus: ProjectOrderlineStatus.Completed,
        })
      );
    }
  };
  // #endregion

  const updateSelectAllCheck = (isChecked: boolean) => {
    const newList = JSON.parse(JSON.stringify(orderLines));
    newList.forEach((orderline: PmrE2EProjectDetailsOrderlineModel) => {
      orderline.isChecked = isChecked;
      if (isRowUpdating(orderline)) orderline.isChecked = false;
    });
    dispatch(
      upsertOrderlineDetailsList({
        projectId: projectId,
        orderlineDetailList: newList,
      })
    );
  };

  const updateRowCheck = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    isChecked: boolean
  ) => {
    const newList = JSON.parse(JSON.stringify(orderLines));
    newList.forEach((orderline: PmrE2EProjectDetailsOrderlineModel) => {
      if (orderline.id === rowData.id) {
        orderline.isChecked = isChecked;
        if (isRowUpdating(rowData)) orderline.isChecked = false;
      }
    });
    dispatch(
      upsertOrderlineDetailsList({
        projectId: projectId,
        orderlineDetailList: newList,
      })
    );
  };

  const setWritebackStatusInprogressOnSave = (
    listForUpdate: PmrE2EProjectDetailsOrderlineModel[]
  ) => {
    const newList = JSON.parse(JSON.stringify(orderLines));
    newList.forEach((orderline: PmrE2EProjectDetailsOrderlineModel) => {
      const forUpdateLine = listForUpdate.find(
        (item) => item.id == orderline.id
      );
      if (forUpdateLine) {
        orderline.flexWritebackStatusId =
          ProjectOrderlineFlexWritebackStatus.InProgress;
      }
    });
    dispatch(
      upsertOrderlineDetailsList({
        projectId: projectId,
        orderlineDetailList: newList,
      })
    );
  };
  const setWritebackStatusToNone = (
    listForUpdate: PmrE2EProjectDetailsOrderlineModel[]
  ) => {
    const newList = JSON.parse(JSON.stringify(orderLines));
    newList.forEach((orderline: PmrE2EProjectDetailsOrderlineModel) => {
      const forUpdateLine = listForUpdate.find(
        (item) => item.id == orderline.id
      );
      if (forUpdateLine) {
        orderline.flexWritebackStatusId =
          ProjectOrderlineFlexWritebackStatus.None;
      }
    });
    dispatch(
      upsertOrderlineDetailsList({
        projectId: projectId,
        orderlineDetailList: newList,
      })
    );
  };

  const getFlexProjectStatus = (orderlineStatus?: ProjectOrderlineStatus) => {
    switch (orderlineStatus) {
      case ProjectOrderlineStatus.OnHold:
        return FlexProjectStatus.OnHold;
      case ProjectOrderlineStatus.InProgress:
        return FlexProjectStatus.InProgress;
      case ProjectOrderlineStatus.Completed:
        return FlexProjectStatus.Completed;
    }
  };

  return {
    applySignalrFlexProjectStatusUpdate,
    updateRowCheck,
    updateSelectAllCheck,
    setWritebackStatusInprogressOnSave,
    getFlexProjectStatus,
    applySignalrFlexProjectLineStatusUpdate,
    applySignalrOrderlineStatusWritebackInprogress,
    applySignalrJapanOrderLineForCompletion,
    applySignalrJapanFlexProjectForCompletion,
    setWritebackStatusToNone,
  };
};

export default useProjectOrderlineDetail;
