import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateRefinerState,
  updateShowFlexTasks,
} from "../../features/projectManagementPmr/projectManagementPmrSlice";

import { updateFavoritesModel } from "../../features/projectManagement/projectManagementSlice";

import useToastr from "../../hooks/useToastr";
import { authProvider } from "../../providers/authProvider";
import { pendingProjectCreatedSuccessMessageKey } from "../project-management/ProjectManagement";
import classes from "./ProjectManagementPmr.module.scss";
import ProjectListHeaderPmr from "./project-list-header-pmr/ProjectListHeaderPmr";
import ProjectListPmr from "./project-list-pmr/ProjectListPmr";
import ProjectManagementRefinersPmr from "./project-management-refners-pmr/ProjectManagementRefinersPmr";
import { useLocation } from "react-router-dom";
import useProjectManagementPmrSignalr from "../../hooks/useProjectManagementPmr";
import ProjectStatus from "./shared/enums/project-status";
import { toLower } from "lodash";
import {
  DEFAULT_REFINERS,
  USE_PROJECT_HANDLER_MAPPING,
  ENABLED_DM_INTEGRATION,
} from "../../utils/constants/feature-flag.constants";
import { DmIntegrationTemplate } from "../project-work-queue/project-work-queue-modal/ProjectWorkQueueModal";

const ProjectManagementPmr = () => {
  const { showSuccess } = useToastr();
  const [isRefinerVisible, setRefinerVisible] = useState(true);
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const showHdeRefiner = () => {
    setRefinerVisible(!isRefinerVisible);
  };

  const projectManagementPmrSignalrHook = useProjectManagementPmrSignalr();

  const userLoggedIn = authProvider.getAccount();

  const handlerRefinerOption = USE_PROJECT_HANDLER_MAPPING
    ? {
        label: `${userLoggedIn.name} (${toLower(userLoggedIn.idToken.email)})`,
        value: userLoggedIn.userName,
        displayName: userLoggedIn.name,
      }
    : {
        label: `${userLoggedIn.name} (${toLower(userLoggedIn.idToken.email)})`,
        value: userLoggedIn.name,
      };

  useEffect(() => {
    const orderLineDescription = urlSearch.get("orderLineDescription");
    const flexProjectNumber = urlSearch.get("flexProjectNumber");
    const e2EProjectName = urlSearch.get("e2EProjectName");
    const e2EProjectId = urlSearch.get("e2EProjectId");
    const isFromEmail = urlSearch.get("isFromEmail");
    const e2EProjectMilestoneid = urlSearch.get("e2EProjectMilestoneId");

    let value: any = null;

    if (!!e2EProjectName && !!e2EProjectId) {
      value = {
        projects: {
          projectNames: [{ label: e2EProjectName, value: e2EProjectId }],
        },
      };

      if (!!isFromEmail) {
        value.projects["contributors"] = [{ ...handlerRefinerOption }];
        if (!!e2EProjectMilestoneid) {
          dispatch(
            updateShowFlexTasks({
              isOn: false,
              isFromClicked: false,
            })
          );
        }
      }
    }

    //#region Override refiners
    if (!!orderLineDescription) {
      value = Object.assign(
        {
          orderLines: {
            orderLineDescriptions: [{ label: orderLineDescription }],
          },
        },
        value ?? {}
      );
    }

    if (!!flexProjectNumber) {
      value = Object.assign(
        {
          orderLines: {
            flexProjectNumbers: [{ label: flexProjectNumber }],
          },
        },
        {}
      );
    }

    if (!DEFAULT_REFINERS) return;

    if (value === null) {
      value = {
        projects: {
          contributors: [{ ...handlerRefinerOption }],
          projectStatuses: [ProjectStatus.InProgress, ProjectStatus.OnHold],
        },
      };
    }
    //#endregion

    dispatch(
      updateRefinerState({
        value,
      })
    );

    dispatch(
      updateFavoritesModel({
        property: "refiners",
        value: value,
      })
    );
  }, []);

  useEffect(() => {
    // HACK: Since the react is inside the iframe, showing success notification on
    // cdp modal doesn't work because the page is being redirected immediately to here.
    // Remove once the react shell is completed.
    const pendingProjectCreatedToastNotifMessage = sessionStorage.getItem(
      pendingProjectCreatedSuccessMessageKey
    );

    if (pendingProjectCreatedToastNotifMessage) {
      if (ENABLED_DM_INTEGRATION) {
        showSuccess("Success", "", () =>
          DmIntegrationTemplate(pendingProjectCreatedToastNotifMessage)
        );
      } else {
        showSuccess("Project Successfully Created", pendingProjectCreatedToastNotifMessage);
      }

      sessionStorage.removeItem(pendingProjectCreatedSuccessMessageKey);
    }
  }, []);

  return (
    <>
      <div className={`ul-grid__row ${classes["project-mangement-container"]}`}>
        <div
          className={clsx(
            `ul-grid__column`,
            classes["project-management-refiners-container"],
            !isRefinerVisible && "display-none"
          )}
        >
          <ProjectManagementRefinersPmr />
        </div>
        <div
          className={
            !isRefinerVisible
              ? `${classes["project-management-main-full"]}`
              : `${classes["project-management-main"]}`
          }
        >
          <div className={classes["refiner-showhide-btn"]}>
            <button
              title="Click here to show/hide"
              className={clsx(!isRefinerVisible && classes["hide"])}
              onClick={showHdeRefiner}
            >
              {isRefinerVisible ? (
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
              ) : (
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              )}
            </button>
          </div>
          <div className="ul-grid__column -x100 -x100-mobile">
            <div className="ul-grid__row -wrap">
              <div className="ul-grid__column  -x100 -x100-mobile">
                <ProjectListHeaderPmr />
              </div>
            </div>
            <div className="ul-grid__row -wrap">
              <div className="ul-grid__column  -x100 -x100-mobile">
                <ProjectListPmr></ProjectListPmr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectManagementPmr;
