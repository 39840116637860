import { uniq } from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import {
  TimestampProjectLineFlexTasksConfirmationModel,
  TimestampProjectLineMilestonesConfirmationModel,
} from "../../../../../shared/models/service-models/TimestampProjectLineMilestones.model";
import classes from "./MassTimestampConfirmationDialog.module.scss";

type Props = {
  visible: boolean;
  e2eMilestoneList?: TimestampProjectLineMilestonesConfirmationModel[];
  e2eFlexTaskList?: TimestampProjectLineFlexTasksConfirmationModel[];
  onConfirm: () => void;
  onCancel: () => void;
  e2eProjectLineIds?: string[];
  isLoading?: boolean;
  isECDAdjustmentsLoading?: boolean;
  isFlexTaskOn?: boolean;
};

export default function MassTimestampConfirmationDialog({
  visible,
  e2eMilestoneList,
  e2eFlexTaskList,
  onConfirm,
  onCancel,
  isLoading = false,
  isECDAdjustmentsLoading,
  isFlexTaskOn,
}: Props) {
  const milestonesTable = (
    <DataTable
      loading={isECDAdjustmentsLoading}
      value={e2eMilestoneList}
      className={classes["milestone-table"]}
      size="small"
      showGridlines={true}
      scrollable
    >
      <Column header="GMAWB Project Name" field="e2EProjectName" />
      <Column header="Order Line Number" field="e2EProjectLineDescription" />
      <Column header="Milestone(s)" field="e2EProjectLineMilestoneName" />
    </DataTable>
  );

  const tasksTable = (
    <DataTable
      loading={isECDAdjustmentsLoading}
      value={e2eFlexTaskList}
      className={classes["milestone-table"]}
      size="small"
      showGridlines={true}
      scrollable
    >
      <Column header="GMAWB Project" field="e2EProjectName" />
      <Column header="FLEX Project" field="flexProjectName" />
      <Column header="Order Line Number" field="e2EProjectLineDescription" />
      <Column header="FLEX Task(s)" field="e2EProjectLineFlexTaskName" />
    </DataTable>
  );

  const renderHeader = isFlexTaskOn
    ? "Confirm Mass-Timestamping of Order Line Tasks"
    : "Mass Timestamp Confirmation";

  const renderTableBody = () => {
    if (isFlexTaskOn) return tasksTable;
    else return milestonesTable;
  };

  const renderTableHeader = () => {
    let tableHeader;
    if (isFlexTaskOn)
      tableHeader = (
        <>
          The following order line FLEX Tasks will be marked as 'Completed' as
          part of the mass-timestamping process. <br />
          <br />
          Review the list below and confirm to Proceed, or click Cancel to stop
          the process:
        </>
      );
    else
      tableHeader = (
        <>The following milestone(s) will be marked as 'Completed'.</>
      );

    return tableHeader;
  };

  const renderTableFooter = () => {
    let tableFooter;
    if (isFlexTaskOn)
      tableFooter = (
        <>Task completion cannot be undone. Would you like to proceed?</>
      );
    else
      tableFooter = (
        <span className={classes["action-note"]}>Do you want to proceed?</span>
      );

    return tableFooter;
  };

  const milestoneBodyTemplate = (
    <>
      {renderTableHeader()}
      {renderTableBody()}
      {renderTableFooter()}
    </>
  );

  const footerTemplate = (
    <>
      <Button
        loading={isLoading}
        onClick={onCancel}
        className={classes["btn-no"]}
      >
        {isFlexTaskOn ? "CANCEL" : "NO"}
      </Button>
      <Button
        loading={isLoading}
        onClick={onConfirm}
        className={classes["btn-yes"]}
      >
        {isFlexTaskOn ? "PROCEED" : "YES"}
      </Button>
    </>
  );

  return (
    <ConfirmationModalV2
      closable={!isLoading}
      bodyClassName={classes["modal-body"]}
      style={{ width: "50vw" }}
      visible={visible}
      buttonYesDisplay="YES"
      buttonNoDisplay="NO"
      onClose={onCancel}
      headerDisplay={renderHeader}
      confirmationMessageHTML={milestoneBodyTemplate}
      customFooter={footerTemplate}
    ></ConfirmationModalV2>
  );
}
