import { useState } from "react";
import useToastr from "../../../../../hooks/useToastr";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectDetailsOrderlineModel from "../../interfaces/pmr-e2e-project-details-orderline-model";
import classes from "./ProjectOrderlineRemarksTemplate.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommenting } from "@fortawesome/free-solid-svg-icons";
import RemarksHistoryDialog from "../remarks-history-dialog/RemarksHistoryDialog";
import RemarksTypesEnum from "../remarks-history-dialog/RemarksTypeEnum";
import { RemarksHistoryModel } from "../../../../../shared/models/service-models/RemarksHistoryModel";

type Props = {
  orderlineDetail: PmrE2EProjectDetailsOrderlineModel;
  latestRemarks: string | undefined;
  remarkType: RemarksTypesEnum;
};

export default function ProjectOrderlineRemarksTemplate({
  orderlineDetail,
  latestRemarks,
  remarkType,
}: Props) {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const [remarksList, setRemarksList] = useState<RemarksHistoryModel[]>([]);
  const handleClick = async () => {
    getItems();
  };
  const { showSuccess } = useToastr();
  const [remarkDisplay, setRemarkDisplay] = useState(latestRemarks);
  const getItems = async () => {
    setIsModalLoading(true);
    setIsDialogVisible(true);

    const remarks = await ProjectService.getRemarks({
      e2EProjectLineId: orderlineDetail.id,
      projectLineRemarksType: remarkType,
    });

    if (remarks.isSuccess) {
      setRemarksList(remarks.data);
    }
    setIsModalLoading(false);
  };
  const getHeader = (singleDisplay: boolean = false) => {
    if (remarkType == RemarksTypesEnum.ULRemarks) {
      return singleDisplay ? "UL Remark" : "UL Remarks";
    }
    return singleDisplay ? "Customer Remark" : "Customer Remarks";
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleAdd = async (requestModel: any) => {
    setIsModalLoading(true);
    const result = await ProjectService.addRemarks(requestModel);

    if (result.isSuccess) {
      showSuccess(
        `${getHeader(true)} Added`,
        `${getHeader(true)} has been added to <b>${
          orderlineDetail.orderLineNumber
        }</b>`
      );
    }
    setRemarkDisplay(requestModel.value);
    await getItems();
    setIsModalLoading(false);
  };

  return (
    <>
      <div className={classes["container"]} onClick={handleClick}>
        <FontAwesomeIcon
          icon={faCommenting}
          title={"Add " + getHeader()}
          className={classes["solid"]}
        />
        {remarkDisplay}
      </div>

      <RemarksHistoryDialog
        header={getHeader()}
        isOpen={isDialogVisible}
        e2eProjectLineId={orderlineDetail.id}
        onAdd={handleAdd}
        isModalLoading={isModalLoading}
        remarkType={remarkType}
        list={remarksList}
        closeDialog={handleDialogClose}
      />
    </>
  );
}
