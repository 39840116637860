import {
  faEdit,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { get, uniqBy } from "lodash";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColumnResizer from "../../components/column-resizer/ColumnResizer";
import axios from "axios";
import {
  hasSelectedE2EProjectId,
  resetInitialProjectLines,
  resetProjectLines,
  selectApplicant,
  selectInitialE2EProjectLines,
  selectProjectLines,
  selectSelectFavoritesModel,
  updateApplicant,
  updateE2EProjectId,
  updateE2EProjectList,
  updateE2EProjectName,
  updateFavoritesModel,
  updateInitialE2EProjectLines,
  updateProductOwner,
  updateProjectLines,
} from "../../features/projectWorkQueue/projectWorkQueueSlice";
import usePrimeReactDataTable from "../../hooks/usePrimeReactDataTable";
import usePwqLazyLoadDataTable from "../../hooks/usePwqLazyLoadDataTable";
import { useSignalR } from "../../hooks/useSignalR";
import useToastr from "../../hooks/useToastr";
import { E2EUserModel } from "../../models/E2EUserModel";
import { AssignFlexHandlerModel } from "../../models/flex/AssignFlexHandler.model.";
import {
  AssignFlexHandlerServiceBusEntry,
  AssignFlexHandlerStatus,
} from "../../models/flex/AssignFlexHandlerStatus.model";
import { ProjectWorkQueueRefinersModel } from "../../models/project-work-queue/ProjectWorkQueueRefiners.model";
import FlexIntegrationService from "../../services/FlexIntegrationService";
import ProjectService from "../../services/ProjectService";
import ReferenceService from "../../services/ReferenceService";
import { formatDateWithTimezone } from "../../shared/date-helper/DateHelper";
import FavoriteTypesEnum from "../../shared/favorites/FavoriteTypesEnum";
import Favorites from "../../shared/favorites/Favorites";
import { E2EProjectLineModel } from "../../shared/models/E2EProject.model";
import { GetDeliveryPathByCatalogResultModel } from "../../shared/models/service-models/GetDeliveryPathByCatalogResult.model";
import ShowHideColumnsPrimeDataTable from "../../shared/show-hide-columns-prime-datatable/ShowHideColumnsPrimeDataTable";
import { getCsvFormatWithoutQuotes } from "../../utils/helpers/array.helpers";
import { isValidNumber } from "../../utils/helpers/object.helpers";
import {
  GridColumnModel,
  GridColumnModelList,
  pWqColumnProjectHandler,
  projectWorkQueueGridColumns,
} from "../project-management/data/project-work-queue-grid-columns";
import classes from "./ProjectWorkQueue.module.scss";
import AssignFlexHandlerCell from "./assign-flex-handler-cell/AssignFlexHandlerCell";
import AssignFlexHandlerModal from "./assign-flex-handler-modal/AssignFlexHandlerModal";
import ProjectAlreadyExistsModal from "./project-already-exists-modal/ProjectAlreadyExistsModal";
import UnsupportedFlexTemplateModal, {
  UnsupportedFlexTemplateDataProps,
} from "./unsupported-flex-template-modal/UnsupportedFlexTemplateModal";
import ProjectWorkQueueModal from "./project-work-queue-modal/ProjectWorkQueueModal";
import ProjectWorkQueueRefiners from "./project-work-queue-refiners/ProjectWorkQueueRefiners";
import * as _ from "lodash";
import { FlexUnsupportedTemplateListModel } from "../../shared/models/FlexProjectTemplateTaskDetail.model";
import ProjectCreationRestrictionModal from "./project-creation-restriction-modal/ProjectCreationRestrictionModal";
import { ProjectCreationRestrictionModel } from "./project-creation-restriction-modal/ProjectCreationRestrictionModel";
import clsx from "clsx";
import ActionsColumnBody from "./actions-column-body/ActionsColumnBody";
import { GetPwqProjectLineDisplayModel } from "../../models/project-work-queue/GetPwqProjectLineDisplayModel.model";
import ProjectWorkQueueService from "../../services/ProjectWorkQueueService";

const ProjectWorkQueue = () => {
  var dispatch = useDispatch();
  const clv = useRef<DataTable>(null);
  const e2eProjectLines = useSelector(selectProjectLines);
  const initialProjectLines = useSelector(selectInitialE2EProjectLines);
  const e2eApplicant = useSelector(selectApplicant);
  const selectFavoritesModel = useSelector(selectSelectFavoritesModel);
  const [favoritTypeId, setFavoriteTypeId] = useState(
    FavoriteTypesEnum.ProjectWorkQueuePmr
  );
  const [pwqFrozenCount, setpwqFrozenCount] = useState(1);
  const [columns, setColumns] = useState(projectWorkQueueGridColumns);
  const [customSearchText, setCustomSearchText] = useState("");
  const [isCreateProjectModalVisible, setCreateProjectModalVisible] =
    useState(false);
  const [certificateSchemeList, setCertificateSchemeList] = useState<any>(null);
  const [fetchProjectLineCancelToken, setFetchProjectLineCancelToken] =
    useState<any>(undefined);
  const [projectChildLines, setProjectChildLines] = useState<
    GetPwqProjectLineDisplayModel[]
  >([]);

  const [isLoadingProjectHandlers, setIsLoadingProjectHandlers] =
    useState(true);

  const [isCdpSaved, setIsCdpSaved] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const [selectedProjectServiceLineIds, setSelectedProjectServiceLineIds] =
    useState<string[]>([]);

  const [assignFlexHandlerStatuses, setAssignFlexHandlerStatuses] = useState<
    AssignFlexHandlerServiceBusEntry[]
  >([]);

  const [projectHandlers, setProjectHandlers] = useState<E2EUserModel[]>([]);

  const [showAssignFlexHandlerModal, setShowAssignFlexHandlerModal] =
    useState(false);

  const [
    showFlexUnsupportedTemplateListModal,
    setShowFlexUnsupportedTemplateListModal,
  ] = useState(false);

  const [
    isProjectAlreadyExistsModalVisible,
    setIsProjectAlreadyExistsModalVisible,
  ] = useState(false);
  const hubUrl = (process.env.REACT_APP_GMAE2E_FUNC_URL + "/api") as string;
  const { connection: signalRConnection } = useSignalR(hubUrl);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [projectIdsQueryParams, setProjectIdsQueryParams] = useState<string[]>(
    params?.projectIds?.split(",") || []
  );

  const [flexUnsupportedTemplateList, setFlexUnsupportedTemplateList] =
    useState<FlexUnsupportedTemplateListModel[]>([]);

  const [unsupportedTemplateDataProp, setUnsupportedTemplateDataProp] =
    useState<UnsupportedFlexTemplateDataProps | null>(null);

  const [
    assignFlexHandlerStatusNewMessage,
    setAssignFlexHandlerStatusNewMessage,
  ] = useState<any>();

  const { showSuccess, showError } = useToastr();
  const [isAddOrderLine, setIsAddOrderLine] = useState(false);
  const [existingProjectLine, setExistingProjectLine] = useState<string[]>([]);
  const [
    showRestrictCreationMessageModal,
    setShowRestrictCreationMessageModal,
  ] = useState<boolean>(false);
  const hasCustomQueryRefiner = !!params?.projectIds;

  const defaultRefiners = hasCustomQueryRefiner
    ? {}
    : {
        status: [
          { label: "In Progress", value: "InProgress" },
          { label: "On Hold", value: "OnHold" },
        ],
      };
  const [selectedRefiners, setSelectedRefiners] = useState(defaultRefiners);
  const [initialGlobalSearch, setInitialGlobalSearch] = useState("");
  const [initialRefiners, setInitialRefiners] = useState({});
  const [disabledRefiners, setDisabledRefiners] = useState({});
  const [projectCreationRestrictions, setProjectCreationRestrictions] =
    useState<ProjectCreationRestrictionModel>({});

  const hasSelectedProjectId = useSelector(hasSelectedE2EProjectId);
  const [addOrderLineDefaultRefiners, setAddOrderLineDefaultRefiners] =
    useState({});

  const [selectedAccountNo, setSelectedAccountNo] = useState<string>("");
  const [selectedPartySiteNumber, setPartySiteNumber] = useState<string>("");

  const initialSortField = "flexProjectCreated";
  const initialSortOrder = "desc";
  const initialItemsPerPage = 100;
  const [favoriteTooltip, setFavoriteToolTip] = useState("");

  const [isFavoriteRefinersLoaded, setIsFavoriteRefinersLoaded] =
    useState(false);
  const getProjectLineListCancelSource = useRef<any>(null);

  const { databaseKey, fixedColumnResizedFunction } = usePrimeReactDataTable(
    clv,
    columns
  );

  const {
    sortField,
    sortOrder,
    itemsPerPage,
    isLazyLoading,
    setIsLazyLoading,
    isLoading,
    setIsLoading,
    getSetPageNo,
    setTotalRecords,
    showLoadMore,
    recordsShownCount,
    setRecordsShownCount,
    loadListDebounce,
    resetScroll,
    defaultSortField,
    defaultSortOrder,
    setSortField,
    setSortOrder,
    loadedList,
    isBcpMode,
    setIsBcpMode,
    cacheStartingPage,
    setCacheStartingPage,
    cacheEndingPage,
    setCacheEndingPage,
  } = usePwqLazyLoadDataTable(
    initialItemsPerPage,
    initialSortField,
    clv,
    loadProjectChildLines,
    customSearchText,
    selectedRefiners,
    [e2eProjectLines],
    isLoadMore,
    sortProjectChildLines
  );

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on(
        "assign-flex-handler",
        setAssignFlexHandlerStatusNewMessage
      );

      signalRConnection.on(
        "assign-flex-handler-all-finished",
        handleAssignFlexHandlerAllFinished
      );

      signalRConnection.on(
        "flex-project-update-sync-success",
        handleFlexProjectUpdate
      );

      signalRConnection.on(
        "flex-project-line-update-sync-success",
        handleFlexProjectLineUpdate
      );
    }
  }, [signalRConnection]);

  useEffect(() => {
    if (assignFlexHandlerStatusNewMessage) {
      handleAssignFlexHandlerStatusUpdate(assignFlexHandlerStatusNewMessage);
    }
  }, [assignFlexHandlerStatusNewMessage]);

  useEffect(() => {
    loadProjectHandlers();
    loadCertificateSchemeData();
    dispatch(
      updateFavoritesModel({
        property: "columns",
        value: columns,
      })
    );
  }, columns);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "frozenCount",
        value: pwqFrozenCount,
      })
    );
  }, [pwqFrozenCount]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "refiner",
        value: selectedRefiners,
      })
    );
  }, [selectedRefiners]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "customSearchText",
        value: customSearchText,
      })
    );
  }, [customSearchText]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "sort",
        value: { sortField: sortField, sortOrder: sortOrder },
      })
    );
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (!isAddOrderLine) return;

    const disabledRefiner = {
      field: "flexCompanyName",
      value: [
        {
          label: `${e2eApplicant?.partySiteNumber} - ${e2eApplicant?.companyName}`,
        },
      ],
    };

    const applicantSoldToValue = {
      partyName: e2eApplicant?.companyName,
      partySiteNumber: e2eApplicant?.partySiteNumber,
    };

    const refiners = {
      status: [
        { label: "In Progress", value: "InProgress" },
        { label: "On Hold", value: "OnHold" },
      ],
      flexCompanyName: [
        {
          label: `${e2eApplicant?.partySiteNumber} - ${e2eApplicant?.companyName}`,
          value: applicantSoldToValue,
        },
      ],
    };

    const customSearchText = "";

    setIsLoadMore(false);
    setAddOrderLineDefaultRefiners(refiners);
    setCustomSearchText(customSearchText);
    setSelectedRefiners(refiners);
    setDisabledRefiners(disabledRefiner);
    loadListDebounce(true, customSearchText, refiners, sortField, sortOrder);
  }, [isAddOrderLine]);

  useEffect(() => {
    if (!isCreateProjectModalVisible && isCdpSaved) {
      loadListDebounce(
        true,
        customSearchText,
        selectedRefiners,
        sortField,
        sortOrder
      );
    }
  }, [isCreateProjectModalVisible]);

  useEffect(() => {
    if (isCreateProjectModalVisible || isAddOrderLine) {
      const handleReloadEvent = (event: any) => {
        if (
          JSON.stringify(e2eProjectLines) !==
          JSON.stringify(initialProjectLines)
        ) {
          event.preventDefault();
          event.returnValue = false;
        }
      };

      const handlePopState = (event: any) => {
        if (
          JSON.stringify(e2eProjectLines) !==
          JSON.stringify(initialProjectLines)
        ) {
          event.preventDefault();
          event.returnValue = false;
        }
      };

      window.addEventListener("beforeunload", handleReloadEvent);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("beforeunload", handleReloadEvent);
        window.removeEventListener("popstate", handleReloadEvent);
      };
    }
  }, [e2eProjectLines]);

  useEffect(() => {
    if (!isFavoriteRefinersLoaded) return;

    loadProjectChildLines(
      true,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      0,
      0
    );
  }, [isFavoriteRefinersLoaded]);

  const loadProjectHandlers = async () => {
    setIsLoadingProjectHandlers(true);
    const { data } = await FlexIntegrationService.getProjectHandlers();
    setProjectHandlers(data);
    setIsLoadingProjectHandlers(false);
  };

  const loadCertificateSchemeData = async () => {
    await ReferenceService.getCertificateSchemeList()
      .then((response) => {
        setCertificateSchemeList(response.data);

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generateFiltersFromRefiners = (refiners: any): any => {
    const refinersToBeJoin = [
      "status",
      "flexHandler",
      "orderCreator",
      "preferredFulfillmentLocation",
      "orderNumber",
      "projectNumber",
      "partySiteNumber",
    ];
    const refinersToBeStringified = ["flexCompanyName"];

    let renderedRefiners: ProjectWorkQueueRefinersModel = {};

    Object.keys(refiners).forEach((key) => {
      let refiner = refiners[key];
      // api current behavior: passing refiners with empty string will be filtered resulting to no result
      let filterValue = undefined;
      const coalescedRefiner = refiner ?? [];

      if (refinersToBeJoin.some((refinerKey) => refinerKey === key)) {
        const joinedValue = coalescedRefiner
          .map((optionValue: any) => optionValue.value ?? optionValue.label) // adding value in option will result to strings of array
          .join("|");

        if (joinedValue.trim() !== "") filterValue = joinedValue;
      } else if (
        refinersToBeStringified.some((refinerKey) => refinerKey === key) &&
        coalescedRefiner.length > 0
      ) {
        const stringifiedValue = JSON.stringify(
          coalescedRefiner.map((optionValue: any) => optionValue.value)
        );
        if (stringifiedValue.trim() !== "") filterValue = stringifiedValue;
      } else {
        const refinerKeys = Object.keys(refiner);

        const refinerWithValue = refinerKeys.filter((refinerKey) => {
          const refinerChild = refiner[refinerKey];
          return isValidNumber(refinerChild) || !!refinerChild;
        });

        if (refinerWithValue.length > 0) {
          const finalValue: any = {};
          refinerWithValue.forEach((refinerKey) => {
            const refinerChild = refiner[refinerKey];
            finalValue[refinerKey] = refinerChild;
          });
          filterValue = finalValue;
        }
      }

      renderedRefiners[key] = filterValue;
    });

    if (projectIdsQueryParams.length > 0) {
      renderedRefiners.projectIds = projectIdsQueryParams.join("|");
    }

    return renderedRefiners;
  };

  const getDateOperator = (dateType: string) => {
    switch (dateType) {
      case "DateOn":
        return "eq";
      case "OnOrBefore":
      case "DateTo":
        return "le";
      case "OnOrAfter":
      case "DateFrom":
        return "ge";
      case "PastDue":
        return "lt";
      default:
        throw new Error(`Unknown date type: ${dateType}`);
    }
  };

  const getNumericOperator = (numericType: string) => {
    if (numericType === "GreaterThan") return "gt";
    if (numericType === "LessThan") return "lt";
    if (numericType === "EqualTo") return "eq";
  };

  const handleAssignFlexHandlerAllFinished = (data: any[]) => {
    if (data?.some((x) => x.Status === "FAILED")) {
      const allFailed = !data?.some((x) => x.Status === "SUCCESS");
      const message = allFailed
        ? "Assigning of FLEX Handler to selected Order Line(s) failed. Please try again. If error persists, please contact GMA Support team."
        : "Assigning of FLEX Handler to some selected Order Line(s) failed. Please check selected records then try again. If error persists, please contact GMA Support team.";

      showError("Error", message);

      const failedProjectIds = data.map((x) => x.ProjectId);

      setAssignFlexHandlerStatus(failedProjectIds, "FAILED");
    } else {
      showSuccess(
        "Success!",
        "FLEX Handler updated for the selected record(s)."
      );
    }
  };

  const handleAssignFlexHandlerStatusUpdate = (data: any) => {
    setAssignFlexHandlerStatus(
      [data.ProjectId],
      data.Status,
      data.NewHandlerEmail
    );

    if (data.Status === "SUCCESS") {
      setProjectChildLines((curr) =>
        curr.map((x) =>
          x.flexProjectId === data.ProjectId
            ? {
                ...x,
                flexHandler: data.NewHandlerEmail,
              }
            : x
        )
      );
    }
  };

  const handleFlexProjectUpdate = (data: any) => {
    const model = JSON.parse(data);
    setProjectChildLines((lines) => {
      return lines.map((line) => {
        if (line.flexProjectId === model.flexProjectId) {
          line.flexProjectName = model.flexProjectName;
          line.flexProjectEcd = model.flexProjectEcd;
          line.flexHandler = model.flexHandler;
          line.orderBookedDate = model.orderBookedDate;
        }

        return line;
      });
    });
  };

  const handleFlexProjectLineUpdate = (data: any) => {
    const model = JSON.parse(data);

    setProjectChildLines((lines) => {
      return lines.map((line) => {
        let updatedOrderLine = _.find(model, {
          flexProjectId: line.flexProjectId,
          flexProjectLineId: line.flexProjectLineId,
        });

        if (updatedOrderLine) {
          line.orderLineEcd = updatedOrderLine.orderLineEcd;
          line.price = updatedOrderLine.price;
          line.orderLineStatus = updatedOrderLine.orderLineStatus;
        }

        return line;
      });
    });
  };

  const getQueryParams = (
    skip: number,
    pageSize: number,
    searchPhrase: string,
    refiners: any,
    sortField: string | null,
    sortOrder: number | null,
    cacheStartingPage: number,
    cacheEndingPage: number
  ) => {
    const filter = {
      refiner: generateFiltersFromRefiners(refiners || {}),
      skip: skip,
      take: pageSize,
      sort: sortField,
      sortBy: sortOrder === 1 ? "asc" : "desc",
      searchString: searchPhrase?.trim() || "",
      // cacheStartingPage: cacheStartingPage,
      // cacheEndingPage: cacheEndingPage,
      isLoadMore: projectChildLines?.length > 0,
    };

    return filter;
  };

  async function loadProjectChildLines(
    isResetPage: boolean = false,
    searchPhrase: string,
    refiners: any,
    sortField: string | null,
    sortOrder: number | null,
    cacheStartingPage: number,
    cacheEndingPage: number
  ) {
    const pageNo = getSetPageNo(isResetPage);
    if (pageNo == 1) resetScroll();
    const setLoading = pageNo == 1 ? setIsLoading : setIsLazyLoading;
    setLoading(true);

    const sorting =
      sortField != null && sortOrder != null
        ? `${sortField.replace(".", "/")} ${sortOrder === 1 ? "asc" : "desc"}`
        : "";

    const parameters = getQueryParams(
      (pageNo - 1) * itemsPerPage,
      itemsPerPage,
      searchPhrase,
      refiners,
      sortField,
      sortOrder,
      cacheStartingPage,
      cacheEndingPage
    );

    if (getProjectLineListCancelSource.current) {
      getProjectLineListCancelSource.current.cancel("Cancelled");
    }

    getProjectLineListCancelSource.current = (
      axios as any
    ).CancelToken.source();

    const response = ProjectWorkQueueService.getRefinedProjectLines(
      parameters,
      getProjectLineListCancelSource.current?.token
    );

    response.then(async (result) => {
      if (result === undefined) return;
      const list = result.records || [];
      const selectedE2eProjectLinesCount = e2eProjectLines?.length || 0;

      setIsLoadMore(result.total > 0 || list.length > 0);

      const filteredList = uniqBy(
        list,
        (item) => item.flexProjectLineId
      ).filter(
        (item) =>
          !e2eProjectLines?.some(
            (line) => line.flexProjectLineId === item.flexProjectLineId
          )
      );

      let newList =
        pageNo == 1 ? [] : JSON.parse(JSON.stringify(projectChildLines));

      newList.push(...filteredList);

      setRecordsShownCount(newList.length);
      setProjectChildLines(newList);

      let resultTotal = 0;
      resultTotal = result.total;

      //Don't count the selected orderline on cdp modal
      if (selectedE2eProjectLinesCount > 0)
        resultTotal = result.total - selectedE2eProjectLinesCount;

      setTotalRecords(resultTotal);

      if (projectIdsQueryParams?.length > 0) {
        setProjectIdsQueryParams([]);
        setSelectedProjectServiceLineIds(
          filteredList.map(
            (projectLine: any) => projectLine.projectServiceLineId
          )
        );
      } else {
        setSelectedProjectServiceLineIds((prevList) => {
          let latestSelectedRow = newList.filter((x: any) =>
            prevList.includes(x.projectServiceLineId)
          );
          return latestSelectedRow.map(
            (projectLine: any) => projectLine.projectServiceLineId
          );
        });
      }

      const projectIds = filteredList.map(
        (projectLine: any) => projectLine.projectId
      );
      loadedList();
      await loadAssignFlexHandlerStatuses(projectIds, pageNo);
      setLoading(false);
    });
  }

  async function sortProjectChildLines(
    sortingField: any = null,
    sortingOrder: any = null,
    isTriggeredByHeader: boolean = false,
    projectList?: GetPwqProjectLineDisplayModel[]
  ) {
    let sortColumn = initialSortField;
    let sortBy = !(initialSortOrder == "desc") ? 1 : -1;

    if (isTriggeredByHeader) {
      sortColumn =
        columns.find(
          (column) => column.id == (sortingField ?? initialSortField)
        )?.id || initialSortField;

      sortBy = sortingOrder ?? !(initialSortOrder == "desc");
    } else {
      sortColumn =
        columns.find((column) => column.id == (sortField ?? initialSortField))
          ?.id || initialSortField;

      sortBy = sortOrder ?? !(initialSortOrder == "desc");
    }

    if (projectList?.length) {
      if (sortColumn == "soldToPsn" || sortColumn == "shipToPsn") {
        projectList = _.orderBy(
          projectList,
          (o: any) => new Number(o[sortColumn]),
          [sortBy == 1 ? "asc" : "desc"]
        );
      } else {
        projectList = _.orderBy(
          projectList,
          [sortColumn],
          [sortBy == 1 ? "asc" : "desc"]
        );
      }
      setProjectChildLines(projectList);
    } else {
      if (sortColumn == "soldToPsn" || sortColumn == "shipToPsn") {
        setProjectChildLines((list) => {
          return _.orderBy(list, (o: any) => new Number(o[sortColumn]), [
            sortBy == 1 ? "asc" : "desc",
          ]);
        });
      } else {
        setProjectChildLines((list) => {
          return _.orderBy(list, [sortColumn], [sortBy == 1 ? "asc" : "desc"]);
        });
      }
    }
  }

  const loadAssignFlexHandlerStatuses = async (
    projectIds: string[],
    pageNo: number
  ) => {
    var { data } =
      await FlexIntegrationService.getProjectsLatestServiceBusEntries(
        projectIds
      );

    setAssignFlexHandlerStatuses((prevList) => {
      let newList = pageNo == 1 ? [] : JSON.parse(JSON.stringify(prevList));

      newList.push(...data);

      return newList;
    });
  };

  const handleFlexHandlerHeaderIconClick = () => {
    setShowAssignFlexHandlerModal(true);
  };

  const handleAssignFlexHandlerModalClose = () => {
    setShowAssignFlexHandlerModal(false);
  };

  const handleAssignFlexHandlerModalSave = (newHandlerEmail: string) => {
    setShowAssignFlexHandlerModal(false);
    handleAssignNewFlexHandler(selectedProjectIds, newHandlerEmail);
  };

  const flexHandlerHeaderTemplate = (headerText: string) => {
    return (
      <div className={classes["flex-handler-column__header"]}>
        <label>{headerText}</label>{" "}
        <button
          disabled={selectedRows.length === 0}
          onClick={handleFlexHandlerHeaderIconClick}
          style={{ all: "unset", cursor: "pointer" }}
        >
          <FontAwesomeIcon title="Assign FLEX Handler" icon={faEdit} />
        </button>
      </div>
    );
  };

  const renderProjectHandlerBody = (data: GetPwqProjectLineDisplayModel) => (
    <AssignFlexHandlerCell
      projectHandlers={projectHandlers}
      projectServiceLine={data}
      status={getAssignProjectHandlerStatus(data.flexProjectId)?.status}
      onAssignFlexHandler={(projectId, newHandlerEmail) =>
        handleAssignNewFlexHandler([projectId], newHandlerEmail)
      }
      inProgressAssignedFlexHandler={
        getAssignProjectHandlerStatus(data.flexProjectId)?.newHandlerEmail
      }
      setColumns={setColumns}
    />
  );

  const getAssignProjectHandlerStatus = (
    projectId: string
  ): AssignFlexHandlerServiceBusEntry | undefined =>
    assignFlexHandlerStatuses.find((x) => x.projectId === projectId);

  const tableColumnHeader = (col: GridColumnModel) => {
    return (
      <div>
        <span title={col.value}>{col.value}</span>

        {col.isFrozen && (
          <ColumnResizer
            field={col.id}
            colWidth={col.width}
            onColumnResize={(e: any) => {
              onColResize(e);
            }}
            datatable={clv}
          />
        )}
      </div>
    );
  };

  const formatCellValue = (displayType: string, data: any) => {
    switch (displayType) {
      case "date":
        return formatDateWithTimezone(data);

      default:
        return data;
    }
  };

  const tableColumnBody = (
    displayType: string,
    rowData: GetPwqProjectLineDisplayModel,
    col: ColumnBodyOptions
  ) => {
    return (
      <div
        title={formatCellValue(displayType, get(rowData, col.field))}
        className={"text-overflow-ellipsis"}
      >
        {formatCellValue(displayType, get(rowData, col.field))}
      </div>
    );
  };

  const renderDynamicColumns = () =>
    columns.map((col: GridColumnModelList) => (
      <Column
        columnKey={col.id}
        key={col.value}
        field={col.id}
        body={
          col.id === pWqColumnProjectHandler
            ? renderProjectHandlerBody
            : tableColumnBody.bind(col, col.displayType)
        }
        header={
          col.id === pWqColumnProjectHandler
            ? flexHandlerHeaderTemplate(col.value)
            : tableColumnHeader(col)
        }
        className={clsx(
          { [classes["last-frozen"]]: col.orderNo === pwqFrozenCount },
          classes["pwq-column"]
        )}
        frozen={col.isFrozen}
        alignFrozen="left"
        hidden={!col.isShown}
        style={{ width: col.width, flexBasis: col.width }}
        sortable={true}
      />
    ));

  const selectedRows = projectChildLines.filter((x) =>
    selectedProjectServiceLineIds.includes(x.flexProjectLineId)
  );

  const selectedLinesWithProjectCreationRestricted = selectedRows.some(
    (childLine: GetPwqProjectLineDisplayModel) =>
      childLine.hasMultipleServiceLines || childLine.hasInvalidIndustryCode
  );

  const updateColumns = (
    frozenCount: number,
    fields: GridColumnModelList[]
  ) => {
    const _fields = fields.map((f: GridColumnModelList) => {
      if (f.orderNo <= frozenCount) {
        return {
          ...f,
          isShown: true,
          isFrozen: true,
        };
      } else {
        return {
          ...f,
          isFrozen: false,
        };
      }
    });

    setColumns(_fields);
    setpwqFrozenCount(frozenCount);
  };

  const hasSelectedRows = selectedRows.length > 0;

  const isCreateE2EButtonEnabled = hasSelectedRows;

  const handleSelectionChange = (e: any) => {
    setSelectedProjectServiceLineIds(
      e.value?.map((x: GetPwqProjectLineDisplayModel) => x.flexProjectLineId) ||
        []
    );
  };

  const handleCustomSearch = (searchText: string) => {
    let newSearchText = searchText;
    if (searchText || searchText != "") {
      newSearchText = newSearchText.trim();
    }

    setCustomSearchText(newSearchText);
    loadListDebounce(
      true,
      newSearchText,
      selectedRefiners,
      sortField,
      sortOrder
    );
  };

  const checkDiff = (a: string[]) => {
    return new Set(a).size !== 1;
  };

  const handleAssignNewFlexHandler = (
    projectIds: string[],
    newHandlerEmail: string
  ) => {
    setAssignFlexHandlerStatus(projectIds, "IN PROGRESS", newHandlerEmail);

    const model: AssignFlexHandlerModel = {
      newHandlerEmail,
      projects: projectChildLines
        .filter((x) => projectIds.includes(x.flexProjectId))
        .map((x) => ({
          lineNumber: x.orderLineNumber,
          projectId: x.flexProjectId,
          projectNumber: x.flexProjectNumber,
        })),
    };

    FlexIntegrationService.assignFlexHandler(model).catch((e: AxiosError) => {
      showError("Error", e.response?.data);
      setAssignFlexHandlerStatus(projectIds, "FAILED", newHandlerEmail);
    });
  };

  const setAssignFlexHandlerStatus = (
    projectIds: string[],
    status: AssignFlexHandlerStatus,
    newHandlerEmail?: string
  ) => {
    setAssignFlexHandlerStatuses((curr) => {
      const retainStatuses = curr.filter(
        (f) => !projectIds.includes(f.projectId)
      );

      const newStatuses = projectIds.map(
        (p) =>
          ({
            projectId: p,
            status,
            newHandlerEmail,
          } as AssignFlexHandlerServiceBusEntry)
      );

      return [...retainStatuses, ...newStatuses];
    });
  };

  const hasSelectedRowsWithDifferentApplicants =
    selectedRows.length > 1 &&
    checkDiff(selectedRows.map((s: any) => s.soldToPsn));

  const selectedProjectIds = selectedRows.map((x) => x.flexProjectId);

  const handleSelectedRefinersChange = (refiners: any) => {
    setSelectedRefiners(refiners);
    loadListDebounce(true, customSearchText, refiners, sortField, sortOrder);
  };

  const isDataArrayNotNullWithData = (data: any): boolean => {
    return data && Array.isArray(data) && data.length > 0;
  };

  const getDeliveryPathListByCatalog = async (
    projectLines: E2EProjectLineModel[]
  ): Promise<E2EProjectLineModel[]> => {
    const isWireless = selectedRows.find((f) =>
      f.serviceCategoryDescription?.toLocaleLowerCase().includes("wireless")
    );
    const servicePrograms = isWireless
      ? selectedRows.map((m) => m.serviceProgram)
      : selectedRows.map((m) => m.serviceCatalogItemNo);
    const serviceProgramsCsv = getCsvFormatWithoutQuotes(servicePrograms);

    await ReferenceService.getDeliveryPathByCatalog(serviceProgramsCsv)
      .then((response) => {
        if (response) {
          if (response.data) {
            const catalogs =
              response.data as GetDeliveryPathByCatalogResultModel[];

            if (isDataArrayNotNullWithData(response.data)) {
              projectLines.forEach((rowData) => {
                const catalog = catalogs.find(
                  (f) => f.itemNumber == rowData.serviceCatalogItemNo
                );

                if (catalog) {
                  if ((catalog.certificateSchemeList?.length ?? 0) > 0) {
                    rowData.isShowAllScheme = false;
                    rowData.isShowAllSchemeDisabled = false;

                    rowData.initialCertificateSchemeList =
                      catalog.certificateSchemeList;
                    rowData.certificateSchemeList =
                      catalog.certificateSchemeList;
                    rowData.schemeId = catalog.certificateSchemeList?.find(
                      (f) => f.selected
                    )?.id;
                    rowData.schemeName = catalog.certificateSchemeList?.find(
                      (f) => f.selected
                    )?.value;
                  }

                  rowData.applicationTypeList = catalog.applicationTypeList;
                  rowData.applicationTypeId = catalog.applicationTypeList?.find(
                    (f) => f.selected
                  )?.id;
                  rowData.deliveryPathList = catalog.deliveryPathList;
                  rowData.deliveryPathId = catalog.deliveryPathList?.find(
                    (f) => f.selected
                  )?.id;
                }
              });
            } else {
            }
          } else {
            //TODO: show error message
          }
        } else {
          //TODO: show error message
        }

        return projectLines;
      })
      .catch((error) => {
        console.error(error);
      });

    return projectLines;
  };

  const getProjectListByCustomer = async (
    accountNo?: string | null,
    partySiteNumber?: string | null
  ) => {
    const fetchedExistingProjects =
      await ProjectService.getProjectListByCustomer(accountNo, partySiteNumber);

    dispatch(updateE2EProjectList(fetchedExistingProjects.data));
    setSelectedAccountNo(accountNo?.toString() ?? "");
    setPartySiteNumber(partySiteNumber?.toString() ?? "");

    return fetchedExistingProjects.data;
  };

  const handleCreateE2EProject = async () => {
    setIsLoading(true);

    let lines =
      selectedRows.map((x: GetPwqProjectLineDisplayModel) => {
        return {
          e2EProjectTemplateId: null,
          flexProjectId: x.flexProjectId,
          flexProjectLineId: x.flexProjectLineId,
          isShowAllScheme: true,
          isShowAllSchemeDisabled: true,
          schemeId: null,
          schemeName: null,
          deliveryPathId: null,
          applicationTypeId: null,
          additionalInfoId: null,
          leadTypeId: 1,
          leadTime: 0,
          isFirstChild: false,
          isFlexWritebackFailed: false,
          e2EProject: null,
          e2EProjectLineTechnologies: null,
          e2EProjectLineModelInfo: null,
          segment: null,
          technologies: [] as any,
          initialCertificateSchemeList: [] as any,
          certificateSchemeList: certificateSchemeList,
          applicationTypeList: [] as any,
          additionalInfoList: [] as any,
          deliveryPathList: [] as any,
          e2eTemplateList: [] as any,
          basemodel: null,
          modelVariants: [] as any,
          isSelectable: true,
          externalId: x.externalId,
          orderLineNumberDescription: x.orderLineNumberDescription,
          serviceCatalogItemNo: x.serviceCatalogItemNo,
          flexProjectNumber: x.flexProjectNumber,
          flexProjectTemplateName: x.flexProjectTemplateName,
          flexProjectName: x.flexProjectName,
          orderNumber: x.orderNumber,
          orderLineDescription: x.orderLineNumberDescription,
        } as E2EProjectLineModel;
      }) || [];

    if (lines.length > 1) {
      const baseLines = [
        {
          flexProjectLineId: "0",
          segment: "Wireless",
          certificateSchemeList: certificateSchemeList,
          isSelectable: false,
        } as E2EProjectLineModel,
      ];

      lines = [...baseLines, ...lines];
    }

    const firstRow = selectedRows.find((f) => f.flexProjectLineId !== "0");
    const accountNo = firstRow?.soldToAccountNumber;

    const applicant = {
      companyName: firstRow?.soldToCompanyName,
      partySiteNumber: firstRow?.soldToPsn,
    };

    const productOwner = {
      companyName: firstRow?.shipToCompanyName,
      partySiteNumber: firstRow?.shipToPsn,
    };

    let projectLines = await getDeliveryPathListByCatalog(lines);

    projectLines = await getFlexProjectTemplate(projectLines);

    const existingProjects = await getProjectListByCustomer(
      accountNo,
      firstRow?.soldToPsn as string
    );

    dispatch(updateApplicant(applicant));
    dispatch(updateProductOwner(productOwner));
    dispatch(updateInitialE2EProjectLines(projectLines));
    dispatch(updateProjectLines(projectLines));
    setInitialRefiners(selectedRefiners);
    setInitialGlobalSearch(customSearchText);
    setExistingProjectLine(
      existingProjectLine.concat(selectedRows.map((x) => x.externalId))
    );
    setIsLoading(false);

    if (flexUnsupportedTemplateList.length) {
      setShowFlexUnsupportedTemplateListModal(true);

      let unsupportedFlexTemplateDataProps: UnsupportedFlexTemplateDataProps = {
        type: "isCreateE2EProject",
        isProceedBtnDisabled:
          flexUnsupportedTemplateList.length == selectedRows.length,
        companyName: applicant.companyName as string,
        hasExistingProjects: existingProjects?.length > 0,
      };

      setUnsupportedTemplateDataProp(unsupportedFlexTemplateDataProps);
      return;
    }

    const hasExistingProjects = existingProjects?.length > 0;

    if (hasExistingProjects) {
      setIsProjectAlreadyExistsModalVisible(true);
    } else {
      dispatch(updateE2EProjectId(null));

      dispatch(
        updateE2EProjectName(
          ProjectService.constructProjectName(applicant.companyName as string)
        )
      );

      setCreateProjectModalVisible(true);
    }
  };

  const getFlexProjectTemplate = async (
    projectLines: E2EProjectLineModel[]
  ): Promise<E2EProjectLineModel[]> => {
    let projectIds: string[] = projectLines
      .map((m) => m.flexProjectId ?? "")
      .filter((id) => id !== null && id !== undefined && id !== "");

    setFlexUnsupportedTemplateList([]);
    setUnsupportedTemplateDataProp(null);

    await FlexIntegrationService.getFlexTemplateByFlexProjectIds(projectIds)
      .then((response) => {
        if (response.isSuccess) {
          const flexProjectTemplates = response.data;

          projectLines.forEach((rowData) => {
            if (rowData.flexProjectId) {
              const flexProjectTemplate = flexProjectTemplates.find(
                (f) => f.flexProjectId === rowData.flexProjectId
              );

              if (flexProjectTemplate?.isSupported) {
                rowData.flexProjectTemplateName =
                  flexProjectTemplate?.projectTemplateName;
              } else {
                let unsupportedTemplate: FlexUnsupportedTemplateListModel = {
                  externalId: rowData.externalId,
                  projectNumber: rowData.flexProjectNumber,
                  orderLineNumber:
                    rowData.orderLineNumberDescription?.toString() ?? "",
                  flexTemplate: flexProjectTemplate?.projectTemplateName,
                };

                flexUnsupportedTemplateList.push(unsupportedTemplate);
                setFlexUnsupportedTemplateList(flexUnsupportedTemplateList);
              }
            }
          });

          return projectLines;
        }
      })
      .catch((error) => console.log(error));

    return projectLines;
  };

  const handleSort = (event: DataTablePFSEvent) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    loadProjectChildLines(
      true,
      customSearchText,
      selectedRefiners,
      event.sortField,
      event.sortOrder ?? null,
      0,
      0
    );
  };

  const handleOnConfirmUnsupportedOrderLines = async (
    resp: UnsupportedFlexTemplateDataProps | null
  ) => {
    setIsLoading(true);

    let unsupportedProjectLines = flexUnsupportedTemplateList.map(
      (m) => m.externalId
    );

    let projectLines = e2eProjectLines?.filter(
      (f) => !unsupportedProjectLines.includes(f.externalId)
    );

    dispatch(updateProjectLines(projectLines));

    if (resp?.type == "isCreateE2EProject") {
      setIsLoading(false);

      if (resp.hasExistingProjects) {
        setIsProjectAlreadyExistsModalVisible(true);
      } else {
        dispatch(updateE2EProjectId(null));

        dispatch(
          updateE2EProjectName(
            ProjectService.constructProjectName(resp?.companyName ?? "")
          )
        );

        setCreateProjectModalVisible(true);
      }
    } else {
      setIsAddOrderLine(false);
      setCreateProjectModalVisible(true);
      setIsLoading(false);
    }

    setShowFlexUnsupportedTemplateListModal(false);
  };

  const handleCloseCreateE2EProjectModal = () => {
    dispatch(resetInitialProjectLines());
    dispatch(resetProjectLines());
    setSelectedRefiners(initialRefiners);
    setCustomSearchText(initialGlobalSearch);
    setDisabledRefiners({});
    setCreateProjectModalVisible(false);
    setFlexUnsupportedTemplateList([]);
    setUnsupportedTemplateDataProp(null);
  };

  const handleProjectAlreadyExistsModalProceed = (
    projectId: string,
    projectName: string
  ) => {
    dispatch(
      updateE2EProjectName(
        projectId === "0"
          ? ProjectService.constructProjectName(e2eApplicant.companyName)
          : projectName
      )
    );

    dispatch(updateE2EProjectId(projectId));

    setIsProjectAlreadyExistsModalVisible(false);
    setCreateProjectModalVisible(true);
  };

  const handleProjectAlreadyExistsModalCancel = () => {
    setIsProjectAlreadyExistsModalVisible(false);
  };

  const handleAddOrderLine = async (lines: E2EProjectLineModel[]) => {
    dispatch(updateProjectLines(lines));
    setIsAddOrderLine(true);
    setCreateProjectModalVisible(false);
  };

  const handleCancelAddOrderLine = () => {
    setIsAddOrderLine(false);
    setCreateProjectModalVisible(true);
  };

  const handleAddToGMAWBProject = async () => {
    setIsLoading(true);

    let currentProjectLines = e2eProjectLines;

    let lines =
      selectedRows.map((x: GetPwqProjectLineDisplayModel) => {
        return {
          e2EProjectTemplateId: null,
          flexProjectId: x.flexProjectId,
          flexProjectLineId: x.flexProjectLineId,
          isShowAllScheme: true,
          isShowAllSchemeDisabled: true,
          schemeId: null,
          schemeName: null,
          deliveryPathId: null,
          applicationTypeId: null,
          additionalInfoId: null,
          leadTypeId: 1,
          leadTime: 0,
          isFirstChild: false,
          isFlexWritebackFailed: false,
          e2EProject: null,
          e2EProjectLineTechnologies: null,
          e2EProjectLineModelInfo: null,
          segment: null,
          technologies: [] as any,
          initialCertificateSchemeList: [] as any,
          certificateSchemeList: certificateSchemeList,
          applicationTypeList: [] as any,
          additionalInfoList: [] as any,
          deliveryPathList: [] as any,
          e2eTemplateList: [] as any,
          basemodel: null,
          modelVariants: [] as any,
          isSelectable: true,
          externalId: x.externalId,
          orderLineNumberDescription: x.orderLineNumberDescription,
          serviceCatalogItemNo: x.serviceCatalogItemNo,
          flexProjectNumber: x.flexProjectNumber,
          flexProjectTemplateName: x.flexProjectTemplateName,
          flexProjectName: x.flexProjectName,
          orderNumber: x.orderNumber,
          orderLineDescription: x.orderLineNumberDescription,
        } as E2EProjectLineModel;
      }) || [];

    if (!!currentProjectLines && currentProjectLines.length == 1) {
      const baseLines = [
        {
          flexProjectLineId: "0",
          segment: "Wireless",
          certificateSchemeList: certificateSchemeList,
          isSelectable: false,
        } as E2EProjectLineModel,
      ];

      currentProjectLines = [...baseLines, ...currentProjectLines];
    }

    let projectLines = await getDeliveryPathListByCatalog(lines);

    projectLines = await getFlexProjectTemplate(projectLines);

    currentProjectLines = currentProjectLines?.map((line) => ({
      ...line,
      isRecentlyAdded: false,
    }));

    dispatch(updateProjectLines(currentProjectLines?.concat(projectLines)));
    setExistingProjectLine(
      existingProjectLine.concat(selectedRows.map((x) => x.externalId))
    );

    if (flexUnsupportedTemplateList.length) {
      setShowFlexUnsupportedTemplateListModal(true);

      let unsupportedFlexTemplateDataProps: UnsupportedFlexTemplateDataProps = {
        type: "isAddToGMAWBProject",
        isProceedBtnDisabled:
          flexUnsupportedTemplateList.length == selectedRows.length,
      };

      setUnsupportedTemplateDataProp(unsupportedFlexTemplateDataProps);
      return;
    }

    setIsAddOrderLine(false);
    setCreateProjectModalVisible(true);
    setIsLoading(false);
  };

  const handleLoadMore = () => {
    loadProjectChildLines(
      false,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      cacheStartingPage,
      cacheEndingPage
    );
  };

  const onColReorder = (e: any) => {
    if (
      e.dropIndex === 0 ||
      e.dropIndex <= pwqFrozenCount ||
      e.dragIndex <= pwqFrozenCount ||
      e.dropIndex === columns.length + 1
    ) {
      clv.current?.reset();
      return;
    }

    const pwqColumns = columns.map((col, i) => {
      const orderNo =
        e.columns.findIndex((fi: any) => fi.props.field === col.id) - 1;

      return {
        ...col,
        orderNo,
      };
    });

    pwqColumns.sort(function (a, b) {
      return a.orderNo - b.orderNo;
    });

    updateColumns(pwqFrozenCount, pwqColumns);
  };

  const onColResize = (e: any) => {
    const newColumns = columns.map((col, i) => {
      if (e.column.props.field !== col.id || col.isFrozen) return col;

      return {
        ...col,
        width: e.element.offsetWidth,
      };
    });
    updateColumns(pwqFrozenCount, newColumns);
  };

  const handleFavoriteSelect = (props: any) => {
    setSelectedRefiners(props["refiner"]);

    setColumns(
      projectWorkQueueGridColumns.map((column) => {
        const favConfig = structuredClone(
          props["columns"] as GridColumnModelList[]
        ).find((fav) => fav.id === column.id);

        if (favConfig) {
          favConfig.value = column.value;
          return favConfig;
        }

        return column;
      })
    );

    setCustomSearchText(props["customSearchText"]);
    setpwqFrozenCount(props["frozenCount"]);
    const sortField = props["sort"]?.sortField || defaultSortField;
    const sortOrder = props["sort"]?.sortOrder || defaultSortOrder;
    setSortField(sortField);
    setSortOrder(sortOrder);
    loadListDebounce(
      true,
      props["customSearchText"],
      props["refiner"],
      sortField,
      sortOrder
    );
  };

  const handleOnSetToDefaultFilter = () => {
    setSelectedRefiners(defaultRefiners);
    setColumns(projectWorkQueueGridColumns);
    setCustomSearchText("");
    setpwqFrozenCount(8);
    loadListDebounce(
      true,
      "",
      defaultRefiners,
      defaultSortField,
      defaultSortOrder
    );
  };

  const handleFavoriteInit = (props: any) => {
    if (props) {
      !hasCustomQueryRefiner && setSelectedRefiners(props["refiner"]);
      setColumns(props["columns"]);
      setCustomSearchText(props["customSearchText"]);
      setpwqFrozenCount(props["frozenCount"]);
      const sortField = props["sort"]?.sortField || defaultSortField;
      const sortOrder = props["sort"]?.sortOrder || defaultSortOrder;
      setSortField(sortField);
      setSortOrder(sortOrder);
    }

    setIsFavoriteRefinersLoaded(true);
  };

  const onProjectCreationWarningIconClick = (
    data: GetPwqProjectLineDisplayModel
  ) => {
    setProjectCreationRestrictions({
      hasInvalidIndustryCode: data.hasInvalidIndustryCode,
      hasMultipleServiceLines: data.hasMultipleServiceLines,
    });

    setShowRestrictCreationMessageModal(true);
  };

  const onProjectCreationWarningIconClose = () => {
    setProjectCreationRestrictions({});
    setShowRestrictCreationMessageModal(false);
  };

  const datatableFooter = () => (
    <>
      {
        <div>
          {showLoadMore() && !isLoading && (
            <>
              <span
                className={`${classes["load-more"]} ${
                  isLazyLoading && classes["disabled"]
                }`}
                onClick={() => !isLazyLoading && handleLoadMore()}
              >
                Load More
              </span>
              {isLazyLoading && (
                <span>
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                </span>
              )}
            </>
          )}
          {!showLoadMore() && recordsShownCount != 0 && (
            <span className={classes["end-of-list"]}>
              - All records has been loaded -
            </span>
          )}
        </div>
      }
    </>
  );

  return (
    <>
      <div className={classes["container"]}>
        <ProjectWorkQueueRefiners
          customSearchText={customSearchText}
          onCustomSearchTextChange={setCustomSearchText}
          onCustomSearch={handleCustomSearch}
          selectedRefiners={selectedRefiners}
          onSelectedRefinersChange={handleSelectedRefinersChange}
          disabledRefiners={disabledRefiners}
          addOrderLineDefaultRefiners={addOrderLineDefaultRefiners}
        />
        <div className={classes["right"]}>
          <div className={classes["table-container"]}>
            <div className={classes["table__header"]}>
              <div className={classes["header-record__container"]}>
                <div className={classes["header-record-shown"]}>
                  ({recordsShownCount || 0}) Records Shown
                </div>
                <span style={{ marginLeft: "0.25rem" }}>
                  | Click
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{
                      color: "#f7a80f",
                      marginLeft: "0.25rem",
                      marginRight: "0.25rem",
                    }}
                  ></FontAwesomeIcon>
                  for details.
                </span>
              </div>
              <div className={classes["header-button-container"]}>
                <div
                  title={favoriteTooltip ? favoriteTooltip : "Favorites"}
                  className={classes["table__header__actions"]}
                >
                  <Favorites
                    favoriteTypesId={favoritTypeId}
                    onFavoriteSelect={handleFavoriteSelect}
                    jsonModel={selectFavoritesModel}
                    onFavoriteInit={handleFavoriteInit}
                    onSetToDefaultFilter={handleOnSetToDefaultFilter}
                    setFavoriteToolTip={setFavoriteToolTip}
                    isDisabled={isAddOrderLine}
                  />

                  <ShowHideColumnsPrimeDataTable
                    fields={columns}
                    frozenColumnCount={pwqFrozenCount}
                    updateColumns={updateColumns}
                    setColumns={setColumns}
                    maxFrozenColumnCount={8}
                    hasShowAndFreezeSelection={true}
                  />
                </div>
              </div>
            </div>

            <div className="pwq-table-container">
              <DataTable
                key={databaseKey}
                ref={clv}
                className={`${classes["table"]} ${
                  projectChildLines.length === 0 ? classes["no-data"] : ""
                }`}
                reorderableColumns
                scrollable
                scrollDirection="both"
                value={projectChildLines}
                columnResizeMode="expand"
                responsiveLayout="scroll"
                loading={isLoading}
                selection={selectedRows}
                onSelectionChange={handleSelectionChange}
                selectionMode="checkbox"
                showGridlines
                onColReorder={onColReorder}
                resizableColumns
                onColumnResizeEnd={onColResize}
                onSort={(event: DataTablePFSEvent) => handleSort(event)}
                sortField={sortField}
                sortOrder={sortOrder}
                footer={
                  recordsShownCount || recordsShownCount > 0
                    ? datatableFooter
                    : null
                }
                lazy
                emptyMessage={"--- No records found. ---"}
                removableSort
                size="small"
              >
                <Column
                  reorderable={false}
                  selectionMode="multiple"
                  alignFrozen="left"
                  frozen
                  bodyStyle={{ borderRight: "none", flex: "none" }}
                  headerStyle={{ borderRight: "none", flex: "none" }}
                  style={{ maxWidth: "40px", flex: "none" }}
                ></Column>
                <Column
                  reorderable={false}
                  field=""
                  header=""
                  alignFrozen="left"
                  frozen
                  body={(data: GetPwqProjectLineDisplayModel) => {
                    if (
                      data?.hasMultipleServiceLines ||
                      data?.hasInvalidIndustryCode
                    ) {
                      return (
                        <FontAwesomeIcon
                          icon={faWarning}
                          style={{
                            color: "#f7a80f",
                            marginLeft: "0.25rem",
                            marginRight: "0.25rem",
                            paddingTop: "0.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            onProjectCreationWarningIconClick(data);
                          }}
                        ></FontAwesomeIcon>
                      );
                    }

                    return <></>;
                  }}
                  style={{
                    maxWidth: "40px",
                    width: "40px",
                    borderLeft: "none",
                    flex: "none",
                  }}
                  headerStyle={{ borderLeft: "none", flex: "none" }}
                  bodyStyle={{ flex: "none" }}
                ></Column>
                {renderDynamicColumns()}
                <Column
                  columnKey="ellipsis-column"
                  frozen
                  alignFrozen="right"
                  body={(rowData) => <ActionsColumnBody rowData={rowData} />}
                />
              </DataTable>
            </div>
          </div>
          <div className={classes["right__footer"]}>
            {!isAddOrderLine ? (
              <Button
                disabled={
                  !isCreateE2EButtonEnabled ||
                  hasSelectedRowsWithDifferentApplicants ||
                  isLoading ||
                  selectedLinesWithProjectCreationRestricted
                }
                className={classes["btn-create-e2e"]}
                onClick={handleCreateE2EProject}
              >
                CREATE GMAWB PROJECT(S)
              </Button>
            ) : (
              <>
                <Button
                  className={classes["btn-cancel"]}
                  onClick={handleCancelAddOrderLine}
                >
                  CANCEL
                </Button>
                <Button
                  className={classes["btn-add-order-line"]}
                  disabled={
                    !isCreateE2EButtonEnabled ||
                    hasSelectedRowsWithDifferentApplicants ||
                    isLoading ||
                    selectedLinesWithProjectCreationRestricted
                  }
                  onClick={handleAddToGMAWBProject}
                  label="ADD TO GMAWB PROJECT"
                ></Button>
              </>
            )}

            {hasSelectedRows && !hasSelectedRowsWithDifferentApplicants && (
              <label className={classes["selected-rows-info"]}>
                {selectedRows.length} line/s selected
              </label>
            )}

            {hasSelectedRowsWithDifferentApplicants && (
              <label className={classes["error"]}>
                Cannot merge Order Lines with different FLEX Sold To (Party Site
                Number)
              </label>
            )}
          </div>
        </div>
      </div>

      <AssignFlexHandlerModal
        onSaveNewFlexHandler={handleAssignFlexHandlerModalSave}
        projectHandlers={projectHandlers}
        onHide={handleAssignFlexHandlerModalClose}
        visible={showAssignFlexHandlerModal}
      />

      {isProjectAlreadyExistsModalVisible && (
        <ProjectAlreadyExistsModal
          visible={isProjectAlreadyExistsModalVisible}
          onCancel={handleProjectAlreadyExistsModalCancel}
          onProceed={handleProjectAlreadyExistsModalProceed}
          accountNo={selectedAccountNo}
          partySiteNumber={selectedPartySiteNumber}
        />
      )}

      <ProjectWorkQueueModal
        isExistingGMAWBProject={hasSelectedProjectId}
        visible={isCreateProjectModalVisible}
        closeModal={handleCloseCreateE2EProjectModal}
        certificateSchemeList={certificateSchemeList}
        handleAddOrderLine={handleAddOrderLine}
        setIsCdpSaved={setIsCdpSaved}
      />

      <UnsupportedFlexTemplateModal
        visible={showFlexUnsupportedTemplateListModal}
        unsupportedOrderLines={flexUnsupportedTemplateList}
        onConfirm={handleOnConfirmUnsupportedOrderLines}
        onCancel={() => {
          setShowFlexUnsupportedTemplateListModal(false);
          setFlexUnsupportedTemplateList([]);
          setUnsupportedTemplateDataProp(null);
        }}
        dataProp={unsupportedTemplateDataProp}
      />

      <ProjectCreationRestrictionModal
        onClose={onProjectCreationWarningIconClose}
        visible={showRestrictCreationMessageModal}
        projectCreationRestrictionModel={projectCreationRestrictions}
      />
    </>
  );
};

export default ProjectWorkQueue;
