import { Dialog, DialogProps } from "primereact/dialog";
import classes from "./ULModal.module.scss";

export interface ULModalProps extends DialogProps {
  children?: any;
}
export default function ULModal(props: ULModalProps) {
  return (
    <Dialog {...props} className={classes["ul-modal-wrapper"]}>
      {props.children}
    </Dialog>
  );
}
