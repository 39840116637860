import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./InvoiceWarningIcon.module.scss";

interface InvoiceWarningIconProps {
  onClick?: () => void;
  title?: string;
}
export default function InvoiceWarningIcon({
  onClick,
  title,
}: InvoiceWarningIconProps) {
  return (
    <>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className={classes["custom-icon"]}
        onClick={onClick}
        title={title}
      />
    </>
  );
}
