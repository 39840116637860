import { useEffect, useRef, useState } from "react";
import classes from "./ProjectListPmr.module.scss";
import {
  GridColumnModel,
  actionsColumnTab,
  projectColumnCheckbox,
  projectColumnCollaborator,
  projectColumnCreatedDateUtc,
  projectColumnExpand,
  projectColumnStatus,
} from "../data/project-grid-columns";
import { Column, ColumnBodyOptions } from "primereact/column";
import { Dictionary, get, groupBy, orderBy } from "lodash";
import ProjectService from "../../../services/ProjectService";
import {
  DataTable,
  DataTableColReorderParams,
  DataTableColumnResizeEndParams,
  DataTablePFSEvent,
  DataTableRowExpansionTemplate,
} from "primereact/datatable";
import Pagination from "../../../components/pagination/Pagination";
import { PaginatedRequestModel } from "../../../shared/models/service-models/PaginatedRequest.model";
import {
  findSortKey,
  getSortOrder,
} from "../../../shared/datatable-helper/datatable-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faUpRightFromSquare,
  faArrowsRotate,
  faPencil,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import ProjectListDetailTab from "../project-list-detail-tab/ProjectListDetailTab";
import ProjectStatusTemplate from "../shared/components/project-status-template/ProjectStatusTemplate";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import {
  selectIsRefinerSearching,
  selectRefinerState,
  selectShowFlexTasks,
  selectShowHideColumnProjects,
  selectShowHideColumnProjectsProject,
  selectIsTimestampConfirmationLoading,
  updateGlobalSearchTarget,
  updateShowFlexTasks,
  updateShowHideColumnByTable,
  selectRequireInvoicePrices,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import PmrE2EProjectModel from "../shared/interfaces/pmr-e2e-project-model";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExpandedRowsProjectList,
  selectForceReloadProject,
  selectRefinerLineResult,
  selectSelectedMilestones,
  selectSelectedTaskOrderlines,
  selectedProjectManagementViewProjects,
  setExpandedRowsProjectList,
  toggleRowExpansionProjectList,
  unselectAllMilestones,
  unselectAllTaskOrderlines,
  updateFavoritesModel,
  updateForceReloadProject,
  updateProjectManagementViewProjects,
  updateRefreshProjectId,
  updateSelectedMilestones,
  updateSelectedTaskOrderlines,
} from "../../../features/projectManagement/projectManagementSlice";
import useDebounce from "../../../hooks/useDebounce";
import { transformOptionLabelValueToValue } from "../../../utils/helpers/object.helpers";
import clsx from "clsx";
import * as axios from "axios";
import moment from "moment";
import { E2EProjectLineFlexTaskGroupItemModel } from "../shared/interfaces/pmr-e2e-project-task-group-item-model";
import { E2EProjectLineMilestoneGroupItemModel } from "../shared/interfaces/pmr-e2e-project-milestone-group-item-model";
import ProjectDeleteModal from "./project-delete-modal/ProjectDeleteModal";
import useToastr from "../../../hooks/useToastr";
import { ResultModel } from "../../../models/result.model";
import { HttpStatus } from "../../../utils/constants/http-status.constants";
import ProjectStatus from "../shared/enums/project-status";
import ProjectWorkQueueModal from "../../project-work-queue/project-work-queue-modal/ProjectWorkQueueModal";
import ReferenceService from "../../../services/ReferenceService";
import {
  resetInitialProjectLines,
  resetProjectLines,
  updateApplicant,
  updateE2EProjectId,
  updateE2EProjectName,
  updateInitialE2EProjectLines,
  updateOriginalProjectName,
  updateProductOwner,
  updateProjectLines,
} from "../../../features/projectWorkQueue/projectWorkQueueSlice";
import { selectSelectFavoritesModel } from "../../../features/projectManagement/projectManagementSlice";
import { E2EProjectLineModel } from "../../../shared/models/E2EProject.model";
import FlexIntegrationService from "../../../services/FlexIntegrationService";
import { E2EProjectModelVariantModel } from "../../../shared/models/E2EProjectModelVariant.model";
import ProjectOrderlineStatus from "../shared/enums/project-orderline-status";
import usePmrShiftSelect from "../../../hooks/usePmrShiftSelect";
import { ProjectTasksMilestoneSteps } from "../data-mock/project-task-milestone-steps";
import { projectFullViewMessageKey } from "../project-full-view/ProjectFullView";
import { Button } from "primereact/button";
import {
  CARRY_OVER_CONFIGURATION_ON_NEW_TAB,
  DEFAULT_REFINERS,
  SELECTION_COUNTER,
} from "../../../utils/constants/feature-flag.constants";
import { PmrE2EProjectFullViewModel } from "../shared/interfaces/pmr-e2e-project-full-view-model";
import { ShowHideColumnsMultipleDataTableModel } from "../../../shared/show-hide-columns-multiple-prime-datatable/interface/ShowHideColumnsMultipleDataTableModel";
import { ProjectShowHideColumnsEnum } from "../shared/enums/project-show-hide-columns";
import { ProjectFullViewRequestModel } from "../../../shared/models/ProjectFullViewRequestModel";
import ProjectTaskStatus from "../shared/enums/project-task-status";
import ProjectListSelectionCounter from "./project-list-selection-counter/ProjectListSelectionCounter";
import InvoiceWarningIcon from "../shared/components/invoice-warning-icon/InvoiceWarningIcon";
import { RequireInvoicePrice } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import InvoiceWarningModal from "../shared/components/invoice-warning-modal/InvoiceWarningModal";

export interface IProjectsHelperModel {
  allFlexTaskGroupItems: E2EProjectLineFlexTaskGroupItemModel[];
  allMilestoneGroupItems: E2EProjectLineMilestoneGroupItemModel[];
  records: PmrE2EProjectModel[];
  hasNextPage: boolean;
  total: number;
  isSuccess: boolean;
}

export const getProjectsHelper = async (
  model: PaginatedRequestModel,
  cancelSource: any
) => {
  let helperResponse: IProjectsHelperModel = {
    allFlexTaskGroupItems: [],
    allMilestoneGroupItems: [],
    records: [],
    hasNextPage: false,
    total: 0,
    isSuccess: false,
  };

  const response = await (DEFAULT_REFINERS
    ? ProjectService.getProjectsPmr(model, cancelSource)
    : ProjectService.getProjects(model, cancelSource));

  if (!response.isSuccess) return helperResponse;

  let allFlexTaskGroupItemsRendered: E2EProjectLineFlexTaskGroupItemModel[] =
    new Array();
  let allMilestoneGroupItemsRendered: E2EProjectLineMilestoneGroupItemModel[] =
    new Array();
  let recordsRendered = response.data.records.map(
    (record: PmrE2EProjectModel, key) => {
      record.isChecked = false;
      allMilestoneGroupItemsRendered = [
        ...allMilestoneGroupItemsRendered,
        ...record.e2EProjectLineMilestoneGroupItems,
      ];
      allFlexTaskGroupItemsRendered = [
        ...allFlexTaskGroupItemsRendered,
        ...record.e2EProjectLineFlexTaskGroupItems,
      ];

      record.flexMilestonesGroup = groupFlexMilestones(
        record.e2EProjectLineFlexTaskGroupItems,
        record.e2EProjectLineMilestoneGroupItems
      );

      return record;
    }
  );

  const {
    allFlexTaskGroupItems,
    allMilestoneGroupItems,
    records,
    ...pagingInfo
  } = response.data as any;

  helperResponse = pagingInfo;

  helperResponse.allFlexTaskGroupItems = allFlexTaskGroupItemsRendered;
  helperResponse.allMilestoneGroupItems = allMilestoneGroupItemsRendered;
  helperResponse.records = recordsRendered;
  helperResponse.isSuccess = true;
  return helperResponse;
};

export const getMilestoneOrder = (
  taskName: string,
  milestoneList: Dictionary<E2EProjectLineMilestoneGroupItemModel[]>
) => {
  const taskMilestoneList =
    ProjectTasksMilestoneSteps.find((list) => list.taskName === taskName)
      ?.milestones || [];

  const milestones = orderBy(
    Object.entries(milestoneList).map(([name, milestones]) => {
      return {
        name: name,
        sortOrder: taskMilestoneList.find(
          (milestone) => milestone.name === name
        )?.sortOrder,
        milestones: milestones,
      };
    }),
    "sortOrder"
  );

  return milestones;
};

export const groupFlexMilestones = (
  tasks: E2EProjectLineFlexTaskGroupItemModel[],
  milestones: E2EProjectLineMilestoneGroupItemModel[]
) => {
  const orderedTask = orderBy(tasks, "sortOrder");
  const groupedTask = groupBy(orderedTask, "taskName");
  let taskWbs = 1;

  const result = Object.entries(groupedTask).map(([taskName, flexTasks]) => {
    const taskIds = flexTasks.map((task) => task.e2EProjectLineFlexTaskId);

    const milestoneList = groupBy(
      milestones.filter((milestone) =>
        taskIds.includes(milestone.e2EProjectLineFlexTaskId)
      ),
      "name"
    );

    const orderedMilestones = getMilestoneOrder(taskName, milestoneList);
    let wbsSuffix = 1;
    const groupedMilestone = orderedMilestones.map((milestone) => {
      const milestoneIds = milestone.milestones.map(
        (milestone) => milestone.e2EProjectLineMilestoneId
      );

      return {
        milestoneName: milestone.name,
        sortOrder: milestone.sortOrder,
        wbs: `${taskWbs} - ${wbsSuffix++}`,
        milestoneIds: milestoneIds,
      };
    });

    return {
      taskName: taskName,
      sortOrder: taskWbs++,
      flexTaskIds: taskIds,
      milestones: groupedMilestone,
    };
  });

  return result;
};

const ProjectListPmr = () => {
  const REACT_APP_FEATUREFLAG_HIDE_PMR_ICONS =
    process.env.REACT_APP_FEATUREFLAG_HIDE_PMR_ICONS === "true";
  let pageSizes: number[] = [50, 100, 200, 500, 1000];
  const projects = useSelector(selectedProjectManagementViewProjects);
  const projectFavorites = useSelector(selectSelectFavoritesModel);
  const forceReloadProject = useSelector(selectForceReloadProject);
  const selectShowHideColumnsForFullView = useSelector(
    selectShowHideColumnProjects
  );
  const selectProjectColumns = useSelector(selectShowHideColumnProjectsProject);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(projectFavorites.recordsPerPage);
  const [sortField, setSortField] = useState<string>("createdDateUtc");
  const [initialSortField, setInitialSortField] =
    useState<string>("createdDateUtc");
  const [sortOrder, setSortOrder] = useState<any>(-1);
  const [initialSortOrder, setInitialSortOrder] = useState<any>(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState<PmrE2EProjectModel[]>(
    []
  );
  const expandedRows = useSelector(selectExpandedRowsProjectList);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<GridColumnModel[]>([]);
  const [selectedRefiners, setSelectedRefiners] = useState<any>({});
  const [isPageReady, setIsPageReady] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [pwqFrozenCount, setpwqFrozenCount] = useState(2);
  const datatableRef = useRef<any>(null);
  const dispatch = useDispatch();
  const selectedMilestones = useSelector(selectSelectedMilestones);
  const selectedTaskOrderlines = useSelector(selectSelectedTaskOrderlines);
  const isRefinerSearching = useSelector(selectIsRefinerSearching);
  const isTimestampConfirmationLoading = useSelector(
    selectIsTimestampConfirmationLoading
  );
  const refinerState = useSelector(selectRefinerState);
  const getProjectListCancelSource = useRef<any>(null);
  const showFlexTaskOnly = useSelector(selectShowFlexTasks);
  const { showSuccess, showError } = useToastr();
  const [certificateSchemeList, setCertificateSchemeList] = useState<any>(null);
  const [
    allE2EProjectLineFlexTaskGroupItems,
    setAllE2EProjectLineFlexTaskGroupItems,
  ] = useState<E2EProjectLineFlexTaskGroupItemModel[]>(new Array());
  const [
    allE2EProjectLineMilestoneGroupItems,
    setAllE2EProjectLineMilestoneGroupItems,
  ] = useState<E2EProjectLineMilestoneGroupItemModel[]>(new Array());

  const [overrideActiveTabIndex, setOverrideActiveTabIndex] = useState<
    number | null
  >(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [e2eProjectToDelete, setE2eProjectToDelete] = useState<string>("");
  const [deleteModalLoading, setDeleteModalLoading] = useState<boolean>(false);
  const [isEditProjectModalVisible, setIsEditProjectModalVisible] =
    useState(false);

  const shiftSelect = usePmrShiftSelect([], projects);

  const [refreshProjectId, setRefreshProjectId] = useState("");
  const [showHideDataForFullViewProject, setShowHideDataForFullViewProject] =
    useState<
      Array<ShowHideColumnsMultipleDataTableModel<ProjectShowHideColumnsEnum>>
    >([]);
  const refinerLineResult = useSelector(selectRefinerLineResult);
  const requireInvoicePrices = useSelector(selectRequireInvoicePrices);
  const [requireInvoicePrice, setRequireInvoicePrice] =
    useState<RequireInvoicePrice | null>(null);

  const getProjectList = async (isCollapseAllRow: boolean) => {
    setLoading(true);

    const model: PaginatedRequestModel = {
      skip: currentPageNo,
      take: pageSize,
      sort: findSortKey(columns, sortField, initialSortField, "id", "orderBy"),
      sortBy: getSortOrder(sortOrder, initialSortOrder),
      ...selectedRefiners,
      showFlexTask: showFlexTaskOnly.isOn,
    };

    if (getProjectListCancelSource.current) {
      getProjectListCancelSource.current.cancel();
    }

    getProjectListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const response = await getProjectsHelper(
        model,
        getProjectListCancelSource.current
      );
      if (response.isSuccess) {
        setAllE2EProjectLineMilestoneGroupItems(
          response.allMilestoneGroupItems
        );
        setAllE2EProjectLineFlexTaskGroupItems(response.allFlexTaskGroupItems);
        //#region get global search targets
        const searchString = (refinerState.value?.searchString ?? "")
          .toLowerCase()
          .trim();
        const globalSearchItems =
          searchString !== ""
            ? response.records
                .filter((item) => {
                  const firstProjectLine = (item.e2EProjectLines ?? [null])[0];
                  return (
                    item.name?.trim().toLowerCase().includes(searchString) ||
                    firstProjectLine?.applicantName
                      ?.trim()
                      .toLowerCase()
                      .includes(searchString) ||
                    firstProjectLine?.applicantPartySiteNumber
                      ?.trim()
                      .toLowerCase()
                      .includes(searchString)
                  );
                })
                .map((item) => item.id)
            : [];
        dispatch(
          updateGlobalSearchTarget({
            projectLevel: globalSearchItems,
          })
        );
        //#endregion
        setHasNextPage(response.hasNextPage);
        dispatch(updateProjectManagementViewProjects(response.records));
        setTotalRecords(response.total);

        if (isCollapseAllRow && !showFlexTaskOnly.isFromClicked)
          collapseAllRow(response.records);
        else if (!isCollapseAllRow) {
          processInitialExpandRow(response.records);
        }
        if (showFlexTaskOnly.isFromClicked) {
          resetShowFlexTaskIsFromClicked();
        }

        unselectAllOrderlines();
      }
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Project List request cancelled");
        return;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setColumns(selectProjectColumns!.fields);
  }, [selectProjectColumns]);

  useEffect(() => {
    setShowHideDataForFullViewProject(selectShowHideColumnsForFullView);
  }, [selectShowHideColumnsForFullView]);

  useEffect(() => {
    if (datatableRef.current) {
      datatableRef.current.restoreColumnWidths();
      datatableRef.current.reset();
    }
  }, [columns]);

  useEffect(() => {
    if (shiftSelect.rangeItems.length > 0) {
      dispatch(
        updateSelectedTaskOrderlines(
          shiftSelect.rangeItems
            .flatMap((item) => item.e2EProjectLineFlexTaskGroupItems)
            .flat()
        )
      );
      dispatch(
        updateSelectedMilestones(
          shiftSelect.rangeItems
            .flatMap((item) =>
              item.e2EProjectLineMilestoneGroupItems.map(
                getSelectedMilestoneOrderlineState
              )
            )
            .flat()
        )
      );
    }
  }, [shiftSelect.rangeItems]);

  useEffect(() => {
    if (isPageReady) {
      dispatch(
        updateFavoritesModel({
          property: "recordsPerPage",
          value: pageSize,
        })
      );
      getProjectList(true);
    }
  }, [currentPageNo, pageSize, sortField, sortOrder]);

  //On Page load, this useEffect is initialize
  useEffect(() => {
    if (!isPageReady) return;
    if (currentPageNo !== 1) {
      setCurrentPageNo(1);
    } else {
      let isFromSaveProjectPwq = localStorage.getItem("isFromSaveProjectPwq");
      if (isFromSaveProjectPwq) {
        getProjectList(false);
        localStorage.removeItem("isFromSaveProjectPwq");
      } else getProjectList(true);
    }
  }, [selectedRefiners]);

  useEffect(() => {
    setPageSize(projectFavorites.recordsPerPage);
  }, [projectFavorites.recordsPerPage]);

  useEffect(() => {
    setIsPageReady(true);
    loadCertificateSchemeData();
  }, []);

  useEffect(() => {
    if (forceReloadProject === true) {
      getProjectList(false);
      dispatch(updateForceReloadProject(false));
    }
  }, [forceReloadProject]);

  const resetShowFlexTaskIsFromClicked = () => {
    dispatch(
      updateShowFlexTasks({
        isOn: showFlexTaskOnly.isOn,
        isFromClicked: false,
      })
    );
  };

  const unselectAllOrderlines = () => {
    if (showFlexTaskOnly.isOn) dispatch(unselectAllTaskOrderlines());
    else dispatch(unselectAllMilestones());
  };
  const processInitialExpandRow = (list: PmrE2EProjectModel[]) => {
    if (!list || list?.length == 0) return;
    const firstRecord: PmrE2EProjectModel = list[0];
    const id: string = firstRecord.id || "";
    const newExpandedRows = { ...expandedRows, [id]: true };
    dispatch(setExpandedRowsProjectList(newExpandedRows));
  };
  const collapseAllRow = (list: PmrE2EProjectModel[]) => {
    if (!list || list?.length == 0) return;

    dispatch(setExpandedRowsProjectList({}));
  };

  const handleSort = (e: DataTablePFSEvent) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    setCurrentPageNo(1);
  };

  const handleRowExpand = (rowData: PmrE2EProjectModel) => {
    dispatch(toggleRowExpansionProjectList({ id: `${rowData.id}` }));
  };

  const handleClickRecalibrated = (rowData: PmrE2EProjectModel) => {
    if (isExpanded(rowData)) return;

    handleRowExpand(rowData);
  };

  const isExpanded = (rowData: PmrE2EProjectModel) => {
    return !!expandedRows[`${rowData.id}`];
  };

  const isSelectAllCheckboxSelected = () => {
    if (projects?.length == 0) return;
    if (showFlexTaskOnly.isOn) return isAllTaskOrderlinesSelected();
    return isAllMilestoneOrderlinesSelected();
  };

  const isAllTaskOrderlinesSelected = (): boolean => {
    let isSelected = false;
    const selectedTaskOrderlineIds = selectedTaskOrderlines.map(
      (taskOrderline) => taskOrderline.e2EProjectLineFlexTaskId
    );
    let rowTaskOrderlineIds = allE2EProjectLineFlexTaskGroupItems
      .filter(
        (item) =>
          ![ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
            item.flexTaskStatus
          )
      )
      .map((item) => item.e2EProjectLineFlexTaskId);

    if (refinerLineResult.flexTaskIds !== null) {
      rowTaskOrderlineIds = rowTaskOrderlineIds.filter((item) =>
        refinerLineResult.flexTaskIds?.includes(item)
      );
    }

    if (selectedTaskOrderlineIds.length <= 0 || rowTaskOrderlineIds.length <= 0)
      return false;

    isSelected = rowTaskOrderlineIds.every((taskOrderline) =>
      selectedTaskOrderlineIds.includes(taskOrderline)
    );
    return isSelected;
  };

  const isAllMilestoneOrderlinesSelected = (): boolean => {
    let isSelected = false;
    const selectedMilestoneIds = selectedMilestones.map(
      (milestoneOrderline) => milestoneOrderline.id
    );
    let rowMilestoneOrderlineIds = allE2EProjectLineMilestoneGroupItems
      .filter(
        (item) =>
          ![ProjectStatus.Completed, ProjectStatus.Cancelled].includes(
            item.status
          )
      )
      .map((item) => item.e2EProjectLineMilestoneId);

    if (refinerLineResult.milestoneIds !== null) {
      rowMilestoneOrderlineIds = rowMilestoneOrderlineIds.filter((item) =>
        refinerLineResult.milestoneIds?.includes(item)
      );
    }

    if (
      selectedMilestoneIds.length <= 0 ||
      rowMilestoneOrderlineIds.length <= 0
    )
      return false;

    isSelected = rowMilestoneOrderlineIds.every((milestoneOrderline) =>
      selectedMilestoneIds.includes(milestoneOrderline)
    );

    return isSelected;
  };

  const handleSelectAllCheck = (event: CheckboxChangeParams) => {
    if (showFlexTaskOnly.isOn) return handleAllTaskOrderlineCheck(event);
    return handleAllMilestoneOrderlineCheck(event);
  };

  const handleAllTaskOrderlineCheck = (event: CheckboxChangeParams) => {
    allE2EProjectLineFlexTaskGroupItems.forEach(
      (taskOrderline: E2EProjectLineFlexTaskGroupItemModel) => {
        dispatch(
          updateSelectedTaskOrderlines({
            isChecked: event.checked,
            taskOrderline: getSelectedTaskOrderlineState(taskOrderline),
          })
        );
      }
    );
  };

  const handleAllMilestoneOrderlineCheck = (event: CheckboxChangeParams) => {
    allE2EProjectLineMilestoneGroupItems.forEach(
      (milestoneOrderline: E2EProjectLineMilestoneGroupItemModel) => {
        dispatch(
          updateSelectedMilestones({
            milestone: getSelectedMilestoneOrderlineState(milestoneOrderline),
            isChecked: event.checked,
          })
        );
      }
    );
  };

  const isProjectLevelSelected = (rowData: PmrE2EProjectModel) => {
    if (showFlexTaskOnly.isOn) return isAllChildTaskOrderlinesSelected(rowData);
    return isAllChildMilestoneOrderlinesSelected(rowData);
  };

  const isAllChildTaskOrderlinesSelected = (
    rowData: PmrE2EProjectModel
  ): boolean => {
    let isSelected = false;
    if (rowData?.e2EProjectLineFlexTaskGroupItems.length == 0) return false;
    const selectedTaskOrderlineIds = selectedTaskOrderlines.map(
      (taskOrderline) => taskOrderline.e2EProjectLineFlexTaskId
    );
    let rowTaskOrderlineIds = rowData.e2EProjectLineFlexTaskGroupItems
      .filter(
        (item) =>
          ![ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
            item.flexTaskStatus
          )
      )
      .map((item) => item.e2EProjectLineFlexTaskId);

    if (refinerLineResult.flexTaskIds !== null) {
      rowTaskOrderlineIds = rowTaskOrderlineIds.filter((item) =>
        refinerLineResult.flexTaskIds?.includes(item)
      );
    }

    if (rowTaskOrderlineIds.length <= 0 || selectedTaskOrderlineIds.length <= 0)
      return false;
    isSelected = rowTaskOrderlineIds.every(
      (taskOrderline) => selectedTaskOrderlineIds.indexOf(taskOrderline) > -1
    );
    return isSelected;
  };

  const isAllChildMilestoneOrderlinesSelected = (
    rowData: PmrE2EProjectModel
  ): boolean => {
    let isSelected = false;
    if (rowData?.e2EProjectLineMilestoneGroupItems.length == 0) return false;
    const selectedMilestoneIds = selectedMilestones.map(
      (milestoneOrderline) => milestoneOrderline.id
    );
    let rowMilestoneOrderlineIds = rowData.e2EProjectLineMilestoneGroupItems
      .filter(
        (item) =>
          ![ProjectStatus.Completed, ProjectStatus.Cancelled].includes(
            item.status
          )
      )
      .map((item) => item.e2EProjectLineMilestoneId);

    if (refinerLineResult.milestoneIds !== null) {
      rowMilestoneOrderlineIds = rowMilestoneOrderlineIds.filter((item) =>
        refinerLineResult.milestoneIds?.includes(item)
      );
    }

    if (
      selectedMilestoneIds.length <= 0 ||
      rowMilestoneOrderlineIds.length <= 0
    )
      return false;

    isSelected = rowMilestoneOrderlineIds.every(
      (milestoneOrderline) =>
        selectedMilestoneIds.indexOf(milestoneOrderline) > -1
    );
    return isSelected;
  };

  const handleRowParentProjectCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectModel
  ) => {
    if (showFlexTaskOnly.isOn)
      return handleChildTaskOrderlineCheck(event, rowData);
    return handleChildMilestoneOrderlineCheck(event, rowData);
  };

  const handleChildTaskOrderlineCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectModel
  ) => {
    rowData.e2EProjectLineFlexTaskGroupItems.forEach(
      (taskOrderline: E2EProjectLineFlexTaskGroupItemModel) => {
        dispatch(
          updateSelectedTaskOrderlines({
            isChecked: event.checked,
            taskOrderline: getSelectedTaskOrderlineState(taskOrderline),
          })
        );
      }
    );
  };

  const getSelectedTaskOrderlineState = (
    taskOrderline: E2EProjectLineFlexTaskGroupItemModel
  ) => {
    return {
      e2EProjectLineFlexTaskId: taskOrderline.e2EProjectLineFlexTaskId,
      status: taskOrderline.flexTaskStatus,
      isRevenueRecognition: taskOrderline.isRevenueRecognition,
      isAdHoc: taskOrderline.isAdhoc,
    };
  };

  const handleChildMilestoneOrderlineCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectModel
  ) => {
    rowData.e2EProjectLineMilestoneGroupItems.forEach(
      (milestoneOrderline: E2EProjectLineMilestoneGroupItemModel) => {
        dispatch(
          updateSelectedMilestones({
            milestone: getSelectedMilestoneOrderlineState(milestoneOrderline),
            isChecked: event.checked,
          })
        );
      }
    );
  };

  const getSelectedMilestoneOrderlineState = (
    milestoneOrderline: E2EProjectLineMilestoneGroupItemModel
  ) => {
    return {
      id: milestoneOrderline.e2EProjectLineMilestoneId,
      status: milestoneOrderline.status,
      e2EProjectLineId: milestoneOrderline.e2EProjectLineId,
      e2EProjectLineFlexTaskId: milestoneOrderline.e2EProjectLineFlexTaskId,
      isRevenueRecognition: milestoneOrderline.isRevenueRecognition,
      isLastMilestone: milestoneOrderline.isLastMilestone,
      isAdHoc: milestoneOrderline.isAdhoc,
    };
  };

  const tableColumnHeader = (col: GridColumnModel) => (
    <div>
      <span title={col.value}>{col.value}</span>
    </div>
  );

  const tableColumnBody = (
    rowData: PmrE2EProjectModel,
    col: ColumnBodyOptions
  ) => {
    return (
      <div
        title={get(rowData, col.field)}
        className={"text-container-ellipsis"}
      >
        {get(rowData, col.field)}
      </div>
    );
  };

  const checkboxColumnHeaderTemplate = (col: GridColumnModel) => {
    return (
      <>
        <Checkbox
          checked={isSelectAllCheckboxSelected()}
          onChange={(event: CheckboxChangeParams) =>
            handleSelectAllCheck(event)
          }
          className={`${classes["project-checkbox"]}`}
        />
      </>
    );
  };

  const checkboxColumnBodyTemplate = (
    rowData: PmrE2EProjectModel,
    col: any
  ) => {
    return (
      <>
        <Checkbox
          checked={isProjectLevelSelected(rowData)}
          onChange={(event: CheckboxChangeParams) => {
            shiftSelect.register([col.rowIndex], event);
            handleRowParentProjectCheck(event, rowData);
          }}
          className={`${classes["project-checkbox"]}`}
        />
      </>
    );
  };

  const isAdhocSyncing = (rowData: any) => {
    return projects.find((x) => x.id == rowData.id)?.isAdhocSyncing;
  };

  const expandColumnBodyTemplate = (rowData: PmrE2EProjectModel) => {
    const requireInvoicePrice = requireInvoicePrices.find(
      (item) => item.projectId === rowData.id
    );
    return (
      <>
        <div className={`${classes["project-row-expander-div"]}`}>
          {isAdhocSyncing(rowData) && (
            <div className="text-center w-100">
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faSpinner}
                spin={true}
                title="Setting up ad hoc tasks in GMAWB project..."
              />
            </div>
          )}
          {!isExpanded(rowData) && (
            <FontAwesomeIcon
              onClick={() => {
                setOverrideActiveTabIndex(null);
                handleRowExpand(rowData);
              }}
              icon={faChevronRight}
              className={`${classes["project-row-expander"]}`}
            />
          )}
          {isExpanded(rowData) && (
            <FontAwesomeIcon
              onClick={(event: any) => {
                setOverrideActiveTabIndex(null);
                handleRowExpand(rowData);
              }}
              icon={faChevronDown}
              className={`${classes["project-row-expander"]}`}
            />
          )}
          {rowData.isMismatchECD && (
            <FontAwesomeIcon
              onClick={() => {
                setOverrideActiveTabIndex(2);
                handleClickRecalibrated(rowData);
              }}
              title="The Order Line ECD of an order line/s has been updated and its schedule/s recalibrated."
              icon={faArrowsRotate}
              className={`${classes["project-arrows-rotate"]}`}
            />
          )}

          {requireInvoicePrice && (
            <InvoiceWarningIcon
              title="System has detected that an order line FLEX Task associated with the ‘UL: Payment’ milestone was completed in FLEX without an Invoice Price. Click to enter an Invoice Price in the Order Line Details tab to fix the discrepancy."
              onClick={() => setRequireInvoicePrice(requireInvoicePrice)}
            />
          )}
        </div>
      </>
    );
  };

  const statusColumnBodyTemplate = (rowData: PmrE2EProjectModel) => {
    return (
      <ProjectStatusTemplate
        tooltip="Status determined based on underlying GMAWB Task Statuses."
        status={rowData[projectColumnStatus]}
      ></ProjectStatusTemplate>
    );
  };

  const collaboratorColumnBodyTemplate = (rowData: PmrE2EProjectModel) => {
    return (
      <ProjectCollaboratorAvatar
        collaborator={rowData[projectColumnCollaborator]}
      ></ProjectCollaboratorAvatar>
    );
  };

  //#region action buttons
  const handleOpenNewTabProject = async (project: PmrE2EProjectModel) => {
    if (CARRY_OVER_CONFIGURATION_ON_NEW_TAB === false) {
      sessionStorage.setItem(
        projectFullViewMessageKey,
        JSON.stringify(project)
      );
      window.parent.postMessage(
        {
          type: "redirectToProjectFullView",
          message: project.name,
          showFlexTaskOnly: showFlexTaskOnly.isOn,
        },
        "*"
      );
    } else {
      const generatedFullViewKey = Math.random().toString(36).slice(2);
      const fullViewProps: PmrE2EProjectFullViewModel = {
        project: project,
        showFlexTasks: {
          isOn: showFlexTaskOnly.isOn,
          isFromClicked: false,
        },
        showHideColumn: showHideDataForFullViewProject,
        refiners: selectedRefiners,
      };

      const requestPayload: ProjectFullViewRequestModel = {
        key: generatedFullViewKey,
        keyCacheValueDetail: JSON.stringify(fullViewProps),
      };

      await ProjectService.setProjectFullViewCacheKey(requestPayload).then(
        (response) => {
          if (response.isSuccess) {
            window.parent.postMessage(
              {
                type: "redirectToProjectFullView",
                message: project.name,
                fullViewProjectKey: generatedFullViewKey,
              },
              "*"
            );
          }
        }
      );
    }
  };

  const openNewTabColumnBodyTemplate = (rowData: PmrE2EProjectModel) => {
    return (
      <button
        className={`${classes["new-tab-button"]}`}
        onClick={() => handleOpenNewTabProject(rowData)}
        title={"Click to open GMAWB Project in a new browser tab."}
      >
        <FontAwesomeIcon
          icon={faUpRightFromSquare}
          className={`${classes["icon-action-column"]}`}
        />
      </button>
    );
  };

  const getFlexProjectTemplateDetails = async (
    flexProjectTemplateIds: string[]
  ) => {
    const response = await FlexIntegrationService.getBatchFlexTemplateDetails(
      flexProjectTemplateIds
    );
    if (!response.isSuccess) return [];
    return response.data;
  };

  const handleEditE2EProject = async (e2eProjectId: string) => {
    setLoading(true);
    setRefreshProjectId(e2eProjectId);

    const result = await ProjectService.getEditProjectDetails(e2eProjectId);
    if (!result || !result.isSuccess || result?.data == null) {
      setLoading(false);
      return;
    }

    const trimmedProjectName = result.data.name?.trim();
    const lines = result.data.e2EProjectLines as E2EProjectLineModel[];

    const flexProjectTemplateIds = lines
      .flatMap((x: E2EProjectLineModel) => {
        return x.flexProjectTemplateId;
      })
      .filter((value) => value !== undefined && value !== null) as string[];

    const distinctProjectTemplateIds = flexProjectTemplateIds.filter(
      (item, index) => {
        return flexProjectTemplateIds.indexOf(item) === index;
      }
    );
    const flexTemplates = await getFlexProjectTemplateDetails(
      distinctProjectTemplateIds
    );

    const mappedLines = lines.map((line) => {
      const baseModel =
        (line.e2EProjectLineModelInfo ?? []).find(
          (model) => model.isBaseModel
        ) ?? null;

      const modelVariants = (line.e2EProjectLineModelInfo ?? [])
        .filter((model) => !model.isBaseModel)
        .map(
          (model) =>
            ({
              modelVariant: model,
            } as E2EProjectModelVariantModel)
        );

      const flexTemplate = flexTemplates.find(
        (template) => template.projectTemplateId === line.flexProjectTemplateId
      );

      return {
        ...line,
        isSelectable:
          line.orderLineStatus !== ProjectOrderlineStatus.Completed &&
          line.orderLineStatus !== ProjectOrderlineStatus.Cancelled,
        certificateSchemeList,
        baseModel,
        modelVariants: modelVariants.length <= 0 ? null : modelVariants,
        flexProjectTemplateName: flexTemplate?.templateName,
      } as E2EProjectLineModel;
    });

    if (!!mappedLines && mappedLines.length > 1) {
      mappedLines.unshift({
        flexProjectLineId: "0",
        segment: "Wireless",
        certificateSchemeList,
        isSelectable: false,
      } as E2EProjectLineModel);
    }

    const firstLine = mappedLines.find((f) => f.flexProjectLineId !== "0");

    const applicant = {
      companyName: firstLine?.soldToCustomer?.name ?? firstLine?.applicantName,
      partySiteNumber:
        firstLine?.soldToCustomerLocation?.partySiteNumber ||
        firstLine?.shipToCustomerLocation?.partySiteNumber ||
        firstLine?.applicantPartySiteNumber,
    };

    const productOwner = {
      companyName: firstLine?.shipToCustomer?.name ?? firstLine?.applicantName,
      partySiteNumber:
        firstLine?.shipToCustomerLocation?.partySiteNumber ||
        firstLine?.applicantPartySiteNumber,
    };

    dispatch(updateApplicant(applicant));
    dispatch(updateProductOwner(productOwner));
    dispatch(updateProjectLines(mappedLines));
    dispatch(updateInitialE2EProjectLines(mappedLines));
    dispatch(updateE2EProjectId(result?.data?.id));
    dispatch(updateE2EProjectName(trimmedProjectName));
    dispatch(updateOriginalProjectName(result?.data?.name));
    setIsEditProjectModalVisible(true);

    setLoading(false);
  };

  const handleCloseEditE2EProjectModal = (isFromCancel: boolean) => {
    dispatch(resetInitialProjectLines());
    dispatch(resetProjectLines());
    setIsEditProjectModalVisible(false);
    setLoading(false);
    if (!isFromCancel) {
      dispatch(updateForceReloadProject(true));
      dispatch(updateRefreshProjectId(refreshProjectId));
    }
  };

  const handleDeleteE2EProjectModal = (e2eProjectId: string) => {
    setShowDeleteModal(true);
    setE2eProjectToDelete(e2eProjectId);
  };

  const handleDeleteE2EProject = async (e2eProjectId: string) => {
    setDeleteModalLoading(true);
    setShowDeleteModal(true);
    setE2eProjectToDelete(e2eProjectId);

    const result: ResultModel<null> = await ProjectService.deleteE2EProject(
      e2eProjectId.toString()
    );

    if (!result) {
      setDeleteModalLoading(false);
      setShowDeleteModal(false);
      return;
    }

    switch (result.statusCode) {
      case HttpStatus.OK:
        showSuccess(
          "GMAWB Project Deleted",
          `GMAWB Project deleted successfully.
          Incomplete order lines have been returned to the Project Work Queue (PWQ).`
        );

        dispatch(updateForceReloadProject(true));
        break;
      case HttpStatus.CONFLICT:
        showError(
          "Error Encountered",
          `You do not have permission to delete this
          GMAWB Project. Only the GMAWB Project 
          Creator can delete this project.`
        );
        break;
      default:
        showError(
          "Error Encountered",
          `GMAWB Project deletion was unsuccessful.
          Please try again. Contact support if this issue 
          persists.`
        );
    }

    setDeleteModalLoading(false);
    setShowDeleteModal(false);
  };

  const loadCertificateSchemeData = async () => {
    await ReferenceService.getCertificateSchemeList()
      .then((response) => {
        setCertificateSchemeList(response.data);

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const actionsColumnBodytemplate = (rowData: PmrE2EProjectModel) => {
    const isCompleted = rowData.status === ProjectStatus.Completed;

    return (
      <>
        {!REACT_APP_FEATUREFLAG_HIDE_PMR_ICONS && (
          <div className={`${classes["actions-item"]}`}>
            <button
              onClick={() => handleEditE2EProject(rowData?.id || "")}
              title={
                isCompleted
                  ? "GMAWB Projects containing completed order lines cannot be edited."
                  : "Edit GMAWB Project"
              }
              disabled={isCompleted}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button
              onClick={() => handleDeleteE2EProjectModal(rowData?.id || "")}
              title={
                rowData.hasCompletedProjectLine
                  ? "GMAWB Projects containing completed order lines cannot be deleted."
                  : "Delete GMAWB Project"
              }
              disabled={rowData.hasCompletedProjectLine}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            {openNewTabColumnBodyTemplate(rowData)}
          </div>
        )}
      </>
    );
  };
  //#endregion

  const createdDateUTCTemplate = (rowData: PmrE2EProjectModel) =>
    moment(rowData.createdDateUtc).format("DD MMM YYYY");

  const processColumnHeaderTemplates = (col: GridColumnModel) => {
    if (col.id === projectColumnCheckbox)
      return checkboxColumnHeaderTemplate(col);
    return tableColumnHeader(col);
  };

  const processColumnBodyTemplates = (col: GridColumnModel) => {
    if (col.id === projectColumnCheckbox)
      return checkboxColumnBodyTemplate.bind(col);
    if (col.id === projectColumnStatus)
      return statusColumnBodyTemplate.bind(col);
    if (col.id === projectColumnExpand)
      return expandColumnBodyTemplate.bind(col);
    if (col.id === projectColumnCollaborator)
      return collaboratorColumnBodyTemplate.bind(col);
    if (col.id === actionsColumnTab) return actionsColumnBodytemplate.bind(col);
    if (col.id === projectColumnCreatedDateUtc)
      return createdDateUTCTemplate.bind(col);

    return tableColumnBody.bind(col);
  };

  const dynamicColumns = columns.map((col: GridColumnModel) => {
    const isCollaborator = col.id === projectColumnCollaborator;
    const isGMAWBProjectColumnOnlyShown =
      selectProjectColumns?.fields.filter(
        (field) => field.isShown === true && field.orderNoShowHide! > 0
      ).length === 1;

    const columnStyle: React.CSSProperties = {
      padding: "0.3rem",
      width: col.width,
      minWidth: 50,
    };

    if (col.isFlexWidth) {
      columnStyle.flexGrow = 1;
      columnStyle.flexBasis = col.width;
      columnStyle.minWidth = 80;
    } else if (
      col.id == actionsColumnTab &&
      REACT_APP_FEATUREFLAG_HIDE_PMR_ICONS
    ) {
      columnStyle.width = "50px";
    } else if (isCollaborator) {
      columnStyle.minWidth = col.widthNum;
    }

    if (col.alignFrozen === "right") {
      columnStyle.borderLeftWidth = "1.5px";
      columnStyle.paddingLeft = "0.5rem";
    }

    return (
      <Column
        field={col.id}
        header={processColumnHeaderTemplates(col)}
        hidden={!col.isShown}
        style={columnStyle}
        body={processColumnBodyTemplates(col)}
        sortable={col.orderBy != ""}
        reorderable={!col.isColumnNotReordable && !col.isFrozen}
        align={col.align ?? undefined}
        alignHeader={col.align ?? undefined}
        frozen={isGMAWBProjectColumnOnlyShown ? undefined : col.isFrozen}
        alignFrozen={col.alignFrozen ?? "left"}
      />
    );
  });

  const rowDetailsTemplate = (
    rowData: PmrE2EProjectModel,
    options: DataTableRowExpansionTemplate
  ) => {
    const projectId = rowData.id || "";
    return (
      <>
        <ProjectListDetailTab
          project={rowData}
          projectId={projectId}
          overrideActiveTabIndex={overrideActiveTabIndex}
          depthIndices={[options.index]}
        ></ProjectListDetailTab>
      </>
    );
  };

  const handlePageChange = (page: number, size: number) => {
    setPageSize(size);
    setCurrentPageNo(page);
  };

  const onColReorder = (e: DataTableColReorderParams) => {
    if (e.dropIndex < 3) {
      datatableRef.current.reset();
    } else {
      let prevNumber = 0;
      let pwqColumns = columns.map((col, i) => {
        const cols: any = e.columns;
        const orderNo =
          cols.findIndex((fi: any) => fi.props.field === col.id) + 1;

        return {
          ...col,
          orderNo,
        };
      });

      pwqColumns?.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      pwqColumns = pwqColumns
        .map((col) => {
          if (col.orderNoShowHide! > 0) prevNumber++;

          const orderNoShowHide = col.orderNoShowHide! > 0 ? prevNumber : 0;

          return {
            ...col,
            orderNoShowHide,
          };
        })
        .sort((col) => col.orderNoShowHide);

      dispatch(
        updateShowHideColumnByTable({
          key: selectProjectColumns?.key,
          newColumns: pwqColumns,
        })
      );
    }
  };

  const onColResize = (e: DataTableColumnResizeEndParams) => {
    let newColumns: any = columns.map((col, i) => {
      if (e.column.props.field == col.id) {
        return {
          ...col,
          width: e.element.offsetWidth,
        };
      } else {
        return col;
      }
    });

    dispatch(
      updateShowHideColumnByTable({
        key: selectProjectColumns?.key,
        newColumns: newColumns,
      })
    );
  };

  const handleRefinerChange = useDebounce(() => {
    setSelectedRefiners(transformOptionLabelValueToValue(refinerState.value));
  }, 0);

  useEffect(handleRefinerChange, [refinerState.value]);

  return (
    <>
      <div className={`${classes["table-container"]}`}>
        <div
          className={clsx(
            classes["project-list-grid-container"],
            !hasNextPage && projects.length > 0 && classes["on-last-page"]
          )}
        >
          <DataTable
            lazy
            emptyMessage="--- No results found. Please try different search criteria or check your spelling. ---"
            ref={datatableRef}
            dataKey="id"
            value={projects}
            loading={
              loading || isRefinerSearching || isTimestampConfirmationLoading
            }
            selection={selectedRecords}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowDetailsTemplate}
            scrollable
            resizableColumns
            size="small"
            scrollDirection="both"
            columnResizeMode="fit"
            onSort={(event: DataTablePFSEvent) => handleSort(event)}
            sortField={sortField}
            sortOrder={sortOrder}
            removableSort
            reorderableColumns
            onColReorder={onColReorder}
            onColumnResizeEnd={onColResize}
          >
            {dynamicColumns}
          </DataTable>
        </div>
        <div className={`${classes["pagination-container"]}`}>
          <Pagination
            onPageChange={(pageInfo) =>
              handlePageChange(pageInfo.page, pageInfo.pageSize)
            }
            onPageSizeChange={(pageSize: number) =>
              handlePageChange(1, pageSize)
            }
            totalRecords={totalRecords}
            currentPage={currentPageNo}
            pageSize={pageSize}
            pageSizes={pageSizes}
            statSlot={
              SELECTION_COUNTER ? <ProjectListSelectionCounter /> : undefined
            }
          />
        </div>
      </div>

      <ProjectDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDeleteProject={handleDeleteE2EProject}
        e2eProjectId={e2eProjectToDelete}
        loading={deleteModalLoading}
      ></ProjectDeleteModal>

      <ProjectWorkQueueModal
        isExistingGMAWBProject={false}
        visible={isEditProjectModalVisible}
        closeModal={handleCloseEditE2EProjectModal}
        certificateSchemeList={certificateSchemeList}
        isEditGMAWBProject={true}
      />

      <InvoiceWarningModal
        visible={!!requireInvoicePrice}
        onHide={() => {
          setRequireInvoicePrice(null);
          setOverrideActiveTabIndex(null);
        }}
        onSubmit={() => setOverrideActiveTabIndex((_) => 2)}
        data={requireInvoicePrice}
      />
    </>
  );
};

export default ProjectListPmr;
