import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./ProjectCollaboratorAssignmentDialog.module.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import ProjectCollaboratorAssignmentDropdown from "./project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EProjectTaskHandler from "../../interfaces/pmr-e2e-project-task-handlers";
import HandlerType from "../../enums/handler-type";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  updateFlexTaskOrderLineContributor,
  updateFlexTaskMilestoneOrderLineContributor,
  upsertProjectFlexTasksContributors,
  updateProjectLevelContributors,
  updateRefreshFlexTaskId,
} from "../../../../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../../interfaces/pmr-e2e-project-task-model";

interface ProjectCollaboratorAssignmentDialogProps {
  e2EProjectId: string;
  workBreakdownStructure: number;
  onClose: () => void;
  show: boolean;
  selectedE2EProjectLineFlexTask:
    | {
        e2EProjectLineFlexTaskId: string;
        collaborators: PmrE2EProjectTaskHandler[];
        isGmaTcTask: boolean;
      }
    | undefined;
  e2EProjectLineMilestoneId?: string;
  e2EProjectLineId: string;
  projectNumber?: string;
  isCoHandlerMilestone: boolean;
}

interface ProjectCollaboratorsDetails {
  e2eProjectId?: string;
  e2EProjectLineFlexTaskId?: string;
  e2EProjectLineMilestoneId?: string;
  projectNumber?: string;
  flexHandlerDetails?: E2EUserPaginatedModel;
  gmaWbHandlerDetails?: E2EUserPaginatedModel;
  taskHandlerDetails?: E2EUserPaginatedModel;
  milestoneHandlerDetails?: E2EUserPaginatedModel;
}

export interface ProjectUserSource {
  currentSkip: number;
  currentTake: number;
  currentSearchPhrase: string;
  data: E2EUserPaginatedModel[];
}

interface DropdownLoading {
  taskHandlerDropdownLoading: boolean;
  milestoneHandlerDropdownLoading: boolean;
}

const ProjectCollaboratorAssignmentDialog = (
  props: ProjectCollaboratorAssignmentDialogProps
) => {
  const initDropdownValues: ProjectUserSource = {
    currentSkip: 0,
    currentTake: 5,
    currentSearchPhrase: "",
    data: [],
  };
  const showFlexTasks = useSelector(selectShowFlexTasks);
  const { showSuccess } = useToastr();
  const dispatch = useDispatch();

  const [flexHandlerDropdownOptions, setFlexHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [gmawbHandlerDropdownOptions, setGmawbHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [taskHandlerDropdownOptions, setTaskHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [milestoneHandlerDropdownOptions, setMilestoneHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);

  const [dropdownLoading, setDropdownLoading] = useState<DropdownLoading>({
    taskHandlerDropdownLoading: true,
    milestoneHandlerDropdownLoading: true,
  });

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const fetchTaskHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) => collaborator?.handlerType === HandlerType.TaskHandler
    );
  const fetchMilestoneHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) =>
        collaborator?.handlerType === HandlerType.MilestoneHandler
    );

  const [taskHandler, setTaskHandler] = useState<E2EUserPaginatedModel>();
  const [milestoneHandler, setMilestoneHandler] =
    useState<E2EUserPaginatedModel>();

  const isModalLoading = showFlexTasks.isOn
    ? dropdownLoading.taskHandlerDropdownLoading
    : dropdownLoading.milestoneHandlerDropdownLoading;

  useEffect(() => {
    if (props.show) {
      if (showFlexTasks.isOn) {
        setDropdownLoading((current) => ({
          ...current,
          taskHandlerDropdownLoading: true,
        }));

        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          HandlerType.TaskHandler,
          props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
          ""
        ).then((response) => {
          let taskHandlerData: E2EUserPaginatedModel[] = structuredClone(
            response?.data
          );
          if (fetchTaskHandlerCollaborator) {
            if (
              taskHandlerData?.some(
                (data) =>
                  data.normalizedEmail ===
                  fetchTaskHandlerCollaborator.userEmail
              )
            ) {
              taskHandlerData = taskHandlerData?.filter(
                (data) =>
                  data.normalizedEmail !==
                  fetchTaskHandlerCollaborator.userEmail
              );
            }
            taskHandlerData?.unshift({
              displayName: fetchTaskHandlerCollaborator.displayName,
              normalizedEmail: fetchTaskHandlerCollaborator.userEmail,
            });
          }

          setTaskHandlerDropdownOptions((current) => ({
            ...current,
            data: taskHandlerData,
          }));

          setTaskHandler((current) => ({
            ...current,
            normalizedEmail: fetchTaskHandlerCollaborator?.userEmail!,
            displayName: fetchTaskHandlerCollaborator?.displayName!,
          }));

          setDropdownLoading((current) => ({
            ...current,
            taskHandlerDropdownLoading: false,
          }));
        });
      }

      if (!showFlexTasks.isOn) {
        setDropdownLoading((current) => ({
          ...current,
          milestoneHandlerDropdownLoading: true,
        }));

        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          HandlerType.MilestoneHandler,
          props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
          ""
        ).then((response) => {
          let milestoneHandler: E2EUserPaginatedModel[] = structuredClone(
            response?.data
          );

          if (fetchMilestoneHandlerCollaborator) {
            if (
              milestoneHandler?.some(
                (data) =>
                  data.normalizedEmail ===
                  fetchMilestoneHandlerCollaborator.userEmail
              )
            ) {
              milestoneHandler = milestoneHandler?.filter(
                (data) =>
                  data.normalizedEmail !==
                  fetchMilestoneHandlerCollaborator.userEmail
              );
            }
            milestoneHandler?.unshift({
              displayName: fetchMilestoneHandlerCollaborator.displayName,
              normalizedEmail: fetchMilestoneHandlerCollaborator.userEmail,
            });
          }

          setMilestoneHandlerDropdownOptions((current) => ({
            ...current,
            data: milestoneHandler,
          }));

          setMilestoneHandler((current) => ({
            ...current,
            normalizedEmail: fetchMilestoneHandlerCollaborator?.userEmail!,
            displayName: fetchMilestoneHandlerCollaborator?.displayName!,
          }));

          setDropdownLoading((current) => ({
            ...current,
            milestoneHandlerDropdownLoading: false,
          }));
        });
      }
    }
  }, [props.show]);

  const dialogStyle = {
    width: "25rem",
    height: showFlexTasks.isOn ? "27rem" : "32rem",
  };

  const handlerUpdateProjectLevelContributors = async () => {
    const response = await ProjectService.getProjectContributorsList(
      props.e2EProjectId
    );
    if (response.isSuccess) {
      dispatch(
        updateProjectLevelContributors({
          e2eProjectId: props.e2EProjectId,
          projectHandlers: response.data.projectHandlers,
        })
      );
    }
  };

  const handleUpdateFlexTaskLevelContributors = async (
    isMilestoneListLoaded: boolean
  ) => {
    const response = await ProjectService.getProjectTasksByProject({
      projectId: props.e2EProjectId,
      isMilestoneListLoaded: isMilestoneListLoaded,
    });

    if (response.isSuccess) {
      let records = response?.data?.e2EProjectLineFlexTasks?.map(
        (task: PmrE2EProjectTaskModel, index: number) => {
          task.workBreakdownStructure = index + 1;
          task.isChecked = false;
          return task;
        }
      );

      dispatch(
        upsertProjectFlexTasksContributors({
          e2eProjectId: props.e2EProjectId,
          tasks: records,
        } as PmrE2EProjectTaskModelList)
      );

      const isSelectedContributorUpdateIsAdhocTask = records
      .some((task: PmrE2EProjectTaskModel) => 
        task.e2EProjectLineFlexTaskGroupItems
          .some(groupItem => groupItem.e2EProjectLineFlexTaskId 
              === props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId));

      if(isSelectedContributorUpdateIsAdhocTask) {
        dispatch(updateRefreshFlexTaskId(props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId));
      }
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    const newContributors: ProjectCollaboratorsDetails = {
      e2eProjectId: props.e2EProjectId,
      projectNumber: props.projectNumber,
      e2EProjectLineFlexTaskId:
        props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId,
      e2EProjectLineMilestoneId: props?.e2EProjectLineMilestoneId,
      taskHandlerDetails: taskHandler,
      milestoneHandlerDetails: milestoneHandler,
    };

    const isMilestoneListLoaded = props?.e2EProjectLineMilestoneId
      ? true
      : false;

    const updateResult = await GMAWBUsersService.saveContributors(
      newContributors
    );
    props.onClose();

    if (updateResult.isSuccess) {
      showSuccess("Success", updateResult.message);

      if (props.e2EProjectLineMilestoneId) {
        dispatch(
          updateFlexTaskMilestoneOrderLineContributor({
            projectId: props.e2EProjectId,
            projectNumber: props.projectNumber,
            workBreakdownStructure: props.workBreakdownStructure,
            e2EProjectLineMilestoneId: props.e2EProjectLineMilestoneId,
            e2EProjectLineId: props.e2EProjectLineId,
            taskHandler,
            milestoneHandler,
          })
        );
      } else {
        dispatch(
          updateFlexTaskOrderLineContributor({
            projectId: props.e2EProjectId,
            projectNumber: props.projectNumber,
            workBreakdownStructure: props.workBreakdownStructure,
            e2EProjectLineFlexTaskId:
              props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId,
            taskHandler,
            e2EProjectLineId: props.e2EProjectLineId,
          })
        );
      }

      handleUpdateFlexTaskLevelContributors(isMilestoneListLoaded);
      handlerUpdateProjectLevelContributors();
    }

    setIsSaveLoading(false);
  };

  const header = (
    <div>
      <span>Assign Contributors</span>
    </div>
  );

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={props.onClose}
          disabled={isModalLoading}
        />
      </div>
      <div className={`${classes["button-save-wrapper"]}`}>
        <Button
          className={`${classes["button-save"]}`}
          label="SAVE"
          onClick={handleSave}
          disabled={isModalLoading}
          loading={isSaveLoading}
        />
      </div>
    </div>
  );

  const onHide = () => {
    setFlexHandlerDropdownOptions(initDropdownValues);
    setGmawbHandlerDropdownOptions(initDropdownValues);
    setTaskHandlerDropdownOptions(initDropdownValues);
    setMilestoneHandlerDropdownOptions(initDropdownValues);
    setTaskHandler(undefined);
    setMilestoneHandler(undefined);
    setIsSaveLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["assign-collaborator-dialog"]}
      header={header}
      footer={footer}
      visible={props.show}
      style={dialogStyle}
      modal
      onHide={onHide}
      contentClassName={classes["content"]}
    >
      {isModalLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading Contributors.</div>
        </div>
      )}
      {!isModalLoading && [
        showFlexTasks.isOn === true && (
          <div className={classes["dropdown"]}>
            <Tooltip
              position="right"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target=".task-handler-modal-tooltip"
            >
              <div className={classes["tooltip-content"]}>
                The assigned Task Handler is specific to this FLEX Task and will
                be reflected in the FLEX system under "Assigned to" for their
                FLEX Project.
              </div>
            </Tooltip>
            <span>
              Task Handler
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`${classes["icon"]} task-handler-modal-tooltip`}
              />
            </span>
            <ProjectCollaboratorAssignmentDropdown
              value={taskHandler}
              dropdownOptions={taskHandlerDropdownOptions}
              setValue={setTaskHandler}
              setDropdownOptions={setTaskHandlerDropdownOptions}
              handlerType={HandlerType.TaskHandler}
              isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
              isDisabled={false}
            />
          </div>
        ),
        showFlexTasks.isOn === false && (
          <div className={classes["dropdown"]}>
            <Tooltip
              position="right"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target=".milestone-handler-modal-tooltip"
            >
              <div className={classes["tooltip-content"]}>
                Assigning the Milestone Handler applies specifically to this
                GMAWB Milestone within the order line.
              </div>
            </Tooltip>

            <span>
              Milestone Handler
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`${classes["icon"]} milestone-handler-modal-tooltip`}
              />
            </span>
            <ProjectCollaboratorAssignmentDropdown
              value={milestoneHandler}
              dropdownOptions={milestoneHandlerDropdownOptions}
              setValue={setMilestoneHandler}
              setDropdownOptions={setMilestoneHandlerDropdownOptions}
              handlerType={HandlerType.MilestoneHandler}
              isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
              isDisabled={false}
            />
          </div>
        ),
      ]}
    </Dialog>
  );
};

export default ProjectCollaboratorAssignmentDialog;
