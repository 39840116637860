enum ProjectStatus {
  NotScheduled = 0,
  InProgress = 1,
  OnHold = 2,
  Cancelled = 3,
  Completed = 4,
  AwaitingAssignment = 5,
  NotStarted = 6,
}
export default ProjectStatus;
