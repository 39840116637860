import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFlexTaskMilestonesList,
  selectFlexTaskOrderLinesList,
  setFlexTaskMilestonesTimestampingStatus,
  setFlexTaskOrderLinesTimestampingStatus,
  updateProjectContributorsFromTimestampResult,
  updateProjectsInfoFromTimestampResult,
} from "../features/projectManagement/projectManagementSlice";
import { ResultModel } from "../models/result.model";
import ProjectStatus from "../pages/project-management-pmr/shared/enums/project-status";
import { PmrE2EProjectFlexTaskMilestones } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-milestone";
import ProjectTimestampService from "../services/ProjectTimestampService";
import {
  E2EProjectLineFlexTaskModel,
  E2EProjectLineMilestoneModel,
} from "../shared/models/E2EProject.model";
import { E2EProjectLineECDAdjustmentModel } from "../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import {
  TimestampProjectLineFlexTasksConfirmationModel,
  TimestampProjectLineMilestonesModel,
} from "../shared/models/service-models/TimestampProjectLineMilestones.model";
import { TimestampResultModel } from "../shared/models/service-models/TimestampResultModel";
import useToastr from "./useToastr";
import { authProvider } from "../providers/authProvider";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { GetNewProjectLinesECDAdjustmentsResultModel } from "../shared/models/service-models/GetNewProjectLinesECDAdjustmentsResultModel";
import { GetNewProjectLinesECDAdjustmentsModel } from "../shared/models/service-models/GetNewProjectLinesECDAdjustmentsModel";
import ProjectTaskStatus from "../pages/project-management-pmr/shared/enums/project-task-status";
import { ECDAdjustmentsErrorType } from "../pages/project-management-pmr/shared/enums/ecd-adjustments-error-type";
import PmrE2EProjectOrderlineModel from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-orderline-model";
import ProjectService from "../services/ProjectService";
import { E2EProjectLineTaskMilestoneRequestModel } from "../shared/models/service-models/E2EProjectLineMilestoneRequest.model";
import { TimestampProjectLineMilestonesConfirmationModel } from "../shared/models/service-models/TimestampProjectLineMilestones.model";
import { last, orderBy, uniqBy } from "lodash";
import {
  updateIsTimestampConfirmationLoading,
  selectShowFlexTasks,
} from "../features/projectManagementPmr/projectManagementPmrSlice";
import { E2EInvoicePriceBulkModel } from "../shared/models/E2EInvoicePriceBulkModel.model";

const useProjectTimestamp = () => {
  const logPrefix = `useProjectTimestamp - ${uuidv4()}`;
  const userInfo = authProvider.getAccountInfo();

  const dispatch = useDispatch();
  const { showSuccess, showError, showInfo } = useToastr();
  const [e2eProjectLineECDAdjustments, setE2EProjectLineECDAdjustments] =
    useState<E2EProjectLineECDAdjustmentModel[]>([]);
  const [ecdAdjustmentsDialogVisible, setECDAdjustmentsDialogVisible] =
    useState(false);
  const [
    massTimestampConfirmationVisible,
    setMassTimeStampConfirmationVisible,
  ] = useState(false);
  const [currentMilestoneIds, setCurrentMilestoneIds] = useState<string[]>([]);
  const [currentTaskIds, setCurrentTaskIds] = useState<string[]>([]);
  const [currentProjectLineIds, setCurrentProjectLineIds] = useState<string[]>(
    []
  );
  const [taskIdsWithInvalidTimeEntry, setTaskIdsWithInvalidTimeEntry] =
    useState<string[]>([]);
  const [
    e2EProjectLineWithInvalidInvoicePrice,
    setE2EProjectLineWithInvalidInvoicePrice,
  ] = useState<E2EInvoicePriceBulkModel[]>([]);
  const [timeEntryModalVisible, setTimeEntryModalVisible] = useState(false);
  const [invoicePriceModalVisible, setInvoicePriceModalVisible] =
    useState(false);

  const [currentECDAdjustmentsResult, setCurrentECDAdjustmentsResult] =
    useState<ResultModel<GetNewProjectLinesECDAdjustmentsResultModel> | null>(
      null
    );
  const [milestoneList, setMilestoneList] = useState<
    TimestampProjectLineMilestonesConfirmationModel[]
  >([]);

  const [flexTaskList, setFlexTaskList] = useState<
    TimestampProjectLineFlexTasksConfirmationModel[]
  >([]);

  const flexTaskMilestonesList = useSelector(selectFlexTaskMilestonesList);
  const flexTaskOrderLineList = useSelector(selectFlexTaskOrderLinesList);
  const showFlexTaskOnly = useSelector(selectShowFlexTasks);

  const isMilestoneTimestampable = (
    milestone: E2EProjectLineMilestoneModel | PmrE2EProjectFlexTaskMilestones
  ) => {
    return (
      milestone.status !== ProjectStatus.Completed &&
      milestone.status !== ProjectStatus.Cancelled &&
      !milestone.isTimestamping
    );
  };

  const isTaskTimestampable = (
    task: E2EProjectLineFlexTaskModel | PmrE2EProjectOrderlineModel
  ) => {
    return (
      task.status !== ProjectTaskStatus.Completed &&
      task.status !== ProjectTaskStatus.Cancelled &&
      !task.isTimestamping
    );
  };

  const hasLastMilestoneAndRR = (
    milestone: PmrE2EProjectFlexTaskMilestones
  ) => {
    return milestone.isLastMilestone && milestone.isRevenueRecognition;
  };

  const setTimestampingStatus = (
    milestoneIds: string[] | undefined,
    taskIds: string[] | undefined,
    isTimestamping: boolean
  ) => {
    setMilestonesTimestampingStatus(milestoneIds || [], isTimestamping);
    setTaskTimestampingStatus(taskIds || [], isTimestamping);
  };

  const checkProjectLinesECDAdjustments = async (
    model: GetNewProjectLinesECDAdjustmentsModel,
    showToastOnError = true,
    showTimeEntryModalOnInvalidTimeEntry = true
  ): Promise<
    ResultModel<GetNewProjectLinesECDAdjustmentsResultModel> | undefined
  > => {
    try {
      setCurrentMilestoneIds(model.milestoneIds || []);
      setCurrentTaskIds(model.taskIds || []);

      setTimestampingStatus(model.milestoneIds, model.taskIds, true);

      const result =
        await ProjectTimestampService.getNewProjectLinesECDAdjustments(model);

      if (result?.data.errorType === ECDAdjustmentsErrorType.CoHandlerError) {
        setTimestampingStatus(model.milestoneIds, model.taskIds, false);
        return result;
      }

      setCurrentECDAdjustmentsResult(result);
      setTimestampingStatus(model.milestoneIds, model.taskIds, false);

      setCurrentProjectLineIds(result?.data?.e2EProjectLineIds);
      setCurrentMilestoneIds(result?.data?.e2EProjectLineMilestoneIds);
      setCurrentTaskIds(result?.data?.e2EProjectLineFlexTaskIds);

      if (result.isSuccess) {
        setE2EProjectLineECDAdjustments(result.data?.ecdAdjustments || []);
        dispatch(updateIsTimestampConfirmationLoading(false));

        if (model.includeMilestonePredecessorsForTimestamping) {
          if (model.isMilestoneLoaded) {
            const parameter: E2EProjectLineTaskMilestoneRequestModel = {
              e2EProjectLineIds: result?.data?.e2EProjectLineIds,
              e2EProjectLineMilestoneIds:
                result?.data?.e2EProjectLineMilestoneIds,
            };

            var milestones = await ProjectService.getMilestoneList(parameter);

            if (milestones.data.length > 0) {
              setMassTimeStampConfirmationVisible(true);
              setMilestoneList(milestones.data);
            } else {
              if (result.data?.ecdAdjustments.length > 0)
                setECDAdjustmentsDialogVisible(true);
              else return result;
            }
          } else {
            const parameter: E2EProjectLineTaskMilestoneRequestModel = {
              e2EProjectLineIds: result?.data?.e2EProjectLineIds,
              e2EProjectLineMilestoneIds:
                result?.data?.e2EProjectLineMilestoneIds,
              e2EProjectLineFlexTaskIds:
                result?.data?.e2EProjectLineFlexTaskIds,
            };

            var flexTasks = await ProjectService.getProjectLineFlexTaskList(
              parameter
            );

            if (flexTasks.data.length > 0) {
              setMassTimeStampConfirmationVisible(true);
              setFlexTaskList(flexTasks.data);
            } else {
              if (result.data?.ecdAdjustments.length > 0)
                setECDAdjustmentsDialogVisible(true);
              else return result;
            }
          }
        } else {
          if (result.data?.ecdAdjustments.length > 0) {
            setECDAdjustmentsDialogVisible(true);
          } else {
            setTimestampingStatus(
              result.data.e2EProjectLineMilestoneIds,
              result.data.e2EProjectLineFlexTaskIds,
              true
            );
          }
          return result;
        }
      }

      if (
        showTimeEntryModalOnInvalidTimeEntry &&
        result.data?.errorType === ECDAdjustmentsErrorType.InvalidTimeEntry
      ) {
        setTaskIdsWithInvalidTimeEntry(
          result.data.e2EProjectLineFlexTaskIdsWithInvalidTimeEntry
        );
        dispatch(updateIsTimestampConfirmationLoading(false));
        setTimeEntryModalVisible(true);
      } else if (
        result.data?.errorType === ECDAdjustmentsErrorType.InvoicePriceError
      ) {
        setE2EProjectLineWithInvalidInvoicePrice(
          result.data.e2EInvoicePriceErrors
        );
        dispatch(updateIsTimestampConfirmationLoading(false));
        setInvoicePriceModalVisible(true);
      } else if (showToastOnError && !result.isSuccess) {
        dispatch(updateIsTimestampConfirmationLoading(false));
        showError("Unable to complete timestamp", result.message);
      }
    } catch (error: any) {
      setTimestampingStatus(model.milestoneIds, model.taskIds, false);
      dispatch(updateIsTimestampConfirmationLoading(false));

      showError(
        "Error",
        error?.request?.response || "There is an error processing your request."
      );
    }
  };

  const timestampMilestones = async (
    model: TimestampProjectLineMilestonesModel
  ) => {
    setTimestampingStatus(model.milestoneIds, model.taskIds, true);

    try {
      return await ProjectTimestampService.timestampMilestones(model);
    } catch (error) {
      appInsights?.trackTrace({
        message: `${logPrefix} - registerValidSW - ${JSON.stringify(error)}`,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

  const onTimestampFinished = (result: ResultModel<TimestampResultModel>) => {
    setTimestampingStatus(
      result.data.milestoneIds,
      result.data.flexTaskIds,
      false
    );

    if (result.isSuccess) {
      dispatch(updateProjectsInfoFromTimestampResult(result.data));
      dispatch(updateProjectContributorsFromTimestampResult(result.data));
    }

    if (
      userInfo?.account?.idToken?.preferred_username?.toLowerCase() !==
      result.data.triggeredBy.toLowerCase()
    )
      return;

    if (result.isSuccess)
      if (result.data.isTimestampedThroughTasks) {
        const updatedTasks = result.data.updatedFlexTasks;

        const nonAdhocTaskResult = updatedTasks.filter((task) => {
          return (
            !task.isAdHoc && task.flexTaskStatus == ProjectTaskStatus.Completed
          );
        });

        const adhocTaskResult = updatedTasks.filter((task) => {
          return (
            task.isAdHoc && task.flexTaskStatus == ProjectTaskStatus.Completed
          );
        });

        if (nonAdhocTaskResult.length > 0) {
          result.data.updatedFlexTasks = nonAdhocTaskResult;
          showTimestampedTasksSuccessNotification(result.data, false);
        }

        if (adhocTaskResult.length > 0) {
          result.data.updatedFlexTasks = adhocTaskResult;
          showTimestampedTasksSuccessNotification(result.data, true);
        }

        return;
      } else {
        const nonAdhocMilestoneResult = result.data.updatedMilestones.filter(
          (milestone) => {
            return (
              !milestone.isAdHoc && milestone.status == ProjectStatus.Completed
            );
          }
        );

        const adhocMilestoneResult = result.data.updatedMilestones.filter(
          (milestone) => {
            return (
              milestone.isAdHoc && milestone.status == ProjectStatus.Completed
            );
          }
        );

        if (nonAdhocMilestoneResult.length > 0) {
          result.data.updatedMilestones = nonAdhocMilestoneResult;
          showTimestampedMilestonesSuccessNotification(result.data, false);
        }

        if (adhocMilestoneResult.length > 0) {
          result.data.updatedMilestones = adhocMilestoneResult;
          showTimestampedMilestonesSuccessNotification(result.data, true);
        }

        return;
      }

    if (result.statusCode === 500) {
      showError(
        "Timestamping Failed",
        "Unable to complete the timestamping due to system error. Please try again."
      );

      return;
    }

    showError("Unable to complete timestamp", result.message);
  };

  const showTimestampedTasksSuccessNotification = (
    result: TimestampResultModel,
    isAdHoc: boolean
  ) => {
    let title = ``;
    let message = ``;

    const e2eProjectLineIds = uniqBy(
      result.updatedFlexTasks,
      `e2EProjectLineId`
    ).map((task) => task.e2EProjectLineId);

    const isMultipleOrderline = e2eProjectLineIds.length > 1;
    const taskType = isAdHoc ? "Ad Hoc Task" : "Task";

    if (isMultipleOrderline) title = `Order Line ${taskType}s Completed`;
    else title = `Order Line ${taskType} Completed`;

    let isMassTimestamp = false;

    for (const id of e2eProjectLineIds) {
      const tasks = result.updatedFlexTasks.filter(
        (task) =>
          task.flexTaskStatus == ProjectTaskStatus.Completed &&
          task.e2EProjectLineId == id
      );
      if (tasks.length > 1) {
        isMassTimestamp = true;
        continue;
      }
    }

    if (isMassTimestamp) {
      if (isMultipleOrderline) {
        message = `Your selected order line tasks have been marked as 'Completed' up to their targeted tasks.`;
        if (result.isAdjustECD)
          message += ` Order Line schedules and ECDs adjusted accordingly.`;
      } else {
        const tasks = result.updatedFlexTasks.filter(
          (task) => task.flexTaskStatus == ProjectTaskStatus.Completed
        );
        const tasksOrderby = orderBy(tasks, ["sortOrder"], ["asc"]);
        const task = last(tasksOrderby);
        message = `'<strong>${task?.orderLineDescription}</strong>' has been successfully marked as 'Completed' until '<strong>${task?.flexTaskName}</strong>'.`;
        if (result.isAdjustECD)
          message += ` Order Line schedule and ECD adjusted accordingly.`;
      }
    } else {
      if (isMultipleOrderline) {
        message = `Your selected order line tasks have been marked as 'Completed' up to their targeted tasks.`;
        if (result.isAdjustECD)
          message += ` Order Line schedules and ECDs adjusted accordingly.`;
      } else {
        const task = result.updatedFlexTasks.find(
          (task) => task.flexTaskStatus == ProjectTaskStatus.Completed
        );
        message = `'<strong>${task?.flexTaskName}</strong>' for '<strong>${task?.orderLineDescription}</strong>' has been successfully marked as 'Completed'.`;
        if (result.isAdjustECD)
          message += ` Order Line schedule and ECD adjusted accordingly.`;
      }
    }

    showSuccess(title, message);
  };

  const showTimestampedMilestonesSuccessNotification = (
    result: TimestampResultModel,
    isAdHoc: boolean
  ) => {
    let title = ``;
    let message = ``;

    const e2eProjectLineIds = uniqBy(
      result.updatedMilestones,
      `e2EProjectLineId`
    ).map((task) => task.e2EProjectLineId);

    const isMultipleOrderline = e2eProjectLineIds.length > 1;
    const milestoneType = isAdHoc ? "Ad Hoc Milestone" : "Milestone";

    if (isMultipleOrderline) title = `Order Line ${milestoneType}s Completed`;
    else title = `Order Line ${milestoneType} Completed`;

    let isMassTimestamp = false;

    for (const id of e2eProjectLineIds) {
      const tasks = result.updatedMilestones.filter(
        (task) =>
          task.status == ProjectStatus.Completed && task.e2EProjectLineId == id
      );
      if (tasks.length > 1) {
        isMassTimestamp = true;
        continue;
      }
    }

    if (isMassTimestamp) {
      if (isMultipleOrderline) {
        message = `Your selected order line milestones have been marked as 'Completed' up to their targeted milestones.`;
        if (result.isAdjustECD)
          message += ` Order Line schedules and ECDs adjusted accordingly.`;
      } else {
        const milestones = result.updatedMilestones.filter(
          (task) => task.status == ProjectStatus.Completed
        );
        const milestonesOrderBy = orderBy(milestones, ["sortOrder"], ["asc"]);
        const milestone = last(milestonesOrderBy);
        message = `'<strong>${milestone?.orderLineDescription}</strong>' has been successfully marked as 'Completed' until '<strong>${milestone?.name}</strong>'.`;
        if (result.isAdjustECD)
          message += ` Order Line schedule and ECD adjusted accordingly.`;
      }
    } else {
      if (isMultipleOrderline) {
        message = `Your selected order line milestones have been marked as 'Completed' up to their targeted milestones.`;
        if (result.isAdjustECD)
          message += ` Order Line schedules and ECDs adjusted accordingly.`;
      } else {
        const milestone = result.updatedMilestones.find(
          (task) => task.status == ProjectStatus.Completed
        );
        message = `'<strong>${milestone?.name}</strong>' for '<strong>${milestone?.orderLineDescription}</strong>' has been successfully marked as 'Completed'.`;
        if (result.isAdjustECD)
          message += ` Order Line schedule and ECD adjusted accordingly.`;
      }
    }

    showSuccess(title, message);
  };

  const hideTimestampConfirmationDialog = () => {
    setMassTimeStampConfirmationVisible(false);
  };

  const onCancelECDAdjustmentsPrompt = () => {
    showInfo(
      "Timestamping has been cancelled",
      "Order line(s) timestamping has been cancelled."
    );

    setECDAdjustmentsDialogVisible(false);
  };

  const onCancelMassTimestampConfirmation = () => {
    const toastMessage = showFlexTaskOnly.isOn
      ? "No order line Tasks were marked as 'Completed'."
      : "No order line Milestones were marked as 'Completed'.";

    showInfo("Mass-Timestamping Cancelled", toastMessage);

    hideTimestampConfirmationDialog();
  };

  const showECDAdjustmentsDialog = () => {
    setECDAdjustmentsDialogVisible(true);
  };

  const hideECDAdjustmentsDialog = () => {
    setECDAdjustmentsDialogVisible(false);
  };

  const setMilestonesTimestampingStatus = (
    milestoneIds: string[],
    isTimestamping: boolean
  ) => {
    dispatch(
      setFlexTaskMilestonesTimestampingStatus({
        milestoneIds,
        isTimestamping,
      })
    );
  };

  const setTaskTimestampingStatus = (
    taskIds: string[],
    isTimestamping: boolean
  ) => {
    dispatch(
      setFlexTaskOrderLinesTimestampingStatus({
        taskIds,
        isTimestamping,
      })
    );
  };

  return {
    onCancelECDAdjustmentsPrompt,
    ecdAdjustmentsDialogVisible,
    timestampMilestones,
    e2eProjectLineECDAdjustments,
    checkProjectLinesECDAdjustments,
    hideECDAdjustmentsDialog,
    showECDAdjustmentsDialog,
    currentMilestoneIds,
    currentProjectLineIds,
    isMilestoneTimestampable,
    onTimestampFinished,
    setMilestonesTimestampingStatus,
    currentECDAdjustmentsResult,
    hasLastMilestoneAndRR,
    isTaskTimestampable,
    currentTaskIds,
    timeEntryModalVisible,
    setTimeEntryModalVisible,
    taskIdsWithInvalidTimeEntry,
    setTimestampingStatus,
    massTimestampConfirmationVisible,
    milestoneList,
    flexTaskList,
    hideTimestampConfirmationDialog,
    invoicePriceModalVisible,
    setInvoicePriceModalVisible,
    e2EProjectLineWithInvalidInvoicePrice,
    onCancelMassTimestampConfirmation,
  };
};

export default useProjectTimestamp;
