import { PropsWithChildren } from "react";
import classes from "./CustomerVisibleIndicator.module.scss";

type Props = {
  suppressTooltip?: boolean;
};

const CustomerVisibleIndicator = (props: PropsWithChildren<Props>) => {
  const showCustomerVisibleIndicator =
    process.env.REACT_APP_FEATUREFLAG_CUSTOMER_VISIBLE_INDICATOR === "true";

  if (!showCustomerVisibleIndicator) return <>{props.children}</>;

  const icon = (
    <svg
      className={classes["svg"]}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill=" #1C8A86"
    >
      <path d="M480-304 304-480l176-176 176 176-176 176Zm56 199q-11 11-26 17t-30 6q-15 0-30-6t-26-17L105-424q-11-11-17-26t-6-30q0-15 6-30t17-26l318-318q12-12 26.5-18t30.5-6q16 0 30.5 6t26.5 18l318 318q11 11 17 26t6 30q0 15-6 30t-17 26L536-105Zm-56-87 288-288-288-288-288 288 288 288Z" />
    </svg>
  );

  return (
    <span
      className={classes["container"]}
      title={
        props.suppressTooltip
          ? undefined
          : "This information is visible to customers."
      }
    >
      <span className={classes["children"]}>{props.children}</span> {icon}
    </span>
  );
};

export default CustomerVisibleIndicator;
