import { Button } from "primereact/button";
import ULModal from "../../../../../shared/ul-modal/ULModal";
import { RequireInvoicePrice } from "../../../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import clsx from "clsx";
import classes from "./InvoiceWarningModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExpandedRowsProjectList,
  toggleRowExpansionProjectList,
} from "../../../../../features/projectManagement/projectManagementSlice";
import { delay } from "lodash";
import {
  selectRequireInvoicePrices,
  updateRequireInvoicePrices,
} from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";

interface InvoiceWarningModalProps {
  visible: boolean;
  onHide: () => void;
  data?: RequireInvoicePrice | null;
  onSubmit?: () => void;
}
export default function InvoiceWarningModal({
  onHide,
  visible,
  data,
  onSubmit,
}: InvoiceWarningModalProps) {
  const projectExpandedRows = useSelector(selectExpandedRowsProjectList);
  const requireInvoicePrices = useSelector(selectRequireInvoicePrices);

  const dispatch = useDispatch();

  const handleOnHide = () => {
    onHide();
  };

  const handleOnSubmit = () => {
    const projectId = data?.projectId ?? "";
    const isExpanded = !!projectExpandedRows[projectId];

    if (!isExpanded) {
      dispatch(
        toggleRowExpansionProjectList({
          id: projectId,
        })
      );
    }

    handleOnHide();
    dispatch(
      updateRequireInvoicePrices(
        requireInvoicePrices.map((item) => {
          if (projectId === item.projectId) {
            return {
              ...item,
              lines: item.lines.map(line => ({
                ...line,
                isEditMode: true
              }))
            };
          }
          return item;
        })
      )
    );

    delay(() => {
      if (onSubmit) onSubmit();
    }, 500);
  };
  return (
    <ULModal
      visible={visible}
      onHide={handleOnHide}
      header={"Invoice Price Required"}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="default-cancel-button"
            onClick={handleOnHide}
            label="CANCEL"
          />
          <Button
            className="default-save-button"
            label="GO TO ORDER LINE DETAILS"
            onClick={handleOnSubmit}
          />
        </div>
      }
      style={{
        maxWidth: "750px",
      }}
    >
      <p>
        The FLEX Task associated with the ‘UL: Payment’ milestone for the
        following Order Lines was completed in FLEX without an invoice price. To
        proceed, please enter an invoice price in the Order Line Details tab.
      </p>

      <div className="p-datatable">
        <table className={clsx("p-datatable-table", classes["custom-table"])}>
          <thead className="p-datatable-thead">
            <tr>
              <th style={{
                width: "30%"
              }}>
                <div className="p-column-header-content">
                  FLEX Project Number
                </div>
              </th>
              <th>
                <div className="p-column-header-content">Order Line Number</div>
              </th>
            </tr>
          </thead>
          <tbody className="p-datatable-tbody">
            {data?.lines.map((line, key) => (
              <tr key={key}>
                <td>{line.flexProjectNumber}</td>
                <td>{line.orderLineDescription}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ULModal>
  );
}
