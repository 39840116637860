import { Button } from "primereact/button";
import classes from "./GenerateStatusReportDialog.module.scss";
import { useEffect, useState } from "react";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import ProjectService from "../../../../../services/ProjectService";
import useToastr from "../../../../../hooks/useToastr";

import clsx from "clsx";
import saveAs from "file-saver";
import { PmrE2EProjectFullViewModel } from "../../interfaces/pmr-e2e-project-full-view-model";
import { useSelector } from "react-redux";
import {
  selectRefinerState,
  selectShowFlexTasks,
} from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import { transformOptionLabelValueToValue } from "../../../../../utils/helpers/object.helpers";
import moment from "moment";

enum ReportType {
  "GMAWB Internal Report" = 1,
  "GMAWB Customer Report" = 2,
}

enum ContentType {
  "FLEX Task Only" = 1,
  "GMAWB Milestone Only" = 2,
  "Tasks and Milestones included" = 3,
}

interface SingleProjectViewPropsModel {
  projectName: string;
  projectFullViewData: PmrE2EProjectFullViewModel | undefined;
}
interface GenerateStatusReportDialogProps {
  onClose: () => void;
  visible: boolean;
  singleProjectViewData?: SingleProjectViewPropsModel | null;
}
export const GenerateStatusReportDialog = (
  props: GenerateStatusReportDialogProps
) => {
  const { showError, showSuccess } = useToastr();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const [selectedContentType, setSelectedContentType] =
    useState<ContentType | null>(null);
  const [includeDate, setIncludeDate] = useState(false);

  const refinerState = useSelector(selectRefinerState);
  const showFlexTaskOnly = useSelector(selectShowFlexTasks);

  const modalHeader = "Generate Status Report";
  const reportTypes = [
    {
      name: ReportType[1],
      value: ReportType["GMAWB Internal Report"],
    },
    {
      name: ReportType[2],
      value: ReportType["GMAWB Customer Report"],
    },
  ];

  const contentTypes = [
    {
      name: ContentType[1],
      value: ContentType["FLEX Task Only"],
    },
    {
      name: ContentType[2],
      value: ContentType["GMAWB Milestone Only"],
    },
    {
      name: ContentType[3],
      value: ContentType["Tasks and Milestones included"],
    },
  ];

  const footerTemplate = (
    <>
      <Button onClick={() => onClose()} className={classes["btn-cancel"]}>
        CANCEL
      </Button>

      <Button
        onClick={() => generateReport()}
        className={classes["btn-confirm"]}
        disabled={
          isProcessing ||
          selectedReportType == null ||
          selectedContentType == null
        }
      >
        GENERATE REPORT
      </Button>
    </>
  );

  const bodyTemplate = (
    <>
      {!isProcessing && (
        <div className={`${classes["body"]}`}>
          <p className={`${classes["text"]}`}>
            The report content is determined by the filters currently applied in
            the Projects page.
          </p>

          <Dropdown
            style={{ width: "100%" }}
            className={`${classes["dropdown"]}`}
            options={reportTypes}
            optionLabel="name"
            optionValue="value"
            value={selectedReportType}
            placeholder={"-- Select Report Type --"}
            onChange={(ev) => {
              setSelectedReportType(ev.value);
              setSelectedContentType(
                ContentType["Tasks and Milestones included"]
              );
            }}
            dropdownIcon={clsx("material-icons", classes["drop-down-icon"])}
          />

          <Dropdown
            style={{ width: "100%" }}
            className={`${classes["dropdown"]}`}
            options={contentTypes}
            optionLabel="name"
            optionValue="value"
            value={selectedContentType}
            placeholder={"-- Select Content Type --"}
            onChange={(ev) => setSelectedContentType(ev.value)}
            dropdownIcon={clsx("material-icons", classes["drop-down-icon"])}
          />

          <div className="p-field-checkbox">
            <Checkbox
              inputId={"include-dates"}
              name={"include-dates"}
              checked={includeDate}
              onChange={() => setIncludeDate(!includeDate)}
            />
            <label htmlFor={"include-dates"}> Include Dates</label>
          </div>
        </div>
      )}

      {isProcessing && (
        <div className={classes["loading-div"]}>
          <div>
            <svg className="ul-progressCircle -medium">
              <circle
                className="ul-progressCircle__outer"
                cx="50%"
                cy="50%"
                r="35%"
              />
              <circle
                className="ul-progressCircle__inner"
                cx="50%"
                cy="50%"
                r="35%"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );

  const generateReport = async () => {
    let requestModel = {};
    if (props.singleProjectViewData) {
      requestModel = {
        includeDates: includeDate,
        projectStatusReportType: selectedReportType,
        projectStatusContentType: selectedContentType,
        skip: 0,
        take: 1,
        sort: "createdDateUtc",
        sortBy: "DESC",
        searchString: props.singleProjectViewData?.projectName,
        showFlexTask: props.singleProjectViewData?.projectFullViewData
          ? props.singleProjectViewData?.projectFullViewData?.showFlexTasks
          : true,
        ...props.singleProjectViewData?.projectFullViewData?.refiners,
        isFirstAndExactProjectName: true,
      };
    } else {
      requestModel = {
        includeDates: includeDate,
        projectStatusReportType: selectedReportType,
        projectStatusContentType: selectedContentType,
        skip: 0,
        take: 0,
        sort: "createdDateUtc",
        sortBy: "DESC",
        ...transformOptionLabelValueToValue(refinerState.value),
        showFlexTask: showFlexTaskOnly.isOn,
      };
    }
    setIsProcessing(true);

    try {
      const result = await ProjectService.downloadStatusReport(requestModel);
      if (result) {
        const fileName = `GMAWB_${
          selectedReportType == ReportType["GMAWB Customer Report"]
            ? "Customer"
            : "Internal"
        }_Report_${moment(new Date()).format("YYYYMMDD")}.xlsx`;
        saveAs(result, fileName);
        showSuccess(
          "Report Generated",
          "The status report has been successfully generated and downloaded."
        );
      } else {
        showError(
          "Report Generation Failed",
          "There's an issue with generating your report. Try again. Contact the support team if the issue persists."
        );
      }
    } catch (err) {
      showError(
        "Report Generation Failed",
        "There's an issue with generating your report. Try again. Contact the support team if the issue persists."
      );
    } finally {
      setIsProcessing(false);
      onClose();
    }
  };

  const onClose = () => props.onClose();

  useEffect(() => {
    setIsModalVisible(props.visible);
  }, [props.visible]);

  return (
    <ConfirmationModalV2
      className={classes["generate-status-modal"]}
      bodyClassName={classes["modal-body"]}
      style={{ width: "50vw" }}
      visible={isModalVisible}
      onClose={onClose}
      headerDisplay={modalHeader}
      customFooter={footerTemplate}
      confirmationMessageHTML={bodyTemplate}
    ></ConfirmationModalV2>
  );
};

export default GenerateStatusReportDialog;
