import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./ProjectContributorBulkAssignmentErrorModal.module.scss";
import { ProjectContributorBulkAssignmentErrorModel } from "./ProjectContributorBulkAssignmentErrorModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

interface ProjecProjectContributorBulkAssignmentModalProps {
  onClose: () => void;
  visible: boolean;
  projectContributorBulkAssignmentErrorModel: ProjectContributorBulkAssignmentErrorModel[];
  isFlexTaskOnly: boolean;
}

const ProjectContributorBulkAssignmentErrorModal = (
  props: ProjecProjectContributorBulkAssignmentModalProps
) => {
  const headerTemplate = () => {
    return (
      <h3 className={classes["dialog-header__title"]}>
        CO Handler Assignment Required
      </h3>
    );
  };

  const footerTemplate = () => {
    return (
      <div className={classes["dialog-footer"]}>
        <Button
          onClick={props.onClose}
          className={classes["btn-ok"]}
          label={"OK"}
        />
      </div>
    );
  };

  const coHandlerErrorTemplate = (
    <>
      <label className={classes["verbiage"]}>
        The following order lines require a CO Handler assignment and cannot be
        included in the bulk-update:
      </label>

      <table className="ul-table">
        <thead>
          <tr>
            <th>GMAWB Project Name</th>
            <th>Order Line Number</th>
            <th>{props.isFlexTaskOnly ? "Task" : "Milestone"} Name</th>
          </tr>
        </thead>

        <tbody>
          {props.projectContributorBulkAssignmentErrorModel.map((item) => (
            <tr>
              <td>{item.e2EProjectName}</td>
              <td>{item.orderLineDescription}</td>
              <td>{item.taskOrMilestoneName}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <label className={classes["verbiage"]}>
        Please exclude these from your selection and assign a CO Handler
        separately. <FontAwesomeIcon icon={faUserPlus} />
      </label>
    </>
  );

  return (
    <Dialog
      className={classes["dialog"]}
      style={{ width: "60rem" }}
      visible={props.visible}
      onHide={props.onClose}
      header={headerTemplate}
      footer={footerTemplate}
    >
      <div className={classes["dialog-body"]}>{coHandlerErrorTemplate}</div>
    </Dialog>
  );
};

export default ProjectContributorBulkAssignmentErrorModal;
